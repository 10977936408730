import {
  WithStatelessDatasFetchingByParameters,
  WithStatelessDatasFetching,
} from "../../hocs";
import {
  ApiList,
  FolderLight,
  FoodchainLight,
  GetFoodchains,
} from "../../models";
import {
  GetSpecifications,
  SpecificationLight,
} from "../../models/specification-contract";
import { SpecificationTilesListProps } from ".";
import {
  specificationSubService,
  folderSubService,
  foodchainSubService,
} from "../../services";
import { LocalLoader } from "../../components";
import {
  SpecificationsSidebarProps,
  FoodchainListProps,
} from "./specifications-page-type";

export const specificationEnhancer = WithStatelessDatasFetchingByParameters<
  ApiList<SpecificationLight[]>,
  SpecificationTilesListProps,
  GetSpecifications
>(
  specificationSubService.getSpecifications,
  LocalLoader,
  "error",
  "specifications",
  ["name", "folder", "pk"]
);

export const foodchainEnhancer = WithStatelessDatasFetchingByParameters<
  ApiList<FoodchainLight[]>,
  FoodchainListProps,
  GetFoodchains
>(foodchainSubService.getFoodchains, LocalLoader, "error", "foodchains", [
  "name",
]);

export const folderEnhancer = WithStatelessDatasFetching<
  ApiList<FolderLight[]>,
  SpecificationsSidebarProps
>(folderSubService.getFolders, LocalLoader, "error", "folders");
