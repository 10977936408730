import React from 'react';

import { useTranslation } from 'react-i18next';
import styles from './batch.module.css';
import { BatchProps } from './batch-types';
import enhancer from './batch-enhancer';
import { ContainerLayout, TitleLayout } from '../../../layouts';
import { GenericTable } from '../../../components/table';
import { FlowsHeader } from '../../../constants';
import { useLocalFilter } from '../../../hooks';
import { batchSubService } from '../../../services';
import { PageLoader } from '../../../components/page-loader';
import { toastHandler } from '../../../utils';
import { BlockButton, ClassicButton, FilterButton } from '../../../components';
import i18next from 'i18next';
import { posthogEvents } from '../../../utils/posthog';

interface UserParam {
  prop : string,
  value: string
}
const InnerBatchPage: React.FC<BatchProps> = React.memo(({ batch }: BatchProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false)
  type DataChoice = "all_flows" | "next_flows";
  const [selectedData, setSelectedData] = React.useState<DataChoice>('all_flows');
  const [userParams, setUserParams] = React.useState<UserParam[]>([]);
  posthogEvents.triggerPageView();
  
  const {
    header, data, manageOrdering, manageSearch,
  } = useLocalFilter(FlowsHeader, batch[selectedData], '-flow_date');


  const [donwloadModal, setDownloadModal] = React.useState<boolean>(false)

  const updateParams = (newProp : string, newvalue : string) => {
    let initalParams = userParams;
    if(initalParams.filter(el => el.prop === newProp).length > 0){
      initalParams.forEach((el : UserParam) => {
       if(el.prop === newProp){
          if(newProp === 'ordering'){
                el.value = el.value === newvalue ? `-${newvalue}`:  newvalue;
          }else{
                el.value = newvalue
          }   
       }
      })
    }else{
      initalParams = [...initalParams, {prop : newProp, value : newvalue}]
    }
    setUserParams(initalParams)
  }

  const getDownload = async () => {
    setLoading(true)
    try {
    const response = await batchSubService.downloadBatchHistory(batch.pk, 'csv', selectedData, userParams);
    const url: Blob = new Blob(['\ufeff' + response], {type: 'data:text/csv;charset=utf-8'});
    const blobUrl = window.URL.createObjectURL(url)
    const templink = document.createElement('a');
    templink.setAttribute('download', `extract_${batch.name}.csv`);
    templink.href=blobUrl;
    document.body.appendChild(templink);
    templink.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl); 
    }, 100)
    } catch(err){
      toastHandler(t('tracking.batchs.error_download'), 'error');
      console.log('error', err);
    }
    setLoading(false)
    setDownloadModal(false)
  };

const handleManageOrdering = ( orderer : string) => {
  updateParams('ordering', orderer)
  manageOrdering(orderer);
}

const handleManageSearch = (searchProps : string, searchValue : string) => {
  const lang = i18next.language;
  updateParams(searchProps, searchValue)
  manageSearch(searchProps, searchValue, lang);
}
  


const handleNextFlow = () => {
  setLoading(true)
  setSelectedData('next_flows')
  setTimeout(() =>(setLoading(false), 1000 ))
}

const handleAllFlows = () => {
  setLoading(true)
  setSelectedData('all_flows')
  setTimeout(() =>(setLoading(false), 1000 ))
}

  return (
    <ContainerLayout>
          {loading && <PageLoader/>}
      <div className={styles.headerContainer}>
        <div>
          <TitleLayout inverted>{`${t('tracking.batchs.title')} : ${batch.name}`}</TitleLayout>
          <BlockButton text={batch.audit ? t(`tracking.batchs.compliant`) : t(`tracking.batchs.nonCompliant`)} warning={!batch.audit} />
        </div>
        

      </div>
      <div className={styles.informations}>
        <div>
          <FilterButton text={t('tracking.batchs.all_flows')} onClick={handleAllFlows}  selected={selectedData === 'all_flows'}/>
          <FilterButton text={t('tracking.batchs.next_flows')} onClick={handleNextFlow}  selected={selectedData === 'next_flows'} style={{marginLeft: '10px'}}/>
        </div>
        <div className={styles.downloadContainer}>
          <ClassicButton text={t('tracking.batchs.download')} onClick={getDownload} secondary/>
        </div>
      </div>
      <div className={styles.tableContainer}>
        { data
          && <GenericTable data={data} header={header} linkType="flows" clickTable endFetching manageOrdering={handleManageOrdering} manageSearch={handleManageSearch} />}

      </div>
    </ContainerLayout>
  );
});

export const TrackingBatchPage = enhancer(InnerBatchPage);
