export const dateIsExpired = (date: Date): boolean => {
  if (!date || typeof date !== 'string') {
    return false;
  }

  const expireAt = new Date(date);
  const now = new Date();
  return expireAt < now;
};

export const dateIsExpiredBuilder = (date: Date) => () => !!date && !dateIsExpired(date);

export const formatTechDate = (date: Date | string): string => {
  if (!date) {
    return '';
  }

  let dateVal: Date;
  if (typeof date === 'string') {
    dateVal = new Date(date);
  } else {
    dateVal = date;
  }

  return [
    dateVal.getFullYear(),
    `0${dateVal.getMonth() + 1}`.slice(-2),
    `0${dateVal.getDate()}`.slice(-2),
  ].join('-');
};

export const formatISODate = (date: Date | string): string => {
  if (!date) {
    return '';
  }

  let dateVal: Date;
  if (typeof date === 'string') {
    dateVal = new Date(date);
  } else {
    dateVal = date;
  }

  return dateVal.toISOString()
};

export const formatDate = (date: Date | string, lang?: string): string => {
  if (!date) {
    return '';
  }

  let dateVal: Date;
  if (typeof date === 'string') {
    dateVal = new Date(date);
  } else {
    dateVal = date;
  }

  // TODO gerer la langue
  return dateVal.toLocaleDateString(lang || 'fr');
};

export const formatTime = (date: Date | string, lang?: string): string => {
  if (!date) {
    return '';
  }

  let dateVal: Date;
  if (typeof date === 'string') {
    dateVal = new Date(date);
  } else {
    dateVal = date;
  }

  // TODO gerer la langue
  return dateVal.toLocaleTimeString(lang || 'fr');
};

export const handleDateSearch = (value: string, lang?: string) : string => {
  //console.log('handleDate', value, lang)
  const regex = /\//gi;
  let verifValue = value.replace(regex, '-').split('-') 
if(lang === 'en'){
        switch(verifValue.length){
          case 2 : {
            return `${verifValue[1]}-${verifValue[0]}`;
            break;
          };
          case 3 : {
            return `${verifValue[2]}-${verifValue[0]}-${verifValue[1]}`;
            break
          };
          default :{
            return value;
            break
          }
        }
    }

return verifValue.reverse().join('-');
}
