import React from 'react';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import {
  ChangePasswordFormProps,
  ChangePasswordProps,
} from './change-password-form-type';
import styles from './change-password-form-component.module.css';
import { PasswordInputControl } from '../form-controls';

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  model,
  onSuccess,
  errorMessage,
}: ChangePasswordFormProps) => {
  const { t } = useTranslation();

  const hint = "composé d'au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 chiffre et un des signes suivants : @ # $ % ^ & + = ! ? / \\ - _ ~ * , ; :";

  return (
    <>
      <Formik<ChangePasswordProps>
        onSubmit={onSuccess}
        initialValues={model}
        render={(props) => (
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.formContainer}>
              {/* <div className={styles.hint}>{hint}</div> */}
              <div className={styles.formColumn}>
                <div className={styles.formLine}>
                  <div className={styles.pwdContainer}>
                    <PasswordInputControl
                      name="oldPassword"
                      required
                      placeholder={t('form.authentication.oldPassword')}
                    />
                  </div>
                </div>
                <div className={styles.formLine}>
                  <div className={`${styles.pwdContainer} ${styles.new}`}>
                    <PasswordInputControl
                      name="newPassword"
                      required
                      placeholder={t('form.authentication.newPassword')}
                      // hint={hint}
                      notEqualValue={props.values.oldPassword}
                      notEqualValueMessage="L'ancien et le nouveau mot de passe doivent être différents"
                    />
                  </div>
                  <div className={styles.pwdContainer}>
                    <PasswordInputControl
                      name="newPasswordConfirm"
                      required
                      placeholder={t('form.authentication.newPasswordConfirm')}
                      equalValue={props.values.newPassword}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.formActions}>
              {/* <SubmitButton text="Je valide mon nouveau mot de passe" disabled={!props.isValid} /> */}
              <button type="submit" disabled={!props.isValid}>
                {t('authentication.actions.changePasswordSubmit')}
              </button>
            </div>
          </form>
        )}
      />
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};
