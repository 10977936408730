import i18next, { TFunction } from 'i18next';
import React from 'react';
import { Table } from 'semantic-ui-react';
import {
  DataTableTransform, OwnerRead, ActorRole, HeaderColumn, DataAlertTransform,
} from '../../../models';
import { formatDate, dateIsExpired } from '../../../utils';
import styles from '../body/body-component.module.css';
import { criticalityDictionnary } from '../../../constants';
import { BlockButton } from '../../buttons';

export const formatTableData = (t: TFunction, dataType: DataTableTransform | DataAlertTransform, data?: any, warning?: boolean, placeholder? : boolean): JSX.Element => {
  if (data === undefined) {
    return <></>;
  }
  switch (dataType) {
    case 'boolean': {
      return data ? <Table.Cell style={{border: warning ? '1px solid red' : undefined, color: placeholder? 'grey' : ''}}>{t('table.utils.true')}</Table.Cell> : <Table.Cell style={{border: warning ? '1px solid red' : undefined, color: placeholder? 'grey' : ''}}>{t('table.utils.false')}</Table.Cell>;
    }
    case 'date': {
      return <Table.Cell style={{border: warning ? '1px solid red' : undefined}}>{formatDate(data, i18next.language)}</Table.Cell>;
    }
    case 'expirationDate': {
      return (
        <Table.Cell style={{border: warning ? '1px solid red' : undefined, color: placeholder? 'grey' : ''}}>
          <div className={styles.criticalityContainer}>

            <div className={styles.criticalityLabel}>
              {formatDate(data, i18next.language)}
            </div>
            {
        dateIsExpired(data)
  && <img src={criticalityDictionnary.critical} className={styles.criticalityPicto} />
      }
          </div>
        </Table.Cell>
      );
    }
    case 'compliant': {
      return data ? <Table.Cell style={{border: warning ? '1px solid red' : undefined, color: placeholder? 'grey' : ''}}><BlockButton text={t('table.utils.compliant')} /></Table.Cell> : <Table.Cell style={{border: warning ? '1px solid red' : undefined}}><BlockButton warning text={t('table.utils.nonCompliant')} /></Table.Cell>;
    }
    case 'owner': {
      return <Table.Cell style={{border: warning ? '1px solid red' : undefined, color: placeholder? 'grey' : ''}}><div></div>{data.name}</Table.Cell>;
    }
    case 'owners': {
      return <Table.Cell style={{border: warning ? '1px solid red' : undefined, color: placeholder? 'grey' : ''}}>{data && data.length > 0 && data.map((el: OwnerRead, key: number) => <div key={key}>{el.name}</div>)}</Table.Cell>;
    }
    case 'role': {
      return <Table.Cell style={{border: warning ? '1px solid red' : undefined, color: placeholder? 'grey' : ''}}>{data && data.length > 0 && data.map((el: ActorRole, key: number) => <div key={key}>{`${el.specifications}: ${el.role.join()}`}</div>)}</Table.Cell>;
    }
    case 'category': {
      return <Table.Cell style={{border: warning ? '1px solid red' : undefined, color: placeholder? 'grey' : ''}}>{t(`table.utils.certificate.${data}`)}</Table.Cell>;
    }
    default: {
      return <Table.Cell style={{border: warning ? '1px solid red' : undefined, color: placeholder? 'grey' : ''}}>{data}</Table.Cell>;
    }
  }
};

export function checkExpiredDate <T>(data: T, header: HeaderColumn<T>[]): boolean {
  for (const head of header) {
    if (head.dataTransform === 'expirationDate' && dateIsExpired(data[head.columnName] as unknown as Date)) {
      return true;
    }
  }
  return false;
}
