import { ApiList } from "../../models";
import { GetSpecifications, SpecificationDetails, SpecificationInfos, GetSpecification, SpecificationLight, GetSpecificationInfo } from "../../models/specification-contract";
import { backendTokenService, securedRestClientContracts } from "../../backend";
import { buildUrlParameters } from "../../utils";

export const specificationSubService = {
    getSpecifications: async ({name, limit, ordering, folder}: GetSpecifications) => {
        const params: {prop: string; value: string | number | undefined}[] = [
            { prop: 'name', value: name },
            { prop: 'limit', value: limit },
            { prop: 'ordering', value: ordering },
            { prop: 'folder', value: folder },
          ];
      
        const paramUrl = buildUrlParameters(params);
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/specifications_light/?${paramUrl}`;
        const response = await securedRestClientContracts.get<ApiList<SpecificationLight[]>>(requestUrl);
        console.log(response.data);
        return response.data;
    },

    getSpecification: async ({id}: GetSpecification) => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/specifications/${id}`;
        const response = await securedRestClientContracts.get<SpecificationDetails>(requestUrl);
        console.log(response.data);
        return response.data;
    },

    getSpecificationInfos: async ({pk}: GetSpecificationInfo) => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/specifications_informations/${pk}`;
        const response = await securedRestClientContracts.get<SpecificationInfos>(requestUrl);
        console.log(response.data);
        return response.data;
    },

    createSpecification: async (specification: SpecificationDetails) => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/specifications/`;
        const response = await securedRestClientContracts.post<SpecificationDetails>(requestUrl, specification);
        console.log(response.data);
        return response.data;
    },

    editSpecification: async (specification: SpecificationDetails) => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/specifications/${specification.pk}`;
        const response = await securedRestClientContracts.put<SpecificationDetails>(requestUrl, specification);
        console.log(response.data);
        return response.data;
    },

    deleteSpecification: async (id: number) => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/specifications/${id}`;
        const response = await securedRestClientContracts.delete(requestUrl);
        console.log(response.data);
        return response.data;
    },
}