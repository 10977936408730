import React, { ComponentType } from 'react';
import querystring from 'query-string';
import { useLocation } from 'react-router-dom';
import { parse } from '../../utils';

export const WithQuerystringReading = <InputParams extends {}>() => <P extends InputParams>(Component: ComponentType<P>) => {
    type WrapperType = Omit<P, keyof InputParams>;
    const WithQuerystringReadingComponent: React.FC<WrapperType> = (
      props: WrapperType,
    ) => {
      const location = useLocation();
      const search = querystring.parse(location.search) as InputParams;
      const params = { ...(props as any).match.params };
      const innerProps = { ...props, ...parse(search), ...params };
      return <Component {...(innerProps as P)} />;
    };

    return WithQuerystringReadingComponent;
};
