
import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './alert-group.module.css';
import { AlertGroupProps } from './alert-group-types';
import { ContainerLayout, TitleLayout } from '../../../layouts';
import { SimpleJson, HeaderColumn, AlertGroup, GetAllAlertGroups, ApiList, AlertGroupLight } from '../../../models';
import { AlertGroupList } from './alert-group-list';
import { alertSubService } from '../../../services';
import { TableProps } from '../../../components/table/table-type';
import { WithLazyloadingDatasFetching } from '../../../hocs';
import { useParams } from '../../../hooks';
import { AlertGroupHeader } from '../../../constants';
import { AlertGroupFilter } from './alert-group-filter';
import { Dimmer, Loader } from 'semantic-ui-react';
import { posthogEvents } from '../../../utils/posthog';

interface InnerAlertGroupPageProps {
  results : ApiList<AlertGroup[]>;
  mail: string
}

export const AlertGroupPage: React.FC<InnerAlertGroupPageProps> = React.memo((props) => {
  const { t } = useTranslation();
  const initParams:SimpleJson = {ordering: '-first_importation_datetime', limit: '15'};
  const initHeader:HeaderColumn<AlertGroupLight>[] = AlertGroupHeader;
  const [searchParams, setSearchParams] = React.useState('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const {
    params, header, manageOrdering, manageSearch, focused
  } = useParams(initHeader, initParams);
  posthogEvents.triggerPageView();
  posthogEvents.triggerEvent({name : 'goTo', datas: {'Page': 'alertGroup', 'trafficSource': props.mail ? 'mail' : 'direct'}})



  const handleSearch = async (search:string) => {
    posthogEvents.triggerSearch('alert', search);
    setSearchParams(search)

  }

  const EnhancedList = WithLazyloadingDatasFetching<AlertGroupLight, TableProps<AlertGroupLight>>(
    alertSubService.getAllAlertGroups, 'data', {...params, search: searchParams}, parseInt(params.limit),
  )(AlertGroupList);

  return (
    <ContainerLayout style={{ backgroundColor: 'transparent' }}>
      <div className={styles.header}>
      <TitleLayout inverted>{t('alerts.title')}</TitleLayout>
      <div className={styles.filterWrapper}>
        <AlertGroupFilter handleSearch={handleSearch} manageSearch={manageSearch} header={header} focused={focused} />
      </div>
      </div>
      <div className={styles.tableWrapper}>
        {
          isLoading && <Dimmer active inverted>
              <Loader size="massive" />
          </Dimmer>
        }

        {!isLoading && 
        <EnhancedList data={[]} header={header}/>
        }
      </div>
    </ContainerLayout>
  );
}); 