import * as React from 'react';

import { Link, useHistory } from 'react-router-dom';
import styles from './menu-component.module.css';
import { GenericList } from '..';
import { MenuItem, HeaderItem } from './MenuItem';
import CF from '../../../assets/images/CF.png';
import { Logout } from '../logout';
import { LanguageHandler } from '../language-handler';
import { header, specificationHeader } from '../../constants';
import { OnBeforeUnloadingContext } from '../../contexts';
import { backendTokenService } from '../../backend';

export const Menu: React.FC = () => {

  const token = backendTokenService.getToken();

  const [active, setActive] = React.useState([false, false]);
  const history = useHistory()

  const handleActiveLogout = (logoutActive: boolean) => {
    setActive([logoutActive, false]);
  };

  const handleActiveLang = (langActive: boolean) => {
    setActive([false, langActive]);
  };

  const handleClose = () => {
    setActive([false, false]);
  };

  const {activeContext, setNext, setAction} = React.useContext(OnBeforeUnloadingContext);

  const handleRedirect = () => {
    history.push('/home'); 
  }

  const handleClick = () => {
    if (activeContext) {
      setAction(true);
      setNext(() => handleRedirect);
    } else {
      handleRedirect();
    }
  }
  

  return (
    <div className={styles.header} onMouseLeave={handleClose} >
      <div className={styles.logoContainer} onClick={handleClick} >
        <img src={CF} className={styles.logo} />
      </div>
      <GenericList
        items={token?.contracts_token ? specificationHeader : header}
        itemRenderer={(childElement: HeaderItem, key: number) => <MenuItem key={key} element={childElement} />}
      />
      <Logout active={active[0]} setActive={handleActiveLogout} />
      <LanguageHandler active={active[1]} setActive={handleActiveLang} />
    </div>
  );
};
