import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './table-form.module.css';
import { ClassicButton } from '../../buttons';
import { GenericTable } from '../../table';
import { TableFormProps } from '.';

export function TableForm <T>({
  data,
  header,
  loadMoreItems,
  loading,
  endFetching,
  error,
  manageOrdering,
  manageSearch,
  focused,
  model,
  onSave,
  errorMessage,
  onCancel,
  onNext,
  onPrevious,
  include,
  blocking,
  multiple,
  handleAll,
}: TableFormProps<T>) {
  const { t } = useTranslation();

  const [item, setItem] = React.useState<number[]>(model);

  const handleSave = () => {
    if (blocking && item.length === 0) {
      return;
    }
    if (!multiple && item.length > 1) {
      return;
    }
    onSave && onSave(item);
  };

  const handleItemsList = React.useCallback((id: number) => {
    if (item && item.includes(id) && (!blocking || (multiple && item.length > 1))) {
      setItem(item.filter((el) => el !== id));
    } else if (!item.includes(id)) {
      setItem(multiple ? [...item.filter((el) => el !== -1), id] : [id]);
    }
  }, [item]);

  const handleNext = () => {
    onNext && onNext(item);
  };

  return (
    <div className={styles.form}>
      <div className={styles.actorsCheckWrapper}>
        <GenericTable
          data={data}
          header={header}
          checkTable
          handleAll={handleAll}
          loadMoreItems={loadMoreItems}
          loading={loading}
          endFetching={endFetching}
          error={error}
          manageOrdering={manageOrdering}
          manageSearch={manageSearch}
          focused={focused}
          handleList={handleItemsList}
          checkedList={item}
        />
      </div>
      <div className={styles.formActions}>
        <div className={styles.cancelActions}>
          {
            onCancel
              ? <ClassicButton text={t(`wizard.${include}.cancel`)} onClick={onCancel} warning />
              : <div>&nbsp;</div>
          }

        </div>
        <div className={styles.navigationActions}>
          <div className={styles.backNavigation}>
            {
            onPrevious
              ? <ClassicButton text={t(`wizard.${include}.back`)} onClick={onPrevious} inverted />
              : <div>&nbsp;</div>
          }
          </div>
          <div className={styles.nextNavigation}>

            {
            onNext
              ? (
                <ClassicButton
                  text={t(`wizard.${include}.next`)}
                  onClick={handleNext}
                  inverted
                  disabled={!!(blocking && item[0] === -1)}
                />
              )
              : <div>&nbsp;</div>
          }
          </div>
        </div>
        <div className={styles.submitActions}>
          {
            onSave
              ? <ClassicButton text={t(`wizard.${include}.submit`)} onClick={handleSave} disabled={!!(blocking && item[0] === -1)} />
              : <div>&nbsp;</div>
          }
        </div>
      </div>

      {errorMessage && (
        <span className={styles.error}>{errorMessage}</span>
      )}
    </div>
  );
}
