import React, { useEffect } from 'react';
import { handleDateSearch } from '..';
import { SimpleJson, HeaderColumn } from '../models';
import { posthogEvents } from '../utils/posthog';

export function useParams <T>(initHeader: HeaderColumn<T>[], initParams: SimpleJson = {}) {
  const [params, setParams] = React.useState<SimpleJson>(initParams);
  const [header, setHeader] = React.useState<HeaderColumn<T>[]>(initHeader);
  const [focused, setFocused] = React.useState<keyof T | undefined>(undefined);

  useEffect(() => {
    setHeader(initHeader);
    setParams(initParams);
  }, [initHeader]);


  const manageOrdering = (orderer: string) => {
    // console.log('ordering sur : ', orderer);
    if (params.ordering === orderer) {
      posthogEvents.triggerEvent({name: 'filter', datas: {'order' : `-${orderer}`}});
      setParams({ ...params, ordering: `-${orderer}` });
    } else {
      posthogEvents.triggerEvent({name: 'filter', datas: {'order' : orderer} });
      setParams({ ...params, ordering: orderer });
    }
  };

  const manageSearch = (searchProp: string, searchValue: string, lang?: string) => {
    // console.log('search sur : ', searchProp, searchValue, params);
    posthogEvents.triggerSearch(searchProp, searchValue);
    if (params[searchProp] !== searchValue) {
      setHeader(header.map((el) => {
        if (el.searchProp === searchProp) {
          setFocused(el.columnName);
          return { ...el, searchValue };
        }
        return el;
      }));
      setParams({ ...params, [searchProp]: searchProp.includes("date") ? handleDateSearch(searchValue, lang) : searchValue});
    }
  };
  return {
    params, header, manageOrdering, manageSearch, focused
  };
}

