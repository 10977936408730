import React from 'react';
import styles from './alert-header.module.css';
import { ClassicButton} from '../../../components'
import { useTranslation } from 'react-i18next';

interface AlertRetrackModalProps {
    updateRetrackModal: () => void;
    handleRetrackChoice: () => void;
}

export const AlertRetrackModal = ({updateRetrackModal, handleRetrackChoice} : AlertRetrackModalProps) => {
    const {t} = useTranslation();

    return <div className={styles.retrackModal}>
        <div className={styles.retrackTitle}>{t('alerts.retrack_modal.title')}</div>
        <div className={styles.retrackContent}>
            <p>{t('alerts.retrack_modal.warning')}</p>
            <p>{t('alerts.retrack_modal.question')}</p>
            
            </div>
        <div className={styles.retrackButtonsContainer}>
        <ClassicButton text={t('alerts.retrack_modal.retrack')} onClick={handleRetrackChoice}/>
        <ClassicButton warning text={t('alerts.retrack_modal.cancel')} onClick={updateRetrackModal}/>
        </div>
    </div>
}