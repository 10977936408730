import React from 'react';
import styles from './alert-fix.module.css';
import { Input } from 'semantic-ui-react';
import { ClassicButton, CommentInput } from '../../../components';
import { AlertFixProps } from './alert-fix-types';
import { StatusType } from '../../../models';
import { useTranslation } from 'react-i18next';

interface AlertStatusModalProps {
    closeStatusModal : () => void;
    updateAlertCluster: (pk: number, comment : string,  users_at: number[]) => void;
    status : StatusType | undefined;
    pk?: number;
}

export const AlertStatusModal = (props : AlertStatusModalProps) => {
    const { closeStatusModal, updateAlertCluster, status, pk } = props;
    const [ inputValue, setInputValue ] = React.useState<string>('');
    const {t} = useTranslation();

    const handleInputChange = (e : React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value)
    }


    return <div className={styles.statusModalContent}>
        <div className={styles.statusModalTitle}>{`${t('alerts.change_status.title')}`}</div>
        <div className={styles.statusModalReason}>{`${t('alerts.change_status.placeholder')}`}</div>
            {pk && 
            <div className={styles.buttonsBloc}>

            <CommentInput
            text={status === 'closed_by_user'? t('alerts.change_status.reOpen'): t('alerts.change_status.close')} 
            pk={pk}
            getComment={updateAlertCluster}
            border={'1px solid #0a405d'}/>            
            <ClassicButton warning text={t('alerts.change_status.cancel')} onClick={closeStatusModal}/>
            </div>
            }
    </div>
}