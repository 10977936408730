import * as React from "react";
import { Loader } from "semantic-ui-react";
import styles from "./tab-panel-component.module.css";
import { TabPanelProps } from "./tab-panel-type";
import { ResultsTable, SpecificationResultsTable } from "../tables";
import { WithLazyloadingDatasFetching } from "../../hocs/with-lazyloading-datas-fetching";
import { TableProps, SpecificationTableProps } from "../tables";
import { RiskStatusResultsTable } from '../tables/viewrisk-status-results-table/viewrisk-results-table';
import { RiskStatusTableProps } from '../tables/viewrisk-status-results-table/viewrisk-result-table-type';
import { SimpleJson, HeaderColumn } from '../../models/utils-contract';
import { ApiList } from '../../models';
import { RiskStatus } from '../../models/risk-status';
import { ViewRiskHeader } from '../../constants/tab-header-contract';
import { useParams } from "../../hooks/use-params-contract";


export function TabPanel<T>({
  limit,
  service,
  manageSearch,
  manageOrdering,
  params,
  header,
}: TabPanelProps<T>) {
  const EnhancedTable = WithLazyloadingDatasFetching<T, TableProps<T>>(
    service,
    "data",
    params,
    limit
  )(ResultsTable);

  console.log(header);

  return (
    <div className={styles.tableWrapper}>
      <EnhancedTable
        data={[]}
        header={header}
        manageOrdering={manageOrdering}
        manageSearch={manageSearch}
      />
    </div>
  );
}

export function SpecificationTabPanel<T>({
  limit,
  service,
  manageSearch,
  manageOrdering,
  params,
  header,
}: TabPanelProps<T>) {
  const EnhancedTable = WithLazyloadingDatasFetching<T, SpecificationTableProps<T>>(
    service,
    "data",
    params,
    limit
  )(SpecificationResultsTable);

  console.log(header);

  return (
    <div className={styles.tableWrapper}>
      <EnhancedTable
        data={[]}
        header={header}
        manageOrdering={manageOrdering}
        manageSearch={manageSearch}
      />
    </div>
  );
}

export function ViewRiskTabPanel<T>({
  limit,
  service,
  params,
  header,
  manageSearch,
  manageOrdering
}: TabPanelProps<T>) {


  const EnhancedTable = WithLazyloadingDatasFetching<T, RiskStatusTableProps<T>>(
    service,
    "data",
    params,
    limit
  )(RiskStatusResultsTable);

  // console.log(header);

  return (
    <div className={styles.tableWrapper}>
      <EnhancedTable
        data={[]}
        header={header}
        params={params}
        manageOrdering={manageOrdering}
        manageSearch={manageSearch}
      />
    </div>
  );
}