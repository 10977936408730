import React, { FormEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./specifications-page.module.css";
import { SpecificationTilesListProps } from "./specifications-page-type";
import { specificationEnhancer } from "./enhancer";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { posthogEvents } from "../../utils/posthog";
import { SpecificationLight } from "../../models";
import { FilterButton } from "../../components";

const InnerSpecificationTilesList: React.FC<SpecificationTilesListProps> = ({
  specifications,
}: SpecificationTilesListProps) => {
  const { t } = useTranslation();

  const [filteredResults, setFilteredResults] = useState<any[]>([])
  const [isSelected, setIsSelected] = useState<string>('selected4')
  useEffect(() => {
    handleFilterCLick('2022', 'selected4')
  }, [])

  const [dataSpecifications, setDataSpecifications] =
    React.useState<string>("");
  const [state, setState] = React.useState<string>("");

  if (!specifications) {
    return <div>Pas de résultat</div>;
  }

  const results = !dataSpecifications
    ? filteredResults
    : filteredResults.filter((specification) =>
        specification.name
          .toLowerCase()
          .includes(dataSpecifications.toLocaleLowerCase())
      );

  posthogEvents.triggerEvent({
    name: "request",
    datas: { specifications: results.length > 0 ? "success" : "error" },
  });


  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    posthogEvents.triggerSearch("specification", e.currentTarget.value);
    setDataSpecifications(e.currentTarget.value);
  };

  const handleClickOnTile = (specification: string) => {
    posthogEvents.triggerEvent({ name: "clickOn", datas: { specification } });
  };

  const handleFilterCLick = (year: string, value: string): any => {
    const filterResults = specifications.results.filter(e => e.year === year)
    setFilteredResults(filterResults)
    setIsSelected(value)
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: "-4%", marginBottom: "3%" }}>
        <form onSubmit={e => e.preventDefault()}>
          <div className="ui icon input">
            <input
              className={styles.inputStyle}
              placeholder={t("specifications.search.specifications")}
              type="text"
              value={dataSpecifications}
              onChange={handleChange}
              data-automation-id="inputSearch"
            />
            <i aria-hidden="true" className="search icon"></i>
          </div>
        </form>
        <h4>{state}</h4>
      </div>
      <div style={{ display: 'flex',}}>
        <FilterButton text="2019" selected={isSelected === 'selected1'} onClick={() => handleFilterCLick('2019', 'selected1')} style={{ marginRight: '15px'}}/>
        <FilterButton text="2020" selected={isSelected === 'selected2'} onClick={() => handleFilterCLick('2020', 'selected2')} style={{ marginRight: '15px'}} />
        <FilterButton text="2021" selected={isSelected === 'selected3'} onClick={() => handleFilterCLick('2021', 'selected3')} style={{ marginRight: '15px'}} />
        <FilterButton text="2022" selected={isSelected === 'selected4'} onClick={() => handleFilterCLick('2022', 'selected4')} style={{ marginRight: '15px'}} />
        <FilterButton text="2023" selected={isSelected === 'selected5'} onClick={() => handleFilterCLick('2023', 'selected5')} style={{ marginRight: '15px'}} />
        <FilterButton text="2024" selected={isSelected === 'selected6'} onClick={() => handleFilterCLick('2024', 'selected6')} style={{ marginRight: '15px'}} />
      </div>
      <div>
        {results.length === 0
          ? <div style={{ fontSize: '20px', padding: '10px' }}> Pas de résultat correspondant à cette année. </div>
          : results.map((spec) => (
          <div
            className={styles.resultCard}
            data-automation-id="resultCard"
            key={spec.pk}
            style={{ marginTop: "35px" }}
          >

            {spec.name.includes(
              "Autodiagnostic des bonnes pratiques de stockage à la ferme"
            ) ? (
              <Link
                to={`/contract/autodiagnostic-ferme/${spec.pk}`}
                onClick={() => handleClickOnTile(spec.name)}
              >
                {spec.name} {spec.year}
              </Link>
            ) : (
              <Link
                to={`/specification/results/${spec.pk}`}
                onClick={() => handleClickOnTile(spec.name)}
              >
                {spec.name} {spec.year}
              </Link>
            )}
          </div>
        ))
        }
      </div>
    </React.Fragment>
  );
};

export const SpecificationTilesList = specificationEnhancer(
  InnerSpecificationTilesList
);
