import React, {
  ComponentType,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { Error } from '../../models/error';
import { ErrorMessage, LocalLoader } from '../../components';
import { pick } from '../../utils';
import { AuthenticatedContext } from '../../contexts';


type NotFoundBehavior = 'error' | 'redirect' | 'ignore';

interface InnerStatelessDatasFetchingComponentProps<T, P> {
  Component: ComponentType<P>;
  LoaderComponent?: React.FunctionComponent;
  props: P;
  remoteFetchAction: () => Promise<T>;
  notFoundBehavior: NotFoundBehavior;
  outputFieldName: string | number | symbol;
  resetFieldName?: string | number | symbol;
  errorMessages?: { title: string; message: string; code: number }[];
}

const storageKey = process.env.REACT_APP_AUTHTOKEN ? process.env.REACT_APP_AUTHTOKEN : '';

function InnerStatelessDatasFetchingComponent<T, P>({
  Component,
  LoaderComponent,
  outputFieldName,
  resetFieldName,
  props,
  notFoundBehavior,
  remoteFetchAction,
  errorMessages,
}: InnerStatelessDatasFetchingComponentProps<T, P>): React.ReactElement<
  InnerStatelessDatasFetchingComponentProps<T, P>
> {
  const [data, setData] = useState<T | null>(null);
  const isInitialized = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | string | null>('');

  const history = useHistory();
  const redirect = history.location && history.location.pathname + history.location.search;

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async (): Promise<void> => {
      if (isSubscribed) {
        setIsLoading(true);
      }
      try {
        const result = await remoteFetchAction();
        if (isSubscribed) {
          setData(result);
        }
      } catch (err) {
        if (isSubscribed) {
          setError(err);
        }
      } finally {
        isInitialized.current = true;
      }

      if (isSubscribed) {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [remoteFetchAction]);

  const reloadData = useCallback(
    async (bypassLoading = false): Promise<void> => {
      if (!bypassLoading) {
        setIsLoading(true);
      }
      try {
        const result = await remoteFetchAction();
        setData(result);
      } catch (err) {
        setError(err);
      }

      if (!bypassLoading) {
        setIsLoading(false);
      }
    },
    [remoteFetchAction],
  );

  const authenticatedContext = React.useContext(AuthenticatedContext);


  if (error && error !== '') {
    if (typeof error === 'string') {
      return <ErrorMessage error={error} />;
    }

    if (error.code === 404 && notFoundBehavior === 'redirect') {
      return <Redirect to="/nofound" />;
    }

    if (error.code === 401) {
      //vider le context si on est sur que c'est une deconnexion et pas un manque de permission
      localStorage.removeItem(storageKey);
      authenticatedContext.setAuthenticated(undefined);
      return (
        <Redirect to={{
          pathname: '/login',
        }}
        />
      );
    }

    if (error.code !== 404 || notFoundBehavior === 'error') {
      if (errorMessages && errorMessages.length > 0) {
        const errorMessage = errorMessages.find((e) => e.code === error.subCode);
        if (errorMessage) {
          return (
            <ErrorMessage
              title={errorMessage.title}
              error={errorMessage.message}
            />
          );
        }

        return <ErrorMessage error={error.message} />;
      }
      return <ErrorMessage error={error.message} />;
    }
  }

  if (isLoading || !isInitialized.current) {
    if (LoaderComponent) {
      return <LoaderComponent />;
    }

    return <LocalLoader />;
  }

  const innerProps = { ...props, [outputFieldName]: data };

  if (resetFieldName) {
    return <Component {...{ ...innerProps, [resetFieldName]: reloadData }} />;
  }

  return <Component {...innerProps} />;
}

export const WithStatelessDatasFetching = <T, InjectedProps>(
  remoteFetchAction: () => Promise<T>,
  LoaderComponent: React.FunctionComponent | undefined,
  notFoundBehavior: NotFoundBehavior,
  outputFieldName: keyof InjectedProps,
  resetFieldName?: keyof InjectedProps,
  errorMessages?: { title: string; message: string; code: number }[],
) => <P extends InjectedProps>(
    Component: ComponentType<P>,
  ): React.FunctionComponent<P> => {
  const WithStatelessDatasFetchingComponent: React.FC<P> = (props: P) => (
    <InnerStatelessDatasFetchingComponent
      Component={Component}
      LoaderComponent={LoaderComponent}
      props={props}
      remoteFetchAction={remoteFetchAction}
      notFoundBehavior={notFoundBehavior}
      outputFieldName={outputFieldName}
      resetFieldName={resetFieldName}
      errorMessages={errorMessages}
    />
  );

  return WithStatelessDatasFetchingComponent;
};

export const WithStatelessDatasFetchingByParameters = <
  T,
  InjectedProps,
  SearchParamsProps
>(
    remoteFetchAction: (params: SearchParamsProps) => Promise<T>,
    LoaderComponent: React.FunctionComponent | undefined,
    notFoundBehavior: NotFoundBehavior,
    outputFieldName: keyof InjectedProps,
    searchFieldNames: (keyof SearchParamsProps)[],
    resetFieldName?: keyof InjectedProps,
    waitForSearchParams = false,
    errorMessages?: { title: string; message: string; code: number }[],
  ) => <P extends InjectedProps>(
    Component: ComponentType<P>,
  ): React.FunctionComponent<P & SearchParamsProps> => {
    const WithStatelessDatasFetchingComponent: React.FC<P &
      SearchParamsProps> = (props: P & SearchParamsProps) => {
        const filterProps: SearchParamsProps = useMemo(
          () => pick(props, searchFieldNames) as SearchParamsProps,
          [props],
        );

        const remoteFetchActionWrapper = useCallback(() => remoteFetchAction(filterProps), [filterProps]);

        if (waitForSearchParams) {
          let missingParams = false;
          searchFieldNames.forEach((s) => {
            if (!props[s]) {
              missingParams = true;
            }
          });

          if (missingParams) {
            return <Component {...props} />;
          }
        }
        

        return (
          <InnerStatelessDatasFetchingComponent
            Component={Component}
            LoaderComponent={LoaderComponent}
            props={props}
            remoteFetchAction={remoteFetchActionWrapper}
            notFoundBehavior={notFoundBehavior}
            outputFieldName={outputFieldName}
            resetFieldName={resetFieldName}
            errorMessages={errorMessages}
          />
        );
      };

    return WithStatelessDatasFetchingComponent;
  };
