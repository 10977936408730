import React, { useRef, useCallback, useState } from 'react';

import { Popup } from 'semantic-ui-react';
import { FileInputControlProps } from './file-input-control-type';
import { useTranslation } from 'react-i18next';
import warningPicto from '../../../../../assets/pictos/warningPictoRed.png';

import styles from './file-input-control-component.module.css';
import { config } from '../../../../../config';

export const FileInputControl: React.FC<FileInputControlProps> = (
  props: FileInputControlProps,
) => {
  const {
    accept,
    acceptHint,
    onChange,
    placeholder,
    name,
    sizeLimit = config.backend.fileMaxSize,
    warning,
  } = props;
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const onBtnClick = useCallback(() => {
    if (ref && ref.current) {
      ref.current.click();
    }
  }, [ref]);

  const hint = `${t('form.certificates.file_hint')} ${sizeLimit}Ko`;
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (
        event
        && event.target
        && event.target.files
        && event.target.files.length > 0
      ) {
        event.persist();
        const { target } = event;
        const { files } = target;
        if (files && files.length > 0) {
          if (
            sizeLimit
            && files.length > 0
            && sizeLimit * 1024 < files[0].size
          ) {
            setError(true);
          } else if (onChange) {
            onChange(event);
            setError(false);
          }
        }
      }
    },
    [onChange, sizeLimit],
  );
  return (
    <>
      <Popup
        header={t('form.certificates.header')}
        position="top center"
        trigger={(
          <div className={styles.triggerBtn}>
            <button
              className={styles.uploadBtn}
              type="button"
              onClick={onBtnClick}
            />
          </div>
)}
      />

      <div>
        <input
          ref={ref}
          hidden
          type="file"
          name={name}
          onChange={handleChange}
          accept={accept}
          placeholder={placeholder}
        />
      </div>
      {hint && sizeLimit && <div className={styles.hint}>{hint}</div>}
      {acceptHint && <div className={styles.hint}>{acceptHint}</div>}
      {warning && <div className={styles.warning}><img className={styles.warningPicto} alt='warning' src={warningPicto}/> {t('form.certificates.warning')}</div>}
      {error && (
        <div className={styles.error}>Le fichier n&apos;est pas valide</div>
      )}
    </>
  );
};
