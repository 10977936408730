import { History, FullHistory, HistoryFields } from "../../pages";
import { newHistoryObject, User } from "../..";
import { formatDate } from "../../utils";
import i18next from "i18next";


export const formatData = (results : FullHistory) => {
    let newData : newHistoryObject[]= [];
    // console.log("FD results", results)
    const flowHistory = results.flowHistory;
    const certifHistory = results.certifHistory;
    const comments = results.comments;

    flowHistory.forEach((el : History) => {
        let objectTest = new newHistoryObject();
        objectTest.user = el.history_user;
        objectTest.date = el.history_date;
        objectTest.type = 'flow';
        el.history_fields !== null && el.history_fields.length>0  && el.history_fields.forEach((el : HistoryFields) => {
        if(el.changed_old){
           switch (typeof(el.changed_old)) {
           case 'object':
           const index = Object.keys(el.changed_old);
           index.forEach(el2 => {
               if((el.changed_old as any)[el2] !== (el.changed_new as any)[el2]){
                   objectTest.changed_fields= [...objectTest.changed_fields, { value : el2, changed_old : (el.changed_old as any)[el2], changed_new: (el.changed_new as any)[el2]}]
               }
           })
                break;
            case 'string' :
               objectTest.changed_fields = [...objectTest.changed_fields, {value : el.changed_field, changed_old :el.changed_old, changed_new : el.changed_new}];
               break;
            case 'number' :
                objectTest.changed_fields = [...objectTest.changed_fields, {value : el.changed_field, changed_old: el.changed_old, changed_new: el.changed_new}];
                break;
            default :
                break;
           };
        } else {
            objectTest.changed_fields = [...objectTest.changed_fields, {value : el.changed_field, changed_old: 'none', changed_new: el.changed_new}]
        }
        })

        newData = [...newData, objectTest];
    })

    certifHistory.forEach((el : History) => {
        let objectTest = new newHistoryObject();
        objectTest.user = el.history_user;
        objectTest.date = el.history_date;
        objectTest.type = 'certificate';
        el.history_fields !== null &&  el.history_fields.length>0 && el.history_fields.forEach((el : HistoryFields) => {
            const formatedOld = (formatDate(el.changed_old as string, i18next.language));
            const formatedNew = (formatDate(el.changed_new as string, i18next.language));
            objectTest.changed_fields= [...objectTest.changed_fields, {value : el.changed_field, changed_old : formatedOld, changed_new : formatedNew}]
        })
        newData = [...newData, objectTest];
    })


    comments.forEach(el => {
        let objectTest = new newHistoryObject();
        objectTest.user = el.user;
        objectTest.date = el.comment_date_time;
        objectTest.description= el.description;
        objectTest.type = 'comment'
        newData = [...newData, objectTest];
        objectTest.users_at = el.users_at ? el.users_at.length > 0? el.users_at : [] : [];
    });

    return newData.sort((el1 :newHistoryObject, el2 : newHistoryObject) => {
       return el1.date > el2.date ? 1 : el1.date < el2.date ? -1 : 0;
    })
}
