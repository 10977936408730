import axios from 'axios';
import i18next from 'i18next';
import { backendTokenService } from './sub-services';
import { concatUrl, getLanguage } from '../../utils';
import { config } from '../../../config';
import { Error } from '../../models';

const securedRestClient = axios.create({
  baseURL: concatUrl([config.backend.baseUrl, i18next.language ? getLanguage(i18next.language) : 'en', config.backend.rest.url]),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function errorResponseHandler(error: any): Promise<Error> {
  // console.log(error.response);
  const exploreError = (errorContent : object) => {
    const keys = Object.keys(errorContent);
    // console.log(keys[0])
    return keys[0]
  }
  const returnedError: Error = {
    message:
      error.response && error.response.data
        ? 
        typeof(error.response.data) === 'object' ?
        exploreError(error.response.data)
        :
        error.response.data.detail
        : error.data.message,
      
    code: error.response ? error.response.status : -1,
    subCode:
      error.response && error.response.data ? error.response.data.code : -1,
    subCodeLib:
      error.response && error.response.data ? error.response.data.title : -1,
  };
  return Promise.reject(returnedError);
}

securedRestClient.interceptors.request.use(
  async (requestConfig) => {
    const originalRequestConfig = requestConfig;
    const token = backendTokenService.getToken();
    originalRequestConfig.headers.authorization = `Bearer ${
      token && token.value ? token.value : ''
    }`;
    // originalRequestConfig.headers['Accept-Language'] = i18next.language ? i18next.language : 'en';
    return originalRequestConfig;
  },
  (err) => Promise.reject(err),
);

securedRestClient.interceptors.response.use(
  (response) => response,
  (error) => errorResponseHandler(error),
);

export { securedRestClient };
