/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDeepValue = (obj: any, propertyPath: string): string => {
  let result = '';
  for (let i = 0, splittedPath = propertyPath.split('.'), len = propertyPath.length; i < len; i += 1) {
    result = obj[splittedPath[i]];
  }
  return result;
};

export function parse<T extends { [index: string]: string | number | Date | boolean | undefined }>(object: any): T {
  if (!object) {
    return object;
  }
  const output: any = {};

  // TODO : a enrichir
  Object.keys(object).forEach((k) => {
    if (!Number.isNaN(Number(object[k]))) {
      output[k] = Number(object[k]);
    } else if (!Number.isNaN(Date.parse(object[k]))) {
      output[k] = new Date(object[k]);
    } else if (/true/i.test(object[k])) {
      output[k] = true;
    } else if (/false/i.test(object[k])) {
      output[k] = false;
    } else {
      output[k] = object[k];
    }
  });

  return output;
}
