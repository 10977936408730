import { WithStatelessDatasFetchingByParameters, WithQuerystringReading } from "../../hocs";
import { GetSpecification, SpecificationDetails } from "../../models/specification-contract";
import { SpecificationEditPageProps } from ".";
import { specificationSubService } from "../../services";
import { LocalLoader, PageLoader } from "../../components";
import React from 'react';
import { compose } from 'redux';


export const enhancer = compose<React.FC<SpecificationEditPageProps>>(
    WithQuerystringReading(),
    WithStatelessDatasFetchingByParameters<SpecificationDetails, SpecificationEditPageProps, GetSpecification>(
    specificationSubService.getSpecification,
    PageLoader,
    'redirect',
    'specification',
    ['id']
    )
)
