import {
    WithStatelessDatasFetchingByParameters,
    WithQuerystringReading,
  } from "../../hocs";
  import { ApiList } from "../../models";
  import { ActorConformity, Actor, ActorConformityResults } from "../../models/actor-conformity-view"
  
  import { ViewActorConformityProps } from "./view-actor-results-page-type";
  import { viewActorSubService } from "../../services";
  import { PageLoader } from "../../components";
  import React from "react";
  import { compose } from "redux";
  
  export const viewActorEnhancer = compose<React.FC<ViewActorConformityProps>>(
    WithQuerystringReading(),
    WithStatelessDatasFetchingByParameters<
      ApiList<ActorConformityResults>,
      ViewActorConformityProps,
      Actor
    >(
      viewActorSubService.getActorDetails, PageLoader,
      "redirect",
      "datas",
      ["actor_code"]
    )
  );