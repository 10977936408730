import { groupSubService } from '../../services';
import { PageLoader } from '../../components/page-loader';
import { WithStatelessDatasFetching } from '../../hocs';
import { ApiList, GroupLight } from '../../models';
import { EcosystemProps } from '.';

const enhancer = WithStatelessDatasFetching<GroupLight[], EcosystemProps>(
  groupSubService.getAllGroup,
  PageLoader,
  'error',
  'groups',
);

export default enhancer;
