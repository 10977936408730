import React from 'react';
import styles from './new-group.module.css';
import { WizardLayout } from '../../../../layouts';
import { GroupWizard } from '../../../../components/wizards/group-wizard';
import { NewGroup } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';

export const EcosystemNewGroupPage: React.FC = () => {
  const group: NewGroup = {
    name: '',
    group_actors: [],
  };

  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.wizardWrapper}>
        <GroupWizard initialGroup={group} />
      </div>
    </WizardLayout>
  );
};
