
import {
  ApiList, AlertComment, DetailedAlert, GetAlert, AlertGroup, GetAlertGroup, GetAllAlertGroups, GetHistory, CommentAlertSet, User, AlertGroupLight,
} from '../../models';
import { securedRestClient, backendTokenService } from '../../backend';
import { buildUrlParameters } from '../../utils';
import { AlertHistoryComment,  APIHistoryProps, History, HistoryType } from '../../pages';

export const alertSubService = {
  
  createAlertComment: async (flow_orchestrator: number, message: string) => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/alerts/comments/`;
    const param = {
      flow_orchestrator,
      description: message,
    };
    //console.log('getAlert : requestUrl :', requestUrl);
    const response = await securedRestClient.post<AlertComment>(requestUrl, param);
    return response.data;
  },

  
  newCreateAlertComment: async (type: HistoryType,  pk: number, message: string, users_at : number[]) => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/alerts/comments/`;
    const commentType  = type === 'alert_cluster' ? 'flow_orchestrator' : 'flow';    
    const param = {
      [commentType] : pk,
      description: message,
      users_at
    };
    //console.log('getAlert : requestUrl :', requestUrl);
    const response = await securedRestClient.post<CommentAlertSet>(requestUrl, param);
    //console.log(response);
    return response.data;
  },

  getAlert: async ({ id }: GetAlert) => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/alerts/${id}`;
    // console.log('getAlert : requestUrl :', requestUrl);
    const response = await securedRestClient.get<DetailedAlert>(requestUrl);
    //console.log(response.data);
    let result: DetailedAlert = response.data;
    const requestUrlComment = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/alerts/comments?alert=${result.pk}`;
    //console.log('getAlertComment : requestUrl :', requestUrlComment);
    const responseComment = await securedRestClient.get<ApiList<AlertComment[]>>(requestUrlComment);
    // console.log(responseComment.data);
    result = {
      ...result,
      comments: responseComment.data,
    };
    return result;
  },

  //GESTIION DES ERREURS

  getAlertGroup: async ({ id }: GetAlertGroup) => {
    //console.log(
      // 'alert subservice , getAlertGroup, id : ',
      // id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/alerts/alert_cluster/${id}`;
    const response = await securedRestClient.get<AlertGroup>(requestUrl);
    // const response: AlertGroup = {pk: 1, first_importation_datetime: '11/05/2020' as unknown as Date, status: true , tag: ['audit'], file: 'fichier', step: 'Elevage', last_event: 'Tim a fait une modification', time: '3D', cluster_title: [{subcategory: 'Données réferentiiel inconnue', total_alert: 100, solved_alert: 29}]}
    // console.log(response.data);
    return response.data;
  },

  getAllAlertGroups: async ({
    ordering, limit, offset, status, tag, first_importation_datetime, source_file, step, search, mail, retrack, cluster_date, alert_type 
  }: GetAllAlertGroups) => {
    // console.log(
    //   'ecosystem service getAllAleertGroups, limit : ', limit,
    //   'offset', offset,
    //   'ordering', ordering,
    //   'status', status,
    //   'tag', tag,
    //   'first_importation_datetime', first_importation_datetime,
    //   'source_file', source_file,
    //   'step', step,
    //   'search', search,
    //   'mail', mail
    // );

    const params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'status', value: status },
      { prop: 'tag', value: tag },
      { prop: 'alert_type', value: alert_type },
      { prop: 'cluster_date', value: cluster_date  },
      { prop: 'first_importation_datetime', value: first_importation_datetime },
      { prop: 'source_file', value: source_file },
      { prop: 'step', value: step },
      { prop: 'ordering', value: ordering ? ordering : '-first_importation_datetime' },
      { prop: 'search', value: search },
      {prop: 'mail', value: mail},
      {prop: 'retrack', value: retrack}
    ];
    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/alerts/alert_cluster/?${paramUrl}`;
    const response = await securedRestClient.get<ApiList<AlertGroupLight[]>>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  updateAlerteStatus : async (id : number, status : string) => {
    // console.log(
    //   'UpdateClusterAlert , id : ',
    //   id,
    // );
    const params = {
      pk: id,
      action: status
    }
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/alerts/close_alert`;
    const response = await securedRestClient.post<AlertGroup>(requestUrl, params);
    return response.data;
},

  getAlertErrorMessage: async(id: number)=> {
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/alerts/alerts/?flow_orchestrator=${id}`
    const response = await securedRestClient.get(requestUrl);
    console.log(response)
    return response
  },

  getAlertHistory: async({ id } : GetHistory) => {
    //console.log('GetAlertHistory, id', id);
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/alerts/alert_cluster_history/${id}`
    const response = await securedRestClient.get<APIHistoryProps>(requestUrl);
    //console.log('GetALertHistory, res', response.data)
    return  response.data
  },

  getFlowAlertHistory: async ({ id } : GetHistory) => {
    //console.log('GetFlowAlertHistory, id', id);
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/alerts/flow_history?id_obj=${id}&model=alert_cluster`
    const response = await securedRestClient.get<ApiList<History[]>>(requestUrl);
    //console.log('GetFlowALertHistory, res', response.data)
    return  response.data.results
  },

  getFlowAlertComment : async ({ id } : GetHistory ) => {
    //console.log('GetFlowAlertComment, id', id);
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/alerts/comments?flow_orchestrator=${id}`
    const response = await securedRestClient.get<ApiList<AlertComment[]>>(requestUrl);
    //console.log('GetFlowALerComment, res', response.data)
    return  response.data
  },

  getCertificateAlertHistory: async ({ id } : GetHistory) => {
    //console.log('getCertifAlertHistory, id', id);
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/alerts/certificate_history?id_obj=${id}&model=alert_cluster`
    const response = await securedRestClient.get<ApiList<History[]>>(requestUrl);
    //console.log(response.data);
  },

  getFullAlertHistory : async({ id, type } : GetHistory) => {
    console.log('getFullAlertHistory', id, type);
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/alerts/flow_history?id_obj=${id}&model=${type}`
    const history = await securedRestClient.get<ApiList<History[]>>(requestUrl);
    const requestCommentUrl = `${token && token.apiVersion ? token.apiVersion : ''}/alerts/comments?${type === "flow" ? type : "flow_orchestrator"}=${id}`
    const comments = await securedRestClient.get<ApiList<AlertHistoryComment[]>>(requestCommentUrl);
    const requestCertifsUrl = `${token && token.apiVersion ? token.apiVersion : ''}/alerts/certificate_history?id_obj=${id}&model=${type}`
    const certifs = await securedRestClient.get<ApiList<History[]>>(requestCertifsUrl)
    //console.log('CERTIFS', certifs)
    
    const response = {
      flowHistory: history.data.results,
      comments: comments.data.results,
      certifHistory: certifs.data.results
    }
    //console.log('getFullAlert', response)
    return response;
  },


  getUserList  : async() => {
    //console.log('get userlist');
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/users/`
    const response = await securedRestClient.get<ApiList<User[]>>(requestUrl);
    const userList = response.data.results
    return  userList
  }
};
