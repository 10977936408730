import { Formik } from 'formik';
import React from 'react';
import {  useTranslation } from 'react-i18next';
import { CertificateType, EntityLight, GroupLight, Roles, SelectOptions } from '../../../models';
import { ecosystemService, groupSubService } from '../../../services';
import { ClassicButton } from '../../buttons';
import { CheckBoxInputControl, InputControl, SelectControl } from '../form-controls'; 
import {
  ActorFormProps,
  ActorProps
} from './actor-form-type';
import styles from './actor-form.module.css';

interface RoleTest {
  role : InnerRoleTest[];
  specifications: number
}

interface InnerRoleTest {
  pk: number;
  name: string;
}


export const ActorForm: React.FC<ActorFormProps> = ({
  model,
  onSave,
  errorMessage,
  onCancel,
  onNext,
  onPrevious,
}: ActorFormProps) => {
  const { t } = useTranslation();
  const [entities, setentities] = React.useState<EntityLight[]>([])
  const [editedModel, setEditedModel] = React.useState<ActorProps>(model)
  const [specifications, setspecifications] = React.useState<CertificateType[]>([])
  const [roles, setRoles] = React.useState<Roles[]>([])
  const [groups, setGroups] = React.useState<GroupLight[]>([])

  //Avoid an infinite loop
  const [launchFormatModel, setLaunchFormatModel] = React.useState<number>(0)
  const formatModel = (model :  ActorProps) => {
    const new_related_actor_role : number[] = [];
    const new_related__specifications :number[] = [];
    model.related_actor_role.forEach((el : RoleTest | number) => {
      if(typeof(el) !== 'number'){
        new_related__specifications.push(el.specifications)
      }
      if(typeof(el) !== 'number'){
        el.role.map( el2 => {
          if(new_related_actor_role.filter(el3 => el3 === el2.pk).length === 0){
          new_related_actor_role.push(el2.pk)
          }
        })
      }
    } )
    setEditedModel({...editedModel, related_actor_role : new_related_actor_role, related_specifications: new_related__specifications})
    setLaunchFormatModel(1)
    }
  
  launchFormatModel === 0 && formatModel(model)
  const entitiesOptions = entities.map((el : EntityLight) => {
    return {key : el.pk, text: el.name, value: el.pk}
  });



const specificationsOptions : SelectOptions[] = specifications.map((el : CertificateType)=> {
  return {key: el.pk, text: el.name, value : el.pk}
} )

const groupsOptions : SelectOptions[] = groups.map((el : GroupLight) => {
  return {key : el.pk, text: el.name, value: el.pk}
})


const createRolesOptions = () => {
  const rolesOptions : SelectOptions[] = [];
  roles.forEach((el : Roles) => {
    let value = `${el.name} : (`
    if(el.entity.length>1){
      for(let i =0; i<=el.entity.length; i++){
        if(i === el.entity.length -1){
        value += `${el.entity[i]})`
      } else if(i < el.entity.length-1){ 
        value += `${el.entity[i]} - `;
      }
    }
      rolesOptions.push({key: el.pk, text: value, value: el.pk})
    } else {
      rolesOptions.push({key: el.pk, text: `${el.name} - ${el.entity[0]}`, value: el.pk})
    }
  })
  return rolesOptions
}

  

  React.useEffect(() => {
    const getResult = async () => {
      //Je peuple mes différentes options pour les champs du formulaires
      const entities : EntityLight[] = await ecosystemService.getEntity();
      setentities(entities);
      const specifications : CertificateType[] = await ecosystemService.getSpecifications();
      setspecifications(specifications);
      const roles : any = await ecosystemService.getRoles();
      setRoles(roles);
      const groups : GroupLight[]= await groupSubService.getAllGroup();
      setGroups(groups)
      }
      getResult();
  }, [])


  return (
    <>
      <Formik<ActorProps>
        onSubmit={onSave}
        initialValues={editedModel}
        render={(props) => (
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.formContainer}>
            {model.is_placeholder && 
                <div className={styles.formLine}>
                {/* <b className={styles.inputLabel}>{t('ecosystem.actors.form.is_ambassador')}</b> */}
                <CheckBoxInputControl
                  name="is_placeholder"
                  label={t('form.actors.is_placeholder')}
                  required
                />
              </div>}
              <div className={styles.formLine}>
                <b className={styles.inputLabel}>{t('form.actors.name')}</b>
                <InputControl
                  name="name"
                  required
                  placeholder={t('form.actors.name')}
                />
              </div>
              <div className={styles.formLine}>
                <b className={styles.inputLabel}>{t('form.actors.siret_number')}</b>
                <InputControl
                  name="siret_number"
                  placeholder={t('form.actors.siret_number')}
                  required
                />
              </div>

              <div className={styles.formLine}>
                <b className={styles.inputLabel}>{t('form.actors.entities')}</b>
                <SelectControl 
                options={entitiesOptions}
                name='related_entity' 
                placeholder={t('form.actors.entities')}
                required={true}

                />
              </div>
              <div className={styles.formLine}>
                <b className={styles.inputLabel}>{t('form.actors.specifications')}</b>
                <SelectControl 
                options={specificationsOptions}
                placeholder={t('form.actors.specifications')}
                name='related_specifications'
                required
                />
              </div>
              <div className={styles.formLine}>
                <b className={styles.inputLabel}>{t('form.actors.roles')}</b>
                <SelectControl 
                options={createRolesOptions()}
                placeholder={t('form.actors.roles')}
                name='related_actor_role'
                />
              </div>
              <div className={styles.formLine}>
                <b className={styles.inputLabel}>{t('form.actors.groups')}</b>
                <SelectControl 
                options={groupsOptions}
                placeholder={t('form.actors.groups')}
                name='related_actor_group'
                />
              </div>
              <div className={styles.formLine}>
                {/* <b className={styles.inputLabel}>{t('ecosystem.actors.form.is_ambassador')}</b> */}
                <CheckBoxInputControl
                  name="is_ambassador"
                  label={t('form.actors.is_ambassador')}
                  required
                />
              </div>

            </div>
            <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
                    onCancel
                      ? <ClassicButton text={t('wizard.actors.cancel')} onClick={onCancel} warning />
                      : <div>&nbsp;</div>
                  }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
                    onPrevious
                      ? <ClassicButton text={t('wizard.actors.back')} onClick={onPrevious} inverted />
                      : <div>&nbsp;</div>
                  }
                </div>
                <div className={styles.nextNavigation}>
                  {
                    onNext
                      ? <ClassicButton onClick={onNext} text={t('wizard.actors.next')} inverted />
                      : <div>&nbsp;</div>
                  }

                </div>
              </div>
              <div className={styles.submitActions}>
                <ClassicButton type="submit" text={t('wizard.actors.submit')} />
              </div>
            </div>
          </form>
        )}
      />
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}

    </>
  );
};
 
