import { RelationProps } from '../components';
import {
  Actor, CertificateRead, SiteRead, ToolRead, ProductRead, FlowLight, BatchLight, AuditResult, HeaderColumn, AlertGroup, AlertGroupLight, Relation,
} from '../models';
import { EnhancedFlow } from '../pages';

export const ActorsHeader: HeaderColumn<Actor>[] = [
  {
    orderer: 'name',
    searchProp: 'actor',
    header: 'table.actors.name',
    searchValue: '',
    searchType: 'string',
    columnName: 'name',
    dataTransform: 'string',
  },
  {
    orderer: 'siret_number',
    searchProp: 'siret_number',
    header: 'table.actors.siret_number',
    searchValue: '',
    searchType: 'string',
    columnName: 'siret_number',
    dataTransform: 'string',
  },
  {
    orderer: '',
    searchProp: 'role',
    header: 'table.actors.roles',
    searchValue: '',
    searchType: 'string',
    columnName: 'roles',
    dataTransform: 'role',
  },
  {
    orderer: 'is_ambassador',
    searchProp: 'is_ambassador',
    header: 'table.actors.is_ambassador',
    searchValue: '',
    searchType: 'booleanSelect',
    columnName: 'is_ambassador',
    dataTransform: 'boolean',
  },
];

export const CertificatesHeader: HeaderColumn<CertificateRead>[] = [
  {
    orderer: 'owner__name',
    searchProp: 'owner_name',
    header: 'table.certificates.owner',
    searchValue: '',
    searchType: 'string',
    columnName: 'owner',
    dataTransform: 'owner',
  },
  {
    orderer: 'name',
    searchProp: 'name',
    header: 'table.certificates.name',
    searchValue: '',
    searchType: 'string',
    columnName: 'name',
    dataTransform: 'string',
  },
  {
    orderer: 'internal_code',
    searchProp: 'internal_code',
    header: 'table.certificates.internal_code',
    searchValue: '',
    searchType: 'string',
    columnName: 'internal_code',
    dataTransform: 'string',
  },
  {
    orderer: 'start_date',
    searchProp: 'start_date',
    header: 'table.certificates.start_date',
    searchValue: '',
    searchType: 'string',
    columnName: 'start_date',
    dataTransform: 'date',
  },
  {
    orderer: 'end_date',
    searchProp: 'end_date',
    header: 'table.certificates.end_date',
    searchValue: '',
    searchType: 'string',
    columnName: 'end_date',
    dataTransform: 'expirationDate',
  },
  {
    orderer: 'category',
    searchProp: 'category',
    header: 'table.certificates.status',
    searchValue: '',
    searchType: 'certificateSelect',
    columnName: 'category',
    dataTransform: 'category',
  },
];

export const SitesHeader: HeaderColumn<SiteRead>[] = [
  {
    orderer: 'owner__name',
    searchProp: 'owner_name',
    header: 'table.sites.owner',
    searchValue: '',
    searchType: 'string',
    columnName: 'owner',
    dataTransform: 'owner',
  },
  {
    orderer: 'name',
    searchProp: 'name',
    header: 'table.sites.name',
    searchValue: '',
    searchType: 'string',
    columnName: 'name',
    dataTransform: 'string',
  },
  {
    orderer: 'internal_code',
    searchProp: 'internal_code',
    header: 'table.sites.internal_code',
    searchValue: '',
    searchType: 'string',
    columnName: 'internal_code',
    dataTransform: 'string',
  },
  {
    orderer: 'site_type',
    searchProp: 'site_type',
    header: 'table.sites.site_type',
    searchValue: '',
    searchType: 'string',
    columnName: 'site_type',
    dataTransform: 'string',
  },
  {
    orderer: 'address',
    searchProp: 'address',
    header: 'table.sites.address',
    searchValue: '',
    searchType: 'string',
    columnName: 'address',
    dataTransform: 'string',
  },
  {
    orderer: 'zip_code',
    searchProp: 'zip_code',
    header: 'table.sites.zip_code',
    searchValue: '',
    searchType: 'string',
    columnName: 'zip_code',
    dataTransform: 'string',
  },
  {
    orderer: 'city',
    searchProp: 'city',
    header: 'table.sites.city',
    searchValue: '',
    searchType: 'string',
    columnName: 'city',
    dataTransform: 'string',
  },
  {
    orderer: 'department',
    searchProp: 'department',
    header: 'table.sites.department',
    searchValue: '',
    searchType: 'string',
    columnName: 'department',
    dataTransform: 'string',
  },
  {
    orderer: 'region',
    searchProp: 'region',
    header: 'table.sites.region',
    searchValue: '',
    searchType: 'string',
    columnName: 'region',
    dataTransform: 'string',
  },
  {
    orderer: 'country',
    searchProp: 'country',
    header: 'table.sites.country',
    searchValue: '',
    searchType: 'string',
    columnName: 'country',
    dataTransform: 'string',
  },
];

export const ToolsHeader: HeaderColumn<ToolRead>[] = [
  {
    orderer: 'owner__name',
    searchProp: 'owner_name',
    header: 'table.tools.owner',
    searchValue: '',
    searchType: 'string',
    columnName: 'owner',
    dataTransform: 'owner',
  },
  {
    orderer: 'name',
    searchProp: 'name',
    header: 'table.tools.name',
    searchValue: '',
    searchType: 'string',
    columnName: 'name',
    dataTransform: 'string',
  },
  {
    orderer: 'internal_code',
    searchProp: 'internal_code',
    header: 'table.tools.internal_code',
    searchValue: '',
    searchType: 'string',
    columnName: 'internal_code',
    dataTransform: 'string',
  },
  {
    orderer: 'capacity',
    searchProp: 'capacity',
    header: 'table.tools.capacity',
    searchValue: '',
    searchType: 'string',
    columnName: 'capacity',
    dataTransform: 'number',
  },
  {
    orderer: 'unit',
    searchProp: 'unit',
    header: 'table.tools.unit',
    searchValue: '',
    searchType: 'string',
    columnName: 'unit',
    dataTransform: 'string',
  },
  {
    orderer: 'tool_type',
    searchProp: 'tool_type',
    header: 'table.tools.tool_type',
    searchValue: '',
    searchType: 'string',
    columnName: 'tool_type',
    dataTransform: 'string',
  },
];

export const ProductsHeader: HeaderColumn<ProductRead>[] = [
  {
    orderer: 'owner__name',
    searchProp: 'owner_name',
    header: 'table.products.owner',
    searchValue: '',
    searchType: 'string',
    columnName: 'owner',
    dataTransform: 'owners',
  },
  {
    orderer: 'name',
    searchProp: 'name',
    header: 'table.products.name',
    searchValue: '',
    searchType: 'string',
    columnName: 'name',
    dataTransform: 'string',
  },
  {
    orderer: 'internal_code',
    searchProp: 'internal_code',
    header: 'table.products.internal_code',
    searchValue: '',
    searchType: 'string',
    columnName: 'internal_code',
    dataTransform: 'string',
  },
  {
    orderer: 'unit',
    searchProp: 'unit',
    header: 'table.products.unit',
    searchValue: '',
    searchType: 'string',
    columnName: 'unit',
    dataTransform: 'string',
  },
  {
    orderer: 'gs1',
    searchProp: 'gs1',
    header: 'table.products.gs1',
    searchValue: '',
    searchType: 'string',
    columnName: 'gs1',
    dataTransform: 'string',
  },
  {
    orderer: 'gs1_type',
    searchProp: 'gs1_type',
    header: 'table.products.gs1_type',
    searchValue: '',
    searchType: 'string',
    columnName: 'gs1_type',
    dataTransform: 'string',
  },
];

export const RelationsHeader: HeaderColumn<Relation>[] = [
  {
    orderer: 'actor',
    searchProp: 'actor',
    header: 'table.relations.actor',
    searchValue: '',
    searchType: 'string',
    columnName: 'actor',
    dataTransform: 'owner',
  },
  {
    orderer: 'owner',
    searchProp: 'owner_name',
    header: 'table.relations.owner',
    searchValue: '',
    searchType: 'string',
    columnName: 'owner',
    dataTransform: 'owner',
  },
  {
    orderer: 'connection',
    searchProp: 'connection',
    header: 'table.relations.connection',
    searchValue: '',
    searchType: 'string',
    columnName: 'connection',
    dataTransform: 'string',
  },
]

export const FlowsHeader: HeaderColumn<FlowLight>[] = [
  {
    orderer: 'step__name',
    searchProp: 'step',
    header: 'table.flows.step',
    searchValue: '',
    searchType: 'string',
    columnName: 'step',
    dataTransform: 'string',
  },
  {
    orderer: 'name',
    searchProp: 'name',
    header: 'table.flows.name',
    searchValue: '',
    searchType: 'string',
    columnName: 'name',
    dataTransform: 'string',
  },
  {
    orderer: 'flow_date',
    searchProp: 'flow_date',
    header: 'table.flows.flow_date',
    searchValue: '',
    searchType: 'string',
    columnName: 'flow_date',
    dataTransform: 'date',
  },
  {
    orderer: 'batch_in',
    searchProp: 'batch_in',
    header: 'table.flows.batch_in',
    searchValue: '',
    searchType: 'string',
    columnName: 'batch_in',
    dataTransform: 'string',
  },
  {
    orderer: 'batch_out',
    searchProp: 'batch_out',
    header: 'table.flows.batch_out',
    searchValue: '',
    searchType: 'string',
    columnName: 'batch_out',
    dataTransform: 'string',
  },
  {
    orderer: 'actor_sender',
    searchProp: 'actor_sender',
    header: 'table.flows.actor_sender',
    searchValue: '',
    searchType: 'string',
    columnName: 'actor_sender',
    dataTransform: 'string',
  },
  {
    orderer: 'actor_receiver',
    searchProp: 'actor_receiver',
    header: 'table.flows.actor_receiver',
    searchValue: '',
    searchType: 'string',
    columnName: 'actor_receiver',
    dataTransform: 'string',
  },
  {
    orderer: 'specifications',
    searchProp: 'specifications',
    header: 'table.flows.specifications',
    searchValue: '',
    searchType: 'string',
    columnName: 'specifications',
    dataTransform: 'string',
  },
  {
    orderer: '',
    searchProp: 'audit',
    header: 'table.flows.audit',
    searchValue: '',
    searchType: 'compliantSelect',
    columnName: 'audit',
    dataTransform: 'compliant',
  },
  {
    orderer: '',
    searchProp: '',
    header: '',
    searchValue: '',
    searchType: 'none',
    columnName: 'problematic_fields',
    dataTransform: 'string',
  },
];

export const BatchsHeader: HeaderColumn<BatchLight>[] = [
  {
    orderer: 'name',
    searchProp: 'name',
    header: 'table.batchs.name',
    searchValue: '',
    searchType: 'string',
    columnName: 'name',
    dataTransform: 'string',
  },
  {
    orderer: 'date',
    searchProp: 'date',
    header: 'table.batchs.date',
    searchValue: '',
    searchType: 'string',
    columnName: 'date',
    dataTransform: 'date',
  },
  {
    orderer: 'specifications',
    searchProp: 'specifications',
    header: 'table.batchs.specifications',
    searchValue: '',
    searchType: 'string',
    columnName: 'specifications',
    dataTransform: 'string',
  },
  {
    orderer: '',
    searchProp: 'audit',
    header: 'table.batchs.audit',
    searchValue: '',
    searchType: 'compliantSelect',
    columnName: 'audit',
    dataTransform: 'compliant',
  },
];

export const AuditsHeader: HeaderColumn<AuditResult>[] = [
  {
    orderer: 'audit_rule',
    searchProp: 'audit_rule',
    header: 'table.audits.audit_rule',
    searchValue: '',
    searchType: 'string',
    columnName: 'audit_rule',
    dataTransform: 'string',
  },
  {
    orderer: 'audit_date',
    searchProp: 'audit_date',
    header: 'table.audits.audit_date',
    searchValue: '',
    searchType: 'string',
    columnName: 'audit_date',
    dataTransform: 'date',
  },
  {
    orderer: 'message',
    searchProp: 'message',
    header: 'table.audits.message',
    searchValue: '',
    searchType: 'string',
    columnName: 'message',
    dataTransform: 'string',
  },
  {
    orderer: '',
    searchProp: 'results',
    header: 'table.audits.results',
    searchValue: '',
    searchType: 'compliantSelect',
    columnName: 'result',
    dataTransform: 'compliant',
  },
];

export const AlertGroupHeader: HeaderColumn<AlertGroupLight>[] = [
  {
    orderer: 'first_importation_datetime',
    searchProp: 'first_importation_datetime',
    header: 'alerts.alertGroup.first_importation_datetime',
    searchValue: '',
    searchType: 'string',
    columnName: 'first_importation_datetime',
    dataTransform: 'date',
  },
  {
    orderer: 'status',
    searchProp: 'status',
    header: 'alerts.alertGroup.status',
    searchValue: '',
    searchType: 'compliantSelect',
    columnName: 'status',
    dataTransform: 'compliant',
  },
  {
    orderer: 'cluster_title',
    searchProp: 'cluster_title',
    header: 'alerts.alertGroup.cluster_title',
    searchValue: '',
    searchType: 'none',
    columnName: 'cluster_title',
    dataTransform: 'cluster_error',
  },
  {
    orderer: 'source_file',
    searchProp: 'source_file',
    header: 'alerts.alertGroup.file',
    searchValue: '',
    searchType: 'string',
    columnName: 'source_file',
    dataTransform: 'string',
  },
  {
    orderer: 'step',
    searchProp: 'step',
    header: 'alerts.alertGroup.step',
    searchValue: '',
    searchType: 'string',
    columnName: 'step',
    dataTransform: 'string',
  },
  {
    orderer: 'tag',
    searchProp: 'tag',
    header: 'alerts.alertGroup.tag',
    searchValue: '',
    searchType: 'none',
    columnName: 'tag',
    dataTransform: 'tag',
  },
  // {
  //   orderer: 'time',
  //   searchProp: 'time',
  //   header: 'alerts.alertGroup.time',
  //   searchValue: '',
  //   searchType: 'string',
  //   columnName: 'time',
  //   dataTransform: 'string',
  // },
  {
    orderer: 'last_event',
    searchProp: 'last_event',
    header: 'alerts.alertGroup.last_event',
    searchValue: '',
    searchType: 'none',
    columnName: 'last_event',
    dataTransform: 'event',
  },
];

export const AlertFlowHeader: HeaderColumn<EnhancedFlow>[] = [
  {
    orderer: 'step',
    searchProp: 'step',
    header: 'table.flow_alerts.step',
    searchValue: '',
    searchType: 'string',
    columnName: 'step',
    dataTransform: 'string',
  },
  {
    orderer: 'name',
    searchProp: 'name',
    header: 'table.flow_alerts.name',
    searchValue: '',
    searchType: 'string',
    columnName: 'name',
    dataTransform: 'string',
  },
  {
    orderer: 'flow_date',
    searchProp: 'flow_date',
    header: 'table.flow_alerts.flow_date',
    searchValue: '',
    searchType: 'string',
    columnName: 'flow_date',
    dataTransform: 'date',
  },
  {
    orderer: 'batch_in',
    searchProp: 'batch_in',
    header: 'table.flow_alerts.batch_in',
    searchValue: '',
    searchType: 'string',
    columnName: 'batch_in',
    dataTransform: 'batch',
  },
  {
    orderer: 'batch_out',
    searchProp: 'batch_out',
    header: 'table.flow_alerts.batch_out',
    searchValue: '',
    searchType: 'string',
    columnName: 'batch_out',
    dataTransform: 'batch',
  },
  {
    orderer: 'actor_sender',
    searchProp: 'actor_sender',
    header: 'table.flow_alerts.actor_sender',
    searchValue: '',
    searchType: 'string',
    columnName: 'actor_sender',
    dataTransform: 'ecosystem_preview',
  },
  {
    orderer: 'actor_receiver',
    searchProp: 'actor_receiver',
    header: 'table.flow_alerts.actor_receiver',
    searchValue: '',
    searchType: 'string',
    columnName: 'actor_receiver',
    dataTransform: 'ecosystem_preview',
  },
  {
    orderer: 'quantity',
    searchProp: 'quantity',
    header: 'table.flow_alerts.quantity',
    searchValue: '',
    searchType: 'string',
    columnName: 'quantity',
    dataTransform: 'string',
  },
  {
    orderer: 'unit',
    searchProp: 'unit',
    header: 'table.flow_alerts.unit',
    searchValue: '',
    searchType: 'string',
    columnName: 'unit',
    dataTransform: 'string',
  },
  {
    orderer: 'site_sender',
    searchProp: 'site_sender',
    header: 'table.flow_alerts.site_sender',
    searchValue: '',
    searchType: 'string',
    columnName: 'site_sender',
    dataTransform: 'ecosystem_preview',
  },
  {
    orderer: 'site_receiver',
    searchProp: 'site_receiver',
    header: 'table.flow_alerts.site_receiver',
    searchValue: '',
    searchType: 'string',
    columnName: 'site_receiver',
    dataTransform: 'ecosystem_preview',
  },
  {
    orderer: 'tool_sender',
    searchProp: 'tool_sender',
    header: 'table.flow_alerts.tool_sender',
    searchValue: '',
    searchType: 'string',
    columnName: 'tool_sender',
    dataTransform: 'ecosystem_preview',
  },
  {
    orderer: 'tool_receiver',
    searchProp: 'tool_receiver',
    header: 'table.flow_alerts.tool_receiver',
    searchValue: '',
    searchType: 'string',
    columnName: 'tool_receiver',
    dataTransform: 'ecosystem_preview',
  },
  {
    orderer: 'product',
    searchProp: 'product',
    header: 'table.flow_alerts.product',
    searchValue: '',
    searchType: 'string',
    columnName: 'product',
    dataTransform: 'ecosystem_preview',
  },
  {
    orderer: '',
    searchProp: 'audit',
    header: 'table.flow_alerts.audit',
    searchValue: '',
    searchType: 'compliantSelect',
    columnName: 'audit',
    dataTransform: 'compliant',
  },
];


