import React from 'react';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './flow.module.css';
import { FlowProps } from './flow-types';
import enhancer from './flow-enhancer';
import { GenericList, BlockButton, ClickableContainer, InputControl, ClassicButton, DatePickerControl, CertificateDocumentForm, FileInputControl } from '../../../components';
import { SimpleJson, Flow, EcosystemPreview, FlowWrite } from '../../../models';
import { ContainerLayout, TitleLayout } from '../../../layouts';
import { formatDate, toastHandler } from '../../../utils';
import { GenericTable } from '../../../components/table';
import { AuditsHeader } from '../../../constants';
import { useLocalFilter } from '../../../hooks';
import { AuthenticatedContext } from '../../../contexts';
import eyeIcon from '../../../../assets/pictos/eyePassword.png';
import eyeIconGrey from '../../../../assets/pictos/eyePasswordGrey.png';
import plusPictoBlue from '../../../../assets/pictos/plusPictoBlue.png';
import { Formik } from 'formik';
import { flowSubService } from '../../../services';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Icon, Modal } from 'semantic-ui-react';
import { Sidebar } from 'semantic-ui-react';
import { AlertHistory } from '../..';
import {ReactComponent as History} from '../../../../assets/pictos/history.svg'
import { DocumentModal } from '../../../components/document-modal';
import posthog from 'posthog-js';
import { posthogEvents } from '../../../utils/posthog';

export interface SimpleCardFlow {
  label: string;
  value: string | number;
  input: boolean;
  error: boolean;
  name?: string;
  editionMode?: boolean;
  url?: string;
  date?:boolean;
}

interface FlowFormProps {
  model: FlowClass;
  onSave: (model: FlowClass) => void;
  flow: Flow;
  editionMode: boolean;
}


export class FlowClass {
  step = '';
  name = '';
  flow_date = new Date();
  actor_sender?:EcosystemPreview = {name: '', pk: -1, is_placeholder: false};
  actor_receiver?:EcosystemPreview = {name: '', pk: -1, is_placeholder: false};
  site_sender?:EcosystemPreview = {name: '', pk: -1, is_placeholder: false};
  site_receiver?:EcosystemPreview = {name: '', pk: -1, is_placeholder: false};
  tool_sender?:EcosystemPreview = {name: '', pk: -1, is_placeholder: false};
  tool_receiver?:EcosystemPreview = {name: '', pk: -1, is_placeholder: false};
  quantity = 0;
  unit = '';
  product?:EcosystemPreview = {name: '', pk: -1, is_placeholder: false};
}



const FlowForm: React.FC<FlowFormProps> = React.memo(({ model, onSave, flow, editionMode }: FlowFormProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const genericData: SimpleCardFlow[] = [
    {
      label: t('tracking.flows.form.name'),
      value: flow.name,
      input: true,
      error: flow.problematic_fields.includes('name'),
      editionMode: editionMode,
      name: 'name'
    },
    {
      label: t('tracking.flows.form.flow_date'),
      value: formatDate(flow.flow_date, i18next.language),
      input: true,
      error: flow.problematic_fields.includes('flow_date'),
      editionMode: editionMode,
      date: true,
      name: 'flow_date'
    },
    {
      label: t('tracking.flows.form.specifications'),
      value: flow.specifications?.join(),
      input: false,
      error: flow.problematic_fields.includes('specifications'),
      name: ''
    },
    {
      label: t('tracking.flows.form.step'),
      value: flow.step,
      input: false,
      error: flow.problematic_fields.includes('step'),
      name: ''
    },
  ]/*.filter((el) => el.value !== undefined && el.value !== null && el.value.toString().trim() !== '')*/;

  const senderData: SimpleCardFlow[] = [
    {
      label: t('tracking.flows.form.actor_sender'),
      value: flow.actor_sender ? flow.actor_sender.name : '',
      input: false,
      error: flow.problematic_fields.includes('actor_sender'),
      editionMode: editionMode,
      url: flow.actor_sender && flow.actor_sender.pk ? `/ecosystem/edit-actor/${flow.actor_sender.pk}?step=0&redirectPath=/alert/${location.pathname.split('/')[2]}` : ''
    },
    {
      label: t('tracking.flows.form.site_sender'),
      value: flow.site_sender ? flow.site_sender.name : '',
      input: false,
      error: flow.problematic_fields.includes('site_sender'),
      editionMode: editionMode,
      url: flow.site_sender && flow.site_sender.pk ? `/ecosystem/edit-site/${flow.site_sender.pk}?step=1&redirectPath=/alert/${location.pathname.split('/')[2]}` : ''
    },
    {
      label: t('tracking.flows.form.tool_sender'),
      value: flow.tool_sender ? flow.tool_sender.name : '',
      input: false,
      error: flow.problematic_fields.includes('tool_sender'),
      editionMode: editionMode,
      url: flow.tool_sender && flow.tool_sender.pk ? `/ecosystem/edit-tool/${flow.tool_sender.pk}?step=1&redirectPath=/alert/${location.pathname.split('/')[2]}` : ''
    },
  ]/*.filter((el) => el.value !== undefined && el.value !== null && el.value.toString().trim() !== '')*/;

  const receiverData: SimpleCardFlow[] = [
    {
      label: t('tracking.flows.form.actor_receiver'),
      value: flow.actor_receiver ? flow.actor_receiver.name : '',
      input: false,
      error: flow.problematic_fields.includes('actor_receiver'),
      editionMode: editionMode,
      url: flow.actor_receiver && flow.actor_receiver.pk ? `/ecosystem/edit-actor/${flow.actor_receiver.pk}?step=0&redirectPath=/alert/${location.pathname.split('/')[2]}` : ''
    },
    {
      label: t('tracking.flows.form.site_receiver'),
      value: flow.site_receiver ? flow.site_receiver.name : '',
      input: false,
      error: flow.problematic_fields.includes('site_receiver'),
      editionMode: editionMode,
      url: flow.site_receiver && flow.site_receiver.pk ? `/ecosystem/edit-site/${flow.site_receiver.pk}?step=1&redirectPath=/alert/${location.pathname.split('/')[2]}` : ''
    },
    {
      label: t('tracking.flows.form.tool_receiver'),
      value: flow.tool_receiver ? flow.tool_receiver.name : '',
      input: false,
      error: flow.problematic_fields.includes('tool_receiver'),
      editionMode: editionMode,
      url: flow.tool_receiver && flow.tool_receiver.pk ? `/ecosystem/edit-tool/${flow.tool_receiver.pk}?step=1&redirectPath=/alert/${location.pathname.split('/')[2]}` : ''
    },
    {
      label: t('tracking.flows.form.product'),
      value: flow.product ? flow.product.name : '',
      input: false,
      error: flow.problematic_fields.includes('product'),
      editionMode: editionMode,
      url: flow.product && flow.product.pk ? `/ecosystem/edit-product/${flow.product.pk}?step=1&redirectPath=/alert/${location.pathname.split('/')[2]}` : ''
    },
    {
      label: t('tracking.flows.form.quantity'),
      value: flow.quantity?.toString(),
      input: true,
      error: flow.problematic_fields.includes('quantity'),
      editionMode: editionMode,
      name: 'quantity'
    },
    {
      label: t('tracking.flows.form.unit'),
      value: flow.unit,
      input: true,
      error: flow.problematic_fields.includes('unit'),
      editionMode: editionMode,
      url: flow.product && flow.product.pk ? `/ecosystem/edit-product/${flow.product.pk}?step=1&redirectPath=/alert/${location.pathname.split('/')[2]}` : ''
    },
  ]/*.filter((el) => el.value !== undefined && el.value !== null && el.value.toString().trim() !== '')*/;

  const buildSpecificData = (specData: SimpleJson | undefined): SimpleCardFlow[] => {
    if (!specData) return [];
    const labels: string[] = Object.keys(specData);
    return labels.map((el: string) => ({
      label: el,
      value: specData[el],
      input: false,
      error: flow.problematic_fields.includes('specific_data'),
      editionMode: editionMode,
      name: ''
    }))/*.filter((el) => el.value !== undefined && el.value !== null && el.value.toString().trim() !== '')*/;
  };
  posthogEvents.triggerPageView();
  const specificData = buildSpecificData(flow.specific_data);

  return (
    <Formik<FlowClass>
      onSubmit={onSave}
      initialValues={model}
      render={(props) => (
        <form className={styles.form} onSubmit={props.handleSubmit}>
        <div className={styles.container}>
        <GenericList
          items={genericData}
          itemRenderer={(childElement: SimpleCardFlow, key: number) => (
            <div key={key} className={styles.element}>
              <CardElementFix
                label={childElement.label}
                value={childElement.value}
                input={childElement.input}
                error={childElement.error}
                name={childElement.name}
                editionMode={childElement.editionMode}
                url={childElement.url}
                date={childElement.date}
              />
            </div>
          )}
        />

      </div>
      <div className={styles.subTitle}><b>{t('tracking.flows.form.senderData')}</b></div>
      <div className={styles.container}>
        <GenericList
          items={senderData}
          itemRenderer={(childElement: SimpleCardFlow, key: number) => (
            <div key={key} className={styles.element}>
              <CardElementFix
                label={childElement.label}
                value={childElement.value}
                input={childElement.input}
                error={childElement.error}
                name={childElement.name}
                editionMode={childElement.editionMode}
                url={childElement.url}
                date={childElement.date}
              />
            </div>
          )}
        />
        {/* {console.log('generic data : ', genericData, 'sender data : ', senderData, 'receiver data : ', receiverData)} */}

      </div>
      <div className={styles.subTitle}><b>{t('tracking.flows.form.receiverData')}</b></div>
      <div className={styles.container}>
        <GenericList
          items={receiverData}
          itemRenderer={(childElement: SimpleCardFlow, key: number) => (
            <div key={key} className={styles.element}>
              <CardElementFix
                label={childElement.label}
                value={childElement.value}
                input={childElement.input}
                error={childElement.error}
                name={childElement.name}
                editionMode={childElement.editionMode}
                url={childElement.url}
                date={childElement.date}
              />
            </div>
          )}
        />

      </div>

      {
  specificData && specificData.length > 0
  && (
  <>
    <div className={styles.subTitle}><b>{t('tracking.flows.form.specificData')}</b></div>
    <div className={styles.container}>
      <GenericList
        items={specificData}
        itemRenderer={(childElement: SimpleCardFlow, key: number) => (
          <div key={key} className={styles.element}>
            <CardElementFix
              label={childElement.label}
              value={childElement.value}
              input={childElement.input}
              error={childElement.error}
              name={childElement.name}
              editionMode={childElement.editionMode}
              url={childElement.url}
              date={childElement.date}
            />
          </div>
        )}
      />

    </div>
        </>
      )}
      
      {editionMode && <ClassicButton type="submit" text={t('tracking.flows.form.submit')} />}
      </form>
  )
} />
  );
});

const InnerWrapper: React.FC<FlowProps> = React.memo(({ result }: FlowProps) => {
  const { t } = useTranslation();
  const history = useHistory()
  const authenticatedContext = React.useContext(AuthenticatedContext);
  const { audits } = result;
  const { flow } = result;
  const {
    data, header, manageOrdering, manageSearch,
  } = useLocalFilter(AuditsHeader, audits.results);

  const [editedFlow, setEditedFlow] = React.useState(flow);
  const [editionMode, setEditionMode] = React.useState(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [modalDisplay, setModalDisplay] = React.useState<boolean>(false);
  const [imageUrl, setImageUrl] = React.useState<string>(flow.attached_file ? flow.attached_file : '');
  const [files, setFiles] = React.useState<File | undefined>(undefined)



  const handleSidebar = () => {
    setVisible(!visible);
  }

  const saveFlowDoc = async () => {
    let newFlow : FlowWrite = {
      ...flow,
      attached_file : files && files
    };
    try {
      const response = await flowSubService.editFlowDocument(newFlow);
      setEditedFlow(response);
    } catch(error){
      console.log('error', error);
      error.code === 400 && toastHandler(t('tracking.flows.errorFile'), 'error');
    }
    openFileForm();
    history.push(`/tracking/flows/${flow.pk}`) //must be a better way
  };

  const onSave = async (flow: FlowClass) => {
    try {
      const response = await flowSubService.updateFlow({...editedFlow,...flow})
      setEditedFlow(response);
      setEditionMode(false);
    } catch {
      console.log('error')
    }
  }

  const handleFileChange = (file : File) => {
    setFiles(file);
  }

  const handleClick = () => {
    setEditionMode(!editionMode)
  }

  const openFileForm = () => {
    setModalDisplay(!modalDisplay);
  }

  React.useEffect(() => {
    imageUrl === '' && imageUrl !== flow.attached_file && flow.attached_file && setImageUrl(flow.attached_file)
  }, [imageUrl])


  return (
    <ContainerLayout>
       <Sidebar.Pushable>
      <Sidebar visible={visible} animation="overlay" direction="right" width="very wide" className={styles.overlay}>
      {visible &&  <AlertHistory type={'flow'} id={flow.pk} handleClick={handleSidebar }/>}
      </Sidebar>
      <Sidebar.Pusher onClick={visible ? handleSidebar : null}>
        <TitleLayout inverted>{t('tracking.flows.title')}</TitleLayout>
        <div className={styles.historyTrigger} onClick={handleSidebar}><History/> {`${t('alerts.alertHistory.getHistory')}`}</div>
        <div className={styles.subTitle}><b>{t('tracking.flows.form.genericData')}</b></div>
        <div className={styles.genericTitle}>
        <div className={styles.conform}>{flow.audit ? <BlockButton text={t('form.utils.compliant')} /> : <BlockButton warning text={t('form.utils.nonCompliant')} />}</div>
        <div className={styles.conform}>
          {
            // editionMode ?
            // <ClassicButton text={t('tracking.flows.form.cancel')} onClick={handleClick} warning />
            // :
            // <ClassicButton text={flow.audit ? t('tracking.flows.form.modify') : t('tracking.flows.form.correct') } onClick={handleClick} />
          }
          </div>
        <div className={styles.fileContainer}>
          {
            flow.attached_file && flow.attached_file !== '' ?
            <ClickableContainer className={styles.fileAvailable} link={`${flow.attached_file}?${authenticatedContext.azure}`}>
              <img src={eyeIcon} className={styles.icon} /><div className={styles.iconText}><b>{t('tracking.flows.file')}</b></div>
            </ClickableContainer>
            :
            <div className={styles.fileUnavailable}>
              <img src={eyeIconGrey} className={styles.eyeIcon} /><div className={styles.iconText}><b>{t('tracking.flows.file')}</b></div>
            </div>
          }
          <div className={styles.fileAvailable} >
            <img className={styles.addDocButton} src={plusPictoBlue} onClick={openFileForm}/>
            <div className={styles.iconText}><b>{flow.attached_file ? t('tracking.flows.changeFile') : t('tracking.flows.addFile')}</b></div>
          </div>
          <DocumentModal 
          open={modalDisplay}
          currentDoc={imageUrl}
          setDocUrl={setImageUrl}
          openFileForm={openFileForm}
          files={files}
          handleFileChange={handleFileChange}
          saveFlowDoc={saveFlowDoc}
          />
        </div>
        </div>
        
          <FlowForm 
            model={editedFlow}
            onSave={(model) => onSave(model)}
            flow={editedFlow}
            editionMode={editionMode}
          />

        <div>
          { audits.results && audits.results.length > 0
            ? (
              <>
                <div className={styles.subTitle}><b>{t('tracking.flows.form.auditData')}</b></div>
                <div className={styles.tableWrapper}>
                <GenericTable data={data} header={header} endFetching manageSearch={manageSearch} manageOrdering={manageOrdering} />
                </div>
              </>
            )
            : <div>{t('tracking.audits.noData')}</div>}
        </div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
    </ContainerLayout>
  );
});

export const TrackingFlowPage = enhancer(InnerWrapper);


export const CardElementFix: React.FC<SimpleCardFlow> = ({ label, value, input, name, editionMode, error, url, date }: SimpleCardFlow) => (
  <>{ !editionMode ? 
    <div className={styles.simpleCard} style={{border: error ? '1px solid red' : undefined}}>
    <div className={styles.cardLabel}><b>{label}</b></div>
    <div className={styles.cardValue}>{value}</div>
    </div>
    :
    url && url !== '' ?
    <Link to={url} style={{width: '100%'}}>
      <div className={styles.simpleCard} style={{border: error ? '1px solid red' : undefined}}>
        <div className={styles.cardLabel}><b>{label}</b></div>
      <div className={styles.cardValue}>{value}</div>
      <Icon name="write" className={styles.icon} />
      </div>
    </Link>
    :

    <div className={styles.simpleCard} style={{border: error ? '1px solid red' : undefined}}>
    <div className={styles.cardLabel}><b>{label}</b></div>
    {
       input && name && !date ?
      <InputControl
        name={name}
        required
        placeholder={label}
        icon={true}
        inline={true}
      />
      :
      date && name ?
      <DatePickerControl
        name={name}
        required
      />
      :
      <div className={styles.cardValue}>{value}</div>
    }
  </div>
}</>
);
