import { RulesProps } from '../models';

export const rules: RulesProps = {
  user: {
    static: [
      // === HOME ====
      // --- visit ---
      '/home:visit',

      // --- actions ---

      // === ALERTS ====
      // --- visit ---
      '/alerts:visit',
      '/alerts/:id:visit',
      '/alert:visit',
      '/alert/:id:visit',

      // --- actions ---

      // === LIVESCAN ====
      // --- visit ---
      '/livescan:visit',
      '/livescan/brand:visit',

      // --- actions ---

      // === PARAMETRE ====
      // --- visit ---
      '/parametre:visit',

      // --- actions ---

      // === ECOSYSTEM ====
      // === Group ===
      // --- visit ---
      '/ecosystem:visit',
      '/ecosystem/new-group:visit',
      '/ecosystem/edit-group/:id:visit',
      '/ecosystem/group/:id:visit',

      // --- actions ---

      // ===Item ===
      // --- visit ---
      '/ecosystem/:item/:id:visit',
      '/ecosystem/actors/:id:visit',
      '/ecosystem/sites/:id:visit',
      '/ecosystem/certificates/:id:visit',
      '/ecosystem/products/:id:visit',
      '/ecosystem/tools/:id:visit',

      // --- actions ---

      // // === Actor ===
      // // --- visit ---
      // '/ecosystem/new-actor:visit',
      // '/ecosystem/edit-actor/:id:visit',

      // // --- actions ---
      // '/ecosystem/actor:create',
      // '/ecosystem/actor:update',
      // '/ecosystem/actor:delete',

      // // === Site ===
      // // --- visit ---
      // '/ecosystem/new-site:visit',
      // '/ecosystem/edit-site/:id:visit',

      // // --- actions ---
      // '/ecosystem/site:create',
      // '/ecosystem/site:update',
      // '/ecosystem/site:delete',

      // // === Tool ===
      // // --- visit ---
      // '/ecosystem/new-tool:visit',
      // '/ecosystem/edit-tool/:id:visit',

      // // --- actions ---
      // '/ecosystem/tool:create',
      // '/ecosystem/tool:update',
      // '/ecosystem/tool:delete',

      // // === Product ===
      // // --- visit ---
      // '/ecosystem/new-product:visit',
      // '/ecosystem/edit-product/:id:visit',

      // // --- actions ---
      // '/ecosystem/product:create',
      // '/ecosystem/product:update',
      // '/ecosystem/product:delete',

      // // === Certficate ===
      // // --- visit ---
      // '/ecosystem/new-certificate:visit',
      // '/ecosystem/edit-certificate/:id:visit',

      // // --- actions ---
      // '/ecosystem/certificate:create',
      // '/ecosystem/certificate:update',
      // '/ecosystem/certificate:delete',

      // // === Relations ===
      // // --- visit --- 
      // '/ecosystem/relation:visit',
      // '/ecosystem/new-relation:visit',
      // '/ecosystem/edit-relation/:id:visit',

      // // --- actions ---
      // '/ecosystem/relation:create',
      // '/ecosystem/new-relation:create',
      // '/ecosystem/new-relation:update',
      // '/ecosystem/new-relation:delete',

      // === TRACKING ====
      // --- visit ---
      '/tracking/flows:visit',
      '/tracking/flows/:id:visit',
      '/tracking/batchs/:id:visit',

      // === SPECIFICATIONS ===
      // --- visit ---
      // A verifier
      "/specifications:visit",
      "/specifications/:id:visit",
      "/specifications/create:visit",
      "/specifications/edit/:id:visit",
      "/specification/results/:pk:visit",
      // --- acitons ---

      // === FOODCHAIN ===
      // --- visit ---
      "/foodchain/results/:name:visit",
      "/foodchain/results/:id:visit",
      // --- acitons ---

      // === FOLDERS ===
      // --- visit ---
      "/folders:visit",
      "/folders/:id:visit",
      "/folders/create:visit",
      "/folders/edit/:id:visit",
      // --- acitons ---

      // === PARAMETRE ====
      // --- visit ---
      // "/settings:visit",
      // --- actions ---

      // === CONTRACTS ====
      // --- visit ---
      "/contract/results/:pk:visit",
      "/contract/datas/:actor_code:visit",
      "/contract/autodiagnostic-ferme:visit",
      "/contract/autodiagnostic-ferme/:pk:visit"

      // --- actions ---
    ],
    dynamic: {
      'posts:edit': ({
        userId,
        postOwnerId,
      }: {
        userId: string;
        postOwnerId: string;
      }) => {
        if (!userId || !postOwnerId) return false;
        return userId === postOwnerId;
      },
    },
  },
  staff: {
    static: [
      // === HOME ====
      // --- visit ---
      '/home:visit',

      // --- actions ---

      // === ALERTS ====
      // --- visit ---
      '/alerts:visit',
      '/alerts/:id:visit',
      '/alert:visit',
      '/alert/:id:visit',

      // --- actions ---
      '/alert/:id:correct',

      // === LIVESCAN ====
      // --- visit ---
      '/livescan:visit',
      '/livescan/brand:visit',

      // --- actions ---

      // === PARAMETRE ====
      // --- visit ---
      '/parametre:visit',

      // --- actions ---

      // === ECOSYSTEM ====
      // === Group ===
      // --- visit ---
      '/ecosystem:visit',
      '/ecosystem/new-group:visit',
      '/ecosystem/edit-group/:id:visit',
      '/ecosystem/group/:id:visit',

      // --- actions ---

      // ===Item ===
      // --- visit ---
      '/ecosystem/:item/:id:visit',
      '/ecosystem/actors/:id:visit',
      '/ecosystem/sites/:id:visit',
      '/ecosystem/certificates/:id:visit',
      '/ecosystem/products/:id:visit',
      '/ecosystem/tools/:id:visit',

      // --- actions ---

      // === Actor ===
      // --- visit ---
      '/ecosystem/new-actor:visit',
      '/ecosystem/edit-actor/:id:visit',

      // --- actions ---
      '/ecosystem/actor:create',
      '/ecosystem/actor:update',
      '/ecosystem/actor:delete',

      // === Site ===
      // --- visit ---
      '/ecosystem/new-site:visit',
      '/ecosystem/edit-site/:id:visit',

      // --- actions ---
      '/ecosystem/site:create',
      '/ecosystem/site:update',
      '/ecosystem/site:delete',

      // === Tool ===
      // --- visit ---
      '/ecosystem/new-tool:visit',
      '/ecosystem/edit-tool/:id:visit',

      // --- actions ---
      '/ecosystem/tool:create',
      '/ecosystem/tool:update',
      '/ecosystem/tool:delete',

      // === Product ===
      // --- visit ---
      '/ecosystem/new-product:visit',
      '/ecosystem/edit-product/:id:visit',

      // --- actions ---
      '/ecosystem/product:create',
      '/ecosystem/product:update',
      '/ecosystem/product:delete',

      // === Certficate ===
      // --- visit ---
      '/ecosystem/new-certificate:visit',
      '/ecosystem/edit-certificate/:id:visit',

      // --- actions ---
      '/ecosystem/certificate:create',
      '/ecosystem/certificate:update',
      '/ecosystem/certificate:delete',

      // === Relations ===
      // --- visit --- 
      '/ecosystem/relation:visit',
      '/ecosystem/new-relation:visit',
      '/ecosystem/edit-relation/:id:visit',

      // --- actions ---
      '/ecosystem/relation:create',
      '/ecosystem/new-relation:create',
      '/ecosystem/new-relation:update',
      '/ecosystem/new-relation:delete',
      // === TRACKING ====
      // --- visit ---
      '/tracking/flows:visit',
      '/tracking/flows/:id:visit',
      '/tracking/batchs/:id:visit',

      // === SPECIFICATIONS ===
      // --- visit ---
      // A verifier
      "/specifications:visit",
      "/specifications/:id:visit",
      "/specifications/create:visit",
      "/specifications/edit/:id:visit",
      "/specification/results/:pk:visit",
      // --- acitons ---

      // === FOODCHAIN ===
      // --- visit ---
      "/foodchain/results/:name:visit",
      "/foodchain/results/:id:visit",
      // --- acitons ---

      // === FOLDERS ===
      // --- visit ---
      "/folders:visit",
      "/folders/:id:visit",
      "/folders/create:visit",
      "/folders/edit/:id:visit",
      // --- acitons ---

      // === PARAMETRE ====
      // --- visit ---
      // "/settings:visit",
      // --- actions ---

      // === CONTRACTS ====
      // --- visit ---
      "/contract/results/:pk:visit",
      "/contract/datas/:actor_code:visit",
      "/contract/autodiagnostic-ferme:visit",
      "/contract/autodiagnostic-ferme/:pk:visit"

      // --- actions ---
    ],
  },
  admin: {
    static: [
      // === HOME ====
      // --- visit ---
      '/home:visit',

      // --- actions ---

      // === ALERTS ====
      // --- visit ---
      '/alerts:visit',
      '/alerts/:id:visit',
      '/alert:visit',
      '/alert/:id:visit',
      // --- actions ---
      '/alert/:id:correct',

      // === LIVESCAN ====
      // --- visit ---
      '/livescan:visit',
      '/livescan/brand:visit',

      // --- actions ---

      // === PARAMETRE ====
      // --- visit ---
      '/parametre:visit',

      // --- actions ---

      // === ECOSYSTEM ====
      // === Group ===
      // --- visit ---
      '/ecosystem:visit',
      '/ecosystem/new-group:visit',
      '/ecosystem/edit-group/:id:visit',
      '/ecosystem/group/:id:visit',

      // --- actions ---

      // ===Item ===
      // --- visit ---
      '/ecosystem/:item/:id:visit',
      '/ecosystem/actors/:id:visit',
      '/ecosystem/sites/:id:visit',
      '/ecosystem/certificates/:id:visit',
      '/ecosystem/products/:id:visit',
      '/ecosystem/tools/:id:visit',

      // --- actions ---

      // === Actor ===
      // --- visit ---
      '/ecosystem/new-actor:visit',
      '/ecosystem/edit-actor/:id:visit',

      // --- actions ---
      '/ecosystem/actor:create',
      '/ecosystem/actor:update',
      '/ecosystem/actor:delete',

      // === Site ===
      // --- visit ---
      '/ecosystem/new-site:visit',
      '/ecosystem/edit-site/:id:visit',

      // --- actions ---
      '/ecosystem/site:create',
      '/ecosystem/site:update',
      '/ecosystem/site:delete',

      // === Tool ===
      // --- visit ---
      '/ecosystem/new-tool:visit',
      '/ecosystem/edit-tool/:id:visit',

      // --- actions ---
      '/ecosystem/tool:create',
      '/ecosystem/tool:update',
      '/ecosystem/tool:delete',

      // === Product ===
      // --- visit ---
      '/ecosystem/new-product:visit',
      '/ecosystem/edit-product/:id:visit',

      // --- actions ---
      '/ecosystem/product:create',
      '/ecosystem/product:update',
      '/ecosystem/product:delete',

      // === Certficate ===
      // --- visit ---
      '/ecosystem/new-certificate:visit',
      '/ecosystem/edit-certificate/:id:visit',

      // --- actions ---
      '/ecosystem/certificate:create',
      '/ecosystem/certificate:update',
      '/ecosystem/certificate:delete',

      // === Relations ===
      // --- visit --- 
      '/ecosystem/relation:visit',
      '/ecosystem/new-relation:visit',
      '/ecosystem/edit-relation/:id:visit',

      // --- actions ---
      '/ecosystem/relation:create',
      '/ecosystem/new-relation:create',
      '/ecosystem/new-relation:update',
      '/ecosystem/new-relation:delete',

      // === TRACKING ====
      // --- visit ---
      '/tracking/flows:visit',
      '/tracking/flows/:id:visit',
      '/tracking/batchs/:id:visit',

      // === SPECIFICATIONS ===
      // --- visit ---
      // A verifier
      "/specifications:visit",
      "/specifications/:id:visit",
      "/specifications/create:visit",
      "/specifications/edit/:id:visit",
      "/specification/results/:pk:visit",
      // --- acitons ---

      // === FOODCHAIN ===
      // --- visit ---
      "/foodchain/results/:name:visit",
      "/foodchain/results/:id:visit",
      // --- acitons ---

      // === FOLDERS ===
      // --- visit ---
      "/folders:visit",
      "/folders/:id:visit",
      "/folders/create:visit",
      "/folders/edit/:id:visit",
      // --- acitons ---

      // === PARAMETRE ====
      // --- visit ---
      // "/settings:visit",
      // --- actions ---

      // === CONTRACTS ====
      // --- visit ---
      "/contract/results/:pk:visit",
      "/contract/datas/:actor_code:visit",
      "/contract/autodiagnostic-ferme:visit",
      "/contract/autodiagnostic-ferme/:pk:visit"

      // --- actions ---
    ],
  },
};
