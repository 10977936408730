import { WithStatelessDatasFetchingByParameters } from "../../hocs";
import { ApiList } from "../../models";
import { SpecificationLight, GetSpecifications } from "../../models/specification-contract";
import { specificationSubService } from "../../services";
import { PageLoader } from "../../components";
import { FolderCreatePageProps } from ".";

export const enhancer = WithStatelessDatasFetchingByParameters<ApiList<SpecificationLight[]>, FolderCreatePageProps, GetSpecifications>(
    specificationSubService.getSpecifications,
    PageLoader,
    'redirect',
    'specifications',
    []
);
