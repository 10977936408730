export class ChangePasswordProps {
  oldPassword = '';

  newPassword = '';

  newPasswordConfirm = '';
}

export interface ChangePasswordFormProps {
  model: ChangePasswordProps;
  onSuccess: (model: ChangePasswordProps) => void;
}
