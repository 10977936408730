import React from 'react';

import styles from './title-layout.module.css';
import { TitleLayoutProps } from '.';

export const TitleLayout: React.FC<TitleLayoutProps> = ({ children, inverted }) => (
  <>
    <div className={`${styles.layout} ${inverted ? styles.inverted : styles.white}`}>
      {children}
    </div>

  </>
);
