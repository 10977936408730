import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './specification-wizard-component.module.css';
import { SpecificationWizardProps } from './specification-wizard-type';
import { Specification } from '../../../models';
import { specificationSubService } from '../../../services';
import { SpecificationForm } from '../../forms';
import checkPictoWhite from '../../../../assets/pictos/checkPictoWhite.png';
import trashPictoRed from '../../../../assets/pictos/trashPictoRed.png';
import specificationPictoBlue from '../../../../assets/pictos/productionspecificationsPictoBlue.png';
import editPictoWhite from '../../../../assets/pictos/editPictoWhite.png';
import { toastHandler } from '../../../utils';
import { WizardLoader } from '../utils';
import { redirectWithUrl } from '../utils/redirect';

export const SpecificationWizard: React.FC<SpecificationWizardProps> = React.memo(({ create, specification, initialStep }: SpecificationWizardProps) => {
  const [editedSpecification, setEditedSpecification] = React.useState<Specification>(specification);
  const [activeStep, setActiveStep] = React.useState(initialStep ? initialStep : 0);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const nextStep = () => {
    setActiveStep(activeStep === 1 ? 1 : activeStep + 1);
  };

  const previousStep = () => {
    setActiveStep((activeStep || 1) - 1);
  };

  const goTo = (index: number) => {
    setActiveStep(index);
  };

  const manageDeleteSpecification = async () => {
    if (editedSpecification.pk && editedSpecification.pk !== -1) {
      setLoading(true);
      try {
        const result = specificationSubService.deleteSpecification(editedSpecification.pk);
        toastHandler(t('wizard.specifications.deleteSuccess'), 'success');
        setLoading(false);
        history.push(redirectWithUrl(location.search, '/specifications'));
      } catch (err) {
        console.log(err);
        setLoading(false);
        toastHandler(t('wizard.specifications.deleteError'), 'error');
      }
    }
  };

  const manageCancel = () => {
    history.push(editedSpecification.pk ? redirectWithUrl(location.search, `/specifications/${editedSpecification.pk}`) : redirectWithUrl(location.search, '/specifications'));
  };

  // const onNextOwner = (owner: number[]) => {
  //   setEditedOwner(owner);
  //   nextStep();
  // };

  // const onSaveOwner = async (owner: number[]) => {
  //   setEditedOwner(owner);
  //   try {
  //     await onSaveGlobalSpecification(owner, editedSpecification);
  //     nextStep();
  //   } catch (err) {
  //     console.log('err: ', err);
  //   }
  // };

  const onSaveSpecification = async (specification: Specification) => {
    setEditedSpecification(specification);
    try {
      const result = await onSaveGlobalSpecification(specification);
      result && history.push(redirectWithUrl(location.search, `/specifications/${result.pk}`));
    } catch {
      setError('Une erreur est sruvenue sur general');
    }
  };

  const onSaveGlobalSpecification = async (specification: Specification) => {
    setLoading(true);
    try {
      const result: Specification = create ? await specificationSubService.createSpecification(specification) : await specificationSubService.editSpecification(specification);
      toastHandler(t('wizard.specification.saveSuccess'), 'success');
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
      toastHandler(t('wizard.specification.saveError'), 'error');
      setError('Une erreur est sruvenue sur general');
    }
  };


  const step = [
    // {
    //   index: 0,
    //   label: t('wizard.products.step2'),
    //   picto: productPictoBlue,
    //   Component: editedOwner
    //     ? (
    //       <OwnerForm
    //         model={editedOwner}
    //         onNext={(model) => onNextOwner(model)}
    //         onSave={editedSpecification.pk && editedSpecification.pk !== -1 ? (model) => onSaveOwner(model) : undefined}
    //         errorMessage={error}
    //         onCancel={manageCancel}
    //         include="products"
    //         blocking
    //         multiple
    //         specificParams={{ ordering: 'name' }}
    //       />
    //     )
    //     : <div>pas d'acteur id</div>,
    // },
    {
      index: 0,
      label: t('wizard.specification.step1'),
      picto: specificationPictoBlue,
      Component: <SpecificationForm
        model={editedSpecification}
        onSave={(model) => onSaveSpecification(model)}
        errorMessage={error}
        onCancel={manageCancel}
        // onPrevious={previousStep}
      />,
    },
  ];


  return (
    <div className={styles.wizard}>
      <div className={styles.form}>
        <div className={styles.activeStep}>
          <div className={styles.header}>
            <div className={styles.titlePictoContainer}><img src={step[activeStep].picto} className={styles.titlePicto} /></div>
            <div className={styles.titleLabel}><b>{step[activeStep].label}</b></div>
            {editedSpecification.pk && editedSpecification.pk !== -1
              && (
              <div className={styles.deleteContainer} onClick={manageDeleteSpecification}>
                <div className={styles.trashPictoContainer}><img src={trashPictoRed} className={styles.trashPicto} /></div>
                <div className={styles.deleteLabel}>{t('wizard.specification.delete')}</div>
              </div>
              )}
          </div>
          <div className={`${styles.formWrapper} ${loading ? styles.blured : ''}`}>
            {loading && <WizardLoader />}
            {step[activeStep].Component}
          </div>
        </div>

      </div>
      <div className={styles.track}>
        {
        step.map((el) => (
          activeStep === el.index
            ? (
              <div className={`${styles.container} ${styles.activeContainer}`} key={el.index}>
                <div className={`${styles.badge} ${styles.activeBadge}`}>{el.index + 1}</div>
                <div className={`${styles.label} ${styles.activeLabel}`}>{el.label}</div>
              </div>
            )
            : editedSpecification.pk
              ? (
                <div className={`${styles.container} ${styles.editContainer}`} key={el.index} onClick={() => goTo(el.index)}>
                  <div className={`${styles.badge} ${styles.editBadge}`}><img src={editPictoWhite} className={styles.editPicto} /></div>
                  <div className={`${styles.label} ${styles.editLabel}`}>{el.label}</div>
                </div>
              )
              : activeStep > el.index
                ? (
                  <div className={`${styles.container} ${styles.doneContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.doneBadge}`}><img src={checkPictoWhite} className={styles.checPicto} /></div>
                    <div className={`${styles.label} ${styles.doneLabel}`}>{el.label}</div>
                  </div>
                )
                : (
                  <div className={`${styles.container} ${styles.todoContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.todoBadge}`}>{el.index + 1}</div>
                    <div className={`${styles.label} ${styles.todoLabel}`}>{el.label}</div>
                  </div>
                )
        ))
      }
      </div>
    </div>
  );
});
