import React from "react";
import { SpecificationsHeaderProps } from "./specifications-page-type";
import styles from "./specifications-page.module.css";
import { Input, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import addIcon from "../../../assets/pictos/addPictoBlue.png";

export const SpecificationsHeader: React.FC<SpecificationsHeaderProps> = ({
  handleInput,
  handleSelectList,
  listSelected,
  checked,
}: SpecificationsHeaderProps) => {
  const [search, setSearch] = React.useState<string>("");
  const [inputFetcher, setInputFetcher] = React.useState<any>();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setSearch(e.target.value);
    if (inputFetcher) {
      clearTimeout(inputFetcher);
    }
    setInputFetcher(
      setTimeout(() => {
        e.target && handleInput(e.target.value);
      }, 500)
    );
  };

  return (
    <div className={styles.header} >
      {/* <div className={styles.input}>
        <Input
          onChange={handleChange}
          value={search || ""}
          className={styles.filterInput}
          placeholder="Rechercher une filière"
          icon
        >
          <Icon name="search" />
          <input />
        </Input>
      </div> */}
      {/* <div className={styles.add}>
        {checked ? (
          <Link to="/foodchains/create">
            <div className={styles.addButton}>
              <img src={addIcon} className={styles.addIcon} />
              <div className={styles.addText}>Créer une nouvelle filière</div>
            </div>
          </Link>
        ) : (
          <Link to="/specifications/create">
            <div className={styles.addButton}>
              <img src={addIcon} className={styles.addIcon} />
              <div className={styles.addText}>
                Créer un nouveau cahier des charges
              </div>
            </div>
          </Link>
        )}
      </div> */}
    </div>
  );
};
