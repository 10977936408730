import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./foodchain-results-page.module.css";
import { FoodchainInfocardProps } from "./foodchain-results-page-type";
import { foodchainInfocardEnhancer } from "./resultEnhancer";
import upArrow from "../../../assets/pictos/up-green-arrow.svg";
import neuArrow from "../../../assets/pictos/white-down-arrow.svg";
import downArrow from "../../../assets/pictos/red-down-arrow.svg";
import iconDown from "../../../assets/pictos/iconDown.png";
import iconUp from "../../../assets/pictos/iconUp.png";
import { useLocation } from 'react-router-dom'
import { useParams } from "../../hooks";
import { Console } from "console";

const InnerFoodchainInfocard: React.FC<FoodchainInfocardProps> = ({
  foodchain,
}: FoodchainInfocardProps) => {
  const { t } = useTranslation();

  if (!foodchain) {
    return <div>Pas de résultat</div>;
  }

  const renderFoodchainVariation = () => {
    return foodchain.variation.map((variation, index) => (
      <div key={index}>{variation.variation_pp}</div>
    ));
  };

  const compliant = window.localStorage.getItem('compliant')
  const total_contracts = window.localStorage.getItem('total_contracts')
  return (
    <div className={styles.middleHeader}>
      <div className={styles.infoCard}>
        <div>
          <div className={styles.infoTitle}>
            <strong>{t("foodchainResults.compliantRate")}</strong> <br />
          </div>
          {/* <div style={{ marginTop: "10%" }}>
            <div>
              <div>
                <div
                  style={{
                    float: "right",
                    margin: "-12% 56% 0 0",
                    display: "block",
                    padding: "8px",
                  }}
                >
                  <div style={{ margin: "47% 0% -33px -18px" }}>
                   
                    {foodchain.compliance_proportion >
                    foodchain.last_month_compliance
                      .last_month_compliance_proportion ? (
                      <img
                        src={upArrow}
                        alt="imglogo"
                        className={styles.logoSvg}
                      />
                    ) : foodchain.compliance_proportion <
                      foodchain.last_month_compliance
                        .last_month_compliance_proportion ? (
                      <img
                        src={downArrow}
                        alt="imglogo"
                        className={styles.logoSvg}
                      />
                    ) : foodchain.compliance_proportion ===
                      foodchain.last_month_compliance
                        .last_month_compliance_proportion ? (
                      <img
                        src={neuArrow}
                        alt="imglogo"
                        className={styles.logoSvg}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <h1 style={{ fontSize: "26px" }}>
                    <strong>{foodchain.compliance_proportion}%</strong>
                  </h1>
                </div>
              </div>

              <div style={{ float: "left" }}>
                <div
                  style={{
                    padding: "10px 8px 0 0",
                    marginLeft: "119px",
                    marginTop: "-53px",
                  }}
                >
                  <strong>
                    <span style={{ fontSize: "26px" }}>
                      {foodchain.compliant_contracts}
                    </span>{" "}
                    <span style={{ fontSize: "0.6em" }}>
                    {t('foodchainResults.compliant.simple')}{foodchain.compliant_contracts}{" "}
                    {t('foodchainResults.outOf')} {foodchain.total_contracts}
                    </span>
                  </strong>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div
                  style={{
                    float: "right",
                    margin: "-17% 55% 0 0",
                    display: "block",
                    padding: "8px",
                  }}
                >
                  <div style={{ float: "right", margin: "45% 0 0 0" }}>
                    <div style={{ fontSize: "1rem" }}>
                      vs.{" "}
                      {
                        foodchain.last_month_compliance
                          .last_month_compliance_proportion
                      }
                      % à J-
                      {foodchain.last_month_compliance.timedelta_days}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    float: "left",
                    margin: "-19% 0 0 65%",
                    display: "block",
                  }}
                >
                  <div style={{ float: "left", margin: "43% 0 0 0" }}>
                    <div style={{ fontSize: "1rem" }}>
                      vs.{" "}
                      {
                        foodchain.last_month_compliance
                          .last_month_compliant_contracts
                      }{" "}
                      à J-
                      {foodchain.last_month_compliance.timedelta_days}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className={styles.cardText}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            {console.log('cardtext', foodchain)}
            <strong>
              {foodchain.compliance_proportion >
              foodchain.last_month_compliance
                .last_month_compliance_proportion ? (
                <img src={upArrow} alt="imglogo" className={styles.logoSvg} />
              ) : foodchain.compliance_proportion <
                foodchain.last_month_compliance
                  .last_month_compliance_proportion ? (
                <img src={downArrow} alt="imglogo" className={styles.logoSvg} />
              ) : foodchain.compliance_proportion ===
                foodchain.last_month_compliance
                  .last_month_compliance_proportion ? (
                <img src={neuArrow} alt="imglogo" className={styles.logoSvg} />
              ) : (
                ""
              )}
              {foodchain.compliance_proportion}%
            </strong>
            &nbsp;&nbsp; <strong>{compliant}</strong>
            <strong className={styles.smallText}>
              {" "}
              {t("foodchainResults.compliant.simple")} &nbsp;
              {t("foodchainResults.outOf")} {total_contracts}
            </strong>{" "}
            {/* <strong> {foodchain.total_contracts}</strong> */}
          </div>
          <p className={styles.cardTextSub}>
            {foodchain.last_month_compliance.last_month_compliance_proportion &&
            foodchain.last_month_compliance.timedelta_days &&
            foodchain.last_month_compliance.last_month_compliant_contracts &&
            foodchain.last_month_compliance.timedelta_days ? (
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; vs.{" "}
                {
                  foodchain.last_month_compliance
                    .last_month_compliance_proportion
                }
                % {t("foodchainResults.atDay")}
                {foodchain.last_month_compliance.timedelta_days}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                vs.{" "}
                {
                  foodchain.last_month_compliance.last_month_compliant_contracts
                }{" "}
                {t("foodchainResults.atDay")}
                {foodchain.last_month_compliance.timedelta_days}
              </>
            ) : null}
          </p>
        </div>
      </div>
      {/* <div className={`${styles.infoCard} ${styles.infoCardInd}`}> */}
      <div className={styles.card}>
        <div>
          <div className={styles.infoTitleCard}>
            <strong>{t("foodchainResults.raking")} </strong>
          </div>

          {/* <div style={{ margin: "-5% 0px 0px 2%" }}>
            <div
              className={styles.complianceInfo}
              style={{ marginTop: "7%", float: "left" }}
            >
              <div style={{ margin: "-13% 0% 2% 21%" }}>
                <img
                  src={iconUp}
                  alt="iconUp"
                  className={styles.logoSvgThumb}
                />
              </div>
              <strong style={{ fontSize: "10px" }}>
                {foodchain.major_indicators_ranking.largest_compliant_indicators.map(
                  (indicator, i) => (
                    <div className={styles.indicators} key={i}>
                      {" "}
                      {indicator.charAt(0).toUpperCase() +
                        indicator.slice(1)}{" "}
                    </div>
                  )
                )}
              </strong>
            </div>

            <div
              className={styles.complianceInfo}
              style={{ float: "right", margin: "-15% -14% 0px 56%" }}
            >
              <div style={{ margin: "-24% 0% 0% 31%" }}>
                <img
                  src={iconDown}
                  alt="iconDown"
                  className={styles.logoSvgThumb}
                />
              </div>
              <strong style={{ fontSize: "1rem" }}>
                {foodchain.major_indicators_ranking.largest_not_compliant_indicators.map(
                  (indicator, i) => (
                    <div className={styles.indicators} key={i}>
                      {" "}
                      {indicator.charAt(0).toUpperCase() +
                        indicator.slice(1)}{" "}
                    </div>
                  )
                )}
              </strong>
            </div>
          </div> */}

          {/* <div style={{ marginTop: "11%" }}>
            <div>
              <div>
                <div
                  style={{
                    float: "right",
                    margin: "-12% 71% 0 0",
                    display: "block",
                    padding: "8px",
                  }}
                >
                  <div style={{ margin: "47% 0% -33px -18px" }}>
                    <img
                      src={iconUp}
                      alt="iconUp"
                      className={styles.logoSvgThumb}
                    />
                  </div>
                </div>
              </div>

              <div style={{ float: "left" }}>
                <div
                  style={{
                    padding: "12px 8px 2px 0",
                    marginLeft: "230px",
                    marginTop: "-28px",
                  }}
                >
                  <img
                    src={iconDown}
                    alt="iconDown"
                    className={styles.logoSvgThumb}
                  />
                </div>
              </div>
            </div>

            <div>
              <div>
                <div
                  style={{
                    float: "right",
                    margin: "-26% 35% 0px 0px",
                    display: "block",
                    padding: "8px",
                  }}
                >
                  <div style={{ float: "right", margin: "45% 0 0 0" }}>
                    <strong style={{ fontSize: "9px" }}>
                      {foodchain.major_indicators_ranking.largest_compliant_indicators.map(
                        (indicator, i) => (
                          <div className={styles.indicators} key={i}>
                            {" "}
                            {indicator.charAt(0).toUpperCase() +
                              indicator.slice(1)}{" "}
                          </div>
                        )
                      )}
                    </strong>
                  </div>
                </div>

                <div
                  style={{
                    float: "left",
                    margin: "-42% 0 0 70%",
                    display: "block",
                    padding: "8px",
                  }}
                >
                  <div style={{ float: "left", margin: "63% 0 0 0" }}>
                    <strong style={{ fontSize: "9px" }}>
                      {foodchain.major_indicators_ranking.largest_not_compliant_indicators.map(
                        (indicator, i) => (
                          <div className={styles.indicators} key={i}>
                            {" "}
                            {indicator.charAt(0).toUpperCase() +
                              indicator.slice(1)}{" "}
                          </div>
                        )
                      )}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className={styles.globalBloc}>
            <div className={styles.blocUp}>
              <div className={styles.blocImage}>
                <img
                  src={iconUp}
                  alt="iconUp"
                  className={styles.logoSvgThumb}
                />
              </div>
              <div className={styles.blocContentLeft}>
                <div>
                  {foodchain.major_indicators_ranking.largest_compliant_indicators.map(
                    (indicator, i) => (
                      <div className={styles.indicatorTitle} key={i}>
                        {" "}
                        {indicator.charAt(0).toUpperCase() +
                          indicator.slice(1)}{" "}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className={styles.blocDown}>
              <div className={styles.blocImage}>
                <img
                  src={iconDown}
                  alt="iconDown"
                  className={styles.logoSvgThumb}
                />
              </div>
              <div className={styles.blocContentRight}>
                <div>
                  {foodchain.major_indicators_ranking.largest_not_compliant_indicators.map(
                    (indicator, i) => (
                      <div className={styles.indicatorTitle} key={i}>
                        {" "}
                        {indicator.charAt(0).toUpperCase() +
                          indicator.slice(1)}{" "}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.infoCard}>
        <div>
          <strong>TOTAL NON CONFORMES MINEURS</strong>
        </div>
        <div>
          <div>
            <h3>
              {foodchain.not_compliant_contracts_min}/
              {foodchain.total_contracts}
            </h3>
          </div>
          <div>
            <strong>{foodchain.variation_not_compliant_minor}</strong>
            111
          </div>
        </div>
      </div> */}
    </div>
  );
};

export const FoodchainInfocard = foodchainInfocardEnhancer(
  InnerFoodchainInfocard
);
