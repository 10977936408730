import React from 'react';
import {
  ContextProviderProps,
  OnBeforeUnloadingState,
} from '../models';
import { ModalButton } from '../components/confirmation-modal/confirmation-modal-type';

const defaultOnBeforeUnloadingState: OnBeforeUnloadingState = {
  action: false,
  setAction: () => {},
  next: () => {},
  setNext: () => {},
  setActiveContext: () => {},
  activeContext: false,
  message: '',
  buttons: [],
  setMessage: () => {},
  setButtons: () => {}
};

export const OnBeforeUnloadingContext = React.createContext<OnBeforeUnloadingState>(
  defaultOnBeforeUnloadingState,
);

export const OnBeforeUnloadingContextProvider = (
  props: ContextProviderProps,
): React.ReactElement => {
  const { children } = props;

  const [activeContext, setActiveContext]= React.useState<boolean>(false);
  //action = controle l'ouverture de la modale d'interception
  const [action, setAction]= React.useState<boolean>(false);
  // next = fonction à trigger sur si on doit poursuivre (save ou continuer sans sauvegarder par exemple)
  const [next, setNext]= React.useState<any>(() => {});
  //message et buttons = contenu de la modale
  const [message, setMessage]= React.useState<string>('');
  const [buttons, setButtons]= React.useState<ModalButton[]>([]);
  
  

  return (
    <OnBeforeUnloadingContext.Provider
      value={{
        setActiveContext,
        activeContext,
        action,
        setAction,
        buttons,
        message,
        setButtons,
        setMessage,
        next,
        setNext
      }}
    >
      {children}
    </OnBeforeUnloadingContext.Provider>
  );
};
