import * as React from 'react';

import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './body-component.module.css';
import { BodyProps } from './body-type';
import { DataTable } from '../header/header-type';
import {
  HeaderColumn,
} from '../../../models';
import { formatTableData } from '../utils';

export function Body<T>({
  data, header, displayList
}: BodyProps<T>) {
  const { t } = useTranslation();
  return (
    <>
      <Table.Body>
        {data.map((el: DataTable<T>) => (
          <Table.Row key={el.pk}>
            {header.filter(el => {
          if (displayList && displayList.length > 0) {
            return displayList.includes(el.columnName)
          } else {
            return true
          }
        }).map((el2: HeaderColumn<T>, key) => (
              <React.Fragment key={key}>{formatTableData(t, el2.dataTransform, el[el2.columnName])}</React.Fragment>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </>
  );
}
