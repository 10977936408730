import React from "react";
import querystring from "query-string";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoginForm } from "../../../components";
import { LoginProps } from "./login-page-type";
import styles from "./login-page.module.css";
import { AuthenticatedContext } from "../../../contexts";
import { AuthLayout } from "../../../layouts";
import backgroundImage from "../../../../assets/images/backgroundLogin.jpg";
import CFLogo from "../../../../assets/images/CF.png";
import { authenticationService, authSubService } from "../../../services";
import { toastHandler } from "../../../utils";
import { posthogEvents } from "../../../utils/posthog";

const storageKey = process.env.REACT_APP_AUTHTOKEN
  ? process.env.REACT_APP_AUTHTOKEN
  : "";

export const LoginPage: React.FC<LoginProps> = () => {
  const trafficSource: string = window.location.toString().includes("mail")
    ? "mail"
    : "direct";
  const authenticatedContext = React.useContext(AuthenticatedContext);
  const { t } = useTranslation();
  let errorMessage: string | undefined;
  authenticatedContext.folder &&
    posthogEvents.identification(
      "permission",
      authenticatedContext.folder.permission
    );

  const login = async (email: string, password: string, remember: boolean) => {
    try {
      const result = await authenticationService.login(email, password);

      localStorage.setItem(
        storageKey,
        JSON.stringify({ ...result.token, remember })
      );
      const azure = await authSubService.getSasToken();
      authenticatedContext.setAuthenticated({ ...result, azure: azure.sas });
      posthogEvents.login(email);
      posthogEvents.triggerEvent({ name: "login", datas: { trafficSource } });
    } catch (err) {
      errorMessage = "error auth";
      toastHandler(t("authentication.actions.loginError"), "error");
      posthogEvents.triggerEvent({
        name: "error",
        datas: { login: errorMessage, trafficSource },
      });
    }
  };

  const location = useLocation();
  const search = querystring.parse(location.search);
  const isAuthenticated = authenticatedContext.authenticated;
  return (
    <AuthLayout image={backgroundImage}>
      <div className={styles.content}>
        <div className={styles.header}>
          <img src={CFLogo} className={styles.logo} />
          <div className={styles.title}>
            <b>{t("authentication.header.title")}</b>
          </div>
        </div>

        <div className={styles.formWrapper}>
          <LoginForm
            model={{ email: "", password: "", remember: false }}
            isAuthenticated={isAuthenticated}
            redirectPath={search.redirectPath as string}
            onSuccess={(model: any) =>
              login(model.email, model.password, model.remember)
            }
            errorMessage={errorMessage}
          />
        </div>

        <div className={styles.footer}>
          <h6>{t("authentication.header.footer")}</h6>
        </div>
      </div>
    </AuthLayout>
  );
};
