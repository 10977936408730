import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangePasswordForm } from '../../components';
import { ChangePasswordProps } from '.';
import {
  ColorLayout, TitleLayout, ContainerLayout,
} from '../../layouts';
import { authSubService } from '../../services';
import { toastHandler } from '../../utils';

export const SettingsPage: React.FC = () => {
  const { t } = useTranslation();

  let errorMessage: string | undefined;

  const updatePassword = async (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string,
  ) => {
    try {
      const result = await authSubService.updatePassword(
        oldPassword,
        newPassword,
        newPasswordConfirm,
      );
      toastHandler(t('authentication.actions.updateSuccess'), 'success');
    } catch (err) {
      toastHandler(t('authentication.actions.updateError'), 'error');
    }
  };

  return (
    <ColorLayout color="#0a405d">
      <ContainerLayout style={{ backgroundColor: 'white' }}>
        <TitleLayout inverted>{t('settings.title')}</TitleLayout>
        <ChangePasswordForm
          model={new ChangePasswordProps()}
          onSuccess={(model: ChangePasswordProps) => {
            updatePassword(
              model.oldPassword,
              model.newPassword,
              model.newPasswordConfirm,
            );
          }}
          errorMessage={errorMessage}
        />
      </ContainerLayout>
    </ColorLayout>
  );
};
