import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';
import { Router } from './modules';
import {
  AuthenticatedContextProvider,
  SidebarContextProvider,
  OnBeforeUnloadingContextProvider,
} from './modules/contexts';

import './App.css';



export const App: React.FC = () => (
  <div className="mainLayout">
    <I18nextProvider i18n={i18n}>
      <AuthenticatedContextProvider>
        <SidebarContextProvider>
          <OnBeforeUnloadingContextProvider>
            <Router />
          </OnBeforeUnloadingContextProvider>
        </SidebarContextProvider>
      </AuthenticatedContextProvider>
    </I18nextProvider>
  </div>
);
