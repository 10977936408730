import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './group-wizard-component.module.css';
import { GroupWizardProps } from './group-wizard-type';
import { NewGroup } from '../../../models';
import { groupSubService } from '../../../services';
import { OwnerForm, GroupForm } from '../../forms';
import checkPictoWhite from '../../../../assets/pictos/checkPictoWhite.png';
import sitePictoBlue from '../../../../assets/pictos/sitePictoBlue.svg';
import trashPictoRed from '../../../../assets/pictos/trashPictoRed.png';
import editPictoWhite from '../../../../assets/pictos/editPictoWhite.png';
import { WizardLoader } from '../utils';
import { toastHandler } from '../../../utils';

export const GroupWizard: React.FC<GroupWizardProps> = React.memo(({ edit, initialGroup }: GroupWizardProps) => {
  const [editedGroup, setEditedGroup] = React.useState<Omit<NewGroup, 'group_actors'>>(initialGroup);
  const [actors, setActors] = React.useState<number[]>(initialGroup.group_actors);
  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();


  const nextStep = () => {
    setActiveStep(activeStep === 1 ? 1 : activeStep + 1);
  };

  const previousStep = () => {
    setActiveStep((activeStep || 1) - 1);
  };

  const goTo = (index: number) => {
    setActiveStep(index);
  };

  const manageDeleteGroup = async () => {
    if (editedGroup.pk && editedGroup.pk !== -1) {
      setLoading(true);
      try {
        const result = await groupSubService.deleteGroup(editedGroup.pk);
        setLoading(false);
        toastHandler(t('wizard.groups.deleteSuccess'), 'success');
        history.push('/ecosystem');
      } catch (err) {
        console.log(err);
        setLoading(false);
        toastHandler(t('wizard.groups.deleteError'), 'error');
      }
    }
  };

  const managePrevious = () => {
    previousStep();
  };

  const manageCancel = () => {
    history.push(editedGroup.pk ? `/ecosystem/group/${editedGroup.pk}` : '/ecosystem');
  };


  const onNextOwner = (owner: number[]) => {
    setActors(owner);
    nextStep();
  };

  const onSaveOwner = async (owner: number[]) => {
    setActors(owner);
    try {
      await onSaveGlobalGroup(owner, editedGroup);
      nextStep();
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const onSaveGroup = async (group: Omit<NewGroup, 'group_actors'>) => {
    setEditedGroup(group);
    try {
      const result = await onSaveGlobalGroup(actors, group);
      result && history.push(`/ecosystem/group/${result.pk}`);
    } catch {
      setError(t('table.utils.error'));
    }
  };

  const onSaveGlobalGroup = async (owner: number[], group: Omit<NewGroup, 'group_actors'>) => {
    setLoading(true);
    try {
      if (owner.length > 0 && owner[0] !== -1) {
        const result: NewGroup = editedGroup.pk && editedGroup.pk !== -1 ? await groupSubService.editGroup({ ...group, group_actors: owner }) : await groupSubService.createGroup({ ...group, group_actors: owner });
        setLoading(false);
        toastHandler(t('wizard.groups.saveSuccess'), 'success');
        return result;
      }
      setLoading(false);
      setError('il manque un proprietaire');
    } catch {
      toastHandler(t('wizard.groups.saveError'), 'error');
      setLoading(false);
      setError(t('table.utils.error'));
    }
  };


  const step = [
    {
      index: 0,
      label: t('wizard.groups.step1'),
      picto: sitePictoBlue,
      Component: actors
        ? (
          <OwnerForm
            model={actors}
            onNext={(model) => onNextOwner(model)}
            onSave={editedGroup.pk && editedGroup.pk !== -1 ? (model) => onSaveOwner(model) : undefined}
            errorMessage={error}
            onCancel={manageCancel}
            include="groups"
            blocking
            multiple
            specificParams={{ ordering: 'name' }}
          />
        )
        : <div>pas d'acteur id</div>,
    },
    {
      index: 1,
      label: t('wizard.groups.step2'),
      picto: sitePictoBlue,
      Component: <GroupForm
        model={editedGroup}
        onSave={(model) => onSaveGroup(model)}
        errorMessage={error}
        onCancel={manageCancel}
        onPrevious={managePrevious}
      />,
    },
  ];


  return (
    <div className={styles.wizard}>
      <div className={styles.form}>
        <div className={styles.activeStep}>
          <div className={styles.header}>
            <div className={styles.titlePictoContainer}><img src={step[activeStep].picto} className={styles.titlePicto} /></div>
            <div className={styles.titleLabel}><b>{step[activeStep].label}</b></div>
            {editedGroup.pk && editedGroup.pk !== -1
              && (
              <div className={styles.deleteContainer} onClick={manageDeleteGroup}>
                <div className={styles.trashPictoContainer}><img src={trashPictoRed} className={styles.trashPicto} /></div>
                <div className={styles.deleteLabel}>{t('wizard.groups.delete')}</div>
              </div>
              )}
          </div>
          <div className={`${styles.formWrapper} ${loading ? styles.blured : ''}`}>
            {loading && <WizardLoader />}
            {step[activeStep].Component}
          </div>
        </div>
      </div>
      <div className={styles.track}>
        {
        step.map((el) => (
          activeStep === el.index
            ? (
              <div className={`${styles.container} ${styles.activeContainer}`} key={el.index}>
                <div className={`${styles.badge} ${styles.activeBadge}`}>{el.index + 1}</div>
                <div className={`${styles.label} ${styles.activeLabel}`}>{el.label}</div>
              </div>
            )
            : editedGroup.pk
              ? (
                <div className={`${styles.container} ${styles.editContainer}`} key={el.index} onClick={() => goTo(el.index)}>
                  <div className={`${styles.badge} ${styles.editBadge}`}><img src={editPictoWhite} className={styles.editPicto} /></div>
                  <div className={`${styles.label} ${styles.editLabel}`}>{el.label}</div>
                </div>
              )
              : activeStep > el.index
                ? (
                  <div className={`${styles.container} ${styles.doneContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.doneBadge}`}><img src={checkPictoWhite} className={styles.checPicto} /></div>
                    <div className={`${styles.label} ${styles.doneLabel}`}>{el.label}</div>
                  </div>
                )
                : (
                  <div className={`${styles.container} ${styles.todoContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.todoBadge}`}>{el.index + 1}</div>
                    <div className={`${styles.label} ${styles.todoLabel}`}>{el.label}</div>
                  </div>
                )
        ))
      }
      </div>
    </div>
  );
});
