import { buildUrlParameters } from '../../utils';
import { backendTokenService, securedRestClient } from '../../backend';
import {
  ApiList, ProductWrite, ProductRead, GetProduct,
} from '../../models';

export const productSubService = {
  // ===============
  // ==== PRODUCT ====
  // ===============

  // --- CREATE PRODUCT ---

  createProduct: async (product: ProductWrite) => {
    // console.log(
    //   'ecosystem service createproduct, product : ',
    //   product,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/products/`;
    const response = await securedRestClient.post<ProductRead>(requestUrl, product);
    // console.log(response.data);
    return response.data;
  },

  // --- READ PRODUCT ---

  getProduct: async ({
    is_placeholder, group_id, id, owner, certificate, limit, offset, ordering, name, internal_code, unit, gs1, gs1_type, owner_name,
  }: GetProduct) => {
    // console.log(
    //   'ecosystem service getProduct, id : ', id,
    //   'owner', owner,
    //   'certificate', certificate,
    //   'limit', limit,
    //   'offset', offset,
    //   'ordering', ordering,
    //   'name', name,
    //   'internal_code', internal_code,
    //   'unit', unit,
    //   'gs1_type', gs1_type,
    //   'gs1', gs1,
    //   'group_id', group_id,
    //   'owner_name', owner_name,
    //   'is_placeholder', is_placeholder,
    // );

    const params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'group_id', value: group_id },
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'ordering', value: ordering },
      { prop: 'owner', value: owner },
      { prop: 'id', value: id },
      { prop: 'certificate', value: certificate },
      { prop: 'name', value: name },
      { prop: 'internal_code', value: internal_code },
      { prop: 'unit', value: unit },
      { prop: 'gs1', value: gs1 },
      { prop: 'gs1_type', value: gs1_type },
      { prop: 'owner_name', value: owner_name },
      { prop: 'is_placeholder', value: is_placeholder },
    ];
    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/products/${id || ''}?${paramUrl}`;
    const response = await securedRestClient.get<ApiList<ProductRead[]>>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  // --- UPDATE PRODUCT ---

  editProduct: async (product: ProductWrite) => {
    // console.log(
    //   'ecosystem service putproduct, product : ',
    //   product,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/products/${product.pk}`;
    const response = await securedRestClient.put<ProductRead>(requestUrl, product);
    // console.log(response.data);
    return response.data;
  },

  // --- DELETE PRODUCT ---

  deleteProduct: async (id: number) => {
    // console.log(
    //   'ecosystem service deleteproduct, product id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/products/${id}`;
    const response = await securedRestClient.delete(requestUrl);
    // console.log(response.data);
    return response.data;
  },

};
