import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './cell-batch.module.css';
import { FlowAlert, Flow, AlertLight } from '../../../models';
import { ClassicButton } from '../../../components/buttons';
import { Modal, Table, Header, Input } from 'semantic-ui-react';
import { CustomPopup } from './custom-popup';
import { useHistory, useLocation } from 'react-router-dom';
import { setMaxListeners } from 'process';


export interface BatchCellProps {
  messages: string[];
  enabled: boolean;
  initialFlow?: Flow;
  flow: Flow;
  error: boolean;
  editFlow: (flow: Flow) => void;
  batch_fields: (keyof Flow)[];
  save: (newFlow: Flow) => Promise<void>;
  batchIn: boolean;
  content?: AlertLight[]
}

export const BatchCell = ({messages, enabled, initialFlow, flow, error, editFlow, batch_fields, save, batchIn, content}: BatchCellProps) => {


  

  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const {t} = useTranslation();

  const openModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
  }

  

  


  const saveFlow = async () => {
    try {
      await save({
        ...flow,
        ...changes,
        specific_data: {
          ...flow.specific_data,
          ...specificChanges
        }
      });
      // await retrack(flow.pk);
      // history.push({
      //   pathname: `/alert/${location.pathname.split('/')[2]}`,
      //   state: {filter: 'connection_key'}
      // })
    } catch (e) {
      console.log(e)
    }

    setOpen(false)
  }

  const initialChanges = {};
  const initialSpecificChanges = {};

  const initializeBatchValue = (flow:Flow, batch_fields: (keyof Flow)[]) => {
    batch_fields.forEach((field:any) => {
      //ici il faut filtrer pour ne pas prendre en compte les cas des elements du referentiel
      //actor, site, tool sender et receiver et product
      if (field.specific_data && Array.isArray(field.specific_data)) {
        field.specific_data.forEach((specificField:any) => {
          (initialSpecificChanges as any)[specificField] = flow.specific_data ? flow.specific_data[specificField]: ''
        })
      } else {
        (initialChanges as any)[field] = flow[field as keyof Flow];
      }
    })
  }


  

  const checkMsg = () => {
  let msgReceived = messages.length>0 ? messages[0].split('"')[1] : "";
  const messageChecked = msgReceived.replace(/\\/gm, "");
  return messageChecked === '{}' ? '' : messageChecked;
  }
  initializeBatchValue(flow, batch_fields);

  const [changes, setChanges] = React.useState(initialChanges);
  const [specificChanges, setSpecificChanges] = React.useState(initialSpecificChanges);
  const [cellContent, setCellContent] = React.useState<string>('')
  const initializeSpecCHangeContent = () => {
    const temp : string[] = [];
    Object.keys(specificChanges).map(el => {
      temp.push((specificChanges as any)[el])
    })
    return temp;
    };
  
  
  const [specChangesContent, setSpecChangesContent] = React.useState<string[]>(initializeSpecCHangeContent());

    // console.log('changes', changes, 'spec changes', specificChanges)
    
  

  const handleChange = (name: string, value: string) => {
    // console.log('changes', name, value)
    setCellContent(value)
    setChanges({
      ...changes,
      [name]: value
    })
  }

  const handleSpecificChange = (name: string, value: string) => {
    let tempvalue : string[] = [...specChangesContent];
    //name = le nom de la propriété de spec change que je change.
    Object.keys(specificChanges).map((el, index) => {
      if(el === name){
        tempvalue[index] = value
      }
    })
    let fullCellContent = '';
    tempvalue.map(el => {
      fullCellContent = `${fullCellContent} ${el}`;
    })
    setCellContent(fullCellContent)

    setSpecificChanges({
      ...specificChanges,
      [name]: `${flow.batch_in? flow.batch_in?.name : ''}${value}`
    })
  }

  return (
    <Modal className={styles.modalBloc}
      closeIcon
      trigger={
        <CustomPopup messages={messages}>
          <Table.Cell
            onClick={() => error && enabled && openModal()}
            style={{
              border: !error ? undefined : enabled ?'1px solid blue' : '1px solid red',
              backgroundColor: enabled && error ? 'white' : 'transparent',
              cursor: enabled && error ? 'pointer' : 'default',
            }}
          >
            <div 
              className={styles.ecosystemDisabled} 
              style={{ color: !error ? 'black' : 'red' }}
              >
                {batchIn ? flow.batch_in ? flow.batch_in.name : cellContent.length>0? cellContent : checkMsg()  : flow.batch_out ? flow.batch_out.name : cellContent.length>0? cellContent : checkMsg()}
            </div>
          </Table.Cell>
        </CustomPopup>
      }
      open={open}
      onClose={closeModal}
    ><Header style={{backgroundColor: "#F7F7F8"}}>
        <div className={styles.modalHeader}>
         {t(`alerts.batch_modal.title_${batchIn ? 'batch_in' : 'batch_out'}`)}
      </div>
      </Header>
      <Modal.Content style={{ height: '100%', backgroundColor: "#F7F7F8" }}>
        <div className={styles.modalContent}>
          <div>
            {
              Object.keys(changes).map((el, key) => {
                return (
                  <div key={key}>
                    <div>
                    {el === 'tool_sender' || el === 'tool_receiver' || el === 'site_receiver' || el === 'site_sender' ? 
                    <div>
                      <div>/!\ Pour modifier le site ou l'outil, modifiez les données écosystème au préalable</div>
                      {t(`alerts.batchError.${el}`)}
                      </div>
                      :
                      <div> 
                      {el}
                      </div>}
                    </div>
                    <Input 
                      onChange={(e) => handleChange(el, e.target.value) }
                      value={el === 'tool_sender' || el === 'tool_receiver' || el === 'site_receiver' || el === 'site_sender' ? '' : (changes as any)[el]}
                      disabled={el === 'tool_sender' || el === 'tool_receiver' || el === 'site_receiver' || el === 'site_sender'}
                    />
                  </div>
                )
              })
            }
            {
              Object.keys(specificChanges).map((el, key) => {
                if(el !== 'uniqueness') {
                  return (
                  <div key={key}>
                    <div>
                    {el}
                    </div>
                    <Input 
                      onChange={(e) => handleSpecificChange(el, e.target.value) }
                      value={(specificChanges as any)[el]}
                    />
                  </div>
                )}
              })
            }
        </div>
            <ClassicButton text={t(`alerts.batch_modal.new_${batchIn ? 'batch_in' : 'batch_out'}`)} onClick={saveFlow}/>
        </div>
      </Modal.Content>
    </Modal>
  )
}
