import {
    ApiList,
  } from "../../models";
  import { ActorConformity, Actor, ActorConformityResults } from "../../models/actor-conformity-view"
  import { backendTokenService, securedRestClientContracts } from "../../backend";
  import { buildUrlParameters } from "../../utils";
  
  export const viewActorSubService = {
    getActorDetails: async ({
    actor_code,
    }: Actor) => {
      const token = backendTokenService.getToken();
      const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ""
      }/contracts/actors/?actor=${actor_code}`;
      const response = await securedRestClientContracts.get<
        ApiList<ActorConformityResults>
      >(requestUrl);
      console.log(response.data.count);
      return response.data;
    },
  };
  