import React from 'react';

import { Placeholder } from 'semantic-ui-react';
import styles from './edit-relation.module.css';

export const EditRelationLoader: React.FC = React.memo(() => (
  <div>
    <Placeholder fluid styleq={styles.wrapper}>
      <Placeholder.Header>
        <Placeholder.Image></Placeholder.Image>
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="short" />
      </Placeholder.Header>

    </Placeholder>
  </div>
));
