import {
  WithStatelessDatasFetchingByParameters,
  WithQuerystringReading,
} from "../../hocs";
import {
  ApiList,
  ContractIndicatorsDetails,
  GetContractIndicatorsDetails,
} from "../../models";
import { ContractResultsPageProps } from "./contract-results-page-type";
import { contractSubService } from "../../services";
import { PageLoader } from "../../components";
import React from "react";
import { compose } from "redux";

export const contractEnhancer = compose<React.FC<ContractResultsPageProps>>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<
    ApiList<ContractIndicatorsDetails>,
    ContractResultsPageProps,
    GetContractIndicatorsDetails
  >(
    contractSubService.getContractIndicatorsDetails,
    PageLoader,
    "redirect",
    "results",
    ["pk"]
  )
);
