import { buildUrlParameters } from '../../utils';
import {
  ApiList, Actor, GetActor, GetAllActor,
} from '../../models';
import { securedRestClient, backendTokenService } from '../../backend';

export const actorSubService = {
  // ===============
  // ==== ACTOR ====
  // ===============

  // --- CREATE ACTOR ---

  createActor: async (actor: Actor) => {
    // console.log(
    //   'ecosystem service createactor, actor : ',
    //   actor,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actors/`;
    const response = await securedRestClient.post<Actor>(requestUrl, actor);
    // console.log(response.data);
    return response.data;
  },

  // --- READ ACTOR ---

  getActor: async ({
    id, site, tool, certificate, product, limit, offset,
  }: GetActor) => {
    // console.log(
    //   'ecosystem service getActor, id : ',
    //   id,
    //   'site',
    //   site,
    //   'tool',
    //   tool,
    //   'certificate',
    //   certificate,
    //   'product',
    //   product,
    //   'limit',
    //   limit,
    //   'offset',
    //   offset,
    // );

    const params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'id', value: id },
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'site', value: site },
      { prop: 'tool', value: tool },
      { prop: 'certificate', value: certificate },
      { prop: 'product', value: product },
    ];
    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actors/${id || ''}?${paramUrl}`;
    const response = await securedRestClient.get<ApiList<Actor[]>>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  getAllActor: async ({
    is_placeholder, group_id, limit, offset, ordering, actor, siret_number, site_name, site_region, specification, is_ambassador, role,
  }: GetAllActor) => {
    const params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'group_id', value: group_id },
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'ordering', value: ordering },
      { prop: 'actor', value: actor },
      { prop: 'siret_number', value: siret_number },
      { prop: 'site_name', value: site_name },
      { prop: 'site_region', value: site_region },
      { prop: 'specification', value: specification },
      { prop: 'is_ambassador', value: is_ambassador === 'all' ? '' : is_ambassador },
      { prop: 'role', value: role },
      { prop: 'is_placeholder', value: is_placeholder },
    ];
    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actors/?${paramUrl}`;
    const response = await securedRestClient.get<ApiList<Actor[]>>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  // --- UPDATE ACTOR ---

  editActor: async (actor: Actor) => {
    // console.log(
    //   'ecosystem service putactor, actor : ',
    //   actor,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actors/${actor.pk}`;
    const response = await securedRestClient.put<Actor>(requestUrl, actor);
    // console.log(response.data);
    return response.data;
  },

  // --- DELETE ACTOR ---

  deleteActor: async (id: number) => {
    // console.log(
    //   'ecosystem service deleteproduct, actor id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actors/${id}`;
    const response = await securedRestClient.delete(requestUrl);
    // console.log(response.data);
    return response.data;
  },
};
