import { compose } from 'redux';
import { WithStatelessDatasFetchingByParameters, WithNotifyRessourceLoadedInputProps, WithQuerystringReading } from '../../../hocs';
import { GetFlowAlert, } from '../../../models';
import { AlertFixProps } from '.';
import { FlowAlertService } from '../../../services';
import { PageLoader } from '../../../components/page-loader';
import { FlowAlertResult } from './alert-fix-types';

const enhancer = compose<
React.ComponentType<GetFlowAlert & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(), 
  WithStatelessDatasFetchingByParameters<FlowAlertResult, AlertFixProps, GetFlowAlert>(
    FlowAlertService.getFlowAlertPage,
    PageLoader,
    'error',
    'results',
    ['id'],
  ),
);


export default enhancer;
