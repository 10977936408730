import React from 'react';
import styles from './comment-input.module.css'
import { CommentInputProps } from './comment-input-type';
import { MentionsInput, Mention } from 'react-mentions';
import { SendCommentButton } from '../../buttons';
import { User } from '../../..';
import { useTranslation } from 'react-i18next';
import { alertSubService } from '../../../services';
import { Input } from 'semantic-ui-react';


interface UsersInfos {
    id : number;
    display: string;
}

export const CommentInput : React.FC<CommentInputProps> = (props : CommentInputProps) => {
    const {t} = useTranslation();
    const { pk, getComment, text, border } = props;
    const [inputValue, setInputValue] = React.useState<string>('');
    const [users, setUsers] = React.useState<User[]>([]);
    
    let datas : UsersInfos[] = []
    users.forEach(el  => {
        datas = [...datas, {id : el.pk, display : el.username}]
    })


    const handleChange = (e : React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(e.target.value)
    }
 

    const handleSubmit = (e : React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let Rawcomment = inputValue;
        let firstSeparation = Rawcomment.split('++');
        let comment = '';
        let users_at : any = [];

        firstSeparation.forEach(el => {
            // console.log('firstSeparation : ', el)
            //ici j'ai d'abord le text, ensuite la mention (le cas échéant), ensuite l'id puis je passe au el suivant
            // si j'ai une mention, j'ai un @@, je vais donc split sur le @@
            const splitOnMention = el.split('@@');
            // console.log('split on mention :', splitOnMention)
            //j'obtiens pour chaque bloc text + mention +id un tableau tel que : [ text, mentionID], si je n'ai que du text, length = 1
            //Si lenght > 1 = j'explore pour récupérer et reformater la mention
            if(splitOnMention.length>1){
                //Si j'ai une mention, j'ai un id, donc un ^^, donc je split sur ^^
                const splitOnId = splitOnMention[1].split('^^');
                // console.log('splitOnId', splitOnId)
                //j'ai donc un tableau donc l'entrée 0 est l'username et l'entrée 1 est l'id (sous forme de string)
                //je vais donc ajouter au commentaire l'index 0 de splitOnMention (qui inclu un espace) et l'index 0 de splitOnId (qui n'inclu pas d'espace).
                //comme je tri
                comment += ` ${splitOnMention[0].trim()} @${splitOnId[0].trim()} `;
                //et ajouter au tableau de userPk l'index 1 de splitonId
                users_at = [...users_at, parseInt(splitOnId[1])]
            }else {
                // Sinon j'ajoute le text au commentaire.
                comment +=  `${(splitOnMention[0]).trim()} `;
            }    //tr
        })
        //ok sauf double espace entre mention et text, je vais donc faire un trim
        const finalComment = comment.trim()
        getComment(pk, finalComment, users_at)
        setInputValue('');
    }

    React.useEffect(() => {
        // fetch async pour le moment, si le fetch devient trop long voir pour faire un appel similaire à cell ecosystem.
        const fetch = async ()=> {
        const realUsers : User[] = await alertSubService.getUserList();
        setUsers(realUsers);
        }
        fetch();
    }, [])

    return <div >
        <form className={styles.inputContainer} onSubmit={handleSubmit}>
        {/* <MentionsInput 
        classNames={styles}
        className='mentions'
        value={inputValue} 
        onChange={ event => setInputValue(event.target.value)} 
        allowSuggestionsAboveCursor
        allowSpaceInQuery
        >
        // ATTENTION ////
        a la réactivation de mention la props background ne sera probablement plus nécessaire (utilisée pour différencier l'input de l'historique blanc sur fond gris de l'input du changement de statut blanc sur fond blanc)
        //ATTENTION///
            <Mention 
            trigger='@'
            data={datas}
            markup={'@@__display__^^__id__++'}
            appendSpaceOnAdd
            />
        </MentionsInput> */}
        <Input
        style={{margin: '10px auto', minWidth: '300px', minEight : '150px', border :`${border? border : 'none'}`, fontFamily: 'OpenSans', wordWrap: 'break-word' }}
        value={inputValue} 
        onChange={ event => setInputValue(event.target.value)} 
        
       />
        
        <SendCommentButton disabled={inputValue.length > 0 ? false : true} text={text ? text : `${t('alerts.alertHistory.sendComment')}`} onSubmit={(e) => handleSubmit(e)} />
        </form>
    </div>
}