import { compose } from 'redux';
import { WithQuerystringReading, WithNotifyRessourceLoadedInputProps, WithStatelessDatasFetchingByParameters } from '../../../hocs';
import { GroupLight, GetGroupLight } from '../../../models';
import { GroupProps } from '.';
import { groupSubService } from '../../../services';
import { PageLoader } from '../../../components/page-loader';

const enhancer = compose<
React.ComponentType<GetGroupLight & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<GroupLight, GroupProps, GetGroupLight>(
    groupSubService.getGroupLight,
    PageLoader,
    'error',
    'groupLight',
    ['id'],
  ),
);

export default enhancer;
