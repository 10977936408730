import React from 'react';
import { Popup } from 'semantic-ui-react';

export interface CustomPopupProps {
    messages: string[]
  }
  
  export const CustomPopup: React.FC<CustomPopupProps> = ({ children, messages }) => {
    return <Popup  wide='very' position='bottom right' disabled={!(messages && messages.length > 0)} trigger={children}>
      <div>
        {
          messages.map((el, index) => <div key={index}>
            {el}
          </div>)
        }
      </div>
    </Popup>
  }
  