import React from 'react';

import styles from './product.module.css';
import { ProductProps } from './product-types';
import enhancer from './product-enhancer';
import { ProductRead, SimpleCard, SimpleJson } from '../../../../models';
import { EcosystemItem } from '../../item/item';
import { useTranslation } from 'react-i18next';

import productPictoWhite from '../../../../../assets/pictos/productPictoWhite.svg';
import { Panels } from '../../utils/model';
import { actorPanel, certificatePanel, initialCount } from '../../utils/constant';


const InnerPage: React.FC<ProductProps> = ({ products, id }: ProductProps) => {
  const {t} = useTranslation();
  if (products.length === 0) {
    return <></>;
  }

  const product = products as unknown as ProductRead;

  const arrayOfSpecData: any[] = []
  const builsSpecData = (spec: SimpleJson | undefined): SimpleCard[] => {
    if(spec) {
      arrayOfSpecData.push(spec)
    }
  const specDataObject = arrayOfSpecData.length === 0 ? [] : Object.entries(arrayOfSpecData[0])
   return specDataObject.map((e: any) => ({
      label: e[1].information,
      value: e[1].value
    })).filter((el) => el.value !== undefined && el.value !== null && el.value.toString().trim() !== '')
  }
  const productData = builsSpecData(product.product_data)
  console.log("products", productData)
  const panels:Panels = {actors: actorPanel, certificates: certificatePanel}


  const itemLight = {
    description: [
      {
        label: t('form.products.internal_code'),
        value: product.internal_code,
      },
      {
        label: t('form.products.unit'),
        value: product.unit,
      },
      {
        label: t('form.products.gs1'),
        value: product.gs1,
      },
      {
        label: t('form.products.gs1_type'),
        value: product.gs1_type,
      },
      ...productData
    ].filter((el) => el.value !== undefined && el.value !== ''),
    title: `${t('ecosystem.products.title')} ${product.name}`,
    picto: productPictoWhite,
    permissionRoute: '/ecosystem/product:update',
    link: `/ecosystem/edit-product/${product.pk}`
  }
  
  return (
    <EcosystemItem panels={panels} itemLight={itemLight} id={id} specificParams={{product: id}} propertyName="product" counts={initialCount} />
  );
};

export const EcosystemProductPage = enhancer(InnerPage);

