import React from 'react';

import { useTranslation } from 'react-i18next';
import styles from './ecosystem-page.module.css';
import { EcosystemProps } from './ecosystem-page-types';
import enhancer from './ecosystem-page-enhancer';
import { GroupList } from '../../components';
import { TitleLayout, ContainerLayout } from '../../layouts';
import { posthogEvents } from '../../utils/posthog';


const InnerEcosystemPage: React.FC<EcosystemProps> = React.memo(
  ({ groups }: EcosystemProps) => {
    const { t } = useTranslation();
    posthogEvents.triggerPageView();
    groups.unshift({
      pk: 0,
      name: 'all',
      favorite: true,
    });

    return (
      <div className={styles.ecosystem}>

        <ContainerLayout>
          <TitleLayout inverted>{t('ecosystem.title')}</TitleLayout>
          <GroupList groups={groups? groups : []} />
        </ContainerLayout>
      </div>
    );
  },
);

export const EcosystemPage = enhancer(InnerEcosystemPage);
