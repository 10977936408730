import React from 'react';
import { Sidebar } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import styles from './default-layout.module.css';
import { Menu } from '../../components';
import { SidebarContext, OnBeforeUnloadingContext } from '../../contexts';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { tokenToString } from 'typescript';
// import 'react-toastify/dist/ReactToastify.css';

export const DefaultLayout: React.FC = ({ children }) => {  

  const { visible, handleVisible } = React.useContext(SidebarContext);

  const handleClick = () => {
    handleVisible(false);

  };
 
  return (
    <>
      <Menu />
      <Sidebar.Pushable className={styles.pushable}>
        <Sidebar visible={visible} animation="overlay" direction="left">
        </Sidebar>
        <Sidebar.Pusher dimmed={visible} onClick={handleClick}>
          <div className={styles.mainContainer}>
            {children}
          </div>
          <ConfirmationModal />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      {/* <ToastContainer /> */}
    </>
  );
};
