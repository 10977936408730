import React from 'react';
import styles from './list-product-form.module.css';
import { ListProductFormProps } from './list-product-form-types';
import { productSubService } from '../../../services';
import { ProductRead } from '../../../models';
import { ProductsHeader } from '../../../constants';
import { useParams } from '../../../hooks';
import { WithLazyloadingDatasFetching } from '../../../hocs/with-lazyloading-datas-fetching';
import { TableFormProps, TableForm } from '../table-form';

export const ListProductForm: React.FC<ListProductFormProps> = React.memo(({
  model, onSave, errorMessage, onCancel, include, specificParams, onPrevious, onNext, blocking, multiple,
}: ListProductFormProps) => {
  const {
    params, header, manageOrdering, manageSearch, focused
  } = useParams(ProductsHeader, specificParams);
  const [editedModel, setEditedModel] = React.useState(model);

  const handleAll = React.useCallback((add: boolean) => {
    const fetchAllItems = async (add: boolean) => {
      try {
        const result = await productSubService.getProduct({ ...params, limit: 100000 });
        const idList = result.results.map((el: any) => el.pk).filter((el) => el !== null);
        setEditedModel(idList as number[]);
      } catch {
        console.log('Une erreur est survenu');
      }
    };
    if (add) {
      fetchAllItems(add);
    } else {
      setEditedModel([]);
    }
  }, [params]);

  const EnhancedTableForm = WithLazyloadingDatasFetching<ProductRead, TableFormProps<ProductRead>>(
    productSubService.getProduct, 'data', params, params.limit ? parseInt(params.limit) : 100,
  )(TableForm);
  return (
    <div className={styles.tableWrapper}>
      <EnhancedTableForm
        data={[]}
        header={header}
        manageOrdering={manageOrdering}
        manageSearch={manageSearch}
        focused={focused}
        model={editedModel}
        onSave={onSave}
        errorMessage={errorMessage}
        onCancel={onCancel}
        onNext={onNext}
        onPrevious={onPrevious}
        include={include}
        blocking={blocking}
        multiple={multiple}
        handleAll={multiple ? handleAll : undefined}
      />
    </div>
  );
});
