import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './cell-audit.module.css';
import { FlowAlert, ApiList, AuditResult, GetAllAudit, Flow } from '../../../models';
import { auditSubService } from '../../../services';
import { BlockButton, ClassicButton } from '../../../components/buttons';
import { OnBeforeUnloadingContext } from '../../../contexts';
import { Modal, Table } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useLocalFilter } from '../../../hooks';
import { AuditsHeader } from '../../../constants';
import { GenericTable } from '../../../components/table';
import { AuditLoader } from './audit-loader';
import { WithStatelessDatasFetchingByParameters } from '../../../hocs';
import { CustomPopup } from './custom-popup';


export interface AuditCellProps {
  messages: string[];
  flow: Flow;
}

export const AuditCell = ({flow, messages}: AuditCellProps) => {
  const [openAuditModal, setOpenAuditModal] = React.useState<boolean>(false);
  const {t} = useTranslation()
  return (
    <Modal closeIcon onClose={()=> setOpenAuditModal(false)} open={openAuditModal} trigger={
      <CustomPopup messages={messages}>
        <Table.Cell onClick={() => setOpenAuditModal(true)}>
          <BlockButton style={{cursor: "pointer"}} warning={!flow.audit} text={flow.audit ? t('table.utils.compliant') : t('table.utils.nonCompliant') } />
        </Table.Cell>
      </CustomPopup>
      }>
        <AuditModal flow={flow.pk.toString()} is_archived={'false'} handleClose={() => setOpenAuditModal(false)} />
    </Modal>
  )
}

interface AuditModalProps {
  audits?: ApiList<AuditResult[]>
  handleClose : () => void
}

const InnerAuditModal = ({audits, handleClose}: AuditModalProps) => {
  const {setAction, setNext, activeContext} = React.useContext(OnBeforeUnloadingContext);
  const history = useHistory();
  const { t } = useTranslation();

  const handleRedirect = () => {
    history.push(`/ecosystem`); 
  }

  const handleClick = () => {
    if (activeContext) {
      setAction(true);
      setNext(() => handleRedirect);
    } else {
      handleRedirect();
    }
  }

  const {
    data, header, manageOrdering, manageSearch,
  } = useLocalFilter(AuditsHeader, audits? audits.results : []);

  return (
    <div className={styles.auditModal}>
      <div className={styles.auditModalTitle}>{t('alerts.audit_alerts.title')}</div>
      {data.length > 0 ? 
      <div className={styles.auditModalTable}>
        <GenericTable data={data} header={header} endFetching manageSearch={manageSearch} manageOrdering={manageOrdering} />
      </div>
      : <div className={styles.auditModalTitle}>{t('alerts.audit_alerts.no_results')}</div>
      }
      <div className={styles.buttonsBloc}>
        <div className={styles.returnButton}><ClassicButton onClick={handleClose} text={t('alerts.audit_alerts.go_back')}/></div>
        <div className={styles.returnButton}><ClassicButton inverted onClick={handleClick} text={t('alerts.audit_alerts.go_ecosystem')}/></div>
      </div>
    </div>
  )
}

const auditEnhancer = WithStatelessDatasFetchingByParameters<ApiList<AuditResult[]>, AuditModalProps,  GetAllAudit>(
  auditSubService.getAllAudit,
  AuditLoader,
  'error',
  'audits',
  ['flow', 'is_archived'],
);

const AuditModal = auditEnhancer(InnerAuditModal)