import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './alert-fix.module.css';
import { AlertFixProps, AlertFilter, AlertSets } from './alert-fix-types';
import enhancer from './alert-fix-enhancer';
import { FlowAlert, AlertContextCategory, Flow } from '../../../models';
import { flowSubService } from '../../../services';
import { OnBeforeUnloadingContext } from '../../../contexts';
import { ModalButton } from '../../../components/confirmation-modal/confirmation-modal-type';
import { HeaderAlert } from './alert-header';
import { TableAlert } from './alert-table';
import { Loader, Sidebar } from 'semantic-ui-react';
import { AlertHistory } from '.';
import { useLocation, useHistory } from 'react-router-dom';
import { toastHandler } from '../../../utils';
import { header } from '../../../constants';


const InnerAlertFix: React.FC<AlertFixProps> = React.memo(({ results }: AlertFixProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const cluster = results?.general;
  const clusterPK  : number = results? results.general.pk : 0;

  const initialAlertButtons: AlertFilter = {
    'missing_data' : {label :t('alerts.alertFix.filter.missing_data'), status: true, resolved: false, flows: []},
    'connection_key' : {label :t('alerts.alertFix.filter.connection_key'), status: true, resolved: false, flows: []},
    'matching_key' : {label :t('alerts.alertFix.filter.matching_key'), status: true, resolved: false, flows: []},
    'audit' : {label :t('alerts.alertFix.filter.audit'), status: true, resolved: false, flows: []},
  }

  const NoError: boolean = results?.flows.results.filter(el => el.problematic_fields.length > 0).length ===  0;

  const initializeAlertButtons = (flows: FlowAlert[]) => {
    flows.forEach(flow => {
      flow.alert_set.forEach(alert => {
        if (alert.empty_field && flow.problematic_fields.includes(alert.empty_field) || !flow.audit) {
          if(alert.status === 'open'){
          initialAlertButtons[alert.subcategory].flows.push(flow.pk);
          initialAlertButtons[alert.subcategory].status = false ;
          }
        } if(alert.status === 'closed'){
          initialAlertButtons[alert.subcategory].resolved = true;
        }
      })
    })
  }
  initializeAlertButtons(results ? results.flows.results : []);

  const [initialFlowAlerts, setInitialFlowAlerts] = React.useState<Flow[]>(results ? results.flows.results : []);
  const [flowAlerts, setFlowAlerts] = React.useState<Flow[]>(initialFlowAlerts);
  const [displayedFlow, setDisplayedFlow] = React.useState<number[]>(initialFlowAlerts.map(el => el.pk));
  const [selectedFlows, setSelectedFlows] = React.useState<number[]>([]);
  const [selectedFilter, setSelectedFilter] = React.useState<AlertContextCategory |  undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [alertButtons, setAlertButtons] = React.useState<AlertFilter>(initialAlertButtons);
  const [flowPk, setFlowPk] = React.useState<number[]>([]);
  const [modifiedFlows, setModifiedFlows] = React.useState<Flow[]>([])


  
  const handleClick = () => {
    setVisible(!visible);
  }

  const alertSets: AlertSets[] = [];

  const initializeAlertSets = (flows: FlowAlert[]) => {
      flows.forEach(flow => {
        const temp: AlertSets = {
          pk: flow.pk,
          alertSet: flow.alert_set
        };
        alertSets.push(temp);
      })
  }

  initializeAlertSets(results ? results.flows.results : []);

  // const handleSelect = (pk:number, all? : boolean, selectAll?: boolean ) => {
  //   if(all){
  //     let allFlows : number[] = [];
  //     results?.flows.results.forEach((el : FlowAlert) => el.problematic_fields.length === 0 && allFlows.push(el.pk)) 
  //     selectAll ? setSelectedFlows(allFlows) : setSelectedFlows([])
  //   }
  //   else if (selectedFlows.includes(pk)) {
  //     setSelectedFlows(selectedFlows.filter(el => el !== pk))
  //     setSelectAll(false)
  //   } else {
     
  //     setSelectedFlows([...selectedFlows, pk])
  //      if(selectedFlows.length === flowAlerts.filter(el => el.problematic_fields.length === 0).length - 1){
  //       setSelectAll(true)
  //     }
  //   }
  // }

  const saveFlow = async (pk:number, flow?:FlowAlert | Flow) => {
    const flowToSave = flowAlerts.find(flow => flow.pk === pk);
    try {
      if (flowToSave) {
        setLoading(true);
        // console.log('flow saved :', flowToSave)
        const result = await flowSubService.updateFlow({...flowToSave, ...flow});
        editFlow(result);
        setInitialFlowAlerts(initialFlowAlerts.map(el => {
          if (result.pk === el.pk) {
            return {...el, ...result}
          } else {
            return el
          }
        }));

        if (selectedFilter) {
          const temp = alertButtons[selectedFilter].flows.filter(flow => flow !== pk);
          setAlertButtons({
            ...alertButtons,
            [selectedFilter]: {
              label: alertButtons[selectedFilter].label,
              flows: temp,
              status: temp.length > 0 ? false : true
            }
          })
        }

        setLoading(false)
        toastHandler(t('alerts.batch_modal.success'), 'success')
      }
    } catch (err) {
      // console.log(err)
      setLoading(false)
      toastHandler(t('alerts.batch_modal.error'), 'error')
    }
  }

  // const retrack = async (pk:number) => {
  //   const untrackedFlows : Flow[] = flowAlerts.filter(el => el.pk !== pk)
  //   const untrackedInitialFlows : Flow[]= initialFlowAlerts.filter(el => el.pk !== pk)


  //   try {
  //     setLoading(true)
  //     const result = await flowSubService.flowTraceability(pk);
  //     setFlowAlerts([...untrackedFlows, result]);
  //     setInitialFlowAlerts([...untrackedInitialFlows, result]);
  //     setActiveContext(false);
  //     setSelectedFlows([]);
  //     setLoading(false)
  //     toastHandler(t('alerts.retrack.success'), 'success')

  //   } catch (err) {
  //     setLoading(false)
  //     console.log('err : ', err)
  //     toastHandler(t('alerts.retrack.error'), 'error')
  //   }
  // }

  const getModifiedFlows = async () => {
    let flowList : Flow[]= [];

    const fetchFLow = async(id : string) => {
      const result = await flowSubService.getFlow({id})
      flowList.push(result)
    };

    const promises = flowPk.map((flow : number) => {
      return fetchFLow(flow.toString())
    })

    await Promise.all(promises)
    setModifiedFlows(flowList);
  }

  const handleGlobalRetrack = async () => {
    // const flowToTrace : Flow[] = flowAlerts.filter(el => selectedFlows.includes(el.pk));
    // const untrackedFlows : Flow[] = flowAlerts.filter(el => !selectedFlows.includes(el.pk))
    // const untrackedInitialFlows : Flow[]= initialFlowAlerts.filter(el => !selectedFlows.includes(el.pk))
    const results : Flow[] = [];
    const allPk : number[]= [];
    flowAlerts.map((el : Flow) => {
      allPk.push(el.pk);
    });


    const sortedAllPk = allPk.sort(function(a, b){ return a - b })

    // const handleSave = async (id : number) => {
    //     const result = await flowSubService.flowTraceability(id, id === sortedAllPk[0], id === sortedAllPk[sortedAllPk.length-1]);
    //     //TODO 
    //     // Si params mail renvouyer sur la page /alert avec ce param
    //     results.push(result);
    // }
    
    // history.push({...history, state : {}})
    try {
      setLoading(true);

      // const promises = flowAlerts.map(flow => {
      //   return handleSave(flow.pk);
      // })
      await flowSubService.flowTraceability(clusterPK, allPk.join());

      // await Promise.all(promises);
      setFlowAlerts([...results]);
      setInitialFlowAlerts([...results]);
      setLoading(false);
      setActiveContext(false);
      setSelectedFlows([]);
      if(results[0] as any === 'error'){
        //Si j'ai une erreur de la traca du flux j'ai une réponse 200 de back avec un tableau de string ['Error'], pour éviter le toast 'success' dans ce cas j'ai ajouté cette condition.
        toastHandler(t('alerts.retrack.error'), 'error')
      } else {
      toastHandler(t('alerts.retrack.success'), 'success')
      }

    } catch(err){
      console.log(err);
      toastHandler(t('alerts.retrack.error'), 'error')
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (selectedFilter && alertButtons[selectedFilter].flows.length === 0) {
      filterFlows(selectedFilter);
    }
  }, [alertButtons])

  const filterFlows = async (alertType: AlertContextCategory) => {
    await handleGlobalSave();
    if (selectedFilter === alertType) {
      setSelectedFilter(undefined);
      setDisplayedFlow(initialFlowAlerts.map(el => el.pk));
    } else {
      const temp:number[] = [];
      setSelectedFilter(alertType);
      initialFlowAlerts.forEach(flow => {
        if (alertButtons[alertType].flows.includes(flow.pk)) {
          temp.push(flow.pk);
        }
      })
      setDisplayedFlow(temp);
    }
  }

  React.useEffect(()=>{
    if(flowPk.length>0){
      getModifiedFlows();
    }
  }, [flowPk])

  React.useEffect(() => {
    // console.log('location : ', location.state);

    if (location.state && (location.state as any).filter ) {
    
     
      //au chargement de la page, on verifie si on n'a pas deja une modal d'ecosystem ouverte. Si oui on filtre manuellement sur la categorie d'erreur matching_key (= erreur d'ecosysteme)
      //on filtre sans passer par filterFlows pour ne pas sauvegarder tous les flux (inutile car on vient d'arriver sur la page)
      // peut etre utile de passer par filterflows en ajoutant un parametre optionnel qui triggerera le handleGlobalSave
      if ((location.state as any).filter === 'matching_key') {
        if(alertButtons['matching_key'].flows.length > 0){
          const temp:number[] = [];
          
          initialFlowAlerts.forEach(flow => {
            if (alertButtons['matching_key'].flows.includes(flow.pk)) {
              temp.push(flow.pk);
            }
          });
          if (temp.length > 0) {
            setSelectedFilter('matching_key');
            setDisplayedFlow(temp);
          }
        } else {
          setSelectedFilter(undefined)
        }
      } else if ((location.state as any).filter === 'connection_key') {
        if(alertButtons['connection_key'].flows.length > 0){
        const temp:number[] = [];
        setSelectedFilter('connection_key');
        initialFlowAlerts.forEach(flow => {
          if (alertButtons['connection_key'].flows.includes(flow.pk)) {
            temp.push(flow.pk);
          }
        })
        if (temp.length > 0) {
          setSelectedFilter('connection_key');
          setDisplayedFlow(temp);
        }
      }else {
        setSelectedFilter(undefined)
      }
      } 


      //SOLUTION DE CONTOURNEMENT => a retirer quand un chanegemnt d'element du referentiel entrainera une mise a jour de sproblematic fields de tous les fulx qui utilise l'element du referentiel
      // filterFlows((location.state as any).filter)
    }
  }, [])

  const editFlow = (flow: FlowAlert | Flow) => {
    // console.log('flow to edit :', flow, flow.problematic_fields);
      setFlowAlerts(flowAlerts.map(el => {
      if (flow.pk === el.pk) {
        return {...el, ...flow}
      } else {
        return el
      }
    }))
    setActiveContext(true);
  }

  

  const handleGlobalSave = async () => {
    const results: Flow[] = [];
    const handleSave = async (flow: Flow) => {
      const result = await flowSubService.updateFlow({...flow});
      results.push(result)
    }

    try {
      setLoading(true);

      const promises = flowAlerts.map(flow => {
        return handleSave(flow)
      });

      await Promise.all(promises);
      setFlowAlerts(results);
      setInitialFlowAlerts(results);
      
      const temp: AlertFilter = initialAlertButtons;

      results.forEach(flow => {
        alertSets.find(alert => alert.pk === flow.pk)?.alertSet.forEach(alert => {
          if (alert.empty_field && flow.problematic_fields.includes(alert.empty_field)) {
            temp[alert.subcategory].flows.push(flow.pk);
            initialAlertButtons[alert.subcategory].status = false;
          }
        })
      })

      setAlertButtons(temp);
      setLoading(false);
      setActiveContext(false);
      setFlowPk([])
      setModifiedFlows([]);
    } catch (err) {
      setLoading(false)
      // console.log(err)
      setActiveContext(true);
      toastHandler('error', 'error')
    }
  }

  const message = t('alerts.alertFix.leave.message');

  const buttons: ModalButton[] = [
    {
      action: () => {},
      text: t('alerts.alertFix.leave.continue'),
      type: 'continue'
    },
    {
      action: () => {},
      text: t('alerts.alertFix.leave.cancel'),
      type: 'cancel'
    },
    {
      action: () => handleGlobalSave(),
      text: t('alerts.alertFix.leave.save'),
      type: 'save'
    },
  ]

  const {setActiveContext, setButtons, setMessage} = React.useContext(OnBeforeUnloadingContext);

  React.useEffect(() => {
    setButtons(buttons)
    setMessage(message);
    return () => setActiveContext(false)
  }, []);


  

  return (
    <Sidebar.Pushable>
    <Sidebar visible={visible} animation="overlay" direction="right" width="very wide" className={styles.overlay}>
      {visible && cluster &&  <AlertHistory type={'alert_cluster'} id={cluster.pk} handleClick={handleClick }/>}
    </Sidebar>
    <Sidebar.Pusher onClick={visible ? handleClick : null}>
    <div className={styles.alertWrapper}>
      
      {/* {console.log('flow Alerts : ', flowAlerts)} */}
      {loading  && <AlertLoader />}
      <HeaderAlert 
        alertButtons={alertButtons} 
        selectedFlows={selectedFlows} 
        globalRetrack={handleGlobalRetrack} 
        save={handleGlobalSave} 
        filter={filterFlows} 
        selected={selectedFilter} 
        cluster={cluster} 
        visible={visible}
        handleClick={handleClick}
        noError={NoError}
        oldAlert={results?.general.old_alert ? results.general.old_alert : false}
      /> 
      <TableAlert 
        initialFlows={initialFlowAlerts} 
        flows={flowAlerts.filter(el => displayedFlow.includes(el.pk)).sort((a:Flow, b:Flow) => a.pk - b.pk)} 
        selectedFlows={selectedFlows}
        fields_in={results ? results.general.fields_in : []}
        batch_in_fields={results ? results.general.batch_in_fields : []}
        batch_out_fields={results ? results.general.batch_out_fields : []}
        editFlow={editFlow}
        filter={selectedFilter}
        saveFlow={saveFlow}
        alertSets={alertSets}
        setFlowPk={setFlowPk}
        flowPk={flowPk}
        modifiedFlows={modifiedFlows}
        handleGlobalSave={handleGlobalSave}
      />
    </div>
    </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
});

export const AlertFixPage = enhancer(InnerAlertFix);

const AlertLoader = () => (
  <div className={styles.loaderWrapper}>
    <Loader active size="huge" className={styles.semanticLoader} />
  </div>
)