import React from "react";
import styles from "./view-risk-header.module.css";
import { riskStatusInfocardEnhancer } from "./view-risk-header.enhancer";
import { RiskStatusInfocardProps } from "./view-risk-header-type";
import { viewRiskStatusService } from "../../..";
import { RiskStatusInformations, ViewRiskHeaderProps } from "../../../models";

export const RiskStatusHeader: React.FC<ViewRiskHeaderProps> = ({
  pk,
}: ViewRiskHeaderProps) => {
  const [data, setData] = React.useState<RiskStatusInformations>()
  React.useEffect(() => {
    const getData = async () => {
    const result = await viewRiskStatusService.getRiskStatusInfos({pk});
    setData(result);
    }
    getData()
  }, [])
  return (
    <>
    {data?.status_distribution && data?.status_distribution?.length > 0 ?
    <div className={styles.principalCard}>
      <h2 className={styles.principalCardTitle}>
        TAUX DE REPARTITION DES NIVEAUX DE RISQUE
      </h2>
      <div className={styles.mainGraphContainer}>
        <div className={styles.graphContainer}>
          <div className={`${styles.cardColorExpert} ${styles.flexCard}`} style={{width: `${data?.status_distribution?.[3].proportion}%`}}>
            <span className={styles.textCardColorPourcent}>
                {data?.status_distribution?.[3].proportion}%
            </span>
            <span className={styles.textCardColorActor}>
              ({data?.status_distribution?.[3].total} acteurs)
            </span>
          </div>
          <div className={`${styles.cardColorMembers} ${styles.flexCard}`} style={{width: `${data?.status_distribution?.[1].proportion}%`}}>
            <span className={styles.textCardColorPourcent}>
                {data?.status_distribution?.[1].proportion}%
            </span>
            <span className={styles.textCardColorActor}>
              ({data?.status_distribution?.[1].total} acteurs)
            </span>
          </div>
          <div className={`${styles.cardColorRisk} ${styles.flexCard}`} style={{width: `${data?.status_distribution?.[0].proportion}%`}}>
            <span className={styles.textCardColorPourcent}>
                {data?.status_distribution?.[0].proportion}%
            </span>
            <span className={styles.textCardColorActor}>
              ({data?.status_distribution?.[0].total} acteurs)
            </span>
          </div>
          <div className={`${styles.cardColorCritic} ${styles.flexCard}`} style={{width: `${data?.status_distribution?.[2].proportion}%`}}>
            <span className={styles.textCardColorPourcent}>
                {data?.status_distribution?.[2].proportion}%
            </span>
            <span className={styles.textCardColorActor}>
              ({data?.status_distribution?.[2].total} acteurs)
            </span>
          </div>
          <div className={`${styles.cardColorNotValid} ${styles.flexCard}`} style={{width: `${data?.status_distribution?.[4].proportion}%`}}>
            <span className={styles.textCardColorPourcent}>
                {data?.status_distribution?.[4].proportion}%
            </span>
            <span className={styles.textCardColorActor}>
              ({data?.status_distribution?.[4].total} acteurs)
            </span>
          </div>
        </div>
      </div>

      <div>
        <div className={styles.miniCard}>
          <div className={styles.miniContainer}>
            <div
              className={`${styles.miniCardColor} ${styles.cardColorExpert}`}
            ></div>
            <span className={styles.miniCardText}><strong>EXPERTS</strong></span>
          </div>
          <div className={styles.miniContainer}>
            <div
              className={`${styles.miniCardColor} ${styles.cardColorMembers}`}
            ></div>
            <span className={styles.miniCardText}><strong>MEMBRES</strong></span>
          </div>
          <div className={styles.miniContainer}>
            <div
              className={`${styles.miniCardColor} ${styles.cardColorRisk}`}
            ></div>
            <span className={styles.miniCardText}><strong>A RISQUE</strong></span>
          </div>
          <div className={styles.miniContainer}>
            <div
              className={`${styles.miniCardColor} ${styles.cardColorCritic}`}
            ></div>
            <span className={styles.miniCardText}><strong>CRITIQUES</strong></span>
          </div>
          <div className={styles.miniContainer}>
            <div
              className={`${styles.miniCardColor} ${styles.cardColorNotValid}`}
            ></div>
            <span className={styles.miniCardText}><strong>NON VALIDES</strong></span>
          </div>
        </div>
      </div>
    </div> 
    : ""
} </>
  
  );
  
};

// export const RiskStatusHeader = riskStatusInfocardEnhancer(ViewRiskTableHeader);
