import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import { SpecificationsPageProps } from "./specifications-page-type";
import { SpecificationTilesList, SpecificationsSidebar } from ".";
//import { Input } from "semantic-ui-react";
import { SpecificationsHeader } from "./specifications-header";
import styles from "./specifications-page.module.css";
import { FoodchainList } from "./foodchain-list";
import { SpecificationTilesCard } from "./specifications-tiles-card";
import { backendTokenService } from "../../backend";
import { posthogEvents } from "../../utils/posthog";


export const SpecificationsPage: React.FC<SpecificationsPageProps> = ({}: SpecificationsPageProps) => {
  const { t } = useTranslation();
  posthogEvents.triggerPageView();

  const token = backendTokenService.getToken();

  const [name, setName] = React.useState<string>("");

  // state toggle checked
  const [checked, setChecked] = React.useState(true);

  const handleChange = (input: string) => {
    posthogEvents.triggerSearch(checked ? 'products' : 'specifications', input);
    setName(input);
  };

  const handleChangeChecked = () => {
    posthogEvents.triggerEvent({name : 'clickOn', datas: {'show' : checked ? 'foodchain' : 'specifications'} })
    setChecked(!checked);
  };

  const [selectedFolder, setSelectedFolder] = React.useState<
    number | undefined
  >(undefined);

  const handleSelectFolder = (pk?: number) => {
    if (pk && pk !== selectedFolder) {
      setSelectedFolder(pk);
    } else {
      setSelectedFolder(undefined);
    }
  };

  //si false on affiche les cdc (specificationList), si true on affiche les filieres(foodchainlist)
  const [listSelected, setListSelected] = React.useState<boolean>(false);

  const handleSelectList = (selected: boolean) => {
    setListSelected(selected);
  };

  if (token && !token.contracts_token) {
    return <Redirect to="./home" />;
  }

  const htmlCDC = `${t('specifications.terms.specifications')}`

  return (
    <div
      className={styles.specificationWrapper}
      style={{ marginTop: "-3%", paddingTop: "30px" }}
    >
      <div className={styles.mainWrapper}>
        <div className={styles.headerWrapper}>
          <div>
            <SpecificationsHeader
              handleInput={handleChange}
              listSelected={listSelected}
              handleSelectList={handleSelectList}
              checked={checked}
            />
          </div>

          <div className={styles.switchButton}>
            <div className={styles.blocSwitchButton}>
              <div style={{ marginTop: '15%' }}>
                <h4 style={{ fontWeight: "bold", fontSize: "1.3em", marginRight: '20%', fontFamily: 'OpenSans-Light, Arial, Helvetica, sans-serif' }} className={`${
                !checked ?  styles.toggleActive : styles.toggleDisable
              }`}>
                  {t('specifications.terms.product')}
                </h4>
              </div>
            </div>
            <div className={styles.blocSwitchButton}>
              <div style={{ marginTop: '-5px' }}>
                <input className={styles.checkButton} type="checkbox" id="switch" onChange={handleChangeChecked} />
                <label className={`checked ${styles.toggleButton}`} htmlFor="switch">Toggle</label>
              </div>
            </div>
            <div className={styles.blocSwitchButton}>
              <div>
                <h4 style={{ fontWeight: "bold", fontSize: "1.3em", fontFamily: 'OpenSans-Light, Arial, Helvetica, sans-serif' }} className={`${
                  checked ? styles.toggleActive : styles.toggleDisable 
                }`}>
                  {/* {t('specifications.terms.specifications')}{" "} */}
                  <div dangerouslySetInnerHTML={ {__html: htmlCDC} } />
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.resultWrapper}>
          {checked ? (
            <SpecificationTilesCard name={name} />
          ) : (
            <SpecificationTilesList name={name} folder={selectedFolder} />
          )}
        </div>
      </div>
    </div>
  );
};
