import React from 'react';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import {
  ToolFormProps,
  ToolProps,
} from './tool-form-type';
import styles from './tool-form.module.css';
import { InputControl, CheckBoxInputControl, SelectControl } from '../form-controls';
import { ClassicButton } from '../../buttons';

export const ToolForm: React.FC<ToolFormProps> = ({
  model,
  onSave,
  errorMessage,
  onCancel,
  onNext,
  onPrevious,
}: ToolFormProps) => {
  const { t } = useTranslation();

  const handleNext = () => {
    onNext && onNext(model);
  };


  const toolOptions= [
    {key : 2, value : 'PRODUCTION', text: t('form.tools.production')},
    {key : 3, value : 'TRANSPORT', text: t('form.tools.transport')},
    {key : 4, value : 'STOCKAGE', text: t('form.tools.storage')},
  ]

  return (
    <>
      <Formik<ToolProps>
        onSubmit={onSave}
        initialValues={model}
        render={(props) => (
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.formContainer}>

              {model && (
              <>
              {model.is_placeholder && 
                <div className={styles.formLine}>
                {/* <b className={styles.inputLabel}>{t('ecosystem.actors.form.is_ambassador')}</b> */}
                <CheckBoxInputControl
                  name="is_placeholder"
                  label={t('form.tools.is_placeholder')}
                  required
                />
              </div>}
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.tools.name')}</b>
                  <InputControl
                    name="name"
                    required
                    placeholder={t('form.tools.name')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.tools.internal_code')}</b>
                  <InputControl
                    name="internal_code"
                    required
                    placeholder={t('form.tools.internal_code')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.tools.tool_type')}</b>
                  <SelectControl
                    name="tool_type"
                    options={toolOptions}
                    placeholder={t('form.tools.tool_type')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.tools.capacity')}</b>
                  <InputControl
                    name="capacity"
                    placeholder={t('form.tools.capacity')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.tools.unit')}</b>
                  <InputControl
                    name="unit"
                    required={model.capacity ? true : false}
                    placeholder={t('form.tools.unit')}
                  />
                </div>


              </>
              )}
            </div>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
              onCancel
                ? <ClassicButton text={t('wizard.tools.cancel')} onClick={onCancel} warning />
                : <div>&nbsp;</div>
            }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
              onPrevious
                ? <ClassicButton text={t('wizard.tools.back')} onClick={onPrevious} inverted />
                : <div>&nbsp;</div>
            }
                </div>
                <div className={styles.nextNavigation}>
                  {
              onNext
                ? <ClassicButton onClick={handleNext} text={t('wizard.tools.next')} inverted />
                : <div>&nbsp;</div>
            }

                </div>
              </div>
              <div className={styles.submitActions}>
                <ClassicButton type="submit" text={t('wizard.tools.submit')} />
              </div>
            </div>
          </form>
        )}
      />
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};
