import * as React from 'react';

import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './body-clickable-component.module.css';
import { BodyClickableProps } from './body-clickable-type';
import { DataTable } from '../header/header-type';
import { ClickableContainer } from '../../clickable-container';
import searchPicto from '../../../../assets/pictos/searchPictoBlue.png';
import timerSand from '../../../../assets/pictos/timer-sand.png';
import editPicto from '../../../../assets/pictos/editPictoBlue.png';
import { HeaderColumn, Relation, TabInclude } from '../../../models';
import { checkExpiredDate, formatTableData } from '../utils';
import { AuthenticatedContext } from '../../..';
import { he } from 'date-fns/locale';

export function BodyClickable <T>({
  data, header, linkType,
}: BodyClickableProps<T>) {
  const { t } = useTranslation();
  return (
    <>
      <Table.Body>
        {data.map((el: DataTable<T>) => (
          <Table.Row key={el.pk} error={checkExpiredDate(el, header)}>
            <InnerBody header={header} linkType={linkType} data={el} />
          </Table.Row>
        ))}
      </Table.Body>
    </>
  );
}

interface InnerBodyProps<T> {
  header: HeaderColumn<T>[];
  linkType: TabInclude;
  data: DataTable<T>;
}

function InnerBody <T>({header, linkType, data}: InnerBodyProps<T>) {
  const { t } = useTranslation();
  const authenticatedContext = React.useContext(AuthenticatedContext);
  const user = authenticatedContext.folder?.permission === 'user';

  const problematic_fields: (keyof T)[] = (data as any).problematic_fields ? (data as any).problematic_fields : [];
  if((data as any).is_placeholder && (data as any).is_placeholder){ 
  return (
    <>
      <Table.Cell collapsing>
        <ClickableContainer
          className={`${styles.menuItemPH} `
        }
        >
          <img src={timerSand} className={styles.searchPicto} />
          
        </ClickableContainer>
        </Table.Cell>
      {header.filter(el2 => el2.columnName !== 'problematic_fields').map((el2: HeaderColumn<T>, key) => (
        <React.Fragment key={key}>{formatTableData(t, el2.dataTransform, data[el2.columnName], problematic_fields.includes(el2.columnName), true)}</React.Fragment>
      ))}
    </>
  )
  }else{

    return (
    <>
    <Table.Cell collapsing>
        <ClickableContainer
          link={`/${linkType === 'flows' || linkType === 'batchs' ? 'tracking' : 'ecosystem'}/${linkType === 'relations' ? 'actors' : linkType}/${linkType === 'relations'? (data as unknown as Relation).actor.pk : data.pk}`}
          className={`${styles.menuItem} `
        }
        >
          <img src={searchPicto} className={styles.searchPicto} />
        </ClickableContainer>
      </Table.Cell>
      {header.filter(el2 => el2.columnName !== 'problematic_fields').map((el2: HeaderColumn<T>, key) => (
        <React.Fragment key={key}>{formatTableData(t, el2.dataTransform, data[el2.columnName], problematic_fields.includes(el2.columnName))}</React.Fragment>
      ))}
      {
        linkType === 'relations' && !user && 
        <Table.Cell collapsing>
        <ClickableContainer
          link={`/ecosystem/edit-relation/${(data as unknown as Relation).pk}`}
          className={`${styles.menuItem} `
        }
        ><img src={editPicto} className={styles.searchPicto}/></ClickableContainer>
        </Table.Cell>
      }
    </>
  )
      }
}