import {
  AuthToken,
  LightFolder,
  AuthenticationFolder,
  RoleType,
  User,
} from '../models';
import { dateIsExpired } from '../utils';
import { posthogEvents } from '../utils/posthog';
import { authSubService } from './sub-services';

const storageKey = process.env.REACT_APP_AUTHTOKEN ? process.env.REACT_APP_AUTHTOKEN : '';
const expirationHours = parseInt(process.env.React_APP_EXPIRATION_HOUR ? process.env.React_APP_EXPIRATION_HOUR : '1')

const pickPermission = (user: User): RoleType => {
  let permission: RoleType = 'user';
  if (user.is_staff) {
    permission = 'staff';
  }
  if (user.is_superuser) {
    permission = 'admin';
  }
  return permission
}

export const authenticationService = {
  login: async (
    email: string,
    password: string,
  ): Promise<AuthenticationFolder> => {
    const result = await authSubService.login(email, password);

    const expireAt = new Date();
    expireAt.setTime(expireAt.getTime() + expirationHours * 60 * 60 * 1000);

    const user = result.user_details[0];
    
    const authFolder: AuthenticationFolder = {
      token: {
        value: result.access,
        refresh: result.refresh,
        expiration: expireAt,
        apiVersion: 'v1',
        remember: false,
        contracts_token: result.user_details[0].contracts_token
      },
      folder: {
        id: user.pk,
        first_name: user.first_name,
        last_name: user.last_name,
        username: user.username,
        full_name: `${user.first_name} ${user.last_name}`,
        permission: pickPermission(user),
      },
    };

    // console.log(authFolder);
    return authFolder;
  },

  getLoggedInUserInfos: async (): Promise<
    { folder: LightFolder; token: AuthToken } | undefined
  > => {
    const alreadyLoggedInUserTokenStr = localStorage.getItem(storageKey);
    if (!alreadyLoggedInUserTokenStr) {
      return undefined;
    }
    let authToken = JSON.parse(alreadyLoggedInUserTokenStr) as AuthToken;

    if (dateIsExpired(authToken.expiration)) { // access token expiré
      if (!authToken.remember) {
        return undefined;
      }
      const refreshedToken = await authSubService.refreshClientToken();
      const newExpirationDate = new Date();
      newExpirationDate.setTime(newExpirationDate.getTime() + (expirationHours * 60 * 60 * 1000));
      authToken = { ...authToken, value: refreshedToken.access, expiration: newExpirationDate };
      localStorage.setItem(storageKey, JSON.stringify(authToken));
    }
    const loggedInFolder = await authSubService.getLoggedInUser();
    posthogEvents.login(loggedInFolder.results[0].email)

    const user = loggedInFolder.results[0];
    const folder = {
      id: user.pk,
      first_name: user.first_name,
      last_name: user.last_name,
      username: user.username,
      full_name: `${user.first_name} ${user.last_name}`,
      permission: pickPermission(user),
    };
    return { folder, token: authToken };
  },
};
