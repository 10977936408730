
import { WithStatelessDatasFetchingByParameters} from '../../../../hocs';
import { alertSubService } from '../../../../services';
import { AlertHistoryProps, FullHistory } from './alert-history-type';
import { GetHistory } from '../../../../models';
import { HistoryLoader } from '../../../../components';


const enhancer = WithStatelessDatasFetchingByParameters<FullHistory, AlertHistoryProps, GetHistory>(
    alertSubService.getFullAlertHistory,
    HistoryLoader,
    'error',
    'results',
    ['id', 'type'],
);


export default enhancer;