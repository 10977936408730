import { compose } from 'redux';
import { WithQuerystringReading, WithNotifyRessourceLoadedInputProps, WithStatelessDatasFetchingByParameters } from '../../../../hocs';
import { GetGroupLight, GetTool } from '../../../../models';
import { ToolProps } from '.';
import { toolSubService } from '../../../../services';
import { GroupList } from '../../group';
import { PageLoader } from '../../../../components/page-loader';

const enhancer = compose<
React.ComponentType<GetGroupLight & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<GroupList, ToolProps, GetTool>(
    toolSubService.getTool,
    PageLoader,
    'error',
    'tools',
    ['id'],
  ),
);


export default enhancer;
