import { StatusDistributionEntity } from './../../models/risk-status';
import { ApiList } from "../../models";
import { RiskStatusResults, RiskStatus, GetRiskStatus, GetRiskStatusId, GetRiskViewDetails, RiskStatusDetails, RiskStatusData, RiskStatusInformations, DownloadCSVRiskStatus } from "../../models/risk-status";
import { backendTokenService, securedRestClientContracts } from "../../backend";
import { buildUrlParameters } from "../../utils";

export const viewRiskStatusService = {
  getRiskStatus: async ({
    specifications ,ordering, search, limit, offset, status, actor
  } : GetRiskStatus)  => {
    const params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'specifications', value : specifications },
      { prop: 'ordering', value: ordering ? ordering : 'actor' },
      { prop: "limit", value: limit },
      { prop: "offset", value: offset},
      { prop: 'search', value: search },
      { prop: 'risk_status', value: status },
      { prop: 'actor', value: actor },
    ];
    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/risk_status_results/?${paramUrl}`;
    const response = await securedRestClientContracts.get<
      ApiList<RiskStatus[]>
    >(requestUrl);
    return response.data;
  },
  getViewRiskDetails: async ({
    pk,
  }: GetRiskViewDetails) => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/risk_status_details/${pk}`;
    const response = await securedRestClientContracts.get<
      ApiList<RiskStatusDetails>
    >(requestUrl);
    return response.data;
  },

  getRiskStatusInfos: async ({ pk }: RiskStatusData) => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/risk_status_informations/${pk}`;
    const response = await securedRestClientContracts.get<RiskStatusInformations>(requestUrl);
    return response.data;
  },

  downloadResults: async ({ id, name }: DownloadCSVRiskStatus) => {
    const params: { prop: string; value: string | number | undefined | any }[] = [
      { prop: "specifications", value: name },
      { prop: "pk", value: id }
    ];

    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/exportCSV?${paramUrl}`;
    const response = await securedRestClientContracts.get(requestUrl);
    return response.data;
  },
};
