import React from 'react';

import { Card, Placeholder } from 'semantic-ui-react';
import styles from './flow.module.css';

export const FlowLoader: React.FC = React.memo(() => (
  <div className={styles.wrapper}>
    <Card.Group itemsPerRow={4}>
      <Card className={styles.top2}>
        <Card.Content>
          <Placeholder className={styles.top}>
            <Placeholder.Image />
          </Placeholder>
        </Card.Content>
      </Card>
      <Card className={styles.top2}>
        <Card.Content>
          <Placeholder className={styles.top}>
            <Placeholder.Image />
          </Placeholder>
        </Card.Content>
      </Card>
      <Card className={styles.top2}>
        <Card.Content>
          <Placeholder className={styles.top}>
            <Placeholder.Image />
          </Placeholder>
        </Card.Content>
      </Card>
      <Card className={styles.top2}>
        <Card.Content>
          <Placeholder className={styles.top}>
            <Placeholder.Image />
          </Placeholder>
        </Card.Content>
      </Card>
    </Card.Group>
    <div className={styles.middle}>
      <Placeholder fluid className={styles.longDiv}>
        <Placeholder.Image />
      </Placeholder>
    </div>
    <div>
      <Card.Group itemsPerRow={4} className={styles.bottomCardGroup}>
        <Card className={styles.top2}>
          <Card.Content>
            <Placeholder className={styles.top}>
              <Placeholder.Image />
            </Placeholder>
          </Card.Content>
        </Card>
        <Card className={styles.top2}>
          <Card.Content>
            <Placeholder className={styles.top}>
              <Placeholder.Image />
            </Placeholder>
          </Card.Content>
        </Card>
        <Card className={styles.top2}>
          <Card.Content>
            <Placeholder className={styles.top}>
              <Placeholder.Image />
            </Placeholder>
          </Card.Content>
        </Card>
        <Card className={styles.top2}>
          <Card.Content>
            <Placeholder className={styles.top}>
              <Placeholder.Image />
            </Placeholder>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
    <div className={styles.middle}>
      <Placeholder fluid className={styles.longDiv}>
        <Placeholder.Image />
      </Placeholder>
    </div>


  </div>
));
