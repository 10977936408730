import {
  WithStatelessDatasFetchingByParameters,
  WithQuerystringReading,
} from "../../hocs";
import { ApiList, GetResults, ResultsLight, Foodchain, GetFoodchain } from "../../models";
import { FoodchainResultsPageProps, FoodchainInfocardProps } from "./foodchain-results-page-type";
import { resultsSubService, foodchainSubService } from "../../services";
import { PageLoader } from "../../components";
import React from "react";
import { compose } from "redux";

export const enhancer = compose<React.FC<FoodchainResultsPageProps>>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<
    ApiList<ResultsLight[]>,
    FoodchainResultsPageProps,
    GetResults
  >(resultsSubService.getFoodchainResultsLight, PageLoader, "redirect", "results", [
    "name",
  ])
);

export const foodchainInfocardEnhancer = compose<
  React.FC<FoodchainInfocardProps>>(
  WithStatelessDatasFetchingByParameters<
    Foodchain,
    FoodchainInfocardProps,
    GetFoodchain
  >(foodchainSubService.getFoodchain, PageLoader, "redirect", "foodchain", [
    "name", "year"
  ])
);