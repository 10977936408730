import React from 'react';
import { useTranslation } from 'react-i18next';
import { SpecificationListProps } from './specification-list-type';
import styles from './specification-list.module.css';

export const SpecificationList: React.FC<SpecificationListProps> = ({
 specifications,
 selected,
 handleSelect,
 displayed,
 hidden
}: SpecificationListProps) => {
  const { t } = useTranslation();
  
  return (
    <div>
      {
        specifications.filter(spec => spec.pk && ( !displayed || displayed.includes(spec.pk)) && (!hidden || !hidden.includes(spec.pk))).map(spec => (
          <div 
            key={spec.pk} 
            onClick={() => spec.pk && handleSelect(spec.pk)}
            className={`${spec.pk && selected.includes(spec.pk) ? styles.selected : styles.specification}`}
            >
            {spec.name}
          </div>
        ))
      }
    </div>
  );
};

