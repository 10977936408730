import React from 'react';
import { BlockButtonProps } from './block-button-type';
import styles from './block-button-component.module.css';
import BrandScroll from '../../../../assets/images/brandScroll.png'

export const BlockButton: React.FC<BlockButtonProps> = ({
  disabled,
  text,
  secondary,
  onClick,
  inverted,
  type = 'button',
  warning,
  style,
  deploy
}: BlockButtonProps) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className={`${styles.classicBtn} ${secondary ? styles.secondary : ''} ${inverted ? styles.inverted : ''} ${warning ? styles.warning : ''} ${disabled ? styles.disabled : ''}`}
    type={type}
    disabled={deploy? undefined : disabled}
    onClick={onClick}
    style={style}
  >
    {text}
    {deploy && <img src={ BrandScroll } className={styles.brandScrollButton} onClick={deploy}/>}
  </button>
);
