import React from 'react';

import styles from './new-actor.module.css';
import { ActorWizard } from '../../../../components';
import { WizardLayout } from '../../../../layouts';
import { Actor } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';


export const EcosystemNewActorPage: React.FC = () => {
  const actor: Actor = {
    name: '',
    internal_code: '',
    siret_number: '',
    is_ambassador: false,
    is_placeholder: false,
    related_entity: [],
    related_specifications: [],
    related_actor_group: [],
    related_actor_role: []
  };
  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.list}>
        <ActorWizard initialActor={actor} />
      </div>
    </WizardLayout>
  );
};
