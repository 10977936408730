import React, { useState, useRef } from "react";
import Chevron from "./Chevron";
import "./Accordion.css";

const Accordion = (props: any) => {
  const [activate, setActivate] = useState("");
  const [height, setHeight] = useState("0px");
  const [rotate, setRotate] = useState("accordion__icon");

  const contents: any = useRef(null);

  function toggleAccordion() {
    setActivate(activate === "" ? "active" : "");
    setHeight(
      activate === "active" ? "0px" : `${contents.current.scrollHeight}px`
    );
    setRotate(
      activate === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
    // console.log(contents.current.scrollHeight);
  }

  return (
    <div className="accordion__section">
      <div className={`accordionContract ${activate}`} onClick={toggleAccordion}>
        <div className="indicator">
          {props.criticality === "major" ? (
            <label className="criticalMAJ">MAJ</label>
          ) : props.criticality === "minor" ? (
            <label className="criticalMIN">MIN</label>
          ) : props.criticality === "neutral" ? (
            <label className="criticalNEU">IND</label>
          ) : null}
        </div>
        <strong className="indicatorContent">{props.name}</strong>
        <div className="indicatorButton">
          {props.conformity === true ? (
            <label className="compliant">CONFORME</label>
          ) : props.conformity === false ? (
            <label className="nonCompliant">NON CONFORME</label>
          ) : (
            <label className="noneCompliant">PAS CONCERNE</label>
          )}
        </div>
        <Chevron className={`${rotate}`} width={14} fill={"#0A405D"} />
      </div>
      <div
        ref={contents}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        {props.children}
      </div>
    </div>
  );
};

export default Accordion;
