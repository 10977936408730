import {
  ApiList,
  GetFoodchains,
  FoodchainLight,
  GetFoodchain,
  Foodchain,
} from "../../models";
import { backendTokenService, securedRestClientContracts } from "../../backend";

export const foodchainSubService = {
  getFoodchains: async () => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/foodchains/`;
    const response = await securedRestClientContracts.get<ApiList<FoodchainLight[]>>(
      requestUrl
    );
    return response.data;
  },

  getFoodchain: async ({ name, year }: GetFoodchain) => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/foodchains/${name}/?year=${year}`;
    const response = await securedRestClientContracts.get<Foodchain>(requestUrl);
    return response.data;
  },

  // getFolder: async ({id}: GetFolder) => {
  //     const token = backendTokenService.getToken();
  //     const requestUrl = `${
  //     token && token.apiVersion ? token.apiVersion : ''
  //     }/contracts/folders/${id}`;
  //     const response = await securedRestClient.get<Folder>(requestUrl);
  //     console.log(response.data);
  //     return response.data;
  // },
};
