import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './reset-password.module.css';
import { ResetPasswordProps } from './reset-password-types';
import enhancer from './reset-password-enhancer';
import { AuthLayout } from '../../../layouts';
import backgroundImage from '../../../../assets/images/backgroundLogin.jpg';
import CFLogo from '../../../../assets/images/CF.png';
import { AuthenticatedContext } from '../../../contexts';
import { ResetPasswordForm } from '../../../components';
import { authSubService } from '../../../services';
import { toastHandler } from '../../../utils';

const InnerResetPassword: React.FC<ResetPasswordProps> = React.memo(({ id, token }: ResetPasswordProps) => {
  let errorMessage: string | undefined;

  const history = useHistory();

  const resetPassword = async (newPassword: string, newPasswordConfirm: string, newId: string, newToken: string) => {
    try {
      const result = await authSubService.resetPasswordConfirm(newPassword, newPasswordConfirm, newId, newToken);
      history.push('/home');
      toastHandler(t('autentication.actions.resetSuccess'), 'success');
    } catch (err) {
      errorMessage = 'error reset password';
      toastHandler(t('autentication.actions.resetError'), 'error')
    }
  };

  const { t } = useTranslation();
  const authenticatedContext = React.useContext(AuthenticatedContext);

  const isAuthenticated = authenticatedContext.authenticated;

  return (
    <AuthLayout image={backgroundImage}>
      <div className={styles.content}>
        <div className={styles.header}>
          <img src={CFLogo} className={styles.logo} />
          <div className={styles.title}>
            <b>{t('authentication.header.title')}</b>
          </div>
        </div>


        <div className={styles.formWrapper}>
          <ResetPasswordForm
            model={{ newPassword: '', newPasswordConfirm: '' }}
            isAuthenticated={isAuthenticated}
            onSuccess={(model: any) => resetPassword(model.newPassword, model.newPasswordConfirm, id, token)}
            errorMessage={errorMessage}
          />
        </div>


        <div className={styles.footer}><h6>{t('authentication.header.footer')}</h6></div>

      </div>
    </AuthLayout>
  );
});

export const ResetPasswordPage = enhancer(InnerResetPassword);
