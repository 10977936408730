import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './folder-wizard-component.module.css';
import { FolderWizardProps } from './folder-wizard-type';
import { Folder } from '../../../models';
import { folderSubService } from '../../../services';
import { SpecificationSwapForm } from '../../forms';
import checkPictoWhite from '../../../../assets/pictos/checkPictoWhite.png';
import trashPictoRed from '../../../../assets/pictos/trashPictoRed.png';
import specificationPictoBlue from '../../../../assets/pictos/productionspecificationsPictoBlue.png';
import editPictoWhite from '../../../../assets/pictos/editPictoWhite.png';
import { toastHandler } from '../../../utils';
import { WizardLoader } from '../utils';
import { redirectWithUrl } from '../utils/redirect';
import { FolderForm } from '../../forms/folder-form/folder-form';

export const FolderWizard: React.FC<FolderWizardProps> = React.memo(({ create, folder, initialStep, specifications }: FolderWizardProps) => {
  const [editedFolder, setEditedFolder] = React.useState<Folder>(folder);
  const [activeStep, setActiveStep] = React.useState(initialStep ? initialStep : 0);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const nextStep = () => {
    setActiveStep(activeStep === 1 ? 1 : activeStep + 1);
  };

  const previousStep = () => {
    setActiveStep((activeStep || 1) - 1);
  };

  const goTo = (index: number) => {
    setActiveStep(index);
  };

  const manageDeleteFolder = async () => {
    if (editedFolder.pk && editedFolder.pk !== -1) {
      setLoading(true);
      try {
        const result = folderSubService.deleteFolder(editedFolder.pk);
        toastHandler(t('wizard.folders.deleteSuccess'), 'success');
        setLoading(false);
        history.push(redirectWithUrl(location.search, '/specifications'));
      } catch (err) {
        console.log(err);
        setLoading(false);
        toastHandler(t('wizard.folders.deleteError'), 'error');
      }
    }
  };

  const manageCancel = () => {
    history.push(redirectWithUrl(location.search, `/specifications`));
  };

  const onNextFolder = (folder: Folder) => {
    setEditedFolder(folder);
    nextStep();
  };


//gestion du comportement au clique sur 'save' sur l'écran du formulaire des specifications du folder
  const onSaveSpecification = async (folder: Folder) => {
    //on edite le state avec ce que le formulaire nous envoie
    setEditedFolder(folder);
    try {
      //on trigger la sauvegarrde
      await onSaveGlobalFolder(folder);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  //gestion du comportement au clique sur 'save' sur l'écran du formulaire general du folder
  const onSaveFolder = async (folder: Folder) => {
    setEditedFolder(folder);
    try {
      const result = await onSaveGlobalFolder(folder);
      //en cas de sauvegarde validée, on redirige hors de la page, soit sur /specification, soit sur ce qui est indiqué dans l'url, dans le parametre redirectBack (voir redirectWithUrl))
      result && history.push(redirectWithUrl(location.search, `/specifications`));
      nextStep();
    } catch {
      setError('Une erreur est sruvenue sur general');
    }
  };

  //fonction qui va gérer la sauvegarder
  const onSaveGlobalFolder = async (folder: Folder) => {
    setLoading(true);
    try {
      const result: Folder = create ? await folderSubService.createFolder(folder) : await folderSubService.editFolder(folder);
      toastHandler(t('wizard.folders.saveSuccess'), 'success');
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
      toastHandler(t('wizard.folders.saveError'), 'error');
      setError('Une erreur est sruvenue sur general');
    }
  };


  const step = [
    {
      index: 0,
      label: t('wizard.folders.step1'),
      picto: specificationPictoBlue,
      //formulaire des infos general du folder (que le nom pour l'instant)
      Component: <FolderForm
        model={editedFolder}
        onSave={(model) => onSaveFolder(model)}
        errorMessage={error}
        onCancel={manageCancel}
        onNext={(model) => onNextFolder(model)}
      />,
    },
    {
      index: 1,
      label: t('wizard.folders.step2'),
      picto: specificationPictoBlue,
      //choix des specifications a mettre dans le folder, sous forme de swapper
      Component: <SpecificationSwapForm
        model={editedFolder}
        onSave={(model) => onSaveSpecification(model)}
        errorMessage={error}
        onCancel={manageCancel}
        onPrevious={previousStep}
        specifications={specifications}
      />
    }
  ];


  return (
    <div className={styles.wizard}>
      <div className={styles.form}>
        <div className={styles.activeStep}>
          <div className={styles.header}>
            <div className={styles.titlePictoContainer}><img src={step[activeStep].picto} className={styles.titlePicto} /></div>
            <div className={styles.titleLabel}><b>{step[activeStep].label}</b></div>
            {editedFolder.pk && editedFolder.pk !== -1
              && (
              <div className={styles.deleteContainer} onClick={manageDeleteFolder}>
                <div className={styles.trashPictoContainer}><img src={trashPictoRed} className={styles.trashPicto} /></div>
                <div className={styles.deleteLabel}>{t('wizard.folders.delete')}</div>
              </div>
              )}
          </div>
          <div className={`${styles.formWrapper} ${loading ? styles.blured : ''}`}>
            {loading && <WizardLoader />}
            {step[activeStep].Component}
          </div>
        </div>

      </div>
      <div className={styles.track}>
        {
        step.map((el) => (
          activeStep === el.index
            ? (
              <div className={`${styles.container} ${styles.activeContainer}`} key={el.index}>
                <div className={`${styles.badge} ${styles.activeBadge}`}>{el.index + 1}</div>
                <div className={`${styles.label} ${styles.activeLabel}`}>{el.label}</div>
              </div>
            )
            : editedFolder.pk
              ? (
                <div className={`${styles.container} ${styles.editContainer}`} key={el.index} onClick={() => goTo(el.index)}>
                  <div className={`${styles.badge} ${styles.editBadge}`}><img src={editPictoWhite} className={styles.editPicto} /></div>
                  <div className={`${styles.label} ${styles.editLabel}`}>{el.label}</div>
                </div>
              )
              : activeStep > el.index
                ? (
                  <div className={`${styles.container} ${styles.doneContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.doneBadge}`}><img src={checkPictoWhite} className={styles.checPicto} /></div>
                    <div className={`${styles.label} ${styles.doneLabel}`}>{el.label}</div>
                  </div>
                )
                : (
                  <div className={`${styles.container} ${styles.todoContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.todoBadge}`}>{el.index + 1}</div>
                    <div className={`${styles.label} ${styles.todoLabel}`}>{el.label}</div>
                  </div>
                )
        ))
      }
      </div>
    </div>
  );
});
