import React from "react";
import { useTranslation } from 'react-i18next';
import { Table, Loader } from "semantic-ui-react";
import styles from "./results-table.module.css";
import { Link } from "react-router-dom";
import { ResultsTableHeader } from "./header/results-table-header";
import { ResultsTableBody } from "./body/results-table-body";
import { TableProps } from "./results-table-type";
import { FilterButton, ClassicButton } from "../../buttons";
import { resultsSubService } from "../../../services";

export function ResultsTable<T>({
  data,
  header,
  manageSearch,
  loadMoreItems,
  loading,
  endFetching,
  error
}: TableProps<T>) {

  const { t } = useTranslation();
  
  const intersectLastTarget = React.useRef<HTMLDivElement>(null);

  const block = React.useRef(false);

  const [display, setDisplay] = React.useState(block.current);

  React.useEffect(() => {
    block.current = false;
  }, [display]);

  React.useEffect(() => {
    const callback: IntersectionObserverCallback = (list) => {
      list.forEach((entry) => {
        if (!block.current && entry.isIntersecting) {
          block.current = true;
          setDisplay(true);
          loadMoreItems && loadMoreItems();
          setTimeout(() => {
            setDisplay(false);
          }, 500);
        }
      });
    };

    const opts = {
      root: null,
      rootMargin: "200px",
      threshold: 1,
    };

    const observerScroll = new IntersectionObserver(callback, opts);

    if (intersectLastTarget.current !== null) {
      observerScroll.observe(intersectLastTarget.current);
    }
  });

  //const [isSelected, setIsSelected] = React.useState<string>("");

  if (error) {
    return (
      <div>
        <Table basic="very" structured selectable textAlign="center">
          <ResultsTableHeader header={header} manageSearch={manageSearch} />
        </Table>
        <div className={styles.errorWrapper}>
          {/* Error */}
          <div className={styles.error}>
            <strong>Erreur</strong>
          </div>
        </div>
      </div>
    );
  }

  if ((!data || data.length === 0) && loading) {
    return (
      <div>
        <Table basic="very" structured selectable textAlign="center">
          <ResultsTableHeader header={header} manageSearch={manageSearch} />
        </Table>
        <div className={styles.loader} />
        {/* Chargement */}
        <Loader active size="huge" className={styles.semanticLoader} />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div>
        <Table basic="very" structured selectable textAlign="center">
          <ResultsTableHeader header={header} manageSearch={manageSearch} />
        </Table>
        <div className={styles.noResultWrapper}>
          {/* No result */}
          <div className={styles.noResult}>
            <strong>Pas de résultat</strong>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.tableWrapper}>
      <div>
        <Table basic="very" structured selectable textAlign="center">
          <ResultsTableHeader header={header} manageSearch={manageSearch} />
          <ResultsTableBody data={data} header={header} />

          {!loading && !block.current && !display && !endFetching && (
            <>
              <div
                ref={intersectLastTarget}
                style={{
                  position: "relative",
                  top: data.length < 11 ? `-${5 + data.length * 25}px` : "-250px"
                    // "-50px",
                }}
              />
            </>
          )}
        </Table>
      </div>
    </div>
  );
}
