import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './alert.module.css';
import { AlertProps } from './alert-types';
import enhancer from './alert-enhancer';
import {
  CommentProps, CommentForm, ClickableContainer, ClassicButton,
} from '../../../components';
import { ContainerLayout, TitleLayout } from '../../../layouts';
import { DetailedAlert, AlertComment } from '../../../models';
import { config } from '../../../../config';
import { formatDate, formatTime, toastHandler } from '../../../utils';
import { alertSubService } from '../../../services';

const InnerGroup: React.FC<AlertProps> = React.memo(({ alert }: AlertProps) => {
  const { t } = useTranslation();

  return (
    <ContainerLayout style={{ backgroundColor: 'white' }}>
      <TitleLayout inverted>{t('alerts.title')}</TitleLayout>
      {
        alert
          ? <AlertRecap alert={alert} />
          : (
            <div>
              {t('alerts.level2.noAlert')}
            </div>
          )
      }
    </ContainerLayout>
  );
});

export const AlertPage = enhancer(InnerGroup);

interface AlertRecapProps {
  alert: DetailedAlert;
}

export const AlertRecap: React.FC<AlertRecapProps> = React.memo(({ alert }: AlertRecapProps) => {
  const { t } = useTranslation();

  let errorMessage: string | undefined;

  const [comments, setComments] = React.useState<AlertComment[]>(alert.comments ? alert.comments.results : []);

  const submitComment = async (comment: CommentProps) => {
    try {
      const result = await alertSubService.createAlertComment(editedAlert.pk, comment.message);
      setComments([result, ...comments]);
    } catch (err) {
      errorMessage = 'erreur lors de la creation';
      toastHandler(t('alerts.comment.submitError'), 'error');
    }
    
  };

  const [editedAlert, setEditedAlert] = React.useState(alert);


  return (
    <div className={styles.alert}>
      <div className={styles.title}>
        <b>{`${t('alerts.level2.title')}: ${editedAlert.name_line}`}</b>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <div className={styles.subtitle}>
            {t('alerts.level2.date')}
            {' '}
:
          </div>
          <div className={styles.description}>
            {`${formatDate(editedAlert.alert_date_time, i18next.language)}: ${formatTime(editedAlert.alert_date_time, i18next.language)}`}
          </div>
        </div>
        { alert.criticality && (
        <div className={styles.info}>
          <div className={styles.subtitle}>
            {t('alerts.level2.criticality')}
            {' '}
:
          </div>
          <div className={styles.description}>
            {editedAlert.criticality}
          </div>
        </div>
        )}
        <div className={styles.info}>
          <div className={styles.subtitle}>
            {t('alerts.level2.description')}
            {' '}
:
          </div>
          <div className={styles.description}>
            {editedAlert.alert_message}
          </div>
        </div>
        {
            editedAlert.flow
            && editedAlert.flow.map( el =>
            <React.Fragment key={el.pk}>
              <div className={styles.info}>
                <div className={styles.subtitle}>
                  {t('alerts.level2.specifications')}
                  {' '}
:
                </div>
                <div className={styles.description}>
                  {el.specifications}
                </div>
              </div>
              <div className={styles.info}>
                <div className={styles.subtitle}>
                  {t('alerts.level2.batch_out')}
                  {' '}
:
                </div>
                <div className={styles.description}>
                  {el.batch_out}
                </div>
              </div>
              <div className={styles.info}>
                <div className={styles.subtitle}>
                  {t('alerts.level2.flow')}
:
                </div>
                <ClickableContainer link={`${config.client.baseUrl}/tracking/flows/${el.pk}`}>
                  <div className={styles.description}>
                    {`${config.client.baseUrl}/tracking/flows/${el.pk}`}
                  </div>
                </ClickableContainer>
              </div>
            </React.Fragment>
            )
          }

        <div className={styles.info}>
          <div className={styles.subtitle}>
            {t('alerts.level2.link')}
:
          </div>
          <ClickableContainer link={`${config.client.baseUrl}/alerts/${editedAlert.pk}`}>
            <div className={styles.description}>
              {`${config.client.baseUrl}/alerts/${editedAlert.pk}`}
            </div>
          </ClickableContainer>
        </div>
      </div>

      <div className={styles.commentsContainer}>
        <div className={styles.commentFormWrapper}>
          <CommentForm
            model={new CommentProps()}
            onSave={(model: CommentProps) => submitComment(model)}
            errorMessage={errorMessage}
          />
        </div>
        {
              comments && comments.length > 0 && (
                <div className={styles.comments}>
                  {
                    comments.map((el: AlertComment) => (
                      <div className={styles.comment} key={el.pk}>
                        <div className={styles.commentHeader}>
                          <div className={styles.commentAuthor}>
                            <b>{`${el.user.first_name} ${el.user.last_name}`}</b>
                          </div>
                          <div className={styles.commentDate}>
                            {`${formatDate(el.comment_date_time, i18next.language)}-${formatTime(el.comment_date_time, i18next.language)}`}
                          </div>
                        </div>
                        <div className={styles.commentMessage}>
                          {el.description}
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }
      </div>
      <div>
        <ClassicButton text={editedAlert.is_archived ? t('alerts.actions.archived') : t('alerts.actions.unarchived')}  />
      </div>
    </div>

  );
});
