import React from 'react';
import { SpecificationsSidebarProps } from './specifications-page-type';
import styles from './specifications-page.module.css';
import { Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import addIcon from '../../../assets/pictos/addPictoBlue.png';
import { folderEnhancer } from './enhancer';

const InnerSpecificationsSidebar: React.FC<SpecificationsSidebarProps> = ({folders, selectedFolder, handleSelectFolder}: SpecificationsSidebarProps) => {
    
    return <div className={styles.sidebar}>
      <Link to='/folders/create'>
        <div>
          créer un nouveau repertoire
        </div>
      </Link>
        <div
          onClick={() => handleSelectFolder()}
          className={styles.allFolder}
          >
          Tous
        </div>
        {
          folders?.results.map(folder => (
            <div 
              key={folder.pk} 
              onClick={() => handleSelectFolder(folder.pk)}
              className={`${folder.pk === selectedFolder ? styles.selectedFolder : styles.folder}`}
              >
                <div>
              {folder.name}
              </div>
              <div>
                {/* ATTENTION => faire un stop propagation pour eviter de trigger le clic sur le repertoire */}
                <Link to={`/folders/edit/${folder.pk}`}>
edit 
                </Link>
                </div>
            </div>
          ))
        }
    </div>
}

export const SpecificationsSidebar = folderEnhancer(InnerSpecificationsSidebar)