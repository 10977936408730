import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { fr, en, it, es } from './locales';

type LoadType = 'all' | 'currentOnly' | 'languageOnly' | undefined

const options = {
  interpolation: {
    escapeValue: false, // react already safes from xss
  },

  whitelist: ['fr', 'en', 'it', 'es'],
  nonExplicitWhitelist: true,
  load: 'languageOnly' as LoadType,

  resources: {
    fr: {
      common: fr.fr,
    },
    en: {
      common: en.en,
    },
    it: {
      common: it.it,
    },
    es: {
      common: es.es,
    }
  },

  fallbackLng: 'en',

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
  },
};

i18n.use(LanguageDetector).init(options);

export default i18n;
