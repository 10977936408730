import React from 'react';
import styles from './list-site-form.module.css';
import { ListSiteFormProps } from './list-site-form-types';
import { siteSubService } from '../../../services';
import { SiteRead } from '../../../models';
import { SitesHeader } from '../../../constants';
import { useParams } from '../../../hooks';
import { WithLazyloadingDatasFetching } from '../../../hocs/with-lazyloading-datas-fetching';
import { TableForm, TableFormProps } from '../table-form';

export const ListSiteForm: React.FC<ListSiteFormProps> = ({
  model, onSave, onNext, onPrevious, errorMessage, onCancel, specificParams, include, blocking, multiple,
}: ListSiteFormProps) => {
  const {
    params, header, manageOrdering, manageSearch, focused
  } = useParams(SitesHeader, specificParams);
  const [editedModel, setEditedModel] = React.useState(model);

  const handleAll = React.useCallback((add: boolean) => {
    const fetchAllItems = async (add: boolean) => {
      try {
        const result = await siteSubService.getSite({ ...params, limit: 100000 });
        const idList = result.results.map((el: any) => el.pk).filter((el) => el !== null);
        setEditedModel(idList as number[]);
      } catch {
        console.log('Une erreur est survenu');
      }
    };
    if (add) {
      fetchAllItems(add);
    } else {
      setEditedModel([]);
    }
  }, [params]);

  const EnhancedTableForm = WithLazyloadingDatasFetching<SiteRead, TableFormProps<SiteRead>>(
    siteSubService.getSite, 'data', params, params.limit ? parseInt(params.limit) : 100,
  )(TableForm);
  return (
    <div className={styles.tableWrapper}>
      <EnhancedTableForm
        data={[]}
        header={header}
        manageOrdering={manageOrdering}
        manageSearch={manageSearch}
        focused={focused}
        model={editedModel}
        onSave={onSave}
        errorMessage={errorMessage}
        onCancel={onCancel}
        onNext={onNext}
        onPrevious={onPrevious}
        include={include}
        blocking={blocking}
        multiple={multiple}
        handleAll={multiple ? handleAll : undefined}
      />
    </div>
  );
};
