import React from 'react';

import styles from './actor.module.css';
import { ActorProps } from './actor-types';
import enhancer from './actor-enhancer';
import { Actor } from '../../../../models';
import { EcosystemItem } from '../../item/item';
import { useTranslation } from 'react-i18next';

import actorPictoWhite from '../../../../../assets/pictos/actorPictoWhite.svg';
import { Panels } from '../../utils/model';
import { sitePanel, toolPanel, productPanel, certificatePanel, relationsPanel, initialCount } from '../../utils/constant';


const InnerPage: React.FC<ActorProps> = ({ actors, id }: ActorProps) => {
  const {t} = useTranslation();
  if (actors.length === 0) {
    return <></>;
  }

  const actor = actors as unknown as Actor;

  const panels:Panels = {sites: sitePanel, tools: toolPanel, products: productPanel, certificates: certificatePanel,  relations: relationsPanel}

  const itemLight = {
    description: [
      {
        label: t('form.actors.siret_number'),
        value: actor.siret_number,
      },
      {
        label: t('form.actors.is_ambassador'),
        value: actor.is_ambassador ? t('form.utils.true') as string : t('form.utils.false') as string,
      },
    ],
    title: `${t('ecosystem.actors.title')} ${actor.name}`,
    picto: actorPictoWhite,
    permissionRoute: '/ecosystem/actor:update',
    link: `/ecosystem/edit-actor/${actor.pk}`
  }
  

  return (
    <EcosystemItem panels={panels} itemLight={itemLight} id={id} specificParams={{owner: id}} propertyName="owner" counts={initialCount} />
  );
};

export const EcosystemActorPage = enhancer(InnerPage);

 