import React from 'react';
import styles from './edit-product.module.css';
import { EditProductProps } from './edit-product-types';
import { ProductWizard } from '../../../../components';
import { WizardLayout } from '../../../../layouts';
import enhancer from './edit-product-enhancer';
import { ProductRead, ProductWrite } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';
import { getParam } from '../../../../components/wizards/utils';
import { useLocation } from 'react-router-dom';

const InnerPage: React.FC<EditProductProps> = ({ products }: EditProductProps) => {
  const location = useLocation();
  if (products.length === 0) {
    return <></>;
  }
  const product = products as unknown as ProductRead;
  const properProduct: ProductWrite = {
    ...product,
    owner: product.owner.map((el) => el.pk),
  };

  const urlStep = getParam(location.search.substring(1), 'step');
  const initialStep:number = urlStep ? parseInt(urlStep) : 0;
  
  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.wizardWrapper}>
        <ProductWizard initialStep={initialStep} edit initialProduct={properProduct} />
      </div>
    </WizardLayout>
  );
};

export const EcosystemEditProductPage = enhancer(InnerPage);
