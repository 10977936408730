import { compose } from 'redux';
import { WithQuerystringReading, WithNotifyRessourceLoadedInputProps, WithStatelessDatasFetchingByParameters } from '../../../../hocs';
import { GetGroupLight, GetCertificate } from '../../../../models';
import { EditCertificateProps } from '.';
import { certificateSubService } from '../../../../services';
import { GroupList } from '../../group';
import { PageLoader } from '../../../../components/page-loader';

const enhancer = compose<
React.ComponentType<GetGroupLight & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<GroupList, EditCertificateProps, GetCertificate>(
    certificateSubService.getCertificate,
    PageLoader,
    'error',
    'certificates',
    ['id'],
  ),
);

export default enhancer;
