import React, { useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import DatePicker from 'react-date-picker';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { DatePickerControlProps } from './date-picker-control-type';
import styles from './date-picker-control-component.module.css';
import { getLanguage } from '../../../../utils';

const calculateAge = (birthdate: Date | string): number => {
  const diff = Date.now() - new Date(birthdate).getTime();
  const age = new Date(diff);

  return Math.abs(age.getUTCFullYear() - 1970);
};

export const DatePickerControl: React.FC<DatePickerControlProps> = ({
  required,
  name,
  minAgeRequired,
  ...rest
}: DatePickerControlProps) => {
  const { t } = useTranslation();
  const validate = useCallback(
    (value: string): string | undefined => {
      let result;
      if (!value && required) {
        result = t('shared.form.required');
      }
      if (value && minAgeRequired && calculateAge(value) < minAgeRequired) {
        result = t('shared.form.minAgeRequired');
      }

      return result;
    },
    [required, t, minAgeRequired],
  );
  return (
    <Field
      required
      name={name}
      validate={validate}
      render={({ field, form }: FieldProps) => (
        <>
          <DatePicker
            {...field}
            {...rest}
            format={getLanguage(i18next.language) === 'fr' ? 'dd-MM-y' : 'MM-dd-y'}
            name={name}
            className={styles.fullWidth}
            clearIcon={null}
            onChange={(value) => {
              form.setFieldValue(name, value);
            }}
            calendarIcon={<div className={styles.icon} />}
          />
          {form && form.errors && form.errors[name] && form.touched && form.touched[name] && (
          <div className={styles.error}>{form.errors[name]}</div>
          )}
        </>
      )}
    />
  );
};
