import { compose } from 'redux';
import { WithQuerystringReading, WithStatelessDatasFetchingByParameters, WithNotifyRessourceLoadedInputProps } from '../../../hocs';
import { BatchProps } from '.';
import { batchSubService } from '../../../services';
import { Batch, GetBatch } from '../../../models';
import { PageLoader } from '../../../components/page-loader';

const enhancer = compose<
React.ComponentType<GetBatch & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<Batch, BatchProps, GetBatch>(
    batchSubService.getBatch,
    PageLoader,
    'redirect',
    'batch',
    ['id'],
  ),
);

export default enhancer;
