import * as React from 'react';
import styles from './expander-panel-component.module.css';
import { ExpanderPanelProps } from './expander-panel-type';
import { useParams } from '../../hooks';
import { WithLazyloadingDatasFetching } from '../../hocs/with-lazyloading-datas-fetching';
import { TableProps } from '../table/table-type';
import { GenericTable } from '../table';
import { Accordion } from 'semantic-ui-react';
import { EcosystemItemCount } from '../../pages/ecosystem-page/item/item-count';
import expanderPictoOpen from '../../../assets/pictos/expanderPictoOpen.png';
import expanderPictoClose from '../../../assets/pictos/expanderPictoClose.png';

export function ExpanderPanel <T>({
  item,
  limit,
  specificParams,
  service,
  initHeader,
  count,
  active,
  handleActive,
  picto
}: ExpanderPanelProps<T>) {
  const {
    params, header, manageOrdering, manageSearch, focused
  } = useParams(initHeader, specificParams);

  const EnhancedTable = WithLazyloadingDatasFetching<T, TableProps<T>>(
    service, 'data', params, limit,
  )(GenericTable);

  return (
    <div>
<Accordion styled className={styles.accordion} fluid exclusive>
<Accordion.Title
  active={active}
  onClick={handleActive}
>
  <div className={styles.title}>
    <EcosystemItemCount picto={picto} item={item} count={count} />
    <div className={styles.pictoContainer}>
      <img src={active ? expanderPictoClose : expanderPictoOpen} className={styles.picto} />
    </div>
  </div>
</Accordion.Title>
<Accordion.Content active={active} className={styles.accordionContent}>
  {active && 
  <div className={styles.tableWrapper}>
      <EnhancedTable data={[]} header={header} linkType={item} clickTable manageOrdering={manageOrdering} manageSearch={manageSearch} focused={focused} />
    </div>}
</Accordion.Content>
</Accordion>
</div>
  );
}
