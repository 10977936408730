import React, { useRef } from "react";
import styles from "./view-risk-details-page.module.css";
import { riskDetailsEnhancer } from "./view-risk-details-page-enhancer";
import actorPictoWhite from "../../../../assets/pictos/actorPictoWhite.svg";
import Accordion from "../../contract-page/accordion/Accordion";
import { RiskStatusDetailsProps } from "../../../models";
// import Chevron from "./accordion/Chevron";

export const InnerViewRiskDetailsPage: React.FC<RiskStatusDetailsProps> = ({
  results,
}: RiskStatusDetailsProps) => {
  if (!results) {
    return <div>Pas de résultat</div>;
  }

  const renderCritereList = () => {
    const tab = [];
    for (const [key, value] of Object.entries(results.audit_values)) {
      const detailResults = JSON.stringify(value, null, 5).toString();
      const detailsCritere = JSON.parse(detailResults).messages;

      const ind = {
        name: key,
        data: JSON.parse(detailResults),
        messages: detailsCritere,
      };

      tab.push(ind);
    }

    return tab.map((k, index: any) => (
      <div key={index}>
        <div className={styles.tabs} key={index}>
          <div className={styles.tab}>
            <Accordion
              criticality={k.data.criticality}
              name={k.name}
              conformity={k.data.conformity}
            >
              <strong style={{ fontSize: "11px" }}>Résumé : </strong>
              <span style={{ color: "#97B6E3", fontSize: "11px" }}>
                {k.data.comment}
              </span>
              {k.messages !== undefined ? (
                <hr style={{ background: "#dfe6e9", margin: "0 50% 25px 0" }} />
              ) : null}
              {k.messages !== undefined ? (
                <div style={{ cursor: "pointer" }}>
                  <strong style={{ fontSize: "11px" }}>
                    Détails du résultat :{" "}
                  </strong>
                  {k.messages &&
                    k.messages.map((message: string, index: number) => (
                      <p
                        key={index}
                        style={{ color: "#97B6E3", fontSize: "11px" }}
                      >
                        {message}
                      </p>
                    ))}
                </div>
              ) : null}
            </Accordion>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className={styles.viewActorLightItem}>
        <div className={styles.viewActorLightItemHeader}>
          <div style={{display: "flex", alignItems: "center"}}>
            <div className={styles.viewActorItemPicto}>
              <img
                src={actorPictoWhite}
                alt=""
                className={styles.viewActorItemPictoLight}
              />
            </div>
            
          </div>
          <div style={{marginLeft: "-1%"}}>
            <strong style={{ color: "#0A405D", fontSize: "2rem" }}>{results.actor}</strong>
          </div>

          {
            results.risk_status === "not valid" ? (
              <strong
                className={`${styles.actorNotValid} ${styles.actorRiskStatus}`}
              >
                NON VALIDE
              </strong>
            ) : results.risk_status === "expert" ? (
              <strong
                className={`${styles.actorExpert} ${styles.actorRiskStatus}`}
              >
                EXPERT
              </strong>
            ) : results.risk_status === "member" ? (
              <strong
                className={`${styles.actorMembers} ${styles.actorRiskStatus}`}
              >
                MEMBRE
              </strong>
            ) : results.risk_status === "at risk" ? (
              <strong
                className={`${styles.actorRisk} ${styles.actorRiskStatus}`}
              >
                A RISQUE
              </strong>
            ) : results.risk_status === "critical" ? (
              <strong
                className={`${styles.actorCritic} ${styles.actorRiskStatus}`}
              >
                CRITIQUE
              </strong>
            ) : null
          }

          <div className={styles.viewActorEdit}></div>
        </div>
      </div>
      <React.Fragment>
        <div className="wrapDataContract">
          <div>
            <div className={styles.mainWrapper} style={{ marginTop: "75px" }}>
              {renderCritereList()}
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export const ViewRiskDetailsPage = riskDetailsEnhancer(
  InnerViewRiskDetailsPage
);
