import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

export const AuditLoader: React.FC = React.memo(() => (
  <div style={{ margin: 'auto', 
    height: '60vh',
    padding: '40px',
    backgroundColor: '#f7f7f8',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'}}>

    <Dimmer active inverted>
      <Loader size="massive" />
    </Dimmer>

  </div>
));