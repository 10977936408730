import React from "react";
import { useTranslation } from 'react-i18next';
import { FoodchainResultsFilterProps } from "./foodchain-results-page-type";
import styles from "./foodchain-results-page.module.css";
import { FilterButton } from "../../components";
import { posthogEvents } from "../../utils/posthog";

export function FoodchainConformityFilter({
  manageSearch,
}: FoodchainResultsFilterProps) {

  const { t } = useTranslation();

  const [isSelected, setIsSelected] = React.useState<string>("");

  const handleFilterClick = (searchProp: string, value: string) => {
    posthogEvents.triggerEvent({ name: 'clickOn',  datas: { [searchProp] : `${value === 'true' ? 'conform' : value === 'false'  ? 'non conform' : 'all'}` }})
    console.log(value);
    console.log("searchProp", searchProp);
    setIsSelected(value);
    manageSearch && manageSearch(searchProp, value as string);
  };

  return (
    <div className={styles.buttonWrapper}>
      <div className={styles.buttonFilter}>
        <FilterButton
          onClick={() => handleFilterClick("conformity", "")}
          text={t('foodchainResults.all')}
          selected={isSelected === ""}
        />
      </div>
      <div className={styles.buttonFilter}>
        <FilterButton
          onClick={() => handleFilterClick("conformity", "true")}
          text={t('foodchainResults.compliant.all')}
          selected={isSelected === "true"}
        />
      </div>
      <div className={styles.buttonFilter}>
        <FilterButton
          onClick={() => handleFilterClick("conformity", "false")}
          text={t('foodchainResults.noCompliant.all')}
          selected={isSelected === "false"}
        />
      </div>
    </div>
  );
}
