import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { PrivateRouteProps } from './private-route-type';
import { AuthenticatedContext } from '../../contexts';
import { checkPermission } from '../../roles';

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  render,
  component: Component,
  ...rest
}: PrivateRouteProps) => {
  const authenticatedContext = React.useContext(AuthenticatedContext);

  const isAuthenticated = authenticatedContext.authenticated;
  const { folder } = authenticatedContext;
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        const redirect = rest.location && rest.location.pathname + rest.location.search;
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: `redirectPath=${redirect}`,
              }}
            />
          );
        }

        if (!folder) {
          // console.log('LOGGED MAIS PAS DE FOLDER');
          // logout + pareil que si !isAuhenticated
          return <div>LOGOUT + LOGIN AGAIN</div>;
        }

        if (!checkPermission(folder.permission, `${rest.path}:visit`, folder)) {
          // comment avoir l'ancien chemin
          console.log('not enought permission', rest.path);
          history.goBack();
          return <div>REDIRECT NOT ENOUGH PERMISSION</div>;
        }

        if (Component) {
          return <Component {...props} />;
        }

        if (render) {
          // console.log('DANS RENDER, POURQUOI PAS DANS COMPONENT ?');
          return render(props);
        }

        return <></>;
      }}
    />
  );
};
