import React from 'react';

import styles from './auth-layout.module.css';
import { AuthLayoutProps } from '.';
import { LanguageHandler } from '../../components';

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children, image }) => {
  const [active, setActive] = React.useState(false);
  return (
  <>
    <div className={styles.layout} style={{ backgroundImage: `url(${image}` }} />
    <div className={styles.content}>
    <div className={styles.language}>
      <LanguageHandler active={active} setActive={setActive} />
    </div>
      {children}
    </div>
  </>
)};
