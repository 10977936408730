import { compose } from 'redux';
import { WithQuerystringReading, WithNotifyRessourceLoadedInputProps, WithStatelessDatasFetchingByParameters } from '../../../../hocs';
import { GetGroupLight, NewGroup } from '../../../../models';
import { EditGroupProps } from '.';
import { groupSubService } from '../../../../services';
import { PageLoader } from '../../../../components/page-loader';

const enhancer = compose<
React.ComponentType<GetGroupLight & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<NewGroup, EditGroupProps, GetGroupLight>(
    groupSubService.getEditGroup,
    PageLoader,
    'error',
    'group',
    ['id'],
  ),
);


export default enhancer;
