import axios from 'axios';
import i18next from 'i18next';
import { backendTokenService } from './sub-services';
import { concatUrl } from '../../utils';
import { configContracts } from '../../../config';
import { Error } from '../../models';

const securedRestClientContracts = axios.create({
  baseURL: concatUrl([configContracts.backend.baseUrl/*, i18next.language ? i18next.language === 'fr-FR' ? 'fr' : i18next.language : 'en'*/, configContracts.backend.rest.url]),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function errorResponseHandler(error: any): Promise<Error> {
  const returnedError: Error = {
    message:
      error.response && error.response.data
        ? error.response.data.detail
        : error.message,
    code: error.response ? error.response.status : -1,
    subCode:
      error.response && error.response.data ? error.response.data.code : -1,
    subCodeLib:
      error.response && error.response.data ? error.response.data.title : -1,
  };

  return Promise.reject(returnedError);
}

securedRestClientContracts.interceptors.request.use(
  async (requestConfig) => {
    const originalRequestConfig = requestConfig;
    const token = backendTokenService.getToken();
    originalRequestConfig.headers.authorization = `Token ${
      token?.contracts_token ? token.contracts_token : ''
    }`;
    // originalRequestConfig.headers['Accept-Language'] = i18next.language ? i18next.language : 'en';
    return originalRequestConfig;
  },
  (err) => Promise.reject(err),
);

securedRestClientContracts.interceptors.response.use(
  (response) => response,
  (error) => errorResponseHandler(error),
);
export { securedRestClientContracts };
