

export const redirectWithUrl = (url:string, defaultUrl:string):string => {
    const searchParams = url.substring(1);
    const redirectionPath = getParam(searchParams, 'redirectPath');
    if (redirectionPath)
        return redirectionPath;
    return defaultUrl;
}

export const getParam = (searchParams:string, key:string):string|undefined => {
    const params = searchParams.split('&');
    const param = params.filter(el => el.startsWith(`${key}=`));
    if (param.length > 0) {
        const result = param[0].substring(key.length + 1);
        return result
    }
    return undefined;
}