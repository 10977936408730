import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SpecificationSwapFormProps,
} from './specification-swap-form-type';
import styles from './specification-swap-form-component.module.css';
import { Folder } from '../../../models';
import { SpecificationSwap } from '../../swaps';
import { ClassicButton } from '../../buttons';

export const SpecificationSwapForm: React.FC<SpecificationSwapFormProps> = ({
  model,
  onSave,
  errorMessage,
  onCancel,
  onNext,
  onPrevious,
  specifications
}: SpecificationSwapFormProps) => {
  const { t } = useTranslation();
  const [folder, setFolder] = React.useState<Folder>(model);

  const handleSubmit = () => {
    onSave(folder);
  }

  const handleNext = () => {
    onNext && onNext(folder);
  };

  const [listLeft, setListLeft] = React.useState<number[]>([]);
  const [listRight, setListRight] = React.useState<number[]>([]);

  const handleListLeft = (list: number[]) => {
    setListRight(listRight.filter(el => !list.includes(el)))
  }

  const handleListRight = (list: number[]) => {
    setListRight([...listRight, ...list]);
    setFolder({
      ...folder,
      specifications: [...listRight, ...list]
    })
  }

  return (
    <>
          <div>
            <SpecificationSwap 
              listLeft={listLeft} 
              listRight={listRight} 
              handleListLeft={handleListLeft} 
              handleListRight={handleListRight} 
              specifications={specifications}
              />
          </div>
          <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
              onCancel
                ? <ClassicButton text={t('wizard.folder.cancel')} onClick={onCancel} warning />
                : <div>&nbsp;</div>
            }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
              onPrevious
                ? <ClassicButton text={t('wizard.folder.back')} onClick={onPrevious} inverted />
                : <div>&nbsp;</div>
            }
                </div>
                <div className={styles.nextNavigation}>
                  {
              onNext
                ? <ClassicButton onClick={handleNext} text={t('wizard.folder.next')} inverted />
                : <div>&nbsp;</div>
            }

                </div>
              </div>
              <div className={styles.submitActions}>
                <ClassicButton type="submit" text={t('wizard.folder.submit')} onClick={handleSubmit} />
              </div>
            </div>
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};
