import React, { useState } from 'react';

import { PasswordInputControlProps } from './password-input-control-type';

import { InputControl } from '../input-control';

import styles from './password-input-control-component.module.css';
import { posthogEvents } from '../../../../utils/posthog';

export const PasswordInputControl: React.FC<PasswordInputControlProps> = ({
  hint,
  ...rest
}: PasswordInputControlProps) => {
  // 'doit contenir au moins 8 caractères dont 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.';
  const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+=/_~,;:\\]).{8,}$/g;
  const devPattern = /^(?=.*?[a-z]).{3,}$/g;
  const defaultHint = '';

  const [type, setType] = useState('password');

  return (
    <>
      <div className={styles.container}>
        <InputControl
          className={styles.input}
          type={type}
          pattern={devPattern}
          hint={hint || defaultHint}
          {...rest}
        />
        <button
          className={styles.toggleButton}
          type="button"
          onClick={() => {
            posthogEvents.triggerEvent({name : 'clickOn', datas: {'eyeButton' : `${type === 'password' ? 'show' : 'hide'}Password`}})
            if (type === 'password') {
              setType('text');
            } else {
              setType('password');
            }
          }}
        />
      </div>
    </>
  );
};
