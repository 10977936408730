import React from 'react';
import { FolderCreatePageProps } from './folder-create-page-type';
import { Folder } from '../../models';
import { FolderWizard } from '../../components';
import { WizardLayout } from '../../layouts/wizard-layout';
import backgroundLogin from '../../../assets/images/backgroundLogin.jpg';
import styles from './folder-create-page.module.css';
import { enhancer } from './enhancer';

const InnerFolderCreatePage: React.FC<FolderCreatePageProps> = ({specifications}: FolderCreatePageProps) => {
 
    const newFolder:Folder = {
        name: '',
        specifications: []
    }

    return <WizardLayout image={backgroundLogin}>
    <div className={styles.wizardWrapper}>
    <FolderWizard 
      folder={newFolder} 
      create 
      specifications={specifications ? specifications.results : []} />
    </div>
  </WizardLayout>
}

export const FolderCreatePage = enhancer(InnerFolderCreatePage);