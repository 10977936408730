import {
  AlertContextCategory, AlertLight, AlertLightAudit, AlertLightFlow, AlertLightEcosystem, AlertPreview,
} from '../models';

export const alertPreviewBuilder = (type: AlertContextCategory, data: AlertLight): AlertPreview => {
  switch (type) {
    case 'audit': {
      const alert = data as AlertLightAudit;
      return ({
        pk: alert.pk,
        criticality: alert.criticality,
        message: alert.alert_title,
        date: alert.alert_date_time,
        flow: alert.flow,
      });
    }
    case 'connection_key': {
      const alert = data as AlertLightFlow;
      return ({
        pk: alert.pk,
        criticality: 'important',
        message: alert.alert_title,
        date: alert.alert_date_time,
        flow: alert.flow,
      });
    }
    case 'matching_key': {
      const alert = data as AlertLightEcosystem;
      return ({
        pk: alert.pk,
        criticality: 'important',
        message: alert.alert_title,
        date: alert.alert_date_time,
      });
    }
    case 'missing_data': {
      const alert = data as AlertLightFlow;
      return ({
        pk: alert.pk,
        criticality: 'important',
        message: alert.alert_title,
        date: alert.alert_date_time,
        flow: alert.flow,
      });
    }
  }
};

export const alertPreviewsBuilder = (type?: AlertContextCategory, data?: AlertLight[]): AlertPreview[] => {
  if (!data || data.length === 0 || !type) return [];
  const result: AlertPreview[] = data.map((el) => alertPreviewBuilder(type, el));
  return result;
};
