import React, { useEffect } from "react";
import { SimpleJson, HeaderColumn } from "../models/utils-contract";
import { posthogEvents } from "../utils/posthog";

export function useParams<T>(
  initHeader: HeaderColumn[],
  initParams: any
) {
  const [params, setParams] = React.useState<SimpleJson>(initParams);
  const [header, setHeader] = React.useState<HeaderColumn[]>(initHeader);

  useEffect(() => {
    setHeader(initHeader);
    setParams(initParams);
  }, [initHeader]);

  const manageOrdering = (orderer: string) => {
    // console.log("ordering sur : ", orderer);
    if (params.ordering === orderer) {
      posthogEvents.triggerEvent({name: 'filter', datas: {'order' : `-${orderer}`}});
      setParams({ ...params, ordering: `-${orderer}` });
    } else {
      posthogEvents.triggerEvent({name: 'filter', datas: {'order' : orderer}});
      setParams({ ...params, ordering: orderer });
    }
  };

  const manageSearch = (searchProp: string, searchValue: string) => {
    // console.log("search sur : ", searchProp, searchValue);
    posthogEvents.triggerSearch(searchProp, searchValue);
    if (params[searchProp] !== searchValue) {
      setHeader(
        header.map((el) => {
          if (el.searchProp === searchProp) {
            return { ...el, searchValue };
          }
          return el;
        })
      );
      setParams({ ...params, [searchProp]: searchValue });
    }
  };

  return {
    params,
    header,
    manageOrdering,
    manageSearch,
  };
}
