import React from 'react';

import styles from './certificate.module.css';
import { CertificateProps } from './certificate-types';
import enhancer from './certificate-enhancer';
import { CertificateRead, SimpleJson, SimpleCard } from '../../../../models';
import { EcosystemItem } from '../../item/item';
import { useTranslation } from 'react-i18next';

import certificatePictoWhite from '../../../../../assets/pictos/certificatePictoWhite.svg';
import { Panels } from '../../utils/model';
import { sitePanel, toolPanel, productPanel, actorPanel, initialCount } from '../../utils/constant';
import { formatDate } from '../../../../utils';
import i18next from 'i18next';


const InnerPage: React.FC<CertificateProps> = ({ certificates, id }: CertificateProps) => {
  const {t} = useTranslation();
  if (certificates.length === 0) {
    return <></>;
  }


  const certificate = certificates as unknown as CertificateRead;

  const panels:Panels = {actors: actorPanel, sites: sitePanel, tools: toolPanel, products: productPanel}

  const buildSpecificData = (specData: SimpleJson | undefined): SimpleCard[] => {
    if (!specData) return [];
    const labels: string[] = Object.keys(specData);
    return labels.map((el: string) => ({
      label: el,
      value: specData[el],
    })).filter((el) => el.value !== undefined && el.value !== null && el.value.toString().trim() !== '');
  };

  const certificateData = buildSpecificData(certificate.certificate_data);
  
  const itemLight = {
    description: [
      {
        label: t('form.certificates.internal_code'),
        value: certificate.internal_code,
      },
      {
        label: t('form.certificates.audit_date'),
        value: formatDate(certificate.audit_date, i18next.language),
      },
      {
        label: t('form.certificates.start_date'),
        value: formatDate(certificate.start_date, i18next.language),
      },
      {
        label: t('form.certificates.end_date'),
        value: formatDate(certificate.end_date, i18next.language),
      },
      {
        label: t('form.certificates.activation_date'),
        value: formatDate(certificate.activation_date, i18next.language),
      },
      ...certificateData
    ]/*.filter((el) => el.value !== undefined && el.value !== '')*/,
    title: `${t('ecosystem.certificates.title')} ${certificate.name}`,
    picto: certificatePictoWhite,
    permissionRoute: '/ecosystem/certificate:update',
    link: `/ecosystem/edit-certificate/${certificate.pk}`,
    document:  certificate.document
  }
  
  return (
    <EcosystemItem panels={panels} itemLight={itemLight} id={id} specificParams={{certificate: id}} propertyName="certificate" counts={initialCount}/>
  );
};

export const EcosystemCertificatePage = enhancer(InnerPage);

