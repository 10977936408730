import * as React from 'react';
import { Link } from 'react-router-dom';
import { ClickablecontainerProps } from './clickable-container-type';

import styles from './clickable-container-component.module.css';

export const ClickableContainer: React.FC<ClickablecontainerProps & { children?: React.ReactNode }> = ({
  link,
  target,
  style,
  className,
  children,
  onHover,
}: ClickablecontainerProps & { children?: React.ReactNode }) => {
  if (link) {
    const externalUrl = !!(link.startsWith('http') || link.startsWith('www'));
    if (externalUrl) {
      const linkTarget = target === undefined ? '_blank' : target;
      return (
        <div className={styles.container}>
          <a
            onMouseEnter={() => {
              if (onHover) onHover.onMouseEnter();
            }}
            onMouseLeave={() => {
              if (onHover) onHover.onMouseLeave();
            }}
            href={link}
            target={linkTarget}
            style={style}
            className={`${className} ${styles.clickableContainer}`}
          >
            {children}
          </a>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <Link
          onMouseEnter={() => {
            if (onHover) onHover.onMouseEnter();
          }}
          onMouseLeave={() => {
            if (onHover) onHover.onMouseLeave();
          }}
          to={link}
          target={target}
          style={style}
          className={`${className} ${styles.clickableContainer}`}
        >
          {children}
        </Link>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div
        onMouseEnter={() => {
          if (onHover) onHover.onMouseEnter();
        }}
        onMouseLeave={() => {
          if (onHover) onHover.onMouseLeave();
        }}
        style={style}
        className={`${className} ${styles.clickableContainer}`}
      >
        {children}
      </div>
    </div>
  );
};
