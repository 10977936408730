import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import {
  ProductFormProps,
  ProductProps,
} from './product-form-type';
import styles from './product-form.module.css';
import { InputControl, CheckBoxInputControl } from '../form-controls';
import { ClassicButton } from '../../buttons';

export const ProductForm: React.FC<ProductFormProps> = ({
  model,
  onSave,
  errorMessage,
  onCancel,
  onDelete,
  onNext,
  onPrevious,
}: ProductFormProps) => {
  const { t } = useTranslation();

  const [displayProductData, setDisplayProductData] = useState<boolean>(false)
  const [count, setCount] = useState<number>( model.product_data? Object.entries(model.product_data).length : 0)
  const [arrayInput, setArrayInput] = useState<any>([])

  const handlePlus = () => {
    setDisplayProductData(true)
    setCount(x => x+1)
    setArrayInput([...arrayInput, count])
  }
  
  useEffect(() => {
   if(model.product_data) {
     Object.entries(model.product_data).map((e, i) => arrayInput.push(i))
    }
    }, [])

  const displayData = arrayInput.map((e: number) => {
    return (
          <div className={styles.productDataForm} key={e}>
            <div>
              <b className={styles.inputLabel}>{t('form.products.information')} {e+1}</b>
              <InputControl
                name={"product_data["+e+"].information"}
                placeholder={t('form.products.information')+(e+1)}
              />
            </div>
            <div>
              <b className={styles.inputLabel}>{t('form.products.value')} {e+1}</b>
              <InputControl
                name={"product_data["+e+"].value"}
                placeholder={t('form.products.value')+(e+1)}
              />
            </div>
          </div>
    )
  } )

  return (
    <>
      <Formik<ProductProps>
        onSubmit={onSave}
        initialValues={model}
        render={(props) => (
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.formContainer}>
            {console.log("fooormik",props)}
              {model && (
              <>
              {model.is_placeholder && 
                <div className={styles.formLine}>
                {/* <b className={styles.inputLabel}>{t('ecosystem.actors.form.is_ambassador')}</b> */}
                <CheckBoxInputControl
                  name="is_placeholder"
                  label={t('form.products.is_placeholder')}
                  required
                />
              </div>}
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.products.name')}</b>
                  <InputControl
                    name="name"
                    required
                    placeholder={t('form.products.name')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.products.internal_code')}</b>
                  <InputControl
                    name="internal_code"
                    required
                    placeholder={t('form.products.internal_code')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.products.unit')}</b>
                  <InputControl
                    name="unit"
                    required
                    placeholder={t('form.products.unit')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.products.gs1')}</b>
                  <InputControl
                    name="gs1"
                    placeholder={t('form.products.gs1')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.products.gs1_type')}</b>
                  <InputControl
                    name="gs1_type"
                    placeholder={t('form.products.gs1_type')}
                  />
                </div>
                <div className={styles.productData}>
                  <b className={styles.inputLabel}>{t('form.products.product_data')}</b>
                  {displayProductData && 
                  
                  <div className={styles.productDataContainer}>
                    {displayData}
                  </div>}
                  <b onClick={handlePlus} style={{fontSize: '20px', cursor: 'pointer', width: '20px'}}> 
                  +
                  </b>
                </div>
              </>
              )}
            </div>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
              onCancel
                ? <ClassicButton text={t('wizard.products.cancel')} onClick={onCancel} warning />
                : <div>&nbsp;</div>
            }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
              onPrevious
                ? <ClassicButton text={t('wizard.products.back')} onClick={onPrevious} inverted />
                : <div>&nbsp;</div>
            }
                </div>
                <div className={styles.nextNavigation}>
                  {
              onNext
                ? <ClassicButton onClick={onNext} text={t('wizard.products.next')} inverted />
                : <div>&nbsp;</div>
            }

                </div>
              </div>
              <div className={styles.submitActions}>
                <ClassicButton type="submit" text={t('wizard.products.submit')} />
              </div>
            </div>
          </form>
        )}
      />
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};
