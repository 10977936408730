import React from 'react';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { ResetPasswordFormProps, ResetPasswordProps } from './reset-password-form-type';

import styles from './reset-password-form.module.css';
import { PasswordInputControl } from '../form-controls';
import { ClassicButton } from '../../buttons';

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  model,
  onSuccess,
  errorMessage,
  isAuthenticated,
}: ResetPasswordFormProps) => {
  const { t } = useTranslation();
  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <div className={styles.loginForm}>

        <Formik<ResetPasswordProps>
          initialValues={model}
          onSubmit={(updatedModel) => {
            onSuccess(updatedModel);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className={styles.form}>
              <div className={styles.formContainer}>
                <div className={styles.inputBloc}>
                  <b className={styles.inputLabel}>{t('form.authentication.password')}</b>
                  <PasswordInputControl
                    name="newPassword"
                    placeholder={t('form.authentication.password')}
                    required
                  />
                </div>
                <div className={styles.inputBloc}>
                  <b className={styles.inputLabel}>{t('form.authentication.passwordConfirm')}</b>
                  <PasswordInputControl
                    name="newPasswordConfirm"
                    placeholder={t('form.authentication.passwordConfirm')}
                    required
                    equalValue={props.values.newPassword}
                  />
                </div>


                <div className={styles.submit}>
                  <ClassicButton type="submit" text={t('form.authentication.actions.resetPassword')} />
                </div>
              </div>
              {errorMessage && (
                <span className={styles.submitError}>{errorMessage}</span>
              )}
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
