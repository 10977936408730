import { backendTokenService, securedRestClient } from '../../backend';
import { buildUrlParameters, formatTechDate } from '../../utils';
import {
  ApiList, CertificateWrite, CertificateRead, GetCertificate,
} from '../../models';

export const certificateSubService = {
  // ===============
  // ==== CERTIFICATE ====
  // ===============

  // --- CREATE CERTIFICATE ---

  createCertificate: async (certificate: CertificateWrite) => {
    // console.log(
    //   'ecosystem service createcertificate, certificate : ',
    //   certificate,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/certificates/`;
    const properCertificate = {
      ...certificate,
      audit_date: formatTechDate(certificate.audit_date),
      start_date: formatTechDate(certificate.start_date),
      end_date: formatTechDate(certificate.end_date),
      activation_date: formatTechDate(certificate.activation_date),
    };
    const response = await securedRestClient.post<CertificateRead>(requestUrl, properCertificate);
    // console.log(response.data);
    return response.data;
  },

  // --- READ CERTIFICATE ---

  getCertificate: async ({
    group_id, id, site, tool, owner, product, limit, offset, ordering, name, internal_code, audit_date, start_date, end_date, still_valid, owner_name, category,
  }: GetCertificate) => {
    // console.log(
    //   'ecosystem service getCerttificat, id : ', id,
    //   'site', site,
    //   'tool', tool,
    //   'owner', owner,
    //   'product', product,
    //   'limit', limit,
    //   'offset', offset,
    //   'name', name,
    //   'ordering', ordering,
    //   'internal_code', internal_code,
    //   'audit_date', audit_date,
    //   'start_date', start_date,
    //   'end_date', end_date,
    //   'still_valid', still_valid,
    //   'group_id', group_id,
    //   'owner_name', owner_name,
    //   'category', category
    // );

    let params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'group_id', value: group_id },
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'ordering', value: ordering },
      { prop: 'owner', value: owner },
      { prop: 'id', value: id },
      { prop: 'tool', value: tool },
      { prop: 'site', value: site },
      { prop: 'product', value: product },
      { prop: 'name', value: name },
      { prop: 'internal_code', value: internal_code },
      { prop: 'audit_date', value: audit_date },
      { prop: 'start_date', value: start_date },
      { prop: 'still_valid', value: still_valid },
      { prop: 'owner_name', value: owner_name },
      { prop: 'category', value: category  ? category === 'all' ? '' : category : "active"}
    ];
    if (end_date && end_date !== '') {
      if (parseInt(end_date) > 0) {
        params = [...params, { prop: 'expire_in', value: end_date }];
      } else {
        params = [...params, { prop: 'expired', value: 'true' }];
      }
    }

    const paramUrl = buildUrlParameters(params);
    // console.log('paramsUrl :', paramUrl);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/certificates/${id || ''}?${paramUrl}`;
    // console.log('requestURL :', requestUrl, 'params', params);
    const response = await securedRestClient.get<ApiList<CertificateRead[]>>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  // --- UPDATE CERTIFICATE ---

  editCertificate: async (certificate: CertificateWrite) => {
    // console.log(
    //   'ecosystem service putcertificate, certificate : ',
    //   certificate,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/certificates/${certificate.pk}`;
    const properCertificate = {
      ...certificate,
      audit_date: formatTechDate(certificate.audit_date),
      start_date: formatTechDate(certificate.start_date),
      end_date: formatTechDate(certificate.end_date),
      activation_date: formatTechDate(certificate.activation_date),
    };
    const response = await securedRestClient.patch<CertificateRead>(requestUrl, properCertificate);
    // console.log(response.data);
    return response.data;
  },

  editCertificateDocument: async (certificate: CertificateWrite) => {
    // console.log(
    //   'ecosystem service putcertificateDocument, certificate : ',
    //   certificate,
    // );
    const formData = new FormData();
    if (certificate.document) {
      formData.append('document', certificate.document, certificate.document.name);
    }

    // console.log('form data :', formData);

    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/certificates/${certificate.pk}`;
    const response = await securedRestClient.patch<CertificateRead>(
      requestUrl,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    // console.log(response.data);
    return response.data;
  },

  // --- DELETE CERTIFICATE ---

  deleteCertificate: async (id: number) => {
    // console.log(
    //   'ecosystem service deleteproduct, actor id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/certificates/${id}`;
    const response = await securedRestClient.delete(requestUrl);
    // console.log(response.data);
    return response.data;
  },

};
