import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CertificateFormProps,
  CertificateProps,
} from './certificate-form-type';
import styles from './certificate-form.module.css';
import {
  InputControl, DatePickerControl, SelectControl, SelectControlOption,
} from '../form-controls';
import { ClassicButton } from '../../buttons';
import enhancer from './certificate-form-enhancer';

export const InnerCertificateForm: React.FC<CertificateFormProps> = ({
  model,
  onSave,
  onPrevious,
  onNext,
  errorMessage,
  onCancel,
  certificateTypes,
}: CertificateFormProps) => {
  const { t } = useTranslation();

  const selectOptions: SelectControlOption[] = certificateTypes ? certificateTypes.results.map((el) => (
    {
      key: el.pk.toString(),
      value: el.pk,
      text: el.name,
    }
  )) : [];

  return (
    <>
      <Formik<CertificateProps>
        onSubmit={onSave}
        initialValues={model}
        render={(props) => (
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.formContainer}>

              {model && (
              <>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.certificates.name')}</b>
                  <InputControl
                    name="name"
                    required
                    placeholder={t('form.certificates.name')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.certificates.certificate_type_id')}</b>
                  <SelectControl
                    name="certificate_type"
                    options={selectOptions}
                    required
                    placeholder={t('form.certificates.certificate_type_id')}
                    style={{ minWidth: '300px' }}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.certificates.internal_code')}</b>
                  <InputControl
                    name="internal_code"
                    required
                    placeholder={t('form.certificates.internal_code')}
                  />
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.certificates.audit_date')}</b>
                  <div className={styles.datePicker}>
                    <DatePickerControl name="audit_date" required />
                  </div>
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.certificates.start_date')}</b>
                  <div className={styles.datePicker}>
                    <DatePickerControl name="start_date" required />
                  </div>
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.certificates.end_date')}</b>
                  <div className={styles.datePicker}>
                    <DatePickerControl name="end_date" required />
                  </div>
                </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.certificates.activation_date')}</b>
                  <div className={styles.datePicker}>
                    <DatePickerControl name="activation_date" required />
                  </div>
                </div>
              </>
              )}
            </div>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
                    onCancel
                      ? <ClassicButton text={t('wizard.certificates.cancel')} onClick={onCancel} warning />
                      : <div>&nbsp;</div>
                  }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
                    onPrevious
                      ? <ClassicButton text={t('wizard.certificates.back')} onClick={onPrevious} inverted />
                      : <div>&nbsp;</div>
                  }
                </div>
                <div className={styles.nextNavigation}>
                  {
                    onNext
                      ? <ClassicButton type="submit" text={t('wizard.certificates.next')} inverted />
                      : <div>&nbsp;</div>
                  }

                </div>
              </div>
              <div className={styles.submitActions}>
                <ClassicButton type="submit" text={t('wizard.certificates.submit')} />
              </div>
            </div>
          </form>
        )}
      />
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};

export const CertificateForm = enhancer(InnerCertificateForm);


export const CertificateFormLoader = () => (
  <div>
      Loaders
  </div>
);
