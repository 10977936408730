const config = {
  backend: {
    baseUrl: '',
    rest: {
      url: '/api',
    },
    fileMaxSize: 10000,
  },
  client: {
    baseUrl: '',
  },
};

if (process.env.REACT_APP_STAGE === 'local') {
  config.backend.baseUrl = process.env.REACT_APP_API_LOCAL || '';
  config.client.baseUrl = process.env.REACT_APP_CLIENT_LOCAL || '';
} else if (process.env.REACT_APP_STAGE === 'recette') {
  config.backend.baseUrl = process.env.REACT_APP_API_RECETTE || '';
  config.client.baseUrl = process.env.REACT_APP_CLIENT_RECETTE || '';
} else if (process.env.REACT_APP_STAGE === 'preprod') {
  config.backend.baseUrl = process.env.REACT_APP_API_PREPROD || '';
  config.client.baseUrl = process.env.REACT_APP_CLIENT_PREPROD || '';
} else if (process.env.REACT_APP_STAGE === 'prod') {
  config.backend.baseUrl = process.env.REACT_APP_API_PROD || '';
  config.client.baseUrl = process.env.REACT_APP_CLIENT_PROD || '';
} else {
  config.backend.baseUrl = process.env.REACT_APP_API_DEV || '';
  config.client.baseUrl = process.env.REACT_APP_CLIENT_DEV || '';
}
export { config };
