import React from 'react';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import {
  CommentFormProps,
  CommentProps,
} from './comment-form-type';
import styles from './comment-form.module.css';
import { InputControl } from '../form-controls';
import { ClassicButton } from '../../buttons';

export const CommentForm: React.FC<CommentFormProps> = ({
  model,
  onSave,
  errorMessage,
}: CommentFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Formik<CommentProps>
        onSubmit={onSave}
        initialValues={model}
        render={(props) => (
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.formContainer}>

              {model && (
              <>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('alerts.comment.form.message')}</b>
                  <InputControl
                    name="message"
                              // required
                    placeholder={t('alerts.comment.form.message')}
                  />
                </div>
              </>
              )}
            </div>
            <div className={styles.formActions}>
              <ClassicButton text={t('alerts.comment.form.submit')} type="submit" />
            </div>
          </form>
        )}
      />
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};
