import React from 'react';
import {
  AuthenticatedState,
  ContextProviderProps,
  LightFolder,
  AuthToken,
} from '../models';
import { dateIsExpired } from '../utils';

const defaultAuthenticatedState: AuthenticatedState = {
  authenticated: false,
  folder: undefined,
  setAuthenticated: (): void => {},
  azure: undefined
};

export const AuthenticatedContext = React.createContext<AuthenticatedState>(
  defaultAuthenticatedState,
);

export const AuthenticatedContextProvider = (
  props: ContextProviderProps,
): React.ReactElement => {
  const { children } = props;
  const [auth, setAuth] = React.useState<
    { folder: LightFolder; token: AuthToken; azure: string } | undefined
  >(undefined);
  const authenticated = auth ? !dateIsExpired(auth.token.expiration) : false;
  const folder = auth ? auth.folder : undefined;
  const azure = auth?.azure;

  return (
    <AuthenticatedContext.Provider
      value={{
        authenticated,
        folder,
        setAuthenticated: setAuth,
        azure
      }}
    >
      {children}
    </AuthenticatedContext.Provider>
  );
};
