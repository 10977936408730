import React from 'react';

import styles from './edit-relation.module.css';
import { EditRelationProps } from '.';
import { WizardLayout } from '../../../../layouts';
import enhancer from './edit-relation-enhancer';
import { RelationWizard } from '../../../../components/wizards/relation-wizard';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';
import { RelationWrite } from '../../../..';

const InnerPage: React.FC<EditRelationProps> = ({ relation }: EditRelationProps) => {

  const properRelation: RelationWrite = {
    ...relation,
    owner: [relation.owner.pk],
    actor: [relation.actor.pk]
  };

  return (
  <WizardLayout image={backgroundLogin}>
    <div className={styles.wizardWrapper}>
      <RelationWizard edit={true} initialRelation={properRelation} />
    </div>
  </WizardLayout>
  );
};

export const EcosystemEditRelationPage = enhancer(InnerPage);
