import React from 'react';
import  editPictoWhite  from '../../../../assets/pictos/editPictoWhite.png' 
import { EditButtonProps } from './edit-button-type';
import styles from './edit-button.module.css'


export const EditButton : React.FC<EditButtonProps> = ({
    text,
    disabled,
    warning,
    style
} : EditButtonProps) => {
    return <div>
        
        <button disabled={disabled}className={disabled? styles.retrackBtn : warning  ? styles.warningBtn : styles.classicBtn}><img className={styles.editImg}src={editPictoWhite}/>{text}</button>
    </div>
};