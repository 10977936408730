import ecosystemPicto from '../../assets/pictos/ecosystemPictoBlue.png';
import ecosystemPictoHover from '../../assets/pictos/ecosystemPictoGreen.png';
import ecosystemPictoDisable from '../../assets/pictos/ecosystemPictoGrey.png';
import livescanPicto from '../../assets/pictos/livescanPictoBlue.png';
import livescanPictoHover from '../../assets/pictos/livescanPictoGreen.png';
import livescanPictoDisable from '../../assets/pictos/livescanPictoGrey.png';
import parametrePicto from '../../assets/pictos/parametrePictoBlue.png';
import parametrePictoHover from '../../assets/pictos/parametrePictoGreen.png';
import parametrePictoDisable from '../../assets/pictos/parametrePictoGrey.png';
import alertsPicto from '../../assets/pictos/alertsPictoBlue.png';
import alertsPictoHover from '../../assets/pictos/alertsPictoGreen.png';
import alertsPictoDisable from '../../assets/pictos/alertsPictoGrey.png';
import homePicto from '../../assets/pictos/homePictoBlue.png';
import homePictoHover from '../../assets/pictos/homePictoGreen.png';
import homePictoDisable from '../../assets/pictos/homePictoGrey.png';
import myproductionPicto from '../../assets/pictos/myproductionPictoBlue.png';
import myproductionPictoHover from '../../assets/pictos/myproductionPictoGreen.png';
import myproductionPictoDisable from '../../assets/pictos/myproductionPictoGrey.png';
import productionspecificationsPicto from '../../assets/pictos/productionspecificationsPictoBlue.png';
import productionspecificationsPictoHover from '../../assets/pictos/productionspecificationsPictoGreen.png';
import productionspecificationsPictoDisable from '../../assets/pictos/productionspecificationsPictoGrey.png';
import trackingPicto from '../../assets/pictos/trackingPictoBlue.png';
import trackingPictoHover from '../../assets/pictos/trackingPictoGreen.png';
import trackingPictoDisable from '../../assets/pictos/trackingPictoGrey.png';
import { HeaderItem } from '../components/menu/MenuItem';


export const header: HeaderItem[] = [
  {
    libelle: 'home',
    link: '/home',
    picto: homePicto,
    pictoHover: homePictoHover,
    pictoDisable: homePictoDisable,
    disable: false,
  },
  {
    libelle: 'alert',
    link: '/alerts/',
    picto: alertsPicto,
    pictoHover: alertsPictoHover,
    pictoDisable: alertsPictoDisable,
    disable: false,
    sidebar: true,
  },
  // {
  //   libelle: 'production',
  //   link: '/production',
  //   picto: myproductionPicto,
  //   pictoHover: myproductionPictoHover,
  //   pictoDisable: myproductionPictoDisable,
  //   disable: true,
  // },
  {
    libelle: 'ecosystem',
    link: '/ecosystem',
    picto: ecosystemPicto,
    pictoHover: ecosystemPictoHover,
    pictoDisable: ecosystemPictoDisable,
    disable: false,
  },
  {
    libelle: 'specification',
    link: '/specifications',
    picto: productionspecificationsPicto,
    pictoHover: productionspecificationsPictoHover,
    pictoDisable: productionspecificationsPictoDisable,
    disable: true
  },
  {
    libelle: 'tracking',
    link: '/tracking/flows',
    picto: trackingPicto,
    pictoHover: trackingPictoHover,
    pictoDisable: trackingPictoDisable,
    disable: false,
  },
  // {
  //   libelle: 'livescan',
  //   link: '/livescan',
  //   picto: livescanPicto,
  //   pictoHover: livescanPictoHover,
  //   pictoDisable: livescanPictoDisable,
  //   disable: true,
  // },
  {
    libelle: 'settings',
    link: '/parametre',
    picto: parametrePicto,
    pictoHover: parametrePictoHover,
    pictoDisable: parametrePictoDisable,
    disable: false,
  },
];

export const specificationHeader: HeaderItem[] = [
  {
    libelle: 'home',
    link: '/home',
    picto: homePicto,
    pictoHover: homePictoHover,
    pictoDisable: homePictoDisable,
    disable: false,
  },
  {
    libelle: 'alert',
    link: '/alerts/',
    picto: alertsPicto,
    pictoHover: alertsPictoHover,
    pictoDisable: alertsPictoDisable,
    disable: false,
    sidebar: true,
  },
  // {
  //   libelle: 'production',
  //   link: '/production',
  //   picto: myproductionPicto,
  //   pictoHover: myproductionPictoHover,
  //   pictoDisable: myproductionPictoDisable,
  //   disable: true,
  // },
  {
    libelle: 'ecosystem',
    link: '/ecosystem',
    picto: ecosystemPicto,
    pictoHover: ecosystemPictoHover,
    pictoDisable: ecosystemPictoDisable,
    disable: false,
  },
  {
    libelle: 'specification',
    link: '/specifications',
    picto: productionspecificationsPicto,
    pictoHover: productionspecificationsPictoHover,
    pictoDisable: productionspecificationsPictoDisable,
    disable: false
  },
  {
    libelle: 'tracking',
    link: '/tracking/flows',
    picto: trackingPicto,
    pictoHover: trackingPictoHover,
    pictoDisable: trackingPictoDisable,
    disable: false,
  },
  // {
  //   libelle: 'livescan',
  //   link: '/livescan',
  //   picto: livescanPicto,
  //   pictoHover: livescanPictoHover,
  //   pictoDisable: livescanPictoDisable,
  //   disable: true,
  // },
  {
    libelle: 'settings',
    link: '/parametre',
    picto: parametrePicto,
    pictoHover: parametrePictoHover,
    pictoDisable: parametrePictoDisable,
    disable: false,
  },
];