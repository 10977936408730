import { backendTokenService, securedRestClient } from '../../backend';
import { buildUrlParameters, formatTechDate } from '../../utils';
import {
  ApiList, CertificateWrite, CertificateRead,  Relation, Relations, GetRelations, RelationWrite, GetRelationLight
} from '../../models';

export const relationSubService = {
  // ===============
  // ==== RELATION ====
  // ===============

  // --- CREATE RELATION ---

  createRelation: async (relation: RelationWrite) => {
    console.log(
      'ecosystem service createRelation, relation : ',
      relation,
    );

    const custom = {
      ...relation,
      owner : relation.owner[0],
      actor : relation.actor[0]
    }
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actortranscodes/`;
const response = await securedRestClient.post<Relation>(requestUrl, custom);
    // console.log(response.data);
    return response.data;
  },

  // --- READ Relation ---

  getRelation: async ({
    pk, owner, limit, offset, ordering, actor, connection, group_id, owner_name, is_placeholder
  }: GetRelations) => {
    // console.log(
    //   'ecosystem service getRelation',
    //   'owner', owner,
    //   'limit', limit,
    //   'offset', offset,
    //   'ordering', ordering,
    //   'actor', actor,
    //   'connection', connection,
    //   'owner_name'; owner_name
    // );

    let params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'group_id', value: group_id },
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'ordering', value: ordering },
      { prop: 'owner', value: owner },
      { prop: 'actor', value: actor },
      { prop: 'connection', value: connection },
      { prop: 'owner_name', value: owner_name },
      { prop: 'is_placeholder', value: is_placeholder }
    ];

    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actortranscodes/?${paramUrl}`;
    const response = await securedRestClient.get<ApiList<Relation[]>>(requestUrl);
    return response.data;
  },

  getEditRelation: async ({ id }: GetRelationLight) => {
    // console.log(
    //   'ecosystem service, getEditRelation, id : ',
    //   id,
    // );

    
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actortranscodes/${id}`;

    const response = await securedRestClient.get<RelationWrite>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  // --- UPDATE RELATION ---

  editRelation:  async (relation : RelationWrite) => {
    // console.log(
    //   'ecosystem service putRelation, relation : ',
    //   relation,
    // );

    const custom = {
      ...relation,
      owner : relation.owner[0],
      actor : relation.actor[0]
    };
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actortranscodes/${relation.pk}`;
    const response = await securedRestClient.patch<Relation>(requestUrl, custom);
    // console.log(response.data);
    return response.data;
  },


  // --- DELETE RELATION ---

  deleteRelation: async (id: number) => {
    // console.log(
    //   'ecosystem service deleteRelation, transcodes id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/actortranscodes/${id}`;
    const response = await securedRestClient.delete(requestUrl);
    // console.log(response.data);
    return response.data;
  },

};
