import fr from "./default.json";
import en from "./default.en.json";
import it from "./default.it.json";
import es from "./default.es.json";

import TrackingFr from "../../../modules/pages/tracking-page/locales/default.json";
import TrackingEn from "../../../modules/pages/tracking-page/locales/default.en.json";
import TrackingIt from "../../../modules/pages/tracking-page/locales/default.it.json";
import TrackingEs from "../../../modules/pages/tracking-page/locales/default.es.json";

import SettingsFr from "../../../modules/pages/settings-page/locales/default.json";
import SettingsEn from "../../../modules/pages/settings-page/locales/default.en.json";
import SettingsIt from "../../../modules/pages/settings-page/locales/default.it.json";
import SettingsEs from "../../../modules/pages/settings-page/locales/default.es.json";

import AlertsFr from "../../../modules/pages/alerts-page/locales/default.json";
import AlertsEn from "../../../modules/pages/alerts-page/locales/default.en.json";
import AlertsIt from "../../../modules/pages/alerts-page/locales/default.it.json";
import AlertsEs from "../../../modules/pages/alerts-page/locales/default.es.json";

import EcosystemFr from "../../../modules/pages/ecosystem-page/locales/default.json";
import EcosystemEn from "../../../modules/pages/ecosystem-page/locales/default.en.json";
import EcosystemIt from "../../../modules/pages/ecosystem-page/locales/default.it.json";
import EcosystemEs from "../../../modules/pages/ecosystem-page/locales/default.es.json";

import HomeFr from "../../../modules/pages/home-page/locales/default.json";
import HomeEn from "../../../modules/pages/home-page/locales/default.en.json";
import HomeIt from "../../../modules/pages/home-page/locales/default.it.json";
import HomeEs from "../../../modules/pages/home-page/locales/default.es.json";

import MenuFr from "../../../modules/components/menu/MenuItem/locales/default.json";
import MenuEn from "../../../modules/components/menu/MenuItem/locales/default.en.json";
import MenuIt from "../../../modules/components/menu/MenuItem/locales/default.it.json";
import MenuEs from "../../../modules/components/menu/MenuItem/locales/default.es.json";

import FormFr from "../../../modules/components/forms/locales/default.json";
import FormEn from "../../../modules/components/forms/locales/default.en.json";
import FormIt from "../../../modules/components/forms/locales/default.it.json";
import FormEs from "../../../modules/components/forms/locales/default.es.json";

import WizardFr from "../../../modules/components/wizards/locales/default.json";
import WizardEn from "../../../modules/components/wizards/locales/default.en.json";
import WizardIt from "../../../modules/components/wizards/locales/default.it.json";
import WizardEs from "../../../modules/components/wizards/locales/default.es.json";

import TablesFr from "../../../modules/components/table/locales/default.json";
import TablesEn from "../../../modules/components/table/locales/default.en.json";
import TablesIt from "../../../modules/components/table/locales/default.it.json";
import TablesEs from "../../../modules/components/table/locales/default.es.json";

import AuthFr from "../../../modules/pages/auth-pages/locales/default.json";
import AuthEn from "../../../modules/pages/auth-pages/locales/default.en.json";
import AuthIt from "../../../modules/pages/auth-pages/locales/default.it.json";
import AuthEs from "../../../modules/pages/auth-pages/locales/default.es.json";

import SpecificationsFr from "../../../modules/pages/specifications-page/locales/default.json";
import SpecificationsEn from "../../../modules/pages/specifications-page/locales/default.en.json";
import SpecificationsIt from "../../../modules/pages/specifications-page/locales/default.it.json";

import FoodchainHeaderFr from "../../../modules/pages/foodchain-results-page/locales/default.json";
import FoodchainHeaderEn from "../../../modules/pages/foodchain-results-page/locales/default.en.json";
import FoodchainHeaderIt from "../../../modules/pages/foodchain-results-page/locales/default.it.json";

import SpecificationsHeaderFr from "../../../modules/pages/specification-results-page/locales/default.json";
import SpecificationsHeaderEn from "../../../modules/pages/specification-results-page/locales/default.en.json";
import SpecificationsHeaderIt from "../../../modules/pages/specification-results-page/locales/default.it.json";

import LoaderFr from "../../../modules/components/local-loader/locales/default.json";
import LoaderEn from "../../../modules/components/local-loader/locales/default.en.json";
import LoaderIt from "../../../modules/components/local-loader/locales/default.it.json";

import TablesBodyFr from "../../../modules/components/tables/locales/default.json";
import TablesBodyEn from "../../../modules/components/tables/locales/default.en.json";
import TablesBodyIt from "../../../modules/components/tables/locales/default.it.json";

import ResultHeaderFr from "../../../modules/components/tables/results-table/locales/default.json";
import ResultHeaderEn from "../../../modules/components/tables/results-table/locales/default.en.json";
import ResultHeaderIt from "../../../modules/components/tables/results-table/locales/default.it.json";


fr.fr.tracking = TrackingFr;
en.en.tracking = TrackingEn;
it.it.tracking = TrackingIt;
es.es.tracking = TrackingEs;

fr.fr.settings = SettingsFr;
en.en.settings = SettingsEn;
it.it.settings = SettingsIt;
es.es.settings = SettingsEs;

fr.fr.alerts = AlertsFr;
en.en.alerts = AlertsEn;
it.it.alerts = AlertsIt;
es.es.alerts = AlertsEs;

fr.fr.ecosystem = EcosystemFr;
en.en.ecosystem = EcosystemEn;
it.it.ecosystem = EcosystemIt;
es.es.ecosystem = EcosystemEs;

fr.fr.home = HomeFr;
en.en.home = HomeEn;
it.it.home = HomeIt;
es.es.home = HomeEs;

fr.fr.menu = MenuFr;
en.en.menu = MenuEn;
it.it.menu = MenuIt;
es.es.menu = MenuEs;

fr.fr.form = FormFr;
en.en.form = FormEn;
it.it.form = FormIt;
es.es.form = FormEs;

fr.fr.wizard = WizardFr;
en.en.wizard = WizardEn;
it.it.wizard = WizardIt;
es.es.wizard = WizardEs;

fr.fr.table = TablesFr;
en.en.table = TablesEn;
it.it.table = TablesIt;
es.es.table = TablesEs;

fr.fr.authentication = AuthFr;
en.en.authentication = AuthEn;
it.it.authentication = AuthIt;
es.es.authentication = AuthEs;

fr.fr.specifications = SpecificationsFr;
en.en.specifications = SpecificationsEn;
it.it.specifications = SpecificationsIt;

fr.fr.loader = LoaderFr;
en.en.loader = LoaderEn;
it.it.loader = LoaderIt;

fr.fr.foodchainResults = FoodchainHeaderFr;
en.en.foodchainResults = FoodchainHeaderEn;
it.it.foodchainResults = FoodchainHeaderIt;

fr.fr.specificationsResults = SpecificationsHeaderFr;
en.en.specificationsResults = SpecificationsHeaderEn;
it.it.specificationsResults = SpecificationsHeaderIt;

fr.fr.tableBody = TablesBodyFr;
en.en.tableBody = TablesBodyEn;
it.it.tableBody = TablesBodyIt

fr.fr.resultHeader = ResultHeaderFr;
en.en.resultHeader = ResultHeaderEn;
it.it.resultHeader = ResultHeaderIt

export { fr, en, it, es };
 