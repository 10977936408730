import React from "react";
import { RiskStatusResultsTableBodyProps } from "./";
import { Table, Label } from "semantic-ui-react";
import searchPictoBlue from "../../../../../assets/pictos/searchPictoBlue.png";
import { Link } from "react-router-dom";
import styles from "./viewrisk-results-table-body.module.css";
import { RiskViewPageFilter } from "./view-risk-status-filter";

export function RiskStatusResultsTableBody<T>({
  data,
}: RiskStatusResultsTableBodyProps<T>) {
  // const { t } = useTranslation();

  const riskStatus = data;

  const [datas, setData] = React.useState(riskStatus);
  const [isSelected, setIsSelected] = React.useState<string>("all");

  const handleClick = (status: string) => {
    setData(
      status !== "all"
        ? riskStatus.filter((e) => e.risk_status === status)
        : riskStatus
    );
    setIsSelected(status);
  };

  return (
    <>
      <Table.Body>
        {riskStatus?.map((result, key = result.pk) => (
          <Table.Row key={key}>
            <Table.Cell>
              <div style={{ float: "left" }}>
                <Link to={`/contract/results/${result.pk}`}>
                  <img
                    src={searchPictoBlue}
                    alt=""
                    style={{ width: "12px", height: "12px" }}
                  />
                </Link>
              </div>
              <Link to={`/contract/datas/${result.actor}`}>
                {result.actor}
              </Link>
            </Table.Cell>

            <Table.Cell>{result.total_indicators}</Table.Cell>

            <Table.Cell>{result.total_compliant_indicators}</Table.Cell>

            <Table.Cell>{result.total_not_compliant_indicators}</Table.Cell>

            <Table.Cell>
              {result.risk_status === "not valid" ? (
                <Label
                  className={`${styles.cardColorNotValid} ${styles.statusButton}`}
                >
                  NON VALIDE
                </Label>
              ) : result.risk_status === "expert" ? (
                <Label
                  className={`${styles.cardColorExpert} ${styles.statusButton}`}
                >
                  EXPERT
                </Label>
              ) : result.risk_status === "member" ? (
                <Label
                  className={`${styles.cardColorMembers} ${styles.statusButton}`}
                >
                  MEMBRE
                </Label>
              ) : result.risk_status === "at risk" ? (
                <Label
                  className={`${styles.cardColorRisk} ${styles.statusButton}`}
                >
                  A RISQUE
                </Label>
              ) : result.risk_status === "critical" ? (
                <Label
                  className={`${styles.cardColorCritic} ${styles.statusButton}`}
                >
                  CRITIQUE
                </Label>
              ) : null}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </>
  );
}
