import React from 'react';
import { SpecificationEditPageProps } from './specification-edit-page-type';
import { SpecificationWizard } from '../../components';
import { WizardLayout } from '../../layouts';
import backgroundLogin from '../../../assets/images/backgroundLogin.jpg';
import styles from './specification-edit-page.module.css';
import { enhancer } from './enhancer';

export const InnerSpecificationEditPage: React.FC<SpecificationEditPageProps> = ({specification}: SpecificationEditPageProps) => {
 console.log(specification)
    return <WizardLayout image={backgroundLogin}>
    <div className={styles.wizardWrapper}>
    <SpecificationWizard specification={specification} />
    </div>
  </WizardLayout>
}

export const SpecificationEditPage = enhancer(InnerSpecificationEditPage)