
import * as React from 'react';
import { Accordion, Responsive } from 'semantic-ui-react';
import styles from './language-handler.module.css';
import enFlag from '../../../assets/images/en.png';
import frFlag from '../../../assets/images/fr.png';
import deFlag from '../../../assets/images/de.png';
import itFlag from '../../../assets/images/it.png';
import spFlag from '../../../assets/images/sp.png';

import enhancer from './language-handler-enhancer';
import { LanguageType } from '../../models';
import { configUI, config } from '../../../config';
import { LanguageHandlerProps } from './language-handler-type';
import { securedRestClient } from '../../backend';
import { concatUrl } from '../../utils';
import i18next from 'i18next';
import { useHistory, useLocation} from 'react-router-dom';
import { posthogEvents } from '../../utils/posthog';

// affiche les drpauex langue dans le header
export const InnerComponent: React.FC<LanguageHandlerProps> = ({
  i18n, t, active, setActive,
}: LanguageHandlerProps) => {
  const history = useHistory();
  const location = useLocation();
  // TODO: verifier que cela fonctionne aillerus qu'a Paris => USA, Italie ...
  const languages = [
    {
      flag: enFlag,
      lang: 'en',
      ref: 'en',
    },
    {
      flag: frFlag,
      lang: 'fr',
      ref: 'fr',
    },
    {
      flag: itFlag,
      lang: 'it',
      ref: 'it',
    },
    {
      flag: spFlag,
      lang: 'es',
      ref: 'es'
    }
  ];

  const [current, setCurrent] = React.useState<LanguageType>(i18n.language.split('-')[0] as LanguageType);

  // gestion de la langue selectionnée
  const handleLanguageChange = (value: LanguageType): void => {
    i18n.changeLanguage(value);
    posthogEvents.triggerEvent({name : 'clickOn', datas : {'setLang' : value} })
    securedRestClient.defaults.baseURL = concatUrl([config.backend.baseUrl, value, config.backend.rest.url])
    setCurrent(value);
    setActive(false);
    if(location.pathname.split('/')[2] === 'flows'){
      history.go(0)
    }
    
  };

  const handleClick = async () => {
    setActive(!active);
  };

  return (
    <div className={styles.languageHandler}>
      <Accordion>
        <Accordion.Title active={active} index={0} onClick={handleClick}>
          <div className={styles.currentLanguage}>
            <div className={styles.currentFlagContainer}>
              <img src={languages.filter((el) => el.lang === current)[0].flag} className={styles.currentFlag} />
            </div>
            <Responsive minWidth={configUI.responsive.largeScreen.minWidth}>
              <div className={styles.currentLabel}>{t(`menu.language.${languages.filter((el) => el.lang === current)[0].ref}`)}</div>
            </Responsive>
          </div>
        </Accordion.Title>
        <Accordion.Content active={active}>
          <div className={styles.languagesContainer}>
            {languages.filter((el) => el.lang !== current).map((el) => (
              <div key={el.lang} className={styles.language} onClick={() => handleLanguageChange(el.lang as LanguageType)}>
                <div className={styles.flagContainer}>
                  <img src={el.flag} className={styles.flag} />
                </div>
                <Responsive minWidth={configUI.responsive.largeScreen.minWidth}>
                  <div className={styles.label}>{t(`menu.language.${el.ref}`)}</div>
                </Responsive>
              </div>
            ))}
          </div>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export const LanguageHandler = enhancer(InnerComponent);
