import React from 'react';
import {
  SidebarState,
  ContextProviderProps,
  AlertContextCategory,
} from '../models';

const defaultSidebarState: SidebarState = {
  visible: false,
  level1: undefined,
  level2: undefined,
  handleVisible: (): void => {},
  handleCloseLevel1: (): void => {},
  handleCloseLevel2: (): void => {},
  handleOpenLevel1: () => {},
  handleOpenLevel2: () => {},
};

export const SidebarContext = React.createContext<SidebarState>(
  defaultSidebarState,
);

export const SidebarContextProvider = (
  props: ContextProviderProps,
): React.ReactElement => {
  const { children } = props;
  const [visible, setVisible] = React.useState<boolean>(false);
  const [level1, setLevel1] = React.useState<AlertContextCategory | undefined>(defaultSidebarState.level1);
  const [level2, setLevel2] = React.useState<number | undefined>(defaultSidebarState.level2);

  const handleVisible = (forceVisible = false) => {
    if (forceVisible === undefined) {
      setVisible(!visible);
    } else {
      setVisible(forceVisible);
    }
  };

  const handleCloseLevel1 = () => {
    setLevel2(defaultSidebarState.level2);
    setLevel1(defaultSidebarState.level1);
  };

  const handleCloseLevel2 = () => {
    setLevel2(defaultSidebarState.level2);
  };

  const handleOpenLevel1 = (alertType: AlertContextCategory) => {
    setLevel2(defaultSidebarState.level2);
    setLevel1(alertType);
  };

  const handleOpenLevel2 = (id: number) => {
    setLevel2(id);
  };

  return (
    <SidebarContext.Provider
      value={{
        visible,
        handleVisible,
        level1,
        level2,
        handleCloseLevel1,
        handleCloseLevel2,
        handleOpenLevel1,
        handleOpenLevel2,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
