import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { DocumentModalProps } from '.';
import { ClassicButton, FileInputControl } from '..';
import { AuthenticatedContext } from '../../contexts';
import styles from './document-modal.module.css';


export const DocumentModal : React.FC<DocumentModalProps> = ({
currentDoc,
open,
openFileForm,
handleFileChange,
saveFlowDoc
} : DocumentModalProps) => {
    const { t } = useTranslation();
    const initialDoc = currentDoc;
    const initialName = initialDoc && initialDoc.split('/');
    const authenticatedContext = React.useContext(AuthenticatedContext);

    const [imageName, setImageName] = React.useState<string>(initialName ? initialName[initialName.length - 1] : '');
    const [imageUrl, setImageUrl] = React.useState<string>(currentDoc && authenticatedContext.azure ? `${currentDoc}?${authenticatedContext.azure}` : '');


const handleSave = () => {
    saveFlowDoc();
}

    return (    
    <Modal
            open={open}
            closeIcon
            onClose={openFileForm}
            >
            <Modal.Content>
                <div className={styles.modalContent}>
                    <div className={styles.modalTitle}>{t('tracking.flow_document.title')}</div>
                    <div className={styles.inputWrapper}>
                        <FileInputControl
                        name="fileSelector"
                        warning={imageUrl !== ''}
                        placeholder="Visuel de l'objet à télécharger"
                        accept="application/pdf, image/jpeg, image/png"
                        acceptHint={t('form.certificates.hint')}
                        onChange={(event) => {
                            if (
                            event
                                && event.target
                                && event.target.files
                                && event.target.files.length > 0
                            ) {
                            const { target } = event;
                            const { files } = target;
                            files && files.length>0 && handleFileChange(files[0]);
                            setImageName(event.target.files[0].name);
                            setImageUrl(URL.createObjectURL(event.target.files[0]))
                            }
                        }} />
                    
                    {imageName.endsWith('pdf') ? 
                  <iframe src={imageUrl} width='400px' height='400px' className={styles.previewImage}/>
                  :
                  <img src={imageUrl} className={styles.previewImage}/>  
                } 
                    {imageName
                    ? (
                        <div className={styles.result}>
                        {imageName}
                        </div>
                    )
                    : 
                    <div className={styles.result}>&nbsp;</div>
                    }
                    </div>
                    <div>
                    <ClassicButton onClick={handleSave} type="submit" text={t('tracking.flow_document.save')} />
                    
                    </div>
                </div>
            </Modal.Content>
            </Modal>
    )
}