import React from 'react';
import { RiskViewPageFilterProps } from '.';
import { FilterButton } from '../../components';
import styles from './view-risk-status-page.module.css'



export const RiskViewPageFilter  = ({ handleClick, selected } : RiskViewPageFilterProps) => {


    return (
        <div className={styles.filterDiv}>
        <FilterButton style={{marginRight: '30px'}} onClick={() => handleClick('')} text={'Tous'} selected={selected === ''} />
        <FilterButton style={{marginRight: '30px'}} onClick={() => handleClick('expert')} text={'expert'} selected={selected === 'expert'} />
        <FilterButton style={{marginRight: '30px'}} onClick={() => handleClick('member')} text={'membre'} selected={selected === 'member'} />
        <FilterButton style={{marginRight: '30px'}} onClick={() => handleClick('at risk')} text={'A risque'} selected={selected === 'at risk'} />
        <FilterButton style={{marginRight: '30px'}} onClick={() => handleClick('critical')} text={'Critique'} selected={selected === 'critical'} />
        <FilterButton style={{marginRight: '30px'}} onClick={() => handleClick('not valid')} text={'Non Valide'} selected={selected === 'not valid'} />
        </div>
    )
}
