import { NewGroup, GroupLight, GetGroupLight, ApiList } from '../../models';
import { backendTokenService, securedRestClient } from '../../backend';

export const groupSubService = {
  // ===============
  // ==== GROUP ====
  // ===============

  // --- CREATE GROUP ---

  createGroup: async (group: NewGroup) => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/groups/`;
    const response = await securedRestClient.post<NewGroup>(requestUrl, group);
    // console.log(response.data);
    return response.data;
  },

  // --- READ GROUP ---

  getAllGroup: async () => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/groups_light/`;
    const response = await securedRestClient.get<ApiList<GroupLight[]>>(requestUrl);
    // console.log(response.data);
    return response.data.results;
  },

  getAllGroupActors: async () => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/groups_light/`;
    const response = await securedRestClient.get<ApiList<NewGroup>>(requestUrl);
    // console.log(response.data);
    return response.data.results;
  },

  getGroupLight: async ({ id }: GetGroupLight) => {
    // console.log(
    //   'ecosystem service, getGropupLight, id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/groups_light/${id}`;
    const response = id === 'all' ?
      {data: { name : 'all', pk:0, favorite: true}} :
      await securedRestClient.get<GroupLight>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  getEditGroup: async ({ id }: GetGroupLight) => {
    // console.log(
    //   'ecosystem service, getEditGroup, id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/groups/${id}`;

    const response = await securedRestClient.get<NewGroup>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  // --- UPDATE GROUP ---

  editGroup: async (group: NewGroup) => {
    // console.log(
    //   'ecosystem service, editGroup, group : ',
    //   group,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/groups/${group.pk}`;
    const response = await securedRestClient.patch<NewGroup>(requestUrl, group);
    // console.log(response.data);
    return response.data;
  },

  editGroupFavorite: async ({ id, favorite }: {id: number; favorite: boolean}) => {
    // console.log(
    //   'ecosystem service, editGroupFavorite, id : ',
    //   id, 'favorite', favorite,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/groups_light/${id}`;
    const response = await securedRestClient.patch<NewGroup>(requestUrl, { pk: id, favorite });
    // console.log(response.data);
    return response.data;
  },

  editGroupLight: async (groupLight: GroupLight) => {
    // console.log(
    //   'ecosystem service putgroupLight, groupLight : ',
    //   groupLight,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/groups_light/${groupLight.pk}`;
    const response = await securedRestClient.put<GroupLight>(requestUrl, groupLight);
    // console.log(response.data);
    return response.data;
  },

  // --- DELETE GROUP ---

  deleteGroup: async (id: number) => {
    // console.log(
    //   'ecosystem service deletegroups  : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/groups/${id}`;
    console.log("DeleteGroup Service have been called", requestUrl)
    const response = await securedRestClient.delete(requestUrl);
    // console.log(response.data);
    return response.data;
  },
};
