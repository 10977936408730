import React from 'react';

import styles from './site.module.css';
import { SiteProps } from './site-types';
import enhancer from './site-enhancer';
import { SiteRead } from '../../../../models';
import { EcosystemItem } from '../../item/item';
import { useTranslation } from 'react-i18next';

import sitePictoWhite from '../../../../../assets/pictos/sitePictoWhite.svg';
import { Panels } from '../../utils/model';
import { toolPanel, actorPanel, certificatePanel, initialCount } from '../../utils/constant';

const InnerPage: React.FC<SiteProps> = ({ sites, id }: SiteProps) => {
  const {t} = useTranslation();
  if (sites.length === 0) {
    return <></>;
  }

  const site = sites as unknown as SiteRead;

  const panels:Panels = {actors: actorPanel, certificates: certificatePanel, tools: toolPanel}

  const itemLight = {
    description: [
      {
        label: t('form.sites.address'),
        value: `${site.address} ${site.zip_code} ${site.city}`,
      },
      {
        label: t('form.sites.site_type'),
        value: site.site_type,
      },
      {
        label: t('form.sites.internal_code'),
        value: site.internal_code,
      },
    ].filter((el) => el.value !== undefined && el.value !== ''),
    title: `${t('ecosystem.sites.title')} ${site.name}`,
    picto: sitePictoWhite,
    permissionRoute: '/ecosystem/site:update',
    link: `/ecosystem/edit-site/${site.pk}`
  }
  
  return (
    <EcosystemItem panels={panels} itemLight={itemLight} id={id} specificParams={{site: id}} propertyName="site" counts={initialCount} />
  );
};

export const EcosystemSitePage = enhancer(InnerPage);

