import * as React from 'react';
import { ClassicTileProps } from './classic-tile-type';

import styles from './classic-tile-component.module.css';
import { ClickableContainer } from '../..';
import { useHover } from '../../../hooks';
import { Loader, Dimmer } from 'semantic-ui-react';
import favoritePictoBlue from '../../../../assets/pictos/favoritePictoBlue.svg';
import noFavoritePictoBlue from '../../../../assets/pictos/noFavoritePictoBlue.svg';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ClassicTile: React.FC<ClassicTileProps> = React.memo(
  ({
    link, status, title, subTitle, notification, image, favorite, handleFavorite, count, loading,
  }: ClassicTileProps) => {
    const { hovered, bind } = useHover();
    const {t} = useTranslation()

    const loader = <Dimmer active inverted><Loader size="small" /></Dimmer>

    return (
      <>
        <div className={`${hovered ? styles.hoveredTile : ''} ${styles.tile}`}>
          <div className={styles.notificationWrapper}>
            {notification !== undefined
              && (
              <div className={styles.notificationContainer}>
                <div className={styles.notification}><b>{notification}</b></div>
              </div>
              )}
            {favorite !== undefined
              && (
                <>
                <div className={styles.notificationContainer}>
                <div className={styles.notification}><b> {title === t('ecosystem.groups.all') ? loading ? loader : count : notification}  {/* { loading ? loader : count ? count : notification } */}</b></div>
              </div>
              <div className={styles.favoriteContainer} onClick={() => handleFavorite()}>
                <img className={styles.favorite} src={favorite ? favoritePictoBlue : noFavoritePictoBlue} />
              </div>
              </>
              )}
          </div>
          <ClickableContainer
            link={link}
            onHover={bind}
          >
            <div className={styles.tileContent}>
              {image && <div className={styles.imageContainer}><img src={image} className={styles.image} /></div>}
              <div className={styles.textWrapper}>
                {status && <div className={styles.status}>{status}</div>}
                {title && <div className={styles.title}><b>{title}</b></div>}
                {subTitle && <div className={styles.subTitle}><i>{subTitle}</i></div>}
              </div>
            </div>

          </ClickableContainer>
        </div>
      </>
    );
  },
);
