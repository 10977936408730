import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { ViewRiskStatusProps, RiskViewResultsHeaderProps } from "../view-risk-status-page";
import { viewRiskStatusEnhancer } from "./viewRiskStatusEnhancer";
import { Table, Label, Modal } from "semantic-ui-react";
import styles from "./view-risk-status-page.module.css";
import { ViewRiskTabPanel } from "../../components/tab-panel-contract";
import { useParams } from "../../hooks/use-params-contract";
import { ViewRiskHeader } from "../../constants";
import { ClickableContainer, FilterButton } from "../../components";
import { RiskViewPageFilter } from "./view-risk-status-filter";
import { RiskStatusHeader } from "./header/view-risk-table-header";
import { da } from "date-fns/locale";
import { resultsSubService, viewRiskStatusService } from "../../services";
import { ClassicButton } from "../../components/buttons/classic-button/classic-button-component";
import { posthogEvents } from "../../utils/posthog";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";


export const ViewRiskStatusPage: React.FC<ViewRiskStatusProps> = ({
  datas,
  name
}: ViewRiskStatusProps) => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const riskStatus = datas.results;
  const [data, setData] = useState(riskStatus);
  const [search, setSearch]: [string, (search: string) => void] =
    useState("");
  const [isSelected, setIsSelected] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false)

  const { params, header, manageOrdering, manageSearch } = useParams(
    ViewRiskHeader,
    { ordering: "actors" }
  );
  
  const handleChange = (e: { target: { value: string } }) => {
    setSearch(e.target.value);
  };

  const handleClick = (status: string) => {
    manageSearch("status", status);
    setIsSelected(status);
  };

  const updateResults = async (name: any) => {
      setLoading(true)
      try {
        const update = await resultsSubService.upDateResults({ name })
        setLoading(false)
        return update
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
  }

  /* const downloadCSV = async (name: any, format: string) => {
    posthogEvents.triggerEvent({
      name: "clickOn",
      datas: { foodchain: "downloadCSV" },
    });
    setLoading(true);
    try {
      const download = await viewRiskStatusService.downloadResults({ name });
      const url: Blob = new Blob(["\ufeff" + download], {
        type:
          format === "csv"
            ? "data:text/csv;charset=utf-8,"
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobUrl = window.URL.createObjectURL(url);
      const templink: any = document.createElement("a");
      templink.setAttribute(
        "download",
        format === "csv" ? `cdc_${name}.csv` : "extract.xlsx"
      );
      templink.href = blobUrl;
      document.body.appendChild(templink);
      templink.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);
  }; */

  //csv export 
  const handlePosthog = () => {
    posthogEvents.triggerEvent({
      name: "clickOn",
      datas: { foodchain: "downloadCSV" },
    });
  }
  const headers = [
    {label: "Code Tiers", key: "actor"},
    {label: "Total Indicateurs", key: "total_indicators"},
    {label: "Total Conforme", key: "total_compliant_indicators"},
    {label: "Total Non Conforme", key: "total_not_compliant_indicators"},
    {label: "Risk Status", key: "risk_status"}
  ]
  const csvReport = {
    data: riskStatus,
    headers: headers,
    filename: 'stockage-ferme.csv',
    separator: ';'
  };
  // end of csv export
  return (
    <div>
      <h1 className={styles.title}>
        Autodiagnostic Stockage Ferme - Rapport du{" "}
        {riskStatus[0].audited_at_day} à {riskStatus[0].audited_at_hour}
      </h1>
    <Modal
      onClose={() => setOpenModal(false)}
      onOpen={() => setOpenModal(true)}
      open={openModal}
      trigger={
      <div className={styles.refreshAudits}>
        <img src={require('../../../assets/pictos/refresh.png')} className={styles.refreshImg} alt="img-reload" />
        Actualiser les audits
      </div> }
    >
      <div style={{ marginLeft: "5%", marginBottom: "2%" }}>
          <div style={{ marginTop: "3%", marginBottom: "2%" }}>
            <strong
              style={{
                fontSize: "2em",
                marginTop: "-4%",
              }}
            >
              {t("foodchainResults.refreshAudits")}
            </strong>
          </div>
          <p style={{ fontSize: "1.5em" }}>
            {t("foodchainResults.popMessage")}
          </p>
          <div
            style={{
              marginTop: "2%",
              float: "right",
              marginRight: "5%",
              marginBottom: "2%",
            }}
          >
            <ClassicButton
              text={t("foodchainResults.cancel")}
              secondary
              onClick={() => setOpenModal(false)}
              data-automation-id="cancelled"
              style={{ marginRight: "15px" }}
            />
            <ClassicButton
              text={t("foodchainResults.confirm")}
              onClick={() => {
                updateResults(riskStatus[0].specifications);
                setOpenModal(false)
              }}
              data-automation-id="confirmed"
            />
          </div>
        </div>
    </Modal>

      <RiskStatusHeader pk={parseInt(window.location.pathname.split('/')[3])} />

      <div className={styles.btnBlock}>
        <RiskViewPageFilter handleClick={handleClick} selected={isSelected} />
        <CSVLink {...csvReport}> <ClassicButton text="exporter en csv" secondary onClick={handlePosthog} />  </CSVLink>
      </div>
    
      <>
        <ViewRiskTabPanel
          limit={100}
          service={viewRiskStatusService.getRiskStatus}
          initHeader={ViewRiskHeader}
          manageSearch={manageSearch}
          manageOrdering={manageOrdering}
          header={header}
          params={params}
        />
      </>
    </div>
  );
};

export const RiskStatusPage = viewRiskStatusEnhancer(ViewRiskStatusPage);
