import * as React from 'react';

import { Table, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './body-check-component.module.css';
import { BodyCheckProps } from './body-check-type';
import { DataTable } from '../header/header-type';
import { HeaderColumn } from '../../../models';
import { formatTableData } from '../utils';

export function BodyCheck <T>({
  data, header, checkedList, handleList, checkFilter, displayList
}: BodyCheckProps<T>) {
  const { t } = useTranslation();

  const handleChange = (data: any) => {
    handleList(parseInt(data.name));
  };

  return (
    <Table.Body>
      {data.filter((el: DataTable<T>) => {
        if (checkFilter === true) {
          return el.pk && checkedList.includes(el.pk);
        } if (checkFilter === false) {
          return el.pk && !checkedList.includes(el.pk);
        }
        return el;
      }).map((el: DataTable<T>) => (
        <Table.Row key={el.pk}>
          <Table.Cell collapsing>
            <Checkbox
              name={el.pk ? el.pk.toString() : ''}
              value=""
              checked={el.pk ? checkedList.includes(el.pk) : false}
              onChange={(event, data) => handleChange(data)}
            />
          </Table.Cell>
          {header.filter(el => {
          if (displayList && displayList.length > 0) {
            return displayList.includes(el.columnName)
          } else {
            return true
          }
        }).map((el2: HeaderColumn<T>) => (
            <React.Fragment key={el2.columnName as string}>{formatTableData(t, el2.dataTransform, el[el2.columnName])}</React.Fragment>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  );
}
