import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './actor-wizard-component.module.css';
import { ActorWizardProps } from './actor-wizard-type';
import { Actor, Error } from '../../../models';
import { actorSubService } from '../../../services';
import checkPictoWhite from '../../../../assets/pictos/checkPictoWhite.png';
import actorPictoBlue from '../../../../assets/pictos/actorPictoBlue.svg';
import { ActorForm } from '../../forms/actor-form';
import trashPictoRed from '../../../../assets/pictos/trashPictoRed.png';
import editPictoWhite from '../../../../assets/pictos/editPictoWhite.png';
import { toastHandler } from '../../../utils';
import { WizardLoader } from '../utils';
import { redirectWithUrl } from '../utils/redirect';

export const ActorWizard: React.FC<ActorWizardProps> = React.memo(({ edit, initialActor, initialStep }: ActorWizardProps) => {
  const [editedActor, setEditedActor] = React.useState<Actor>({...initialActor, is_placeholder:false});
  const [activeStep, setActiveStep] = React.useState(initialStep ? initialStep : 0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const manageSubmitActor = async (actor: Actor) => {
    console.log('submitted actor', actor)
    setLoading(true);
    try {
      setEditedActor(actor);
      const result = edit ? await actorSubService.editActor(actor) : await actorSubService.createActor(actor);
      toastHandler(t('wizard.actors.saveSuccess'), 'success');
      setLoading(false);
      history.push({pathname: redirectWithUrl(location.search, `/ecosystem/actors/${result.pk}`), state: location.state});
    } catch(err) {
      const error : Error = err as any as Error;
      setLoading(false);
      toastHandler(error.message === 'siret_number' ? t('wizard.actors.siretError') : t('wizard.actors.saveError'), 'error');
      setError(t('table.utils.error'));
    }
  };

  const manageDeleteActor = async () => {
    if (editedActor.pk && editedActor.pk !== -1) {
      setLoading(true);
      try {
        const result = await actorSubService.deleteActor(editedActor.pk);
        setLoading(false);
        history.push({pathname: redirectWithUrl(location.search, '/ecosystem'), state: location.state});
        toastHandler(t('wizard.actors.deleteSuccess'), 'success');
      } catch (err) {
        setLoading(false);
        console.log(err);
        toastHandler(t('wizard.actors.deleteError'), 'error');
      }
    }
  };

  const manageCancel = () => {
    setEditedActor(initialActor);
    history.push({pathname: editedActor.pk ? redirectWithUrl(location.search, `/ecosystem/actors/${editedActor.pk}`) : redirectWithUrl(location.search, '/ecosystem'), state: location.state});
  };

  const goTo = (index: number) => {
    setActiveStep(index);
  };

  const step = [
    {
      index: 0,
      label: t('wizard.sites.step1'),
      picto: actorPictoBlue,
      Component: <ActorForm
        model={editedActor}
        onSave={(model) => manageSubmitActor(model)}
        errorMessage={error}
        onDelete={edit ? manageDeleteActor : undefined}
        onCancel={manageCancel}
      />,
    },
  ];


  return (
    <div className={styles.wizard}>
      <div className={styles.form}>
        {
        step.map((el) => (
          <div className={`${activeStep === el.index ? styles.activeStep : styles.step}`} key={el.index}>
            <div className={styles.header}>
              <div className={styles.titlePictoContainer}><img src={el.picto} className={styles.titlePicto} /></div>
              <div className={styles.titleLabel}><b>{el.label}</b></div>
              {editedActor.pk && editedActor.pk !== -1
              && (
              <div className={styles.deleteContainer} onClick={manageDeleteActor}>
                <div className={styles.trashPictoContainer}><img src={trashPictoRed} className={styles.trashPicto} /></div>
                <div className={styles.deleteLabel}>{t('wizard.actors.delete')}</div>
              </div>
              )}
            </div>
            <div className={`${styles.formWrapper} ${loading ? styles.blured : ''}`}>
              {loading && <WizardLoader />}
              {el.Component}
            </div>
          </div>
        ))
      }
      </div>
      <div className={styles.track}>
        {
        step.map((el) => (
          activeStep === el.index
            ? (
              <div className={`${styles.container} ${styles.activeContainer}`} key={el.index}>
                <div className={`${styles.badge} ${styles.activeBadge}`}>{el.index + 1}</div>
                <div className={`${styles.label} ${styles.activeLabel}`}>{el.label}</div>
              </div>
            )
            : editedActor.pk
              ? (
                <div className={`${styles.container} ${styles.editContainer}`} key={el.index} onClick={() => goTo(el.index)}>
                  <div className={`${styles.badge} ${styles.editBadge}`}><img src={editPictoWhite} className={styles.editPicto} /></div>
                  <div className={`${styles.label} ${styles.editLabel}`}>{el.label}</div>
                </div>
              )
              : activeStep > el.index
                ? (
                  <div className={`${styles.container} ${styles.doneContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.doneBadge}`}><img src={checkPictoWhite} className={styles.checPicto} /></div>
                    <div className={`${styles.label} ${styles.doneLabel}`}>{el.label}</div>
                  </div>
                )
                : (
                  <div className={`${styles.container} ${styles.todoContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.todoBadge}`}>{el.index + 1}</div>
                    <div className={`${styles.label} ${styles.todoLabel}`}>{el.label}</div>
                  </div>
                )
        ))
      }
      </div>
    </div>
  );
});
