import { AuthToken } from '../../../models';

const storageKey = process.env.REACT_APP_AUTHTOKEN ? process.env.REACT_APP_AUTHTOKEN : '';

export const backendTokenService = {
  getToken: (): AuthToken | undefined => {
    const tokenValue = localStorage.getItem(storageKey);
    if (!tokenValue) {
      return undefined;
    }

    return JSON.parse(tokenValue) as AuthToken;
  },

};
