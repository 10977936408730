import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SpecificationFormProps,
} from './specification-form-type';
import styles from './specification-form.module.css';
import { ClassicButton } from '../../buttons';
import { useClickOutside } from '../../../hooks';
import { SimpleJson, Specification } from '../../../models';
import { specificationSubService } from '../../../services';
import { Input } from 'semantic-ui-react';

export const SpecificationForm: React.FC<SpecificationFormProps> = ({
  model,
  onSave,
  errorMessage,
  onCancel,
  onNext,
  onPrevious,
}: SpecificationFormProps) => {
  const { t } = useTranslation();
  const [openList, setOpenList] = React.useState(false);
  const wrapperRef = useClickOutside(() => setOpenList(false));
  const [search, setSearch] = React.useState<string | undefined>('');
  const [inputFetcher, setInputFetcher] = React.useState<any>();
  const [list, setList] = React.useState<any[]>([]);
  const [specification, setSpecification] = React.useState<Specification>(model)

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    //console.log('handleChange :', e.target);
    e.persist();
    setSearch(e.target.value);
    if (inputFetcher) {
      clearTimeout(inputFetcher);
    }

    setInputFetcher(setTimeout(() => {
      manageSearch(e.target.value);
    }, 500));
  };

  const nameChange = (name: string) => {
    setSpecification({...specification, name: name});
    setSearch(name);
    setOpenList(false);
  }

  React.useEffect(() => {
    manageSearch('');
  }, [])

  const manageSearch = async (search: string) => {
    let params: SimpleJson = { limit: '5', ordering: 'name', name: search }
    try {
      const results = await specificationSubService.getSpecifications(params);
      setList(results.results)
    } catch (err) {
      console.log('error');
      setList([]);
    }
  }

  const handleSubmit = () => {
    onSave(specification);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpecification({...specification, year: e.target.value})
  }

  return (
    <>
          <div>
            <div className={styles.formContainer}>

              {model && (
              <>
              <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.specification.name')}</b>
              <div className={styles.inputContainer} >
                
              <Input
                placeholder={t('form.specification.name')}
                className={styles.input} 
                onClick={() => setOpenList(true)}  onChange={handleInputChange} value={search || specification.name} />
              {
                openList && <div ref={wrapperRef} className={styles.modalList}>
                  {
                    list.length === 0 ?
                      <div className={styles.modalListContent}>{t('form.specification.no_results')}</div>
                      :
                      <div>
                        {
                          list.map(el => (
                            <div className={styles.modalListContent} key={el.pk} onClick={() => nameChange(el.name)}>{el.name}</div>
                          ))
                        }
                      </div>
                  }
                </div>
              }
            </div>
            </div>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.specification.year')}</b>
                  <div className={styles.inputWrapper}>
                  <Input
                    className={styles.input}
                    placeholder={t('form.specification.year')}
                    onChange={handleChange}
                    value={specification.year}
                  />

                  </div>
                </div>
              </>
              )}
            </div>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
              onCancel
                ? <ClassicButton text={t('wizard.specification.cancel')} onClick={onCancel} warning />
                : <div>&nbsp;</div>
            }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
              onPrevious
                ? <ClassicButton text={t('wizard.specification.back')} onClick={onPrevious} inverted />
                : <div>&nbsp;</div>
            }
                </div>
                <div className={styles.nextNavigation}>
                  {
              onNext
                ? <ClassicButton onClick={onNext} text={t('wizard.specification.next')} inverted />
                : <div>&nbsp;</div>
            }

                </div>
              </div>
              <div className={styles.submitActions}>
                <ClassicButton type="submit" text={t('wizard.specification.submit')} onClick={handleSubmit} />
              </div>
            </div>
          </div>
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};
