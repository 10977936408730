import React from 'react';

import { Loader } from 'semantic-ui-react';
import styles from './alert-light.module.css';

export const AlertLightLoader: React.FC = React.memo(() => (
  <div className={styles.loader}>
    <Loader inline active size="huge" className={styles.semanticLoader} />
  </div>
));
