import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LoginPage,
  HomePage,
  EcosystemPage,
  EcosystemGroupPage,
  TrackingFlowPage,
  ResetPasswordPage,
  ForgetPasswordPage,
  TrackingFlowsBatchsPage,
  EcosystemNewGroupPage,
  TrackingBatchPage,
  EcosystemEditGroupPage,
  EcosystemNewActorPage,
  EcosystemEditActorPage,
  EcosystemNewSitePage,
  EcosystemEditSitePage,
  EcosystemNewToolPage,
  EcosystemEditToolPage,
  EcosystemNewProductPage,
  EcosystemEditProductPage,
  EcosystemNewCertificatePage,
  EcosystemEditCertificatePage,
  AlertGroupPage,
  EcosystemSitePage,
  EcosystemToolPage,
  EcosystemCertificatePage,
  EcosystemProductPage,
  AlertFixPage,
  EcosystemActorPage,
  EcosystemNewRelationpage,
  SpecificationsPage,
  SpecificationCreatePage,
  SpecificationEditPage,
  SpecificationResultsPage,
  FoodchainResultsPage,
  FolderCreatePage,
  FolderEditPage,
  ContractResultsPage,
  ActorResultsPage,
  RiskStatusPage,
  ViewRiskDetailsPage
} from '../pages';
import { DefaultLayout } from '../layouts';
import { AuthenticatedContext } from '../contexts';
import { PrivateRoute } from './private-route';
import { SettingsPage } from '../pages/settings-page';
import { RouterLoader } from './router-loader';
import { authenticationService, authSubService } from '../services';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga'
import i18next from 'i18next';
import { EcosystemEditRelationPage } from '../pages/ecosystem-page/relations/edit-relation';
import { posthogEvents } from "../utils/posthog";

export const Router: React.FunctionComponent = () => {
  const authenticatedContext = React.useContext(AuthenticatedContext);
  const [loading, setLoading] = React.useState(true);
  const [postHogInitialized, setPostHogInitialized] = React.useState<boolean>(false)

  const postHogInitialize = () => {
    posthogEvents.initialize();
    setPostHogInitialized(true);
  }

  const handleGA = () => {
  ReactGA.initialize('UA-132482656-22 ');
  ReactGA.event({
    category: 'resolution',
    action: `${window.screen.width * window.devicePixelRatio} X ${window.screen.height * window.devicePixelRatio}`
  })
  // console.log(`${window.screen.width * window.devicePixelRatio} X ${window.screen.height * window.devicePixelRatio}`);
  }

  (process.env.REACT_APP_STAGE === 'preprod' || process.env.REACT_APP_STAGE === 'prod' ) && !postHogInitialized && postHogInitialize();
  if(process.env.REACT_APP_STAGE === 'prod'){
  if(authenticatedContext.folder?.permission === 'user'){
  handleGA();
  ReactGA.pageview(window.location.pathname + window.location.search);
  if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_VERSION) {
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID as unknown as number, process.env.REACT_APP_HOTJAR_VERSION as unknown as number);
}
}
}

  React.useEffect(() => {
    const initializeAuth = async () => {
      try {
        const result = await authenticationService.getLoggedInUserInfos();
        if (result) {
          const azure = await authSubService.getSasToken();
          authenticatedContext.setAuthenticated({
            ...result,
            azure: azure.sas,
          });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    setLoading(true);
    initializeAuth();
  }, []);

  if (loading) {
    return <RouterLoader />;
  }

  return (
    <>
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/reset-password/:id/:token" component={ResetPasswordPage} />
      <Route path="/mot-de-passe-oublie" component={ForgetPasswordPage} />
      <DefaultLayout>
        <Switch>
          <PrivateRoute exact path="/home" component={HomePage} />
          <PrivateRoute
            exact
            path="/tracking/flows"
            component={TrackingFlowsBatchsPage}
          />
          <PrivateRoute
            exact
            path="/tracking/flows/:id"
            component={TrackingFlowPage}
          />
          <PrivateRoute
            exact
            path="/tracking/batchs/:id"
            component={TrackingBatchPage}
          />
          <PrivateRoute exact path="/ecosystem" component={EcosystemPage} />
          <PrivateRoute exact path="/ecosystem/new-group" component={EcosystemNewGroupPage} />
          <PrivateRoute
            exact
            path="/ecosystem/edit-group/:id"
            component={EcosystemEditGroupPage}
          />
          <PrivateRoute exact path="/ecosystem/new-actor" component={EcosystemNewActorPage} />
          <PrivateRoute
            exact
            path="/ecosystem/edit-actor/:id"
            component={EcosystemEditActorPage}
          />
          <PrivateRoute exact path="/ecosystem/new-site" component={EcosystemNewSitePage} />
          <PrivateRoute
            exact
            path="/ecosystem/edit-site/:id"
            component={EcosystemEditSitePage}
          />
          <PrivateRoute exact path="/ecosystem/new-tool" component={EcosystemNewToolPage} />
          <PrivateRoute
            exact
            path="/ecosystem/edit-tool/:id"
            component={EcosystemEditToolPage}
          />
          <PrivateRoute exact path="/ecosystem/new-certificate" component={EcosystemNewCertificatePage} />
          <PrivateRoute
            exact
            path="/ecosystem/edit-certificate/:id"
            component={EcosystemEditCertificatePage}
          />
          <PrivateRoute exact path="/ecosystem/new-product" component={EcosystemNewProductPage} />
          <PrivateRoute
            exact
            path="/ecosystem/edit-product/:id"
            component={EcosystemEditProductPage}
          />

          <PrivateRoute exact path="/ecosystem/new-relation" component={EcosystemNewRelationpage}/>
          <PrivateRoute
          exact
          path="/ecosystem/edit-relation/:id"
          component={EcosystemEditRelationPage}
          />


          <PrivateRoute
            exact
            path="/ecosystem/group/:id"
            component={EcosystemGroupPage}
          />
          <PrivateRoute
            exact
            path="/ecosystem/actors/:id"
            component={EcosystemActorPage}
          />
          <PrivateRoute
            exact
            path="/ecosystem/sites/:id"
            component={EcosystemSitePage}
          />
          <PrivateRoute
            exact
            path="/ecosystem/tools/:id"
            component={EcosystemToolPage}
          />
          <PrivateRoute
            exact
            path="/ecosystem/certificates/:id"
            component={EcosystemCertificatePage}
          />
          <PrivateRoute
            exact
            path="/ecosystem/products/:id"
            component={EcosystemProductPage}
          />

          {/* Cahier des charges Menu */}
          <PrivateRoute
              exact
              path="/specifications"
              component={SpecificationsPage}
            />
            <PrivateRoute
              exact
              path="/specification/results/:pk"
              component={SpecificationResultsPage}
            />
            <PrivateRoute
              exact
              path="/foodchain/results/:name"
              component={FoodchainResultsPage}
            />
            <PrivateRoute
              exact
              path="/contract/results/:pk"
              component={ContractResultsPage}
            />
            <PrivateRoute
              exact
              path="/contract/datas/:actor_code"
              component={ActorResultsPage}
            />
            <PrivateRoute
              exact
              path="/contract/autodiagnostic-ferme/:pk"
              component={RiskStatusPage}
            />
            <PrivateRoute
            exact
            path="/contract/autodiagnostic-ferme/:pk"
            component={ViewRiskDetailsPage}
          />
            <PrivateRoute
              exact
              path="/folders/create"
              component={FolderCreatePage}
            />
            <PrivateRoute
              exact
              path="/folders/edit/:id"
              component={FolderEditPage}
            />

          <PrivateRoute exact path="/alert/:id" component={AlertFixPage} />
          <PrivateRoute exact path="/alerts" component={AlertGroupPage} />
          <PrivateRoute exact path="/parametre" component={SettingsPage} />


          <Redirect to="/home" />
        </Switch>
      </DefaultLayout>
    </Switch>
    <ToastContainer />
    </>
  );
};
