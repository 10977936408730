import React from 'react';
import styles from './cell-string.module.css';
import { Table } from 'semantic-ui-react';
import { CustomPopup } from './custom-popup';

export interface StringCellProps {
  messages: string[];
  enabled: boolean;
  initialValue?: string | number;
  value?: string | number;
  error: boolean;
  editCell: (value: string) => void;
}

export const StringCell = ({messages, enabled, initialValue, value, error, editCell}: StringCellProps) => {

  const modified = initialValue === value && value !== null;
  const handleComma = (q : string) => {
    const regex = /[,]/gm;
    const subst = '.'
    const checkedValue : string = q.replace(regex, subst);
    editCell(checkedValue)
  }

  return (
    <CustomPopup messages={messages.filter(el => typeof el === 'string')}>
        <Table.Cell style={{ border: !error ? undefined : enabled ? '1px solid blue' : modified ? '1px solid orange' : '1px solid red'}} >
          {
            error && enabled ?
              <div>
                <input
                  className={styles.input}
                  onChange={(e) => handleComma(e.target.value)}
                  defaultValue={value?.toString() || ''}
                />
              </div>
              :
              <div className={styles.defaultDisabled} style={{ color: !error ? 'black' : modified ? 'orange' : 'red' }}>{value}</div>
          }
        </Table.Cell>
      </CustomPopup>
  )
}
