import {
  ApiList, AlertPreview,
} from '../models';
import { HomeResults } from '../pages';
import { alertSubService, flowSubService } from './sub-services';

export const homeService = {

  getHome: async () => {
    const flowResponse = await flowSubService.getAllFlows({ limit: 10, offset: 0 });
    // console.log(flowResponse);
    const globalResults: HomeResults = {
      alerts: {
        matching_key: {
          count : 0,
          next : null,
          previous : null,
          results: []
        },
        
        missing_data: {
          count : 0,
          next : null,
          previous : null,
          results: []
        },
        connection_key: {
          count : 0,
          next : null,
          previous : null,
          results: []
        },
        audit: {
          count : 0,
          next : null,
          previous : null,
          results: []
        },
      },
      flows: flowResponse,
    };
    // console.log('globalResults :', globalResults);
    return globalResults;
  },

};
