import * as React from "react";

import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styles from "./alert-group.module.css";
import { HeaderColumn, AlertGroup, AlertGroupLight } from "../../../models";
import { AlertGroupItemProps } from "./alert-group-types";
import { formatAlertData } from "../utils/data";
import { ClickableContainer, ClassicButton, EditButton } from '../../../components';
import { posthogEvents } from '../../../utils/posthog';

export function AlertGroupItem({ data, header }: AlertGroupItemProps) {
  const { t } = useTranslation();
  const clickButton = () => {
    posthogEvents.triggerEvent({name : 'clickOn', datas: {'status': data.retrack ? 'retrack' : data.status}})
  }
  console.log(header)
  return (
    <div className={styles.customTable}>
      <div>
        <div className={styles.alertItem}>{header.map((el: HeaderColumn<AlertGroupLight>, key) => { 
         return el.header !== "alerts.alertGroup.last_event" ? 
          (
         <div 
         key={key}
         className={
          el.dataTransform === 'date' || 
          el.dataTransform === 'tag' || 
          el.dataTransform === 'compliant' ? 
          styles.alertItemColumnSmall 
          : 
          styles.alertItemColumn}
         >
          <div className={styles.alertItemColumnHeader} key={key}>{el.header === "alerts.alertGroup.status" ? t("alerts.alertGroup.id_alert") : t(el.header)}</div>
          <div className={styles.alertItemColumnContent}>{formatAlertData(t, el.dataTransform, data[el.columnName], data.pk)}</div>
          </div>) 
          : "" 
        
        })}
          <div className={styles.alertButton}>
            <ClickableContainer 
            link={`/alert/${data.pk}`}
            target={'_blank'}
            >
              <EditButton
              disabled={data.retrack}
                          warning={data.status === 'open'}
                          text={
                            data.retrack
                              ? t("alerts.alertGroup.retrack")
                              : data.status === "open"
                              ? t("alerts.alertGroup.correct")
                              : t("alerts.alertGroup.modify")
                          }
                          />
            </ClickableContainer>
          </div>
        </div>

      </div>
    </div>
  );
}
