import * as React from 'react';
import { Container, Responsive } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { MenuItemProps } from './menu-item-type';

import styles from './menu-item-component.module.css';
import { useHover } from '../../../hooks';
import { ClickableContainer } from '../../clickable-container';
import { configUI } from '../../../../config';
import { SidebarContext, OnBeforeUnloadingContext } from '../../../contexts';
import { useHistory } from 'react-router-dom';
import { posthogEvents } from '../../../utils/posthog';


export const MenuItem: React.FC<MenuItemProps> = React.memo(
  ({ element }: MenuItemProps) => {
    const { hovered, bind } = useHover();
    const history = useHistory()

    const { handleVisible } = React.useContext(SidebarContext);

    const handleClick = (disable: boolean) => {

      if (!disable) {
        handleVisible(false);
      }
      if (activeContext) {
        setAction(true);
        setNext(() => handleRedirect);
      } else {
        handleRedirect();
      }
    };

    const { t } = useTranslation();

    const {setAction, activeContext, setNext} = React.useContext(OnBeforeUnloadingContext);

    const handleRedirect = () => {
      posthogEvents.triggerEvent({ name: 'goTo', datas: {'Page' : element.libelle} });
      history.push(element.link); 
    }
    
    return (
      <>
        <ClickableContainer
          onHover={bind}
          className={`${styles.menuItem}`}
        >
          <div className={`${styles.itemContainer} ${element.disable && styles.disable}`} onClick={() => handleClick(element.disable)}>
            <div className={styles.pictoContainer}>
              <img
                src={element.disable ? element.pictoDisable : hovered && !element.disable ? element.pictoHover : element.picto}
                className={styles.picto} 
              />
            </div>
            <Responsive
              as={React.Fragment}
              minWidth={configUI.responsive.tablet.minWidth}
            >
              <div className={`${styles.libelle} ${hovered && !element.disable ? styles.hovered : ''}`}>{t(`menu.${element.libelle}`)}</div>
            </Responsive>
          </div>
        </ClickableContainer>
      </>
    );
  },
);
