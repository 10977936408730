import React from 'react';
import { HeaderColumn } from '../models';
import { applyOrdering, applyFilter, handleDateSearch } from '../utils';
import { posthogEvents } from '../utils/posthog';

export function useLocalFilter <T>(initHeader: HeaderColumn<T>[], initData: T[], initOrder?: string) {
  const [header, setHeader] = React.useState<HeaderColumn<T>[]>(initHeader);
  const [data, setData] = React.useState<T[]>(initData);
  const [lastOrderer, setLastOrderer] = React.useState<string>('');
  const [order, setOrder] = React.useState<boolean>(true); //false = croissant, true = décroissant

  React.useEffect(() => {
    setData(initData)
  }, [initData])

  const fillSearchValue = (searchProps: string, searchValue: string, newHeader: HeaderColumn<T>[], lang?: string): void => {
    // console.log('search localfilter', searchProps, searchValue);
    posthogEvents.triggerSearch(searchProps, searchValue);
    for (const head of newHeader) {
      if (head.searchProp === searchProps) {
        searchProps.includes('date') ? head.searchValue = handleDateSearch(searchValue, lang) : searchValue === 'all' && searchProps === 'audit' ? head.searchValue = "" :  head.searchValue = searchValue;
        setHeader([
          ...newHeader,
        ]);
      }
    }
  };

  const manageOrdering = (orderer: string): void => {
    let tempOrder = order;
    if(lastOrderer === orderer){
      setOrder(!order)
      tempOrder = !tempOrder;
      posthogEvents.triggerEvent({name: 'filter', datas: {'order' : `-${orderer}` }});
    } else {
      setLastOrderer(orderer);
      setOrder(true);
      tempOrder = true;
      posthogEvents.triggerEvent({name: 'filter', datas: {'order' : orderer} });
    }
    const newData = [...data];
    setData(applyOrdering(newData, initHeader, orderer, tempOrder));
  };

  React.useEffect(() => {
    let firstOrder : boolean = true;
    if(initOrder !== undefined){
      if(initOrder[0] === '-'){
        setOrder(false)
        firstOrder = false;
        setLastOrderer(initOrder.substring(1));
        } else {
          setLastOrderer(initOrder)
        }
        initOrder && setData(applyOrdering(initData, initHeader, initOrder, firstOrder));
      }
   
  }, []);

  const manageSearch = (searchProp: string, searchValue: string, lang? : string): void => {
    // console.log('search', searchProp, searchValue)
    fillSearchValue(searchProp, searchValue, header, lang);
    setData(applyOrdering(applyFilter(initData, header), header, lastOrderer, order));
  };
  

  return {
    header, data, manageOrdering, manageSearch,
  };
}
