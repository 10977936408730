import React from 'react';
import styles from './item-count.module.css';
import { ItemCountProps } from './item-count-types';
import { useTranslation } from 'react-i18next';

export const EcosystemItemCount: React.FC<ItemCountProps> = ({ count, picto, item }: ItemCountProps) => {
  const {t} = useTranslation();
  return (
    <div className={styles.innerTitle}>
      <div className={styles.itemPictoContainer}><img src={picto} alt={item} className={styles.itemPicto} /></div>
      <b className={styles.accordionTitle}>{`${t(`ecosystem.${item}.label`)} (${count})`}</b>
    </div>
  );
};

