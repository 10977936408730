import React from 'react';
import { Field, FieldProps } from 'formik';
import { Form, Checkbox } from 'semantic-ui-react';

import { CheckBoxInputControlProps } from './checkbox-input-control-type';

export const CheckBoxInputControl: React.FC<CheckBoxInputControlProps> = ({
  name,
  label,
  ...rest
}: CheckBoxInputControlProps) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps) => (
      <Form.Field>
        <Checkbox
          {...rest}
          {...field}
          value="" // -- La valeur s'attend à revevoir un string et elle recoit un boolean, l'état est géré avec la props checked
          checked={field.value}
          onChange={(event, data) => form.setFieldValue(name, data.checked)}
          name={name}
          label={label}
        />
      </Form.Field>
    )}
  />
);
