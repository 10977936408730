import { Flow, Actor } from '.';
import { SimpleJson } from './utils';
import { FlowLight } from './tracking';
import { User } from './authentication';
import { ApiList } from './api';
import { AlertGroupListProps, HistoryType } from '../pages';

export interface Alert extends AlertLight {
    name_line?: string;
    context?: string;
    alert_message?: string;
    is_archived: boolean;
}

export interface AlertLight {
    pk: number;
    alert_title?: string;
    alert_date_time: Date;
    subcategory: AlertSubCategory;
    category: AlertCategory;
    flow: FlowLight[];
    flow_orchestrator: number;
    empty_field?: keyof Flow;
    status?: AlertStatus;
}

export interface AlertLightEcosystem extends AlertLight{
}

export interface AlertLightFlow extends AlertLight{
}

export interface AlertLightAudit extends AlertLight {
  criticality: Criticality;
}

export interface AlertEcosystem extends Alert {
  user: User;
}

export interface AlertFlow extends Alert {
  cf_tracking_code: string;
  actor: Actor;
}

export interface AlertAudit extends Alert {
  audit_type: string;
  criticality: Criticality;
}

export interface DetailedAlert extends Alert {
  criticality?: Criticality;
  comments?: ApiList<AlertComment[]>;
}


export type AlertStatus = 'open' | 'in_progress' | 'closed';

export type AlertCategory = 'flow' | 'audit' | 'ecosystem';

export type Criticality = 'critical' | 'important' | 'medium';

export type AlertSubCategory = 'missing_data' | 'connection_key' | 'matching_key' | 'audit';

export type AlertContextCategory = AlertSubCategory /*| 'audit'*/;

export interface AlertCount {
  missingDataCount?: number;
  connectionKeyCount?: number;
  matchingKeyCount?: number;
  auditAlertCount?: number;
} // push vide

export interface AlertPreview {
    pk: number;
    criticality: Criticality;
    date: Date;
    message?: string;
    flow?: FlowLight[];
}

export interface GetAlerts {
    type?: AlertContextCategory;
    offset: number;
    limit: number;
    is_archived?: string;
    category?: AlertCategory;
    subcategory?: AlertSubCategory;
  }

export interface GetAlert {
    id: number;
  }

  export interface GetHistory {
    id: number;
    type: HistoryType;
  }

export interface AuditResult {
    pk: number;
    name: string;
    audit_date: Date;
    flow: Flow;
    audit_rule: string;
    criticality: Criticality;
    result: boolean;
    details: SimpleJson;
    message: string;
}

export interface AlertComment {
  pk: number;
  comment_title: string;
  alert: Alert;
  user: User;
  description: string;
  comment_date_time: Date;
}


//GESTION DES ERREURS 
export type StatusType = 'closed_by_user' | 'closed' | 'open'

export interface AlertGroupLight {
  pk: number;
  first_importation_datetime: Date;
  status: StatusType ;
  tag: string[]; 
  source_file: string;
  step: string; 
  last_event: string;
  cluster_title: string[];
  retrack : boolean;
  old_alert: boolean;
}

export interface AlertGroup extends AlertGroupLight {
  time: string; 
  fields_in: (keyof Flow)[];
  batch_in_fields: any[];
  batch_out_fields: any[];
}


export interface GetAlertGroup {
  id: string;
}


export interface GetAllAlertGroups {
  limit?: number;
  offset?: number;
  ordering?: string;
  status?: string;
  tag?: string;
  first_importation_datetime?: string;
  source_file?: string;
  step?: string;
  search?:string;
  mail?: string;
  retrack?: string;
  alert_type?: string;
  cluster_date?: string;
}

export interface ElementHistory {
  history_id: number; //id de l'objet historique unique par flux/certif_history
  id: number; // pk du flux 
  history_user: string,
  history_date: Date;
  changed_fields : ChangedFields[];
}
export interface NewElementHistory {
  history_id: number; //id de l'objet historique unique par flux/certif_history
  id: number; // pk du flux 
  history_user: string,
  history_date: Date;
  history_change_reason : ChangedFields[];
}


export interface ChangedFields {
  value: string;
  changed_old: any;
  changed_new: any;
}

export interface CommentAlertSet {
  pk: number;
  alert: number | null; //pk de l'alert
  user: string;
  description?: string;
  comment_date_time: Date,
  users_at : number[];
}


export class  newHistoryObject {
  user : string = '';
  date : Date = new Date();
  type: string = '';
  description?: string = '';
  changed_fields: ChangedFields[] = [];
  users_at: number[] = []
}
