import {
    WithStatelessDatasFetchingByParameters,
    WithQuerystringReading,
  } from "../../../hocs";
  import {
    ApiList, GetRiskStatus, GetRiskViewDetails, RiskStatusDetails, RiskStatusDetailsProps,
  } from "../../../models";
  import { viewRiskStatusService } from "../../../services";
  import { PageLoader } from "../../../components";
  import React from "react";
  import { compose } from "redux";
  
  export const riskDetailsEnhancer = compose<React.FC<RiskStatusDetails>>(
    WithQuerystringReading(),
    WithStatelessDatasFetchingByParameters<
      ApiList<RiskStatusDetails>,
      RiskStatusDetailsProps,
      GetRiskViewDetails
    >(
      viewRiskStatusService.getViewRiskDetails,
      PageLoader,
      "ignore",
      "results",
      ["pk"]
    )
  );
  