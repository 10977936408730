import React from "react";
import { useTranslation } from "react-i18next";
import { SpecificationResultsTableBodyProps } from ".";
import styles from "./specification-results-table-body.module.css";
import searchPictoBlue from "../../../../../assets/pictos/searchPictoBlue.png";
import { Table, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";

export function SpecificationResultsTableBody<T>({
  data,
}: SpecificationResultsTableBodyProps<T>) {
  const { t } = useTranslation();
  
  console.log(data);

  return (
    <Table.Body>
      {data?.map((result, key = result.pk) => (
        <Table.Row key={key}>
          <Table.Cell>
            <div style={{ float: "left" }}>
              <Link to={`/contract/results/${result.pk}`}>
                <img
                  src={searchPictoBlue}
                  alt=""
                  style={{ width: "12px", height: "12px" }}
                />
              </Link>
            </div>
            <Link to={{pathname:`/contract/datas/${result.actor}`, state:{actorCode:result.actor}}}>
                {result.actor}
            </Link>
          </Table.Cell>

          <Table.Cell>{result.foodchain}</Table.Cell>

          <Table.Cell>{result.parcel}</Table.Cell>

          <Table.Cell>{result.audited_at_day}</Table.Cell>

          <Table.Cell>{result.total_indicators}</Table.Cell>

          <Table.Cell>{result.total_compliant_indicators}</Table.Cell>

          <Table.Cell>{result.total_not_compliant_indicators}</Table.Cell>

          <Table.Cell>{result.nb_not_compliant_minor_indicators}</Table.Cell>

          <Table.Cell className={styles.majConformity}>
            {result.nb_not_compliant_major_indicators}
          </Table.Cell>

          <Table.Cell>
            {result.conformity_proportion_indicator_minor}
          </Table.Cell>

          <Table.Cell className={styles.majConformity}>
            {result.conformity_proportion_indicator_major}
          </Table.Cell>

          <Table.Cell>
            {result.conformity ? (
              <Label className={styles.compliant} circular>
                {t("tableBody.compliant")}
              </Label>
            ) : (
              <Label className={styles.nonCompliant} circular>
                {t("tableBody.noCompliant")}
              </Label>
            )}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  );
}
