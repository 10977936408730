import { buildUrlParameters } from '../../utils';
import { backendTokenService, securedRestClient } from '../../backend';
import {
  ApiList, ToolWrite, ToolRead, GetTool,
} from '../../models';

export const toolSubService = {

  // ===============
  // ==== TOOL ====
  // ===============

  // --- CREATE TOOL ---

  createTool: async (tool: ToolWrite) => {
    console.log(
      'ecosystem service createtool, tool : ',
      tool,
    );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/tools/`;
    const response = await securedRestClient.post<ToolRead>(requestUrl, tool);
    console.log(response.data);
    return response.data;
  },

  // --- READ TOOL ---

  getTool: async ({
    is_placeholder, group_id, id, owner, site, certificate, limit, offset, name, internal_code, capacity, unit, tool_type, ordering, owner_name,
  }: GetTool) => {
    console.log(
      'ecosystem service getTool, id : ', id,
      'owner', owner,
      'site', site,
      'certificate', certificate,
      'limit', limit,
      'offset', offset,
      'name', name,
      'internal_code', internal_code,
      'capacity', capacity,
      'unit', unit,
      'tool_type', tool_type,
      'ordering', ordering,
      'group_id', group_id,
      'owner_name', owner_name,
      'is_placeholder', is_placeholder,
    );

    const params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'group_id', value: group_id },
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'ordering', value: ordering },
      { prop: 'owner', value: owner },
      { prop: 'id', value: id },
      { prop: 'site', value: site },
      { prop: 'certificate', value: certificate },
      { prop: 'name', value: name },
      { prop: 'internal_code', value: internal_code },
      { prop: 'capacity', value: capacity },
      { prop: 'unit', value: unit },
      { prop: 'tool_type', value: tool_type },
      { prop: 'owner_name', value: owner_name },
      { prop: 'is_placeholder', value: is_placeholder },
    ];
    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/tools/${id || ''}?${paramUrl}`;
    const response = await securedRestClient.get<ApiList<ToolRead[]>>(requestUrl);
    console.log(response.data);
    return response.data;
  },

  // --- UPDATE TOOL ---

  editTool: async (tool: ToolWrite) => {
    console.log(
      'ecosystem service puttool, tool : ',
      tool,
    );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/tools/${tool.pk}`;
    const response = await securedRestClient.put<ToolRead>(requestUrl, tool);
    console.log(response.data);
    return response.data;
  },

  // --- DELETE TOOL ---

  deleteTool: async (id: number) => {
    console.log(
      'ecosystem service deletetool, tool id : ',
      id,
    );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/tools/${id}`;
    const response = await securedRestClient.delete(requestUrl);
    console.log(response.data);
    return response.data;
  },

};
