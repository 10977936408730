import React from 'react';
import styles from './new-site.module.css';
import { SiteWizard } from '../../../../components';
import { WizardLayout } from '../../../../layouts';
import { SiteWrite } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';

export const EcosystemNewSitePage: React.FC = () => {
  const site: SiteWrite = {
    name: '',
    internal_code: '',
    address: '',
    zip_code: '',
    country: '',
    city: '',
    region: '',
    department: '',
    owner: -1,
    site_type: '',
    is_placeholder: false
  };
  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.wizardWrapper}>
        <SiteWizard initialSite={site} />
      </div>
    </WizardLayout>
  );
};
