import React from "react";
// import {RiskStatusTableHeaderProps,SearchCellProps} from './viewrisk-results-table-header-type'
import {
  RiskStatusTableHeaderProps,
  SearchCellProps,
} from "./viewrisk-results-table-header-type";
import styles from "./viewrisk-results-table-header.module.css";
import { Table, Label } from "semantic-ui-react";
import searchPictoBlue from "../../../../../assets/pictos/searchPictoBlue.png";
import { SelectedValue } from "../../../../models";

export function RiskStatusTableHeader<T>({
  header,
  manageSearch,
  name,
  pk,
}: RiskStatusTableHeaderProps<T>) {
  

  return (
    <>
      <Table.Header>
        <Table.Row>
          {header.map((el, key) => (
            <Table.HeaderCell key={key}>
              <div className={styles.header}>
                {el.columnName}

                <div className={styles.searchWrapper}>
                  {el.searchCell ? (
                    <SearchCell
                      column={el}
                      manageSearch={manageSearch}
                      searchValue={el.searchValue}
                    />
                  ) : null}
                </div>
              </div>
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
    </>
  );
}

function SearchCell<T>({
  column,
  manageSearch,
  searchValue,
}: SearchCellProps<T>) {
  const [search, setSearch] = React.useState<string | undefined>(
    searchValue === "" ? undefined : searchValue
  );
  const [inputFetcher, setInputFetcher] = React.useState<any>();

  const handleInputSubmit = (searchProp: string) => {
    if (search !== undefined && (search.length > 0 || search.length === 0)) {
      manageSearch && manageSearch(searchProp, search);
    }
  };

  const handleInputChange = async (
    searchProp: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("handleChange :", searchProp, e.target);
    e.persist();
    setSearch(e.target.value);
    if (inputFetcher) {
      clearTimeout(inputFetcher);
    }

    setInputFetcher(
      setTimeout(() => {
        manageSearch && e.target && manageSearch(searchProp, e.target.value);
      }, 500)
    );
  };

  const selectChange = (searchProp: string, value: SelectedValue) => {
    manageSearch && manageSearch(searchProp, value as string);
  };

  return (
    <div className={styles.inputContainer}>
      <input
        className={styles.input}
        onChange={(e) => handleInputChange(column.searchProp, e)}
        value={search || ""}
      />
      <div
        className={styles.button}
        onClick={() => handleInputSubmit(column.searchProp)}
      >
        <img src={searchPictoBlue} className={styles.searchPicto} />
      </div>
    </div>
  );
}
