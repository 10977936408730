import React from 'react';
import { FolderEditPageProps } from './folder-edit-page-type';
import { FolderWizard } from '../../components';
import { WizardLayout } from '../../layouts';
import backgroundLogin from '../../../assets/images/backgroundLogin.jpg';
import styles from './folder-edit-page.module.css';
import { enhancer } from './enhancer';

export const InnerFolderEditPage: React.FC<FolderEditPageProps> = ({result}: FolderEditPageProps) => {
 console.log(result)
    return <WizardLayout image={backgroundLogin}>
    <div className={styles.wizardWrapper}>
    <FolderWizard folder={result.folder} specifications={result.specifications} />
    </div>
  </WizardLayout>
}

export const FolderEditPage = enhancer(InnerFolderEditPage)