import React, { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Header, Modal, Table } from 'semantic-ui-react';
import { ClassicButton } from '../../../components/buttons';
import { ListProductForm, ListSiteForm, ListToolForm, OwnerForm } from '../../../components/forms';
import { AuthenticatedContext, OnBeforeUnloadingContext } from '../../../contexts';
import { EcosystemPreview, Flow, SimpleJson, TabInclude } from '../../../models';
import { checkPermission } from '../../../roles';
import { flowSubService } from '../../../services';
import styles from './cell-ecosystem.module.css';
import { CustomPopup } from './custom-popup';

export interface EcosystemCellProps {
  messages: string[];
  enabled: boolean;
  initialFlow?: Flow;
  flow: Flow;
  error: boolean;
  editFlow: (flow: Flow) => void;
  save: () => Promise<void>;
  referentiel: keyof Flow;
  open?: boolean;
  setFlowPk: React.Dispatch<SetStateAction<number[]>>;
  flowPk: number[];
  modified: boolean;
  handleGlobalSave: () => Promise<void>;
}

export const EcosystemCell = ({open, messages, enabled, initialFlow, flow, error, editFlow, save, referentiel, setFlowPk, flowPk, modified, handleGlobalSave}: EcosystemCellProps) => {
  
  const [openModal, setOpenModal] = React.useState(open);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { folder } = React.useContext(AuthenticatedContext);

  const staff : boolean = folder ? checkPermission(folder.permission, '/alert/:id:correct', {}) : false;

 

  const openEcosystem = () => {
    setOpenModal(true);
  }


  const closeEcosystem = () => {
    const pkList=[...flowPk, flow.pk]
    setFlowPk(pkList)
    // il faut forcer un refetch des flux
    // history.push({
    //   pathname: `/alert/${location.pathname.split('/')[2]}`,
    //   state: {filter: 'matching_key'}
    // })
    handleGlobalSave()
    // toastHandler('La modification a bien été appliquée à tous les flux concernés', "info" )
    setOpenModal(false);
  }


  return (
    <Modal className={styles.modalBloc}
      closeIcon
      trigger={
        <CustomPopup messages={messages}>
          <Table.Cell
            onClick={() => error && enabled && openEcosystem()}
            style={{
              border: modified? '1px solid orange' : !error ? undefined : !enabled ?'1px solid red' : "1px solid blue",
              backgroundColor: enabled && error ? 'white' : 'transparent',
              cursor: enabled && error ? 'pointer' : 'default'
            }}
          >
            <div className={styles.ecosystemDisabled} style={{ color: !error ? 'black' : enabled ? 'blue' :  'red' }}>{flow[referentiel] && ((flow[referentiel] as any).name)}</div>
          </Table.Cell>
        </CustomPopup>
      }
      open={openModal}
      onClose={closeEcosystem}
    ><Header style={{backgroundColor: "#F7F7F8"}}>
        <div className={styles.modalHeader}>
         {`${t(`alerts.ecosystem_preview.title`)} : ${flow.name}`}
      </div>
      </Header>

      <Modal.Content style={{ backgroundColor: "#F7F7F8" }}>
        { staff ? 
          <EcosystemModalContent flow={flow} editFlow={editFlow} save={save} closeModal={closeEcosystem} />
          :
          <TempModal />
        }
      </Modal.Content>
    </Modal>
  )
}

const TempModal =  () => {
  const { t } = useTranslation();
  return (
    <div className={styles.modalContent}>
          <div style={{width: '100%'}}>
           
          </div>
        {t(`alerts.ecosystem_preview.contact_CF`)}
        </div>
  )
}

interface EcosystemModalContentProps {
  flow: Flow;
  editFlow: (flow: Flow) => void;
  save: () => Promise<void>;
  closeModal: () => void
}

const EcosystemModalContent = ({closeModal, flow, editFlow, save}: EcosystemModalContentProps) => {
  const { t } = useTranslation();
  const history = useHistory()
  const location = useLocation();

  //on stocke les pk des acteurs receiver et sender pour les utiliser dans les filtres des requetes des siites, outils, produits...
  const [ownerReceiver, setOwnerReceiver] = React.useState<number | undefined>(flow.actor_receiver ? flow.actor_receiver.pk : undefined);
  const [ownerSender, setOwnerSender] = React.useState<number | undefined>(flow.actor_sender ? flow.actor_sender.pk : undefined);
  
  const { folder } = React.useContext(AuthenticatedContext);
  const staff : boolean = folder ? checkPermission(folder.permission, '/alert/:id:correct', {}) : false;
  //liste ordonnée des écrans à afficher dans la modal (façon wizard)
  const initialList: (keyof Flow)[] = [
    'actor_sender', "site_sender", "tool_sender", "actor_receiver", 'site_receiver', 'tool_receiver', 'product'
  ]

  const nextList: {
    flowProperty: keyof Flow,
    include: TabInclude,
    params: SimpleJson,
    step: number,
    link: string,
    owner?: "actor_sender" | "actor_receiver"
  }[] = [];

  interface LinkedOwnerProps {
    site_sender : string;
    site_receiver: string;
    tool_sender : string;
    tool_receiver: string;
    product : string
  }

  // Objet test pour faire les lien entre le champ du flow et le propriétaire

  const linkedOwner : LinkedOwnerProps = {
    site_sender : 'actor_sender',
    site_receiver : 'actor_receiver',
    tool_sender: 'site_sender',
    tool_receiver: 'site_receiver',
    product: 'actor_sender' 
  } 

  //on remplit un tableau dont chaque élément contient les informations nécessaires à l'affichage d'une modale
  // on ne laisse pas le choix dans l'ordre de correction à l'utilisateur
  const fillNextList = () => {
    // console.log('bla', linkedOwner['site_sender'])
    initialList.forEach(element => {
      // console.log('dans le forEach avant le if', element)
      if (flow.problematic_fields.includes(element)) {
        // //si true je rattache à un owner
        const temp: {
          //nom de la propriété (actor_sender, site_receiver ...)
          flowProperty: keyof Flow,
          //nom normalisé de la propriété : actors, sites, products, tools (utilisé surtout dans la partie référentiel)
          include: TabInclude,
          //une partie des paramètres pour faire les requêtes de liste de sites, outils ...
          params: SimpleJson,
          // étape du wizard à laquelle arriver si on souhaite créer l'acteur, site ...
          step: number,
          //permet de construire l'url de redirection si on souahite créer l'acteur, le site ...
          link: string,
          // les actions possibles sur les site_sender, tool_receiver ... changent suivant qu'il aient un propriétaire ou non, il faut alors savoir si on vérifier la présence de l'actor_sender ou receiver
          owner?: "actor_sender" | "actor_receiver"
        }  = {
          flowProperty: element,
          include: element === 'actor_receiver' || element === 'actor_sender' ?
            'actors' :
            element === 'site_receiver' || element === 'site_sender' ?
            'sites' :
            element === 'tool_receiver' || element === 'tool_sender' ?
            'tools' :
            'products',
            // ajout du params owner si il y a lieu de 
           params: element === 'tool_sender' || element === 'tool_receiver' ? {limit: '5', is_placeholder: 'false', site :  (flow[(linkedOwner[element]as keyof Flow)] as EcosystemPreview).pk.toString()} : element  === 'site_sender' || element === 'site_receiver' ? {limit: '5', is_placeholder: 'false', owner : (flow[(linkedOwner[element]as keyof Flow)] as EcosystemPreview).pk.toString()} : {limit: '5', is_placeholder: 'false'},
        
           step: element === 'actor_receiver' || element === 'actor_sender' ?
           0 : 1,
           link: element === 'actor_receiver' || element === 'actor_sender' ?
           'actor' :
           element === 'site_receiver' || element === 'site_sender' ?
           'site' :
           element === 'tool_receiver' || element === 'tool_sender' ?
           'tool' :
           'product',
           
        }
        nextList.push(temp)
      }
    });
  }

  //on initialise notre tableau d'informations
  fillNextList();
  console.log('voici ma nextList!', nextList)

  // curseur de notre tableau d'information
  const [current, setCurrent] = React.useState(0);

  //on avance dans le tableau d'information
  const next = () => {
    setCurrent(current + 1);
  }

  const editProperty = (pk: number, property: keyof Flow) => {
    console.log('pk du choix ! =>', pk, property)

    editFlow({
      ...flow,
      [property]: {
        pk,
        name: "",
        is_placeholder: false
      }
    });
    //si on modifie un acteur, il faut modifier les states ownerSender ou ownerReceiver pour pouvoir utiliser les propriétaires à jour lors des requêtes des sites, outils, produits...
    if (property === 'actor_sender'){
      setOwnerSender(pk)
    } else if (property === 'actor_receiver') {
      setOwnerReceiver(pk);
    }
  }

  // n'est plus utile car on n'est plus sensé arrivé sur cet écran en ayant des modifications en cours
  const {setAction, setNext, activeContext} = React.useContext(OnBeforeUnloadingContext);

  const handleRedirect = () => {
    // on redirige vers l'edition d'un element du referentiel et on ajoute dans search un parametre pour rediriger sur la page des alertes, et un state pour garder en memeoir la modal a ouvrir (open:...)
    history.push({
      pathname: `/ecosystem/edit-${nextList[current].link}/${flow[nextList[current].flowProperty] && (flow[nextList[current].flowProperty] as any).pk}`,
      search: `?step=${nextList[current].step}&redirectPath=/alert/${location.pathname.split('/')[2]}`, 
      state: {open: flow.pk, filter: 'matching_key'}
    }); 
  }

  const handleClick = () => {
    if (activeContext) {
      setAction(true);
      setNext(() => handleRedirect);
    } else {
      handleRedirect();
    }
  }

  //si on sort du tableau (plus d'élément à corriger) on ferme la modal
  // on peut injecter ici de la logique avant de revenir sur les alertes, par exemple sauvegarder le flux
  if (current >= nextList.length) {
    // console.log('fin de tableau : ', nextList, current);
    //sauvegarde du flux necessaire ?
    closeModal();
    return <></>
  }

  return (
    <div className={styles.modalContent}>
          <div style={{width: '100%'}}>
            <div className={styles.modalSearch}>
              {t(`alerts.ecosystem_preview.subtitle_${nextList[current].flowProperty}`)}
          </div>
            <div>
              {
                nextList[current] && (nextList[current].flowProperty !== 'actor_sender' && nextList[current].flowProperty !== 'actor_receiver') &&
                <CustomList 
                  listType={nextList[current].include}
                  params={{
                    ...nextList[current].params, 
                    ...( nextList[current].owner === "actor_receiver" ? 
                      ownerReceiver && {owner: ownerReceiver.toString()} : 
                      nextList[current].owner === "actor_sender"  ?  
                      ownerSender && {owner: ownerSender.toString()} : 
                      undefined)
                    }}
                    old={ flow[nextList[current].flowProperty] as EcosystemPreview && (flow[nextList[current].flowProperty] as EcosystemPreview).pk }
                  next={next} 
                  save={save}
                  include={nextList[current].link}
                  editProperty={(pk: number) => editProperty(pk, nextList[current].flowProperty)} 
                  />
              }
              {
                nextList[current] && (nextList[current].flowProperty === 'actor_sender' || nextList[current].flowProperty === 'actor_receiver') &&
                <CustomList 
                  listType={nextList[current].include}
                  //pas de filtre supplémentaire comme pour les sites, outils, produit (= on prends tous les acteurs). Il est possible qu'un filtre sur les transcodes soit à ajouter plus tard, quand la notion de transcode sera intégrée dans le frontend
                  params={{...nextList[current].params}} 
                  next={next} 
                  save={save} 
                  editProperty={(pk: number) => editProperty(pk, nextList[current].flowProperty)} 
                  include={nextList[current].link}
                  old={ flow[nextList[current].flowProperty] as EcosystemPreview && (flow[nextList[current].flowProperty] as EcosystemPreview).pk}
                  />
              }
            </div>
          </div>
          {
            staff && flow[nextList[current].flowProperty] && (flow[nextList[current].flowProperty] as any).pk && 
            <div className={styles.createOption}>
              <div className={styles.modalOption}>
                {t(`alerts.ecosystem_preview.or_${nextList[current].flowProperty}`)}
              </div>
              <ClassicButton text={t(`alerts.ecosystem_preview.new_${nextList[current].link}`)} onClick={handleClick}/>
            </div>
          }
        </div>
  )
}

interface CustomListProps {
  editProperty: (pk:number) => void;
  save: () => Promise<void>;
  params: SimpleJson;
  listType: TabInclude;
  next: () => void;
  old?:number;
  include: string;
}

const CustomList = ({ params, editProperty, save, listType, next, old, include}: CustomListProps) => {

  //stocke les éléments cochés dans le tableau. On en fournit pas la propriété 'multiple' aux List...Form, donc 1 seule case est cochable à la fois
  const [list, setList] = React.useState<number[]>([])
  
  const onSave = async (newList: number[]) => {
    setList(newList);
    editProperty(newList[0])
    if (old) {
      // le back mets à jour tous les flux avec le nouvel element du referentiel
      await flowSubService.replaceEcosystemInFlow(old, newList[0], include);
    } else {
      await save();
    }
    next();
  }

  // on reutilise les listes d'éléments du referentiel présentes dans les wizards
  switch (listType) {
    case 'products': {
      return <ListProductForm include='products' blocking specificParams={params} model={list} onSave={onSave} />
    }
    case 'sites': {
      return <ListSiteForm include='sites' blocking specificParams={params} model={list} onSave={onSave} />
    }
    case 'tools': {
      return <ListToolForm include='tools' blocking specificParams={params} model={list} onSave={onSave} />
    }
    default: {
      return <OwnerForm include='actors' blocking specificParams={params} model={list} onSave={onSave} />
    }
  }
}


