import React, { useRef } from 'react';
import styles from './alert-history.module.css'
import enhancer from './alert-history-enhancer';
import { AlertHistoryProps, APIHistoryProps } from './alert-history-type';
import { newHistoryObject, CommentAlertSet, User } from '../../../../models';
import {ReactComponent as History} from '../../../../../assets/pictos/history.svg'
import i18next from 'i18next';
import { ModificationComponent, CommentBloc, CommentInput } from '../../../../components';
import  close from '../../../../../assets/pictos/close.png'
import { formatData } from '../../../../components/history/utils';
import { alertSubService, toastHandler } from '../../../..';
import { useTranslation } from 'react-i18next';





export const InnerAlertHistory : React.FC<AlertHistoryProps>= (props) => {
    // console.log('HISTORY', props);
    const {id, type, results, handleClick} = props;
    const el= React.useRef<HTMLDivElement | null>(null)
    const [ historyResults, setHistoryResults] = React.useState<newHistoryObject[]>(results? formatData(results) : [])
    const  {t} = useTranslation();


    async function getComment  (pk : number, comment : string, users_at: number[]) {
        try{
        const res : CommentAlertSet= await alertSubService.newCreateAlertComment( type, pk, comment, users_at);
        const tempComment = new newHistoryObject();
        tempComment.type = 'commentaire';
        tempComment.user = res.user;
        tempComment.date = res.comment_date_time;
        tempComment.description = res.description;
        setHistoryResults([...historyResults, tempComment])
        if(el){
            el.current?.scrollIntoView({behavior: 'smooth', block: "end"})
        }
        } catch(e){
           toastHandler(`${t('alerts.alertHistory.commentError')}`, 'error');
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
    })

    return <div className={styles.container}>
        <img src={close} className={styles.closeIcon} onClick={handleClick}/>
        <div className={styles.title}><History/> {`${t('alerts.alertHistory.history')}`}</div>
                <div className={styles.historyBloc}>
                    {
                    historyResults.length>0?
                    historyResults.map((el : newHistoryObject, index)=> (
                        <div key={index}>
                        {el.type ==='flow' || el.type === 'certificate' ?
                        <ModificationComponent name={el.user} date={el.date} type={el.type} changedFields={el.changed_fields}/>
                        : 
                        <CommentBloc author={el.user} description={el.description} date={el.date}/>
                        }</div>
                    ))
                    :
                    <div className={styles.noHistory}> {t('alerts.alertHistory.no_history')}
                    </div>
                    }
                            <div ref={el} id={'dummy'}></div>
                </div>
                <div className={styles.inputCommentBloc}>
                <CommentInput pk={props.id} getComment={getComment} />
                </div>
        </div>
}

export const AlertHistory = enhancer(InnerAlertHistory)