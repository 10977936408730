import { compose } from 'redux';
import { WithQuerystringReading, WithNotifyRessourceLoadedInputProps, WithStatelessDatasFetchingByParameters } from '../../../../hocs';
import { GetRelationLight, RelationWrite } from '../../../../models';
import { relationSubService } from '../../../../services';
import { PageLoader } from '../../../../components/page-loader';
import { EditRelationProps } from '.';

const enhancer = compose<
React.ComponentType<GetRelationLight & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<RelationWrite, EditRelationProps, GetRelationLight>(
    relationSubService.getEditRelation,
    PageLoader,
    'error',
    'relation',
    ['id'],
  ),
);


export default enhancer;
