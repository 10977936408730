import React, { FormEvent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./specifications-page.module.css";
import { FoodchainListProps } from "./specifications-page-type";
import { foodchainEnhancer } from "./enhancer";
import { Link } from "react-router-dom";
import { FilterButton } from "../../components";

//Importation d'icone
import farmer from "../../../assets/pictos/farmer.svg";
import outLine from "../../../assets/pictos/Out-line.svg";
import field from "../../../assets/pictos/field.svg";
import shape from "../../../assets/pictos/shape.svg";
import { posthogEvents } from "../../utils/posthog";
import { FoodchainLight } from "../../models";

const InnerSpecificationTilesCard: React.FC<FoodchainListProps> = ({
  foodchains,
}: FoodchainListProps) => {
  const { t } = useTranslation();

  const [filteredResults, setFilteredResults] = useState<any[]>([])
  const [isSelected, setIsSelected] = useState<string>('selected4')

  useEffect(() => {
    handleFilterCLick('2022', 'selected4')
  }, [])

  const [foodchainName, setFoodchainName] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");

  if (!foodchains) {
    return <div>Pas de résultat</div>;
  }

  const results = !foodchainName
    ? filteredResults
    : filteredResults.filter((foodchain) =>
        foodchain.name.toLowerCase().includes(foodchainName.toLocaleLowerCase())
      );

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    posthogEvents.triggerSearch('foodchain', e.currentTarget.value);
    setFoodchainName(e.currentTarget.value);
  };

  const handleClickOnCard = (foodchain : string, year: string, compliant: any, total_contracts: any ) => {
    posthogEvents.triggerEvent({ name: 'clickOn', datas: {foodchain}})
    window.localStorage.setItem('year', year)
    window.localStorage.setItem('name', foodchain)
    window.localStorage.setItem('compliant', compliant)
    window.localStorage.setItem('total_contracts', total_contracts)
  }

  posthogEvents.triggerEvent({ name: 'request', datas: { 'foodchain' : results.length > 0 ? 'success' : 'error'}})

  const handleFilterCLick = (year: string, value: string): any => {
    const filterResults = foodchains.results.filter(e => e.year === year)
    setFilteredResults(filterResults)
    setIsSelected(value)
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: "-4%", marginBottom: "3%" }}>
        <form onSubmit={e => e.preventDefault()}>
          <div className="ui icon input">
            <input
              className={styles.inputStyle}
              placeholder={t("specifications.search.product")}
              type="text"
              value={foodchainName}
              onChange={handleChange}
              data-automation-id="inputSearch"
            />
            <i aria-hidden="true" className="search icon"></i>
          </div>
        </form>
        <h4>{state}</h4>
      </div>
      <div style={{ display: 'flex',}}>
        <FilterButton text="2019" selected={isSelected === 'selected1'} onClick={() => handleFilterCLick('2019', 'selected1')} style={{ marginRight: '15px'}}/>
        <FilterButton text="2020" selected={isSelected === 'selected2'} onClick={() => handleFilterCLick('2020', 'selected2')} style={{ marginRight: '15px'}} />
        <FilterButton text="2021" selected={isSelected === 'selected3'} onClick={() => handleFilterCLick('2021', 'selected3')} style={{ marginRight: '15px'}} />
        <FilterButton text="2022" selected={isSelected === 'selected4'} onClick={() => handleFilterCLick('2022', 'selected4')} style={{ marginRight: '15px'}} />
        <FilterButton text="2023" selected={isSelected === 'selected5'} onClick={() => handleFilterCLick('2023', 'selected5')} style={{ marginRight: '15px'}} />
        <FilterButton text="2024" selected={isSelected === 'selected6'} onClick={() => handleFilterCLick('2024', 'selected6')} style={{ marginRight: '15px'}} />
      </div>
      <div className={styles.row}>
        {results.length === 0
         ? <div style={{ fontSize: '20px', padding: '10px' }}> Pas de résultat correspondant à cette année. </div>
         : results.map((foodchain, i) => (
          <Link key={i}
          to={{pathname:`/foodchain/results/${foodchain.name.toLocaleLowerCase()}`, }}
          onContextMenu={() => handleClickOnCard(foodchain.name, foodchain.year, foodchain.compliant_contracts, foodchain.total_contracts)}
          onClick={() => handleClickOnCard(foodchain.name, foodchain.year, foodchain.compliant_contracts, foodchain.total_contracts)}
          target="_blank">
            <div className={styles.column}>
              <div className={styles.card} data-automation-id="resultCard">
                <h2 className={styles.filiereTitle}>{foodchain.name}</h2>
                <h3>{foodchain.specifications}</h3>

                <div>
                  <strong>{foodchain.compliance_proportion} % </strong>
                  <strong>
                    <small className={styles.smallFiliereTitle}>
                      {t("specifications.compliance")}{" "}
                    </small>
                  </strong>
                  <strong>
                    {foodchain.compliant_contracts}/{foodchain.total_contracts}
                  </strong>
                </div>
                <div>
                  <img
                    style={{ width: "13px", height: "13px" }}
                    src={farmer}
                    alt="farmer-icon"
                  />
                  <strong style={{ fontSize: "11px" }}>
                    {" "}
                    {foodchain.total_actors} {t("specifications.farmers")}
                  </strong>
                </div>

                <div>
                  {foodchain.name === "Bas GES Colza" ||
                  foodchain.name === "Bas GES Tournesol" ||
                  foodchain.name === "Bas GES Blé" ? null : (
                    <div>
                      <img
                        style={{ width: "13px", height: "13px" }}
                        src={outLine}
                        alt="outline-icon"
                      />
                      <strong style={{ fontSize: "11px" }}>
                        {" "}
                        {foodchain.total_contracts}{" "}
                        {t("specifications.parcels")}
                      </strong>
                    </div>
                  )}
                  {/* <div>
                    <img
                      style={{ width: "13px", height: "13px" }}
                      src={outLine}
                      alt="outline-icon"
                    />
                    <strong style={{ fontSize: "11px" }}>
                      {" "}
                      {foodchain.total_contracts} {t("specifications.parcels")}
                    </strong>
                  </div> */}
                  {/* <img
                    style={{ width: "13px", height: "13px" }}
                    src={outLine}
                    alt="outline-icon"
                  />
                  <strong style={{ fontSize: "11px" }}>
                    {" "}
                    {foodchain.total_contracts} {t('specifications.parcels')}
                  </strong> */}
                </div>

                <div>
                  <img
                    style={{ width: "13px", height: "13px" }}
                    src={shape}
                    alt="shape-icon"
                  />
                  <strong style={{ fontSize: "11px" }}>
                    {" "}
                    {foodchain.total_area} ha
                  </strong>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </React.Fragment>
  );
};

export const SpecificationTilesCard = foodchainEnhancer(
  InnerSpecificationTilesCard
);
