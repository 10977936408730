import React from 'react';
import { ClassicButtonProps } from './classic-button-type';
import styles from './classic-button-component.module.css';

export const ClassicButton: React.FC<ClassicButtonProps> = ({
  disabled,
  text,
  secondary,
  onClick,
  inverted,
  type = 'button',
  warning,
  style
}: ClassicButtonProps) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className={`${styles.classicBtn} ${secondary && inverted ? styles.secondaryInverted : secondary ? styles.secondary : inverted ? styles.inverted : ''} ${warning ? styles.warning : ''} ${disabled ? styles.disabled : ''}`}
    type={type}
    disabled={disabled}
    onClick={onClick}
    style={style}
  >
    {text}
  </button>
);
