import {
  GetAllBatchs, AllBatchs, GetBatch, Batch, BatchLight, SimpleJson,
} from '../../models';
import { buildUrlParameters } from '../../utils';
import { backendTokenService, securedRestClient } from '../../backend';

export const batchSubService = {
  getAllBatchs: async ({
    ordering, limit, offset, specifications, name, audit, date,
  }: GetAllBatchs) => {
    // console.log(
    //   'ecosystem service getAllBatchs, limit : ', limit,
    //   'offset', offset,
    //   'ordering', ordering,
    //   'date', date,
    //   'audit', audit,
    //   'specifications', specifications,
    //   'name', name,
    // );

    const params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'date', value: date },
      { prop: 'audit', value: audit === 'all' ? '' : audit },
      { prop: 'name', value: name },
      { prop: 'specifications', value: specifications },
      { prop: 'ordering', value: ordering },
    ];

    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/tracking/batches/?${paramUrl}`;
    const response = await securedRestClient.get<AllBatchs>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  getBatch: async ({ id }: GetBatch) => {
    // console.log(
    //   'ecosystem service, getBatch, id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/tracking/batches/${id}`;
    const response = await securedRestClient.get<Batch>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  updateBatchLight: async (batch: BatchLight) => {
    // console.log(
    //   'batch alert service, update batch, batch : ',
    //   batch,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/tracking/batches/${batch.pk}`;
    const response = await securedRestClient.patch<BatchLight>(requestUrl, batch);
    // console.log(response.data);
    return response.data;
  },

  downloadBatchHistory : async (pk: number, format : string, datas: string, params : {prop : string, value: string}[]) => {
    // console.log(
    //   'batch sub service download format', format
    // );
    const filterParams = buildUrlParameters(params)
    const token = backendTokenService.getToken()
    console.log(filterParams)
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/tracking/batches/${pk}?extract=${format}${datas === 'next_flows' ? `&next_flows` : ''}${filterParams}`;
    const response = await securedRestClient.get<any>(requestUrl)
    return response.data;
  }
};
