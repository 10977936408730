import React from 'react';

import styles from './container-layout.module.css';
import { ContainerLayoutProps } from '.';

export const ContainerLayout: React.FC<ContainerLayoutProps> = ({ children, style }) => (
  <>
    <div className={styles.layout} style={style}>
      {children}
    </div>

  </>
);
