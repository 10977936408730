import { compose } from 'redux';
import { WithQuerystringReading, WithNotifyRessourceLoadedInputProps, WithStatelessDatasFetchingByParameters } from '../../../../hocs';
import { GetGroupLight, GetActor } from '../../../../models';
import { EditActorProps } from '.';
import { actorSubService } from '../../../../services';
import { GroupList } from '../../group';
import { PageLoader } from '../../../../components/page-loader';

const enhancer = compose<
React.ComponentType<GetGroupLight & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<GroupList, EditActorProps, GetActor>(
    actorSubService.getActor,
    PageLoader,
    'error',
    'actors',
    ['id'],
  ),
);


export default enhancer;
