import React from "react";
import { Table, Loader } from "semantic-ui-react";
import styles from "./viewrisk-results-table.module.css";
import { RiskStatusResultsTableBody } from "./body/viewrisk-results-table-body";
import { RiskStatusTableHeader } from "./header/viewrisk-results-table-header";
import { RiskStatusTableProps } from "./viewrisk-result-table-type";


export function RiskStatusResultsTable<T>({
  data,
  header,
  params,
  manageSearch,
  loadMoreItems,
  loading,
  endFetching,
  error,
}: RiskStatusTableProps<T>) {
  const intersectLastTarget = React.useRef<HTMLDivElement>(null);

  const block = React.useRef(false);

  const [display, setDisplay] = React.useState(block.current);
  const [datas, setDatas] = React.useState(data);
  const [isSelected, setIsSelected] = React.useState<string>("");

  const handleClick = (status: string) => {
    manageSearch('status', status)
    setIsSelected(status);
  };

  React.useEffect(() => {
    block.current = false;
  }, [display]);

  React.useEffect(() => {
    const callback: IntersectionObserverCallback = (list) => {
      list.forEach((entry) => {
        if (!block.current && entry.isIntersecting) {
          block.current = true;
          setDisplay(true);
          loadMoreItems && loadMoreItems();
          setTimeout(() => {
            setDisplay(false);
          }, 500);
        }
      });
    };

    const opts = {
      root: null,
      rootMargin: "200px",
      threshold: 1,
    };

    const observerScroll = new IntersectionObserver(callback, opts);

    if (intersectLastTarget.current !== null) {
      observerScroll.observe(intersectLastTarget.current);
    }
  });

  if (error) {
    return (
      <div>
        <Table basic="very" structured selectable textAlign="center">
          <RiskStatusTableHeader params={params} header={header} manageSearch={manageSearch} />
        </Table>
        <div className={styles.errorWrapper}>
          <div className={styles.error}>
            <strong>Erreur</strong>
          </div>
        </div>
      </div>
    );
  }

  if ((!data || data.length === 0) && loading) {
    return (
      <div>
        <Table basic="very" structured selectable textAlign="center">
          <RiskStatusTableHeader params={params} header={header} manageSearch={manageSearch} />
        </Table>
        <div className={styles.loader} />
        {/* Chargement */}
        <Loader active size="huge" className={styles.semanticLoader} />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <div>
        <Table basic="very" structured selectable textAlign="center">
          <RiskStatusTableHeader  params={params} header={header} manageSearch={manageSearch} />
        </Table>
        <div className={styles.noResultWrapper}>
          {/* No result */}
          <div className={styles.noResult}>
            <strong>Pas de résultat</strong>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.tableWrapper}>
        <Table basic="very" structured selectable textAlign="center">
          <RiskStatusTableHeader header={header} params={params} manageSearch={manageSearch} />
          <RiskStatusResultsTableBody data={data} header={header} />
          {!loading && !block.current && !display && !endFetching && (
            <>
              <div
                ref={intersectLastTarget}
                style={{
                  position: "relative",
                  top:
                    data.length < 11 ? `-${5 + data.length * 25}px` : "-250px",
                  // "-50px",
                }}
              />
            </>
          )}
        </Table>
      </div>
    </>
  );
}
