import React from 'react';

import { Placeholder } from 'semantic-ui-react';
import styles from './group.module.css';

export const GroupLoader: React.FC = React.memo(() => (
  <div>
    <Placeholder fluid className={styles.placeholder}>
      <Placeholder.Image square />
    </Placeholder>
  </div>
));
