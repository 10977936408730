import { compose } from 'redux';
import { WithStatelessDatasFetchingByParameters, WithNotifyRessourceLoadedInputProps, WithQuerystringReading } from '../../../hocs';
import { GetAlert, DetailedAlert } from '../../../models';
import { AlertProps } from '.';
import { alertSubService } from '../../../services';
import { PageLoader } from '../../../components';

const enhancer = compose<
React.ComponentType<GetAlert & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(), WithStatelessDatasFetchingByParameters<DetailedAlert, AlertProps, GetAlert>(
    alertSubService.getAlert,
    PageLoader,
    'error',
    'alert',
    ['id'],
  ),
);


export default enhancer;
