import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

export const BatchLoader: React.FC = React.memo(() => (
  <div>

    <Dimmer active inverted>
      <Loader size="massive" />
    </Dimmer>

  </div>
));
