import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './alert-header.module.css';
import { AlertFilter, AlertButtonProps } from './alert-fix-types';
import { AlertContextCategory, StatusType, AlertGroup, CommentAlertSet } from '../../../models';
import { FlowAlertService, alertSubService, flowSubService } from '../../../services';
import { BlockButton, ClassicButton } from '../../../components/buttons';
import { Modal } from 'semantic-ui-react';
import { AlertStatusModal } from './alert-status-modal';
import i18next from 'i18next';
import { formatDate, toastHandler } from '../../../utils';
import {ReactComponent as History} from '../../../../assets/pictos/history.svg'
import {useHistory} from 'react-router-dom'
import { AlertRetrackModal } from './alert-retrack-modal';

export interface HeaderAlertProps {
  filter: (alertType: AlertContextCategory) => void;
  save: () => void;
  globalRetrack: () => void;
  selected?: AlertContextCategory;
  cluster?: AlertGroup;
  selectedFlows: number[];
  alertButtons: AlertFilter;
  visible: boolean;
  handleClick: () => void;
  noError: boolean;
  oldAlert: boolean
}

export const HeaderAlert = ({alertButtons, filter, save, globalRetrack, selected, cluster, selectedFlows, visible, handleClick, noError, oldAlert}: HeaderAlertProps) => {
  const {t} = useTranslation();
  const [statusModal, setStatusModal ] = React.useState<boolean>(false);
  const [retrackModal, setRetracksModal ] = React.useState<boolean>(false);
  const [statusModalDisplay, setStatusModalDisplay ] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<StatusType | undefined>(cluster?.status);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [auditModal, setAuditModal] = React.useState<boolean>(false);


  const history = useHistory();

// test pour que le bouton se grise sans avoir besoin du refresh de la page
  const update = (element : AlertContextCategory) => {
    setRefresh(!refresh);
    filter(element);
  }



  const updateStatusModal = () => {
    setStatusModal(!statusModal)
  }

  const updateRetrackModal : VoidFunction= () : void => {
    setRetracksModal(!retrackModal)
  }

  const handleRetrackChoice = () : void => {
    save();
    globalRetrack();
    setRetracksModal(false)
    setTimeout( () => {history.push('/alerts')}, 2000)
  }

  const closeStatusModal = () => {
    setStatusModal(false)
    setStatusModalDisplay(false)
  }

  const openAuditModal = () => {
    setAuditModal(true);
  }

  const closeAuditModal = () => {
    setAuditModal(false);
  }

  async function getComment  (pk : number, comment : string, users_at: number[]) {
    let statusValue = "";
    switch(status){
      case 'open' :{
        statusValue = "close";
      };
      break;
      default :{
        statusValue = "open"
      }
    }
    try{
    const res : CommentAlertSet= await alertSubService.newCreateAlertComment('alert_cluster', pk, comment, users_at);
    if (cluster?.pk) {
      const response = await FlowAlertService.updateCluster(comment, cluster?.pk, statusValue)
      setStatus(response.status)
      }
      toastHandler(`${t('alerts.change_status.success')}`, 'success');
    } catch(e){
       toastHandler(`${t('alerts.alertHistory.commentError')}`, 'error');
    }
     closeStatusModal()
}

React.useEffect(() => {
}, [refresh])


  return (
    <div className={styles.header}>
        <div className={styles.general}>
          <div>{`${t('alerts.alertFix.file')} : ${cluster?.source_file}`}</div>
          <div>{`${t('alerts.alertFix.importation_date')} ${cluster && formatDate(new Date(cluster.first_importation_datetime), i18next.language)}`}</div>
          <div className={styles.historyTrigger} onClick={handleClick}><History/> {`${t('alerts.alertHistory.getHistory')}`}</div>
        </div>
      <div className={styles.actions}>
        <div className={styles.status}>
          <div>
          {t('alerts.alertFix.status')}
          </div>
          <div className={styles.statusUpdate}>
            {status === 'closed_by_user' ?
          
          <BlockButton disabled text={t('alerts.alertFix.closed')} deploy={updateStatusModal}/>
          : status === 'closed' ?
          <BlockButton text={t('alerts.alertFix.resolved')} disabled/>
          :
          <BlockButton warning text={t('alerts.alertFix.nonResolved')} deploy={updateStatusModal} />
          }
          {/* <div><img src={ BrandScroll } className={styles.brandScrollButton} onClick={updateStatusModal}/></div> */}
          {statusModal && (
            <Modal closeIcon onClose={() => closeStatusModal()} open={statusModalDisplay} trigger={
            <div className={styles.changeStatus} onClick={() => (setStatusModalDisplay(!statusModalDisplay))}>{t('alerts.change_status.modify')}</div>}
            >
            {cluster &&<AlertStatusModal status={status} closeStatusModal={closeStatusModal} updateAlertCluster={getComment} pk={cluster.pk}/>}
            </Modal>
          )}
          </div>
        </div>
          <Modal closeIcon onClose={updateRetrackModal} open={retrackModal} trigger={
            <div className={styles.retrackContainer}>
                {
                oldAlert || noError && 
                status === 'open' 
                && <div className={styles.noError}>{t('alerts.alertFix.trace_warning')}</div>
                
                }
                <ClassicButton 
                disabled={status !== 'open' ||  oldAlert }
                text={t('alerts.alertFix.trace')}
                onClick={noError? handleRetrackChoice : updateRetrackModal} 
                />
            </div>
          }>
            <AlertRetrackModal updateRetrackModal={updateRetrackModal} handleRetrackChoice={handleRetrackChoice}/>
          </Modal>
        </div>
        <Modal
        closeIcon
        open={auditModal}
        onClose={closeAuditModal}
        >
          <div className={styles.retrackModal}>
            <TempModal/>
            </div>
        </Modal>
      <div className={styles.buttons}>
        {Object.keys(alertButtons).map((el: string) => (
             <AlertButtonElement 
             status={selected === el as AlertContextCategory}
             key={el} 
             label={alertButtons[el as AlertContextCategory].label} 
             handleFilter={() => update(el as AlertContextCategory)} 
             disabled={alertButtons[el as AlertContextCategory].flows.length > 0 && !oldAlert  && selected === undefined || selected === el as AlertContextCategory} 
             generalStatus={status}
             content={alertButtons[el as AlertContextCategory].flows.length>0 && !oldAlert }
             resolved={alertButtons[el as AlertContextCategory].resolved}
             openModal={openAuditModal}
             />
          ))}
      </div>
      </div>
  )
}

const AlertButtonElement = ({label, handleFilter, status, disabled, generalStatus, content, resolved, openModal}: AlertButtonProps) => {
  const {t} = useTranslation()
  return(
  <div onClick={label === 'Audits' && disabled ? openModal : generalStatus === 'closed_by_user' ? undefined : content? handleFilter : undefined} className={`${styles.alertButton} ${status? styles.blueAlertButton : content ? styles.redAlertButton : resolved ? styles.greenAlertButton : styles.grayAlertButton}`}>
    <div className={`${generalStatus === 'closed_by_user'? styles.alertClosed : !disabled ? styles.disabled : ""} ${styles.labelButton}`}>
      {label}
    </div>
    {generalStatus !== 'closed_by_user' &&  <div className={resolved ? styles.resolved :styles.correct}>
      {!content? resolved ? t('alerts.alertFix.resolved') : '' : status? t('alerts.alertFix.stop') : t('alerts.alertFix.correct')} 
    </div>}
    
  </div>)
}

const TempModal =  () => {
  const { t } = useTranslation();
  return (
    <div className={styles.modalContent}>
          <div style={{width: '100%'}}>
           
          </div>
        {t(`alerts.ecosystem_preview.contact_CF`)}
        </div>
  )
}
