import React, { useRef } from "react";
import { ContractResultsPageProps } from "./contract-results-page-type";
import styles from "./contract-results-page.module.css";
import "./contract-results-page.module.css";
import { contractEnhancer } from "./contractEnhancer";
import { Form, Icon } from "semantic-ui-react";
import Accordion from "./accordion/Accordion";
import { Link } from "react-router-dom";
// import Chevron from "./accordion/Chevron";

export const InnerContractResultsPage: React.FC<ContractResultsPageProps> = ({
  results,
}: ContractResultsPageProps) => {
  const obj = results.audit_values;

  const conformity = results.audit_values;
  const indicators = Object.entries(results.audit_values);
  console.log(results.audit_values);

  const [state, setState] = React.useState({ conformity, data: {} });
  // Indicators
  const [data, setData] = React.useState({ indicators, data: {} });

  const [open, setOpen] = React.useState(false);

  const [modal, setModal] = React.useState(false);

  // const [activate, setActivate] = React.useState("");
  // const [height, setHeight] = React.useState("0px");
  // const [rotate, setRotate] = React.useState("accordion__icon");

  if (!results) {
    return <div>Pas de résultat</div>;
  }

  const renderForm = () => {
    const details = results;
    return (
      <React.Fragment>
        <Form style={{ paddingTop: "10px" }} key={details.pk}>
          <Form.Group unstackable widths={2}>
            <Form.Field>
              <label className={styles.formLabel}>Code tiers</label>
              <div>
              <Link
                  to={{pathname:`/contract/datas/${details.actor}`, state:{actorCode:details.actor}}}
                  style={{ cursor: "pointer" }}
                  
                >
                  <div className={styles.formInputCodeTiers}>
                    <div style={{ marginBottom: "6px", marginLeft: "16px" }}>
                    {details.actor}
                    </div>
                 </div>
                  
                </Link>

                {/* A retirer console.log() */}
              </div>
            </Form.Field>
            <Form.Field>
              <label className={styles.formLabel}>
                Libellé de pointeur filière
              </label>
              <div>
                <input
                  disabled
                  value={details.foodchain}
                  className={styles.formInput}
                />
              </div>
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field>
              <label className={styles.formLabel}>Code Contrat</label>
              <div>
                <input
                  disabled
                  value={details.name}
                  className={styles.formInput}
                />
              </div>
            </Form.Field>
            <Form.Field>
              <label className={styles.formLabel}>Occupation Sol ID</label>
              <div style={{ border: "none" }}>
                <input
                  disabled
                  value={details.parcel}
                  className={styles.formInput}
                />

                <div></div>
              </div>
            </Form.Field>
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  };

  /**
   * Affichage de la fênetre modale
   * Code à refactoriser
   */

  const renderCritereList = () => {
    const tab = [];
    let keyDetailsCritere = "";
    for (const [key, value] of Object.entries(obj)) {
      const detailResults = JSON.stringify(value, null, 5).toString();
      const detailsCritere = JSON.parse(detailResults).messages;

      const ind = {
        name: key,
        data: JSON.parse(detailResults),
        messages: detailsCritere,
      };

      tab.push(ind);
    }

    return tab.map((k, index: any) => (
      <div>
        <div className={styles.tabs} key={index}>
          <div className={styles.tab}>
            {/* <input className={styles.checked} type="checkbox" id={index} />
            <div className={styles.cardIndicator}>
              <label className={styles.contentCard} htmlFor={index} >
                <div className={styles.indicator}>
                  {k.data.criticality === "major" ? (
                    <label className={styles.criticalMAJ}>MAJ</label>
                  ) : k.data.criticality === "minor" ? (
                    <label className={styles.criticalMIN}>MIN</label>
                  ) : k.data.criticality === "neutral" ? (
                    <label className={styles.criticalNEU}>IND</label>
                  ) : null}
                </div>
                <strong className={styles.indicatorContent}>{k.name}</strong>
                <div className={styles.indicatorButton}>
                  {k.data.conformity === true ? (
                    <label className={styles.compliant}>CONFORME</label>
                  ) : k.data.conformity === false ? (
                    <label className={styles.nonCompliant}>NON CONFORME</label>
                  ) : (
                    <label className={styles.noneCompliant}>PAS CONCERNE</label>
                  )}
                  <Chevron className={`${rotate}`} width={14} fill={"#0A405D"} />
                </div>
              </label>
            </div>
            <div className={styles.tabContent}>
              <div style={{ cursor: "pointer", paddingBottom: "2.5%" }}>
                <strong style={{ fontSize: "11px" }}>Résumé : </strong>
                <span style={{ color: "#97B6E3", fontSize: "11px" }}>
                  {k.data.comment}
                </span>
              </div>
              <div>
                {k.messages !== undefined ? (
                  <hr
                    style={{ background: "#dfe6e9", margin: "0 50% 25px 0" }}
                  />
                ) : null}
                {k.messages !== undefined ? (
                  <div style={{ cursor: "pointer" }}>
                    <strong style={{ fontSize: "11px" }}>
                      Détails du résultat :{" "}
                    </strong>
                    {k.messages &&
                      k.messages.map((message: string) => (
                        <p style={{ color: "#97B6E3", fontSize: "11px" }}>
                          {message}
                        </p>
                      ))}
                  </div>
                ) : null}
              </div>
            </div> */}
            <Accordion criticality={k.data.criticality} name={k.name} conformity={k.data.conformity}>
              <strong style={{ fontSize: "11px" }}>Résumé : </strong>
              <span style={{ color: "#97B6E3", fontSize: "11px" }}>
                {k.data.comment}
              </span>
              {k.messages !== undefined ? (
                <hr style={{ background: "#dfe6e9", margin: "0 50% 25px 0" }} />
              ) : null}
              {k.messages !== undefined ? (
                <div style={{ cursor: "pointer" }}>
                  <strong style={{ fontSize: "11px" }}>
                    Détails du résultat :{" "}
                  </strong>
                  {k.messages &&
                    k.messages.map((message: string) => (
                      <p style={{ color: "#97B6E3", fontSize: "11px" }}>
                        {message}
                      </p>
                    ))}
                </div>
              ) : null}
            </Accordion>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <React.Fragment>
      <div className="wrapDataContract">
        {/* Form critère */}
        {renderForm()}

        {/* details critère */}
        <div>
          <div className={styles.mainWrapper} style={{ marginTop: "75px" }}>
            {renderCritereList()}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const ContractResultsPage = contractEnhancer(InnerContractResultsPage);