import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SpecificationSwapProps,
} from './specification-swap-type';
//import styles from './specification-swap-component.module.css';
//import { Input, Button } from 'semantic-ui-react';
import { Button } from 'semantic-ui-react';
import { SpecificationList } from '../../list';
// import { SpecificationList } from '../../list';

export const SpecificationSwap: React.FC<SpecificationSwapProps> = ({
  listLeft, listRight, handleListLeft, handleListRight, specifications
}: SpecificationSwapProps) => {
  const { t } = useTranslation();

  const [selectedLeft, setSelectedLeft] = React.useState<number[]>([]);
  const [selectedRight, setSelectedRight] = React.useState<number[]>([]);

  const handleMoveToRight = () => {
    handleListRight(selectedLeft);
    setSelectedLeft([]);
  }

  const handleMoveToLeft = () => {
    handleListLeft(selectedRight);
    setSelectedRight([]);
  }

  const handleSelect = (pk: number, left: boolean) => {
    if (left) {
      if (selectedLeft.includes(pk)) {
        setSelectedLeft(selectedLeft.filter(el => el !== pk));
      } else {
        setSelectedLeft([...selectedLeft, pk])
      }
    } else {
      if (selectedRight.includes(pk)) {
        setSelectedRight(selectedRight.filter(el => el !== pk));
      } else {
        setSelectedRight([...selectedRight, pk])
      }
    }
  }


  return (
    <div>
      <div style={{backgroundColor: 'blue'}}>
        <SpecificationList 
          hidden={listRight} 
          selected={selectedLeft} 
          handleSelect={(pk: number) => handleSelect(pk, true)}
          specifications={specifications}
          />
      </div>

      <Button onClick={handleMoveToRight}>MOVE LEFT TO RIGHT</Button>
      <Button onClick={handleMoveToLeft}>MOVE RIGHT TO LEFT</Button>

      <div style={{backgroundColor: 'green'}}>
        <SpecificationList 
          displayed={listRight} 
          selected={selectedRight} 
          handleSelect={(pk: number) => handleSelect(pk, false)} 
          specifications={specifications}
          />
      </div>
    </div>
  );
};
