import React from "react";
import { SpecificationResultsPageProps } from "./specification-results-page-type";
import styles from "./specification-results-page.module.css";
import { enhancer } from "./resultEnhancer";
import { SpecificationResultsHeader } from "./specification-results-header";
import { SpecificationTabHeader } from "../../constants/tab-header-contract";
import { SpecificationTabPanel } from "../../components/tab-panel-contract";
import { resultsSubService } from "../../services";
import { useParams } from "../../hooks/use-params-contract";

export const InnerSpecificationResultsPage: React.FC<SpecificationResultsPageProps> = ({
  results,
  pk
}: SpecificationResultsPageProps) => {
  const specification = results?.results[0].specifications;
  const {
    params,
    header,
    manageOrdering,
    manageSearch,
  } = useParams(SpecificationTabHeader, { specification_pk:pk })

  if (!results) {
    return <div>Pas de résultat</div>;
  }
  return (
    <div className={styles.mainWrapper} style={{ paddingTop: "1vh" }}>
      <SpecificationResultsHeader
        results={results}
        pk={pk}
        manageSearch={manageSearch}
      />

      <div className={styles.resultsWrapper}>
        <SpecificationTabPanel
          limit={100}
          service={resultsSubService.setResultsLight}
          initHeader={SpecificationTabHeader}
          manageSearch={manageSearch}
          manageOrdering={manageOrdering}
          header={header}
          params={params}
        />
      </div>
    </div>
  );
};

export const SpecificationResultsPage = enhancer(InnerSpecificationResultsPage);
