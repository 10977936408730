import { backendTokenService, securedRestClient } from '../../backend';
import {
  Flow, GetFlow, GetAllFlows, AllFlows, GetFlowAlert, FlowAlert, ApiList, FlowWrite,
} from '../../models';
import { buildUrlParameters, formatTechDate, formatISODate } from '../../utils';

export const flowSubService = {
  getFlow: async ({ id }: GetFlow) => {
    // console.log(
    //   'ecosystem service, getFlow, id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/tracking/flows/${id}`;
    const response = await securedRestClient.get<Flow>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  getAllFlows: async ({
    ordering, limit, offset, actor_receiver, actor_sender, audit, batch_in, batch_out, flow_date, name, specifications, step,
  }: GetAllFlows) => {
    // console.log(
    //   'ecosystem service getAllFlows, limit : ', limit,
    //   'offset', offset,
    //   'ordering', ordering,
    //   'actor_receiver', actor_receiver,
    //   'actor_sender', actor_sender,
    //   'audit', audit,
    //   'batch_in', batch_in,
    //   'batch_out', batch_out,
    //   'flow_date', flow_date,
    //   'specifications', specifications,
    //   'name', name,
    //   'step', step,
    // );

    const params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'actor_receiver', value: actor_receiver },
      { prop: 'actor_sender', value: actor_sender },
      { prop: 'audit', value: audit === 'all' ? '' : audit },
      { prop: 'batch_in', value: batch_in },
      { prop: 'batch_out', value: batch_out },
      { prop: 'flow_date', value: flow_date },
      { prop: 'name', value: name },
      { prop: 'specifications', value: specifications },
      { prop: 'step', value: step },
      { prop: 'ordering', value: ordering },
    ];

    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/tracking/flows/?${paramUrl}`;
    const response = await securedRestClient.get<AllFlows>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  editFlowDocument: async (flow: FlowWrite) => {
    // console.log(
    //   'ecosystem service putcertificateDocument, certificate : ',
    //   certificate,
    // );
    // console.log('edit flow doc', flow);
    const formData = new FormData();
    if (flow.attached_file) {
      // console.log('flow attached', flow.attached_file)
      formData.append('attached_file', flow.attached_file, flow.attached_file.name);
    }

    // console.log('form data :', formData);

    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/tracking/flows/${flow.pk}`;
    const response = await securedRestClient.patch<Flow>(
      requestUrl,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    // console.log('réponse', response.data);
    return response.data;
  },

  updateFlow: async (flow: Flow) => {
    // console.log(
    //   'flow alert service, update Flow, flow : ',
    //   flow,
    // );
    // console.log('updateFlow', flow.attached_file);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/tracking/flows/${flow.pk}`;
    const proprerFlow = {
      ...flow,
      flow_date: flow.flow_date ? formatISODate(flow.flow_date) : undefined
    }
  const response = await securedRestClient.patch<Flow>(requestUrl, {...proprerFlow});
    // console.log(response.data);
    return response.data;
  },

  flowTraceability: async (id: number, flowList: string) => {
    // console.log(
    //   'flow alert service, traceabiliity flow id : ',
    //   id, flowList
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/alerts/retrack`;
    const params = {
      flow_orchestrator_pk: id,
      flows_list: flowList
    }
    const response = await securedRestClient.post<Flow>(requestUrl, params);
    // console.log(response.data);
    return response.data;
  },

  getFlowAlert: async ({ id }: GetFlowAlert) => {
    // console.log(
    //   'ecosystem service, getFlowAlert, id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/alerts/flow_alert/?flow_orchestrator=${id}`;
    const response = await securedRestClient.get<ApiList<FlowAlert[]>>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  replaceEcosystemInFlow: async (ecosystemOld:number, ecosystemNew:number, ecosystemElement:string) => {
    // console.log(
    //   'flow service, replaceEcosystemInFlow : ',
    //   'ecosystemOld', ecosystemOld,
    //   'ecosystem New', ecosystemNew,
    //   'ecosystem Element', ecosystemElement
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/replace_placeholder`;
    const params = {
      pk1: ecosystemOld,
      pk2: ecosystemNew,
      model: ecosystemElement
    }
    const response = await securedRestClient.post<ApiList<FlowAlert[]>>(requestUrl, params);
    // console.log(response.data);
    return response.data;
  }
};
