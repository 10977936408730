import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./specification-results-page.module.css";
import { SpecificationInfocardProps } from "./specification-results-page-type";
import { specificationInfocardEnhancer } from "./resultEnhancer";
import upArrow from "../../../assets/pictos/up-green-arrow.svg";
import neuArrow from "../../../assets/pictos/white-down-arrow.svg";
import downArrow from "../../../assets/pictos/red-down-arrow.svg";
import iconDown from "../../../assets/pictos/iconDown.png";
import iconUp from "../../../assets/pictos/iconUp.png";
import { useParams } from "../../hooks";

const InnerSpecificationInfocard: React.FC<SpecificationInfocardProps> = ({
  specification,
}: SpecificationInfocardProps) => {
  const { t } = useTranslation();
  console.log(specification)

  if (!specification) {
    return <div>Pas de résultat</div>;
  }

  const renderFoodchainVariation = () => {
    return specification.variation.map((variation, index) => (
      <div key={index}>{variation.variation_pp}</div>
    ));
  };

  return (
    <div className={styles.middleHeader}>
      <div className={styles.infoCard}>
        <div>
          <div className={styles.infoTitle}>
            <strong>{t("specificationsResults.compliantRate")}</strong> <br />
          </div>

          <div className={styles.cardText}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong>
              {specification.compliance_proportion >
              specification.last_month_compliance
                .last_month_compliance_proportion ? (
                <img src={upArrow} alt="imglogo" className={styles.logoSvg} />
              ) : specification.compliance_proportion <
                specification.last_month_compliance
                  .last_month_compliance_proportion ? (
                <img src={downArrow} alt="imglogo" className={styles.logoSvg} />
              ) : specification.compliance_proportion ===
                specification.last_month_compliance
                  .last_month_compliance_proportion ? (
                <img src={neuArrow} alt="imglogo" className={styles.logoSvg} />
              ) : (
                ""
              )}
              {specification.compliance_proportion}%
            </strong>
            &nbsp;&nbsp; <strong>{specification.compliant_contracts}</strong>
            <strong className={styles.smallText}>
              {" "}
              {t("specificationsResults.compliant.simple")} &nbsp;
              {t("specificationsResults.outOf")} {specification.total_contracts}
            </strong>{" "}
            {/* <strong> {foodchain.total_contracts}</strong> */}
          </div>
          <p className={styles.cardTextSub}>
            {specification.last_month_compliance
              .last_month_compliance_proportion &&
            specification.last_month_compliance.timedelta_days &&
            specification.last_month_compliance
              .last_month_compliant_contracts &&
            specification.last_month_compliance.timedelta_days ? (
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; vs.{" "}
                {
                  specification.last_month_compliance
                    .last_month_compliance_proportion
                }
                % {t("specificationsResults.atDay")}
                {specification.last_month_compliance.timedelta_days}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                vs.{" "}
                {
                  specification.last_month_compliance
                    .last_month_compliant_contracts
                }{" "}
                {t("specificationsResults.atDay")}
                {specification.last_month_compliance.timedelta_days}
              </>
            ) : null}
          </p>
        </div>
      </div>
      {/* <div className={`${styles.infoCard} ${styles.infoCardInd}`}> */}
      <div className={styles.card}>
        <div>
          <div className={styles.infoTitleCard}>
            <strong>{t("specificationsResults.raking")} </strong>
          </div>
          

          <div className={styles.globalBloc}>
            <div className={styles.blocUp}>
              <div className={styles.blocImage}>
                <img
                  src={iconUp}
                  alt="iconUp"
                  className={styles.logoSvgThumb}
                />
              </div>
              <div className={styles.blocContentLeft}>
                <div>
                  {specification.major_indicators_ranking.largest_compliant_indicators.map(
                    (indicator, i) => (
                      <div className={styles.indicatorTitle} key={i}>
                        {" "}
                        {indicator.charAt(0).toUpperCase() +
                          indicator.slice(1)}{" "}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className={styles.blocDown}>
              <div className={styles.blocImage}>
                <img
                  src={iconDown}
                  alt="iconDown"
                  className={styles.logoSvgThumb}
                />
              </div>
              <div className={styles.blocContentRight}>
                <div>
                  {specification.major_indicators_ranking.largest_not_compliant_indicators.map(
                    (indicator, i) => (
                      <div className={styles.indicatorTitle} key={i}>
                        {" "}
                        {indicator.charAt(0).toUpperCase() +
                          indicator.slice(1)}{" "}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.infoCard}>
        <div>
          <strong>TOTAL NON CONFORMES MINEURS</strong>
        </div>
        <div>
          <div>
            <h3>
              {foodchain.not_compliant_contracts_min}/
              {foodchain.total_contracts}
            </h3>
          </div>
          <div>
            <strong>{foodchain.variation_not_compliant_minor}</strong>
            111
          </div>
        </div>
      </div> */}
    </div>
  );
};

export const SpecificationInfocard = specificationInfocardEnhancer(
  InnerSpecificationInfocard
);
