import React, {useState} from 'react'
import { DropdownBtnProps, DropdownItemProps } from '../DropdownFilterBtn/dropdown-filter-type'
import './dropdown-filter-btn.css'
import { FilterButton } from '..'

const DropdownFilterBtn = ({
    onDropdownItemClick, itemElement, title, 
}: DropdownBtnProps) => {

    const [open, setOpen] = useState<boolean>(false)

    const DropdownItem = ({
        children, onClick
    }: DropdownItemProps) => {
        return (
            <div className="menu-item" onClick={onClick}>
                {children}
            </div>
        )
    }
    const styleBtn = {
        borderBottomRightRadius: '0%',
        borderBottomLeftRadius: '0%',
    }
    

    return (
    <div className='dropdown-container' onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        <FilterButton text={title} style={open ? styleBtn : {}} />
        {open && 
        <div className="dropdown"  >
             {itemElement.map((e: string) => (
                <DropdownItem key={e} onClick={() => onDropdownItemClick(e)}>{e}</DropdownItem>
            ))}
        </div>}
    </div>
    )
}

export default DropdownFilterBtn
