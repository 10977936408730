import React from 'react';

import styles from './new-certificate.module.css';
import { WizardLayout } from '../../../../layouts';
import { CertificateWrite } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';
import { CertificateWizard } from '../../../../components/wizards';

export const EcosystemNewCertificatePage: React.FC = () => {
  const certificate: CertificateWrite = {
    name: '',
    internal_code: '',
    audit_date: new Date(),
    certificate_type: '',
    start_date: new Date(),
    end_date: new Date(),
    activation_date: new Date(),
    product_certified: [],
    tool_certified: [],
    site_certified: [],
    owner: -1,
    still_valid: false,
    category: ''
  };
  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.wizardWrapper}>
        <CertificateWizard initialCertificate={certificate} />
      </div>
    </WizardLayout>
  );
};
