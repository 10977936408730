import React, { useCallback } from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import { Form, Input, Icon } from 'semantic-ui-react';
import ReactInputMask from 'react-input-mask';

import { useTranslation } from 'react-i18next';
import { InputControlProps } from './input-control-type';

import styles from './input-control-component.module.css';

export const InputControl: React.FC<InputControlProps> = ({
  name,
  required,
  pattern,
  type,
  hint,
  equalValue,
  equalValueMessage,
  notEqualValue,
  notEqualValueMessage,
  arrayContext,
  mask,
  onChange,
  className,
  noPaste,
  maxLength,
  inline,
  icon,
  ...rest
}: InputControlProps) => {
  const { t } = useTranslation();
  const validate = useCallback(
    (value: string): string | undefined => {
      let result;
      if (!value && !!required) {
        result = t('form.utils.required');
      } else if (!!value && !!pattern) {
        if (pattern.test(value) === true) {
          result = undefined;
        } else if (!pattern.test(value)) {
          result = t('form.utils.pattern');
        }
      }

      if (!result) {
        if (!!value && !!equalValue && value !== equalValue) {
          result = equalValueMessage || t('form.utils.equal');
        } else if (!!value && !!notEqualValue && value === notEqualValue) {
          result = notEqualValueMessage || t('form.utils.notEqual');
        }
      }
      return result;
    },
    [
      required,
      pattern,
      equalValue,
      equalValueMessage,
      notEqualValue,
      notEqualValueMessage,
    ],
  );

  const getErrorMessage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (form: FormikProps<any>) => {
      if (!form || !form.errors || !form.touched) {
        return undefined;
      }

      let formError;
      let formTouched;
      if (arrayContext) {
        const nestedErrors = form.errors[arrayContext.parentFieldName]
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (form.errors[arrayContext.parentFieldName] as any)[
            arrayContext.index
          ]
          : null;

        if (nestedErrors) {
          formError = nestedErrors[arrayContext.fieldName];
        }
        const nestedTouched = form.touched[arrayContext.parentFieldName]
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (form.touched[arrayContext.parentFieldName] as any)[
            arrayContext.index
          ]
          : null;

        if (nestedTouched) {
          formTouched = nestedTouched[arrayContext.fieldName];
        }
      } else {
        formError = form.errors[name];
        formTouched = form.touched[name];
      }

      if (formTouched && formError) {
        return formError;
      }

      return undefined;
    },
    [name, arrayContext],
  );

  return (
    <Field required name={name} validate={validate}>
      {({ field, form }: FieldProps) => {
        const errorMessage = getErrorMessage(form);
        return (
          <Form.Field required={required} className={className}>
            {hint && <div className={styles.hint}>{hint}</div>}
            <Input
              className={inline ? styles.inline : styles.input}
              type={type || 'text'}
              {...rest}
              name={field.name}
              value={name ==='capacity' && field.value === 0 ? '' : field.value}
              icon={icon && <Icon name="write" className={styles.icon} />}
              onBlur={field.onBlur}
              onPaste={(e: React.ClipboardEvent) => {
                if (noPaste) e.preventDefault();
              }}
              onChange={(event, data) => {
                field.onChange(event);
                if (onChange) {
                  onChange(event, data);
                }
              }}
              maxLength={maxLength}
            >
              {!mask ? null : (
                <ReactInputMask mask={mask} {...field} {...rest} />
              )}
            </Input>
            {errorMessage && <div className={styles.error}>{errorMessage}</div>}
          </Form.Field>
        );
      }}
    </Field>
  );
};
