import * as React from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./tool-wizard-component.module.css";
import { ToolWizardProps } from "./tool-wizard-type";
import { ToolWrite, ToolRead } from "../../../models";
import { toolSubService } from "../../../services";
import { ToolForm, OwnerForm, ListSiteForm } from "../../forms";
import checkPictoWhite from "../../../../assets/pictos/checkPictoWhite.png";
import toolPictoBlue from "../../../../assets/pictos/toolPictoBlue.svg";
import trashPictoRed from "../../../../assets/pictos/trashPictoRed.png";
import editPictoWhite from "../../../../assets/pictos/editPictoWhite.png";
import { toastHandler } from "../../../utils";
import { WizardLoader } from "../utils";
import { redirectWithUrl } from "../utils/redirect";

export const ToolWizard: React.FC<ToolWizardProps> = React.memo(
  ({ edit, initialTool, initialStep }: ToolWizardProps) => {
    const [editedTool, setEditedTool] = React.useState<
      Omit<ToolWrite, "owner">
    >({ ...initialTool, is_placeholder: false });
    const [editedOwner, setEditedOwner] = React.useState<number[]>([
      initialTool.owner,
    ]);
    const [activeStep, setActiveStep] = React.useState(
      initialStep ? initialStep : 0
    );
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();

    const nextStep = () => {
      setActiveStep(
        activeStep === step.length - 1 ? step.length - 1 : activeStep + 1
      );
    };

    const previousStep = () => {
      setActiveStep((activeStep || 1) - 1);
    };

    const goTo = (index: number) => {
      setActiveStep(index);
    };

    const manageDeleteTool = async () => {
      if (editedTool.pk && editedTool.pk !== -1) {
        setLoading(true);
        try {
          const result = toolSubService.deleteTool(editedTool.pk);
          setLoading(false);
          toastHandler(t("wizard.tools.deleteSuccess"), "success");
          history.push({
            pathname: redirectWithUrl(location.search, "/ecosystem"),
            state: location.state,
          });
        } catch (err) {
          console.log(err);
          setLoading(false);
          toastHandler(t("wizard.tools.deleteError"), "error");
        }
      }
    };

    const manageCancel = () => {
      history.push({
        pathname: editedTool.pk
          ? redirectWithUrl(
              location.search,
              `/ecosystem/tools/${editedTool.pk}`
            )
          : redirectWithUrl(location.search, "/ecosystem"),
        state: location.state,
      });
    };

    const onNextOwner = (owner: number[]) => {
      if (owner !== editedOwner) {
        setEditedTool({ ...editedTool, site: [] });
      }
      setEditedOwner(owner);
      nextStep();
    };

    const onNextTool = (tool: Omit<ToolWrite, "owner">) => {
      setEditedTool({ ...editedTool, ...tool });
      nextStep();
    };

    const onSaveOwner = async (owner: number[]) => {
      if (owner !== editedOwner) {
        setEditedTool({ ...editedTool, site: [] });
      }
      setEditedOwner(owner);
      try {
        await onSaveGlobalTool(owner, {
          ...editedTool,
          site: owner !== editedOwner ? [] : editedTool.site,
        });
        nextStep();
      } catch (err) {
        console.log("err: ", err);
      }
    };

    const onSaveTool = async (tool: Omit<ToolWrite, "owner">) => {
      setEditedTool(tool);
      try {
        const result = await onSaveGlobalTool(editedOwner, tool);
        result && setEditedTool(result);
        nextStep();
      } catch {
        setError(t('table.utils.error'));
      }
    };

    const onSaveSite = async (site: number[]) => {
      console.log("on save site");
      setEditedTool({ ...editedTool, site });
      try {
        const result = await onSaveGlobalTool(editedOwner, {
          ...editedTool,
          site,
        });
        result &&
          history.push({
            pathname: redirectWithUrl(
              location.search,
              `/ecosystem/tools/${result.pk}`
            ),
            state: location.state,
          });
      } catch {
        setError(t('table.utils.noResult'));
      }
    };

    const onSaveGlobalTool = async (
      owner: number[],
      tool: Omit<ToolWrite, "owner">
    ) => {
      setLoading(true);
      try {
        if (owner.length > 0 && owner[0] !== -1) {
          const result: ToolRead =
            editedTool.pk && editedTool.pk !== -1
              ? await toolSubService.editTool({
                  ...editedTool,
                  ...tool,
                  owner: owner[0],
                })
              : await toolSubService.createTool({ ...tool, owner: owner[0] });
          toastHandler(t("wizard.tools.saveSuccess"), "success");
          setLoading(false);
          return result;
        }
        setLoading(false);
        setError("il manque un proprietaire");
      } catch {
        setLoading(false);
        toastHandler(t("wizard.tools.saveError"), "error");
        setError(t('table.utils.error'));
      }
    };

    const step = [
      {
        index: 0,
        label: t("wizard.tools.step2"),
        picto: toolPictoBlue,
        Component: editedOwner ? (
          <OwnerForm
            model={editedOwner}
            onNext={(model) => onNextOwner(model)}
            onSave={
              editedTool.pk && editedTool.pk !== -1
                ? (model) => onSaveOwner(model)
                : undefined
            }
            errorMessage={error}
            onCancel={manageCancel}
            include="tools"
            blocking
            specificParams={{ ordering: "name" }}
            multiple={true}
          />
        ) : (
          <div>pas d'acteur id</div>
        ),
      },
      {
        index: 1,
        label: t("wizard.tools.step1"),
        picto: toolPictoBlue,
        Component: (
          <ToolForm
            model={editedTool}
            onSave={(model) => onSaveTool(model)}
            errorMessage={error}
            onCancel={manageCancel}
            onNext={
              editedTool.pk && editedTool.pk !== -1
                ? (model) => onNextTool(model)
                : undefined
            }
            onPrevious={previousStep}
          />
        ),
      },
      {
        index: 2,
        label: t("wizard.tools.step3"),
        picto: toolPictoBlue,
        Component: editedOwner ? (
          <ListSiteForm
            model={editedTool.site}
            onSave={(model) => onSaveSite(model)}
            errorMessage={error}
            onCancel={manageCancel}
            onPrevious={previousStep}
            specificParams={{
              owner: editedOwner[0].toString(),
              ordering: "name",
            }}
            include="tools"
            multiple
          />
        ) : (
          <div>pas d'acteur id</div>
        ),
      },
    ];

    return (
      <div className={styles.wizard}>
        <div className={styles.form}>
          <div className={styles.activeStep}>
            <div className={styles.header}>
              <div className={styles.titlePictoContainer}>
                <img
                  src={step[activeStep].picto}
                  className={styles.titlePicto}
                />
              </div>
              <div className={styles.titleLabel}>
                <b>{step[activeStep].label}</b>
              </div>
              {editedTool.pk && editedTool.pk !== -1 && (
                <div
                  className={styles.deleteContainer}
                  onClick={manageDeleteTool}
                >
                  <div className={styles.trashPictoContainer}>
                    <img src={trashPictoRed} className={styles.trashPicto} />
                  </div>
                  <div className={styles.deleteLabel}>
                    {t("wizard.tools.delete")}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`${styles.formWrapper} ${
                loading ? styles.blured : ""
              }`}
            >
              {loading && <WizardLoader />}
              {step[activeStep].Component}
            </div>
          </div>
        </div>
        <div className={styles.track}>
          {step.map((el) =>
            activeStep === el.index ? (
              <div
                className={`${styles.container} ${styles.activeContainer}`}
                key={el.index}
              >
                <div className={`${styles.badge} ${styles.activeBadge}`}>
                  {el.index + 1}
                </div>
                <div className={`${styles.label} ${styles.activeLabel}`}>
                  {el.label}
                </div>
              </div>
            ) : editedTool.pk ? (
              <div
                className={`${styles.container} ${styles.editContainer}`}
                key={el.index}
                onClick={() => goTo(el.index)}
              >
                <div className={`${styles.badge} ${styles.editBadge}`}>
                  <img src={editPictoWhite} className={styles.editPicto} />
                </div>
                <div className={`${styles.label} ${styles.editLabel}`}>
                  {el.label}
                </div>
              </div>
            ) : activeStep > el.index ? (
              <div
                className={`${styles.container} ${styles.doneContainer}`}
                key={el.index}
              >
                <div className={`${styles.badge} ${styles.doneBadge}`}>
                  <img src={checkPictoWhite} className={styles.checPicto} />
                </div>
                <div className={`${styles.label} ${styles.doneLabel}`}>
                  {el.label}
                </div>
              </div>
            ) : (
              <div
                className={`${styles.container} ${styles.todoContainer}`}
                key={el.index}
              >
                <div className={`${styles.badge} ${styles.todoBadge}`}>
                  {el.index + 1}
                </div>
                <div className={`${styles.label} ${styles.todoLabel}`}>
                  {el.label}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
);
