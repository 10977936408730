import {
  WithStatelessDatasFetchingByParameters,
  WithQuerystringReading,
} from "../../hocs";
import {
  ApiList,
  GetResults,
  ResultsLight,
  SpecificationInfos,
  GetSpecificationInfo,
} from "../../models";
import {
  SpecificationResultsPageProps,
  SpecificationInfocardProps,
} from "./specification-results-page-type";
import { resultsSubService, specificationSubService } from "../../services";
import { PageLoader } from "../../components";
import React from "react";
import { compose } from "redux";

export const enhancer = compose<React.FC<SpecificationResultsPageProps>>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<
    ApiList<ResultsLight[]>,
    SpecificationResultsPageProps,
    GetResults
  >(resultsSubService.getSpecificationResultsLight, PageLoader, "redirect", "results", [
    "pk",
  ])
);

export const specificationInfocardEnhancer = compose<
  React.FC<SpecificationInfocardProps>
>(
  WithStatelessDatasFetchingByParameters<
    SpecificationInfos,
    SpecificationInfocardProps,
    GetSpecificationInfo
  >(specificationSubService.getSpecificationInfos, PageLoader, "redirect", "specification", [
    "pk",
  ])
);
