import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CertificateDocumentFormProps,
  CertificateDocumentProps,
} from './certificate-document-form-type';
import styles from './certificate-document-form.module.css';
import { FileInputControl } from '../form-controls';
import { ClassicButton } from '../../buttons';
import { AuthenticatedContext } from '../../../contexts';

export const CertificateDocumentForm: React.FC<CertificateDocumentFormProps> = ({
  model,
  onNext,
  onSave,
  onPrevious,
  errorMessage,
  onCancel,
  onDelete,
  currentDoc,
}: CertificateDocumentFormProps) => {
  const initialDoc = currentDoc;
  const initialName = initialDoc && initialDoc.split('/');
  const { t } = useTranslation();
  const authenticatedContext = React.useContext(AuthenticatedContext);

  const [imageName, setImageName] = React.useState<string>(initialName ? initialName[initialName.length - 1] : '');
  const [imageUrl, setImageUrl] = React.useState<string | undefined>(currentDoc && authenticatedContext.azure ? `${currentDoc}?${authenticatedContext.azure}` : '');
  return (
    <>
      <Formik<CertificateDocumentProps>
        onSubmit={onSave}
        initialValues={model}
        render={(props) => (
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.formContainer}>
              {model && (
              <div className={styles.formLine}>
                <div className={styles.inputDocumentWrapper}>
                  <div className={styles.title}>
                    <b>{t('form.certificates.document')}</b>
                  </div>
                  <div className={styles.inputWrapper}>
                    <FileInputControl
                      name="fileSelector"
                      placeholder="Visuel de l'objet à télécharger"
                      accept="application/pdf, image/jpeg, image/png"
                      acceptHint={t('form.certificates.hint')}
                      onChange={(event) => {
                        if (
                          event
                              && event.target
                              && event.target.files
                              && event.target.files.length > 0
                        ) {
                          const { target } = event;
                          const { files } = target;
                          props.setFieldValue(
                            'document',
                            files && files.length > 0 ? files[0] : undefined,
                          );
                          // console.log('files : ', event.target.files)
                          setImageName(event.target.files[0].name);
                          setImageUrl(URL.createObjectURL(event.target.files[0]))
                        }
                      }}
                    />
                  </div>
                  {imageName.endsWith('pdf') ? 
                  <iframe src={imageUrl} width='400px' height='400px' className={styles.previewImage}/>
                  :
                  <img src={imageUrl} className={styles.previewImage}/>  
                } 
                  {imageName
                    ? (
                      <div className={styles.result}>
                        {imageName}
                      </div>
                    )
                    : <div className={styles.result}>&nbsp;</div>}
                </div>
              </div>
              )}
            </div>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
                    onCancel
                      ? <ClassicButton text={t('wizard.certificates.cancel')} onClick={onCancel} warning />
                      : <div>&nbsp;</div>
                  }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
                    onPrevious
                      ? <ClassicButton text={t('wizard.certificates.back')} onClick={onPrevious} inverted />
                      : <div>&nbsp;</div>
                  }
                </div>
                <div className={styles.nextNavigation}>
                  {
                    onNext
                      ? <ClassicButton type="submit" text={t('wizard.certificates.next')} inverted />
                      : <div>&nbsp;</div>
                  }

                </div>
              </div>
              <div className={styles.submitActions}>
                 <ClassicButton type="submit" text={t('wizard.certificates.submit')} />
              </div>
            </div>
          </form>
        )}
      />
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};
