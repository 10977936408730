import React, { SetStateAction } from 'react';
import styles from './alert-row.module.css';
import { FlowAlert, AlertContextCategory, Flow, AlertLight } from '../../../models';
import { Table, Checkbox } from 'semantic-ui-react';
import { StringCell } from './cell-string';
import { DateCell } from './cell-date';
import { BatchCell } from './cell-batch';
import { EcosystemCell } from './cell-ecosystem';
import { AuditCell } from './cell-audit';
import { modifiedValue } from '../utils';
import searchPicto from '../../../../assets/pictos/searchPictoBlue.png';
import { ClickableContainer } from '../../../components';


export interface RowAlertProps {
  initialFlow?: Flow;
  flow: Flow;
  fields_in: (keyof Flow)[]
  batch_in_fields: any[];
  batch_out_fields: any[];
  editFlow: (flow: FlowAlert | Flow) => void;
  filter?: AlertContextCategory;
  saveFlow: (pk:number, flow?:FlowAlert | Flow) => Promise<void>;
  open?: boolean;
  alertSet?: AlertLight[];
  setFlowPk: React.Dispatch<SetStateAction<number[]>>
  flowPk: number[];
  modified: boolean;
  handleGlobalSave: () => Promise<void>;


}

export const RowAlert = ({alertSet, open, saveFlow, filter, initialFlow, flow, fields_in, batch_in_fields, batch_out_fields, editFlow, setFlowPk, flowPk, modified, handleGlobalSave}: RowAlertProps) => {

  const [tempDate, setTempDate] = React.useState<string  | undefined>(undefined);
  const [modifiedDate, setModifiedDate] = React.useState<boolean>(false);
  const enabled: (keyof Flow)[] = [];
  const initializeEnabled = () => {
    alertSet?.filter(alert => alert.subcategory === filter).forEach(alert => {
      if (alert.empty_field) {
        enabled.push(alert.empty_field);
      }
    })
  }
  
  initializeEnabled();

  const editString = (name: keyof Flow, value: string) => {
    editFlow({
      ...flow,
      [name]: value
    })
  }

  React.useEffect(() => {
  }, [tempDate])

  return (
    <Table.Row>
       <Table.Cell collapsing>
        <ClickableContainer
          link={`/tracking/flows/${flow.pk}`}
          className={`${styles.searchPicto} `}
          target='_blank'
        >
          <img src={searchPicto} className={styles.searchPicto}/>
        </ClickableContainer>
      </Table.Cell>
      {
        fields_in.includes('step') && 
        <StringCell 
          messages={ flow.problematic_fields.includes('step') && alertSet? 
            alertSet.filter(alert => alert.empty_field === 'step').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[] 
            : [] } 
          enabled={enabled.includes('step') && flow.problematic_fields.includes('step')}
          initialValue={initialFlow?.step}
          value={flow.step}
          error={flow.problematic_fields.includes('step')}
          editCell={(value: string) => editString('step', value)}
        />
      }
      {
        fields_in.includes('name') && 
        <StringCell 
          messages={ flow.problematic_fields.includes('name')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'name').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('name') && flow.problematic_fields.includes('name')}
          initialValue={initialFlow?.name}
          value={flow.name}
          error={flow.problematic_fields.includes('name')}
          editCell={(value: string) => editString('name', value)}
          
        />
      }
      {
        fields_in.includes('flow_date') && 
        <DateCell 
          messages={ flow.problematic_fields.includes('flow_date')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'flow_date').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('flow_date') && flow.problematic_fields.includes('flow_date')}
          initialValue={initialFlow?.flow_date}
          value={flow.flow_date}
          error={flow.problematic_fields.includes('flow_date')}
          editCell={(value: string) => editString('flow_date', value)}
          setTempDate={setTempDate}
          tempDate={tempDate}
          setModifiedDate={setModifiedDate}
          modifiedDate={modifiedDate}
        />
      }
      {
        fields_in.includes('batch_in') && 
        <BatchCell 
          messages={ flow.problematic_fields.includes('batch_in')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'batch_in').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('batch_in') && flow.problematic_fields.includes('batch_in')}
          initialFlow={initialFlow}
          flow={flow}
          error={flow.problematic_fields.includes('batch_in')}
          editFlow={editFlow}
          batch_fields={batch_in_fields}
          save={(newFlow:Flow) => saveFlow(flow.pk, newFlow)}
          batchIn={true}
          content={alertSet && alertSet.filter(alert => alert.empty_field ==='batch_in')}
        />
      }
      {
        fields_in.includes('batch_out') && 
        <BatchCell 
          messages={ flow.problematic_fields.includes('batch_out')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'batch_out').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('batch_out') && flow.problematic_fields.includes('batch_out')}
          initialFlow={initialFlow}
          flow={flow}
          error={flow.problematic_fields.includes('batch_out')}
          editFlow={editFlow}
          batch_fields={batch_out_fields}
          save={(newFlow:Flow) => saveFlow(flow.pk, newFlow)}
          batchIn={false}
          content={alertSet && alertSet.filter(alert => alert.empty_field ==='batch_in')}
        />
      }
      {
        fields_in.includes('actor_sender') && 
        <EcosystemCell 
          messages={ flow.problematic_fields.includes('actor_sender')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'actor_sender').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('actor_sender') && flow.problematic_fields.includes('actor_sender')}
          initialFlow={initialFlow}
          flow={flow}
          error={flow.problematic_fields.includes('actor_sender')}
          editFlow={editFlow}
          save={() => saveFlow(flow.pk)}
          referentiel="actor_sender"
          open={open}
          setFlowPk={setFlowPk}
          flowPk={flowPk}
          modified={modified}
          handleGlobalSave={handleGlobalSave}
        />
      }
      {
        fields_in.includes('actor_receiver') && 
        <EcosystemCell 
          messages={ flow.problematic_fields.includes('actor_receiver')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'actor_receiver').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('actor_receiver') && flow.problematic_fields.includes('actor_receiver')}
          initialFlow={initialFlow}
          flow={flow}
          error={flow.problematic_fields.includes('actor_receiver')}
          editFlow={editFlow}
          save={() => saveFlow(flow.pk)}
          referentiel="actor_receiver"
          setFlowPk={setFlowPk}
          flowPk={flowPk}
          modified={modified}
          handleGlobalSave={handleGlobalSave}
        />
      }
      {
        fields_in.includes('quantity') && 
        <StringCell 
          messages={ flow.problematic_fields.includes('quantity')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'quantity').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('quantity') && flow.problematic_fields.includes('quantity')}
          initialValue={initialFlow?.quantity}
          value={flow.quantity}
          error={flow.problematic_fields.includes('quantity')}
          editCell={(value: string) => editString('quantity', value)}
        />
      }
      {
        fields_in.includes('unit') && 
        <StringCell 
          messages={ flow.problematic_fields.includes('unit')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'unit').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[] 
            : []} 
          enabled={enabled.includes('unit') && flow.problematic_fields.includes('unit')}
          initialValue={initialFlow?.unit}
          value={flow.unit}
          error={flow.problematic_fields.includes('unit')}
          editCell={(value: string) => editString('unit', value)}
        />
      }
      {
        fields_in.includes('site_sender') && 
        <EcosystemCell 
          messages={ flow.problematic_fields.includes('site_sender')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'site_sender').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('site_sender') && flow.problematic_fields.includes('site_sender')}
          initialFlow={initialFlow}
          flow={flow}
          error={flow.problematic_fields.includes('site_sender')}
          editFlow={editFlow}
          save={() => saveFlow(flow.pk)}
          referentiel="site_sender"
          setFlowPk={setFlowPk}
          flowPk={flowPk}
          modified={modified}
          handleGlobalSave={handleGlobalSave}
        />
      }
      {
        fields_in.includes('tool_sender') && 
        <EcosystemCell 
          messages={flow.problematic_fields.includes('tool_sender')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'tool_sender').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('tool_sender') && flow.problematic_fields.includes('tool_sender')}
          initialFlow={initialFlow}
          flow={flow}
          error={flow.problematic_fields.includes('tool_sender')}
          editFlow={editFlow}
          save={() => saveFlow(flow.pk)}
          referentiel="tool_sender"
          setFlowPk={setFlowPk}
          flowPk={flowPk}
          modified={modified}
          handleGlobalSave={handleGlobalSave}
        />
      }
      {
        fields_in.includes('site_receiver') && 
        <EcosystemCell 
          messages={ flow.problematic_fields.includes('site_receiver')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'site_receiver').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[] 
            : []} 
          enabled={enabled.includes('site_receiver') && flow.problematic_fields.includes('site_receiver')}
          initialFlow={initialFlow}
          flow={flow}
          error={flow.problematic_fields.includes('site_receiver')}
          editFlow={editFlow}
          save={() => saveFlow(flow.pk)}
          referentiel="site_receiver"
          setFlowPk={setFlowPk}
          flowPk={flowPk}
          modified={modified}
          handleGlobalSave={handleGlobalSave}
        />
      }
      {
        fields_in.includes('tool_receiver') && 
        <EcosystemCell 
          messages={ flow.problematic_fields.includes('tool_receiver')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'tool_receiver').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('tool_receiver') && flow.problematic_fields.includes('tool_receiver')}
          initialFlow={initialFlow}
          flow={flow}
          error={flow.problematic_fields.includes('tool_receiver')}
          editFlow={editFlow}
          save={() => saveFlow(flow.pk)}
          referentiel="tool_receiver"
          setFlowPk={setFlowPk}
          flowPk={flowPk}
          modified={modified}

          handleGlobalSave={handleGlobalSave}
        />
      }
      {
        fields_in.includes('product') && 
        <EcosystemCell 
          messages={flow.problematic_fields.includes('product')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'product').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          enabled={enabled.includes('product') && flow.problematic_fields.includes('product')}
          initialFlow={initialFlow}
          flow={flow}
          error={flow.problematic_fields.includes('product')}
          editFlow={editFlow}
          save={() => saveFlow(flow.pk)}
          referentiel="product"
          setFlowPk={setFlowPk}
          flowPk={flowPk}
          modified={modified}
          handleGlobalSave={handleGlobalSave}
        />
      }
      {
        fields_in.includes('audit') && 
        <AuditCell 
          messages={flow.problematic_fields.includes('audit')&& alertSet ?
            alertSet.filter(alert => alert.empty_field === 'audit').map(alert => alert.alert_title).filter(message => message  !== undefined) as string[]
            : []} 
          flow={flow}
        />
      }
    </Table.Row>
  )
}
