import * as React from 'react';

import { useHistory } from 'react-router-dom';
import { Accordion, Responsive } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './logout-component.module.css';
import { AuthenticatedContext } from '../../contexts';
import logoutPicto from '../../../assets/pictos/logoutPicto.png';
import accountPicto from '../../../assets/pictos/accountPictoBlue.png';
import { ClickableContainer } from '../clickable-container';
import { configUI } from '../../../config';
import { LogoutProps } from './logout-type';
import { authSubService } from '../../services';
import { posthogEvents } from '../../utils/posthog';

const storageKey = process.env.REACT_APP_AUTHTOKEN ? process.env.REACT_APP_AUTHTOKEN : '';

export const Logout: React.FC<LogoutProps> = React.memo(({ active, setActive }: LogoutProps) => {
  const authenticatedContext = React.useContext(AuthenticatedContext);
  const history = useHistory();

  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      await authSubService.logout();
      localStorage.removeItem(storageKey);
      authenticatedContext.setAuthenticated(undefined);
      history.push('/');
      setActive(false);
      posthogEvents.logout();
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = async () => {
    posthogEvents.triggerEvent({ name : 'clickOn', datas : { 'header' : 'userName' }});
    setActive(!active);
  };


  if (!authenticatedContext.authenticated) {
    return <></>;
  }
  return (
    <div className={styles.logout}>
      <Accordion>
        <Accordion.Title active={active} index={0} onClick={handleClick}>

          <ClickableContainer className={styles.accountPictoContainer}>
            <div className={styles.accountContainer}>
              <img src={accountPicto} className={styles.accountPicto} />

              <Responsive minWidth={configUI.responsive.largeScreen.minWidth}>
                <div className={styles.accountLibelle}>{authenticatedContext.folder && authenticatedContext.folder.full_name}</div>
              </Responsive>
            </div>
          </ClickableContainer>
        </Accordion.Title>
        <Accordion.Content active={active}>
          <div className={styles.logoutContainer} onClick={handleLogout}>
            <div className={styles.logoutPictoContainer}>
              <img src={logoutPicto} className={styles.logoutPicto} />
            </div>
            <Responsive minWidth={configUI.responsive.largeScreen.minWidth}>
              <div className={styles.logoutLibelle}>{t('menu.logout')}</div>
            </Responsive>
          </div>
        </Accordion.Content>
      </Accordion>
    </div>
  );
});
