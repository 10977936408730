import React, { SetStateAction, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import styles from './alert-table.module.css';
import { FlowAlert, AlertContextCategory, Flow, AlertLight } from '../../../models';
import { Table, Checkbox } from 'semantic-ui-react';
import { RowAlert } from './alert-row';
import { AlertSets } from './alert-fix-types';
import { useLocation } from 'react-router-dom';
import { alertSubService } from '../../../services';

export interface TableAlertProps {
  initialFlows: Flow[];
  flows: Flow[];
  selectedFlows: number[];
  fields_in: (keyof Flow)[]
  batch_in_fields: any[];
  batch_out_fields: any[];
  editFlow: (flow: FlowAlert | Flow) => void;
  filter?: AlertContextCategory;
  saveFlow: (pk:number, flow?:FlowAlert | Flow) => Promise<void>;
  alertSets: AlertSets[];
  setFlowPk: React.Dispatch<SetStateAction<number[]>>
  flowPk: number[];
  modifiedFlows: Flow[];
  handleGlobalSave: ()=> Promise<void>;
}

export const TableAlert = ({alertSets, saveFlow, filter, initialFlows, flows, selectedFlows, fields_in, batch_out_fields, batch_in_fields, editFlow, setFlowPk, flowPk, modifiedFlows, handleGlobalSave}:TableAlertProps) => {
  const {t} = useTranslation();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState<string>()
    const canBeReTrace = flows.filter(el => (el.problematic_fields.length === 0) || el.problematic_fields.includes('batch_in') || el.problematic_fields.includes('batch_out') );

  // const handleSelectAll =  () => {
  //   handleSelect(0, true, !selectAll);
  //   setSelectAll(!selectAll)
  // }
  useEffect(() => {
    const idFlow = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    getErrorMessage(idFlow)
  }, [])
  const getErrorMessage = async(id: any) => {
    const result = await alertSubService.getAlertErrorMessage(id)
    result.data.results.map((e: any) => e.alert_message.includes(t('alerts.alertFix.messageCSM')) 
    ? setErrorMessage(e.alert_message) 
    : setErrorMessage(''))
  }
  
  return (
    <div className={styles.tableWrapper}>
      {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            {
              fields_in.includes('step') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.step')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('name') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.name')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('flow_date') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.flow_date')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('batch_in') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.batch_in')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('batch_out') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.batch_out')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('actor_sender') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.actor_sender')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('actor_receiver') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.actor_receiver')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('quantity') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.quantity')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('unit') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.unit')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('site_sender') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.site_sender')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('tool_sender') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.tool_sender')}
              </div>
            </Table.HeaderCell>
            }
             {
              fields_in.includes('site_receiver') && 
              <Table.HeaderCell>
                 <div>
                {t('table.flow_alerts.site_receiver')}
              </div>
              
            </Table.HeaderCell>
            }
            {
              fields_in.includes('tool_receiver') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.tool_receiver')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('product') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.product')}
              </div>
            </Table.HeaderCell>
            }
            {
              fields_in.includes('audit') && 
              <Table.HeaderCell>
              <div>
                {t('table.flow_alerts.audit')}
              </div>
            </Table.HeaderCell>
            }
            </Table.Row>
          </Table.Header>
        <Table.Body>
        {flows.map(flow => {
          for(let i= 0; i<modifiedFlows.length; i ++){
            const modifiedFlow : Flow = modifiedFlows[i];
            if(modifiedFlow.pk === flow.pk){
              return (<RowAlert
                key={modifiedFlow.pk}
                initialFlow={initialFlows.find(el => el.pk === modifiedFlow.pk)} 
                flow={modifiedFlow}
                fields_in={fields_in}
                batch_in_fields={batch_in_fields}
                batch_out_fields={batch_out_fields}
                editFlow={editFlow}
                filter={filter}
                saveFlow={saveFlow}
                open={(location.state as any) && (location.state as any).open === modifiedFlow.pk}
                alertSet={alertSets.find(el => el.pk === modifiedFlow.pk)?.alertSet}
                setFlowPk={setFlowPk}
                flowPk={flowPk}
                modified={true}
                handleGlobalSave={handleGlobalSave}
                />)
            }
          }
          return (<RowAlert
            key={flow.pk}
            initialFlow={initialFlows.find(el => el.pk === flow.pk)} 
            flow={flow}
            fields_in={fields_in}
            batch_in_fields={batch_in_fields}
            batch_out_fields={batch_out_fields}
            editFlow={editFlow}
            filter={filter}
            saveFlow={saveFlow}
            open={(location.state as any) && (location.state as any).open === flow.pk}
            alertSet={alertSets.find(el => el.pk === flow.pk)?.alertSet}
            setFlowPk={setFlowPk}
            flowPk={flowPk}
            modified={false}
            handleGlobalSave={handleGlobalSave}
            />)
        })}
        </Table.Body>
      </Table>
    </div>
  )
}
