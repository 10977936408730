import { HeaderColumn } from "../models/utils-contract";

export const ResultsHeader: HeaderColumn[] = [
  {
    orderer: "actor",
    searchProp: "actor",
    header: "resultHeader.name",
    searchValue: "",
    searchType: "number",
    columnName: "Code tiers",
    searchCell: true,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "parcel",
    searchProp: "parcel",
    header: "resultHeader.parcel",
    searchValue: "",
    searchType: "number",
    columnName: "Occupation Sol ID",
    searchCell: true,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "total_indicators",
    searchProp: "total_indicators",
    header: "resultHeader.total_indicators",
    searchValue: "",
    searchType: "number",
    columnName: "Total indicateurs",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "total_compliant_indicators",
    searchProp: "total_compliant_indicators",
    header: "resultHeader.total_compliant_indicators",
    searchValue: "",
    searchType: "number",
    columnName: "Total conforme",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },

  {
    orderer: "total_not_compliant_indicators",
    searchProp: "total_not_compliant_indicators",
    header: "resultHeader.total_not_compliant_indicators",
    searchValue: "",
    searchType: "number",
    columnName: "Total non-conforme",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "nb_minor_indicator",
    searchProp: "nb_minor_indicator",
    header: "resultHeader.nb_minor_indicator",
    searchValue: "",
    searchType: "number",
    columnName: "Non-conforme ",
    searchCell: false,
    headerLabelMin: true,
    headerLabelMaj: false,
  },
  {
    orderer: "nb_major_indicator",
    searchProp: "nb_major_indicator",
    header: "resultHeader.nb_major_indicator",
    searchValue: "",
    searchType: "number",
    columnName: "Non-conforme ",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: true,
  },
  {
    orderer: "conformity_proportion_indicator_minor",
    searchProp: "conformity_proportion_indicator_minor",
    header: "resultHeader.conformity_proportion_indicator_minor",
    searchValue: "",
    searchType: "number",
    columnName: "% Conforme ",
    searchCell: false,
    headerLabelMin: true,
    headerLabelMaj: false,
  },
  {
    orderer: "conformity_proportion_indicator_major",
    searchProp: "conformity_proportion_indicator_major",
    header: "resultHeader.conformity_proportion_indicator_major",
    searchValue: "",
    searchType: "number",
    columnName: "% Conforme ",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: true,
  },
  {
    orderer: "conformity",
    searchProp: "conformity",
    header: "resultHeader.conformity",
    searchValue: "",
    searchType: "boolean",
    columnName: "Statut",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
];

export const SpecificationTabHeader: HeaderColumn[] = [
  {
    orderer: "actor",
    searchProp: "actor",
    header: "resultHeader.name",
    searchValue: "",
    searchType: "number",
    columnName: "Code tiers",
    searchCell: true,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "foodchain",
    searchProp: "foodchain",
    header: "resultHeader.foodchain",
    searchValue: "",
    searchType: "string",
    columnName: "Libellé pointeur filière",
    searchCell: true,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "parcel",
    searchProp: "parcel",
    header: "resultHeader.parcel",
    searchValue: "",
    searchType: "date",
    columnName: "Occupation Sol ID",
    searchCell: true,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "audited_at_day",
    searchProp: "audited_at_day",
    header: "resultHeader.audited_at_day",
    searchValue: "",
    searchType: "number",
    columnName: "Dernier contrôle",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "total_indicators",
    searchProp: "total_indicators",
    header: "resultHeader.total_indicators",
    searchValue: "",
    searchType: "number",
    columnName: "Total indicateurs",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "total_compliant_indicators",
    searchProp: "total_compliant_indicators",
    header: "resultHeader.total_compliant_indicators",
    searchValue: "",
    searchType: "number",
    columnName: "Total conforme",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },

  {
    orderer: "total_not_compliant_indicators",
    searchProp: "total_not_compliant_indicators",
    header: "resultHeader.total_not_compliant_indicators",
    searchValue: "",
    searchType: "number",
    columnName: "Total non-conforme",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "nb_minor_indicator",
    searchProp: "nb_minor_indicator",
    header: "resultHeader.nb_minor_indicator",
    searchValue: "",
    searchType: "number",
    columnName: "Non-conforme ",
    searchCell: false,
    headerLabelMin: true,
    headerLabelMaj: false,
  },
  {
    orderer: "nb_major_indicator",
    searchProp: "nb_major_indicator",
    header: "resultHeader.nb_major_indicator",
    searchValue: "",
    searchType: "number",
    columnName: "Non-conforme ",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: true,
  },
  {
    orderer: "conformity_proportion_indicator_minor",
    searchProp: "conformity_proportion_indicator_minor",
    header: "resultHeader.conformity_proportion_indicator_minor",
    searchValue: "",
    searchType: "number",
    columnName: "% Conforme ",
    searchCell: false,
    headerLabelMin: true,
    headerLabelMaj: false,
  },
  {
    orderer: "conformity_proportion_indicator_major",
    searchProp: "conformity_proportion_indicator_major",
    header: "resultHeader.conformity_proportion_indicator_major",
    searchValue: "",
    searchType: "number",
    columnName: "% Conforme ",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: true,
  },
  {
    orderer: "conformity",
    searchProp: "conformity",
    header: "resultHeader.conformity",
    searchValue: "",
    searchType: "boolean",
    columnName: "Statut",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
];

export const ViewRiskHeader : HeaderColumn[] = [
  {
    orderer: "actor",
    searchProp: "actor",
    header: "resultHeader.name",
    searchValue: "",
    searchType: "number",
    columnName: "Code tiers",
    searchCell: true,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "total_indicators",
    searchProp: "total_indicators",
    header: "resultHeader.total_indicators",
    searchValue: "",
    searchType: "number",
    columnName: "Total indicateurs",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "total_compliant_indicators",
    searchProp: "total_compliant_indicators",
    header: "resultHeader.total_compliant_indicators",
    searchValue: "",
    searchType: "number",
    columnName: "Total conforme",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "total_not_compliant_indicators",
    searchProp: "total_not_compliant_indicators",
    header: "resultHeader.total_not_compliant_indicators",
    searchValue: "",
    searchType: "number",
    columnName: "Total non-conforme",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
  {
    orderer: "conformity",
    searchProp: "conformity",
    header: "resultHeader.conformity",
    searchValue: "",
    searchType: "boolean",
    columnName: "Statut",
    searchCell: false,
    headerLabelMin: false,
    headerLabelMaj: false,
  },
]