import { ApiList, GetFolders, FolderLight, GetFolder, Folder } from "../models";
import { backendTokenService, securedRestClient } from "../backend";
import { buildUrlParameters } from "../utils";
import { specificationSubService, folderSubService } from "./sub-services";
import { FolderEditPageResult } from "../pages";

export const folderService = {
    getEditFolderPage: async ({id}: GetFolder) => {
        const resultSpecifications = await specificationSubService.getSpecifications({});
        const resultFolder = await folderSubService.getFolder({id});
        const result: FolderEditPageResult = {specifications: resultSpecifications.results, folder: resultFolder};
        return result;
    },
}