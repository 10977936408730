import { WithStatelessDatasFetching } from '../../hocs';
import { PageLoader } from '../../components/page-loader';
import { HomeResults, HomeProps } from './home-page-types';
import { homeService } from '../../services';

const enhancer = WithStatelessDatasFetching<HomeResults, HomeProps>(
  homeService.getHome,
  PageLoader,
  'error',
  'results',
);

export default enhancer;
