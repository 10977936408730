import React from 'react';

import { Tab } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './flows-batchs.module.css';
import { TabPanel } from '../../../components';
import { ColorLayout, ContainerLayout, TitleLayout } from '../../../layouts';
import { batchSubService, flowSubService } from '../../../services';
import { BatchsHeader, FlowsHeader } from '../../../constants';
import { posthogEvents } from '../../../utils/posthog';

export const TrackingFlowsBatchsPage: React.FC = () => {
  const { t } = useTranslation();
  posthogEvents.triggerPageView();

  const panes = [
    {
      menuItem: t('tracking.batchs.label'),
      render: () => (
        <TabPanel
          include="batchs"
          limit={100}
          specificParams={{ ordering: '-date' }}
          service={batchSubService.getAllBatchs}
          initHeader={BatchsHeader}
        />
      ),
    },
    {
      menuItem: t('tracking.flows.label'),
      render: () => (
        <TabPanel
          include="flows"
          limit={100}
          specificParams={{ ordering: '-flow_date' }}
          service={flowSubService.getAllFlows}
          initHeader={FlowsHeader}
        />
      ),
    },
  ];

  return (
    <ColorLayout color="#0a405d">
      <ContainerLayout>
        <TitleLayout>{t('tracking.title')}</TitleLayout>
        <div className={styles.list}>
          <Tab panes={panes} menu={{ widths: 2 }} style={{ height: ' 100%' }} />
        </div>
      </ContainerLayout>
    </ColorLayout>
  );
};
