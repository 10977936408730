import i18next, { TFunction } from 'i18next';
import React from 'react';
import { Table, Popup } from 'semantic-ui-react';
import {
  DataTableTransform, DataAlertTransform,
} from '../../../models';
import { formatDate } from '../../../utils';
import styles from '../alert-group/alert-group.module.css'
import { BlockButton, StatusButton } from '../../../components';

export const formatAlertData = (t: TFunction, dataType: DataTableTransform | DataAlertTransform, data?: any, pk?: number): JSX.Element => {

  
  if (data === undefined) {
    return <></>;
  }
  switch (dataType) {
    case 'boolean': {
      return data ? <div>{t('table.utils.true')}</div> : <div>{t('table.utils.false')}</div>;
    }
    case 'date': {
      return <div className={styles.string}>{formatDate(data, i18next.language)}</div>;
    }
    case 'compliant': {
      return <div className={styles.string}>{pk}</div>;
    }
    case 'tag': {
      return <div>{data && data.length > 0 && data.map((el: string, key: number) => <div key={key} className={styles.tags}>{el}</div>)}</div>;
    }
    case 'event': {
      return <div className={styles.event}>{data && data.length > 0 ? data : t('alerts.alertGroup.no_event')}</div>;
    }
    case 'cluster_error': {
    return <div className={styles.clusterData}>
        {
        data && data.length > 0 && data.map((el: string, key: number) =>{
          return (
          <div key={key} className={styles.subcategory}>
            {el}
          </div>
      )})
    }
     </div>;
      }
    default: {
    return <div>
                {data.length >=21 && 
                <CustomPopup  messages={data}>
                  <div className={styles.string}>
                    {data && data.length > 21 ? `${data.substr(0, 18)}...` : data}
                </div>
                </CustomPopup>}
                {data.length < 21 && <div className={styles.string}>{data}</div> }
              </div>
    }
  }
};

export const modifiedValue = (editedValue:any, initialValue:any, dataType: DataTableTransform | DataAlertTransform): boolean => {
  // console.log('MODIFIED VALUE => initialValue = ', initialValue, 'editedValue = ', editedValue)
  
  if (!editedValue && !initialValue) {
    return false;
  }

  if (!editedValue || !initialValue) {
    return true;
  }
  
  switch(dataType) {
    case 'date': {
      return editedValue !== initialValue;
    }
    case 'batch': {
      return editedValue.name !== initialValue.name;
    }
    case 'ecosystem_preview': {
      return editedValue.pk !== initialValue.pk;
    }
    case 'string': {
      return editedValue !== initialValue;
    }
    default: {
      return editedValue !== initialValue;
    }
  }
}

export interface CustomPopupProps {
  messages: string;
}

export const CustomPopup: React.FC<CustomPopupProps> = ({ children, messages }) => {
  return <Popup disabled={!(messages && messages.length > 0)} trigger={children}>
    <div>
      {messages}
    </div>
  </Popup>
}