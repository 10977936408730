import * as React from 'react';

import { Modal } from 'semantic-ui-react';
import styles from './confirmation-modal.module.css';
import { ModalButton } from './confirmation-modal-type';
import { OnBeforeUnloadingContext } from '../../contexts';
import { ClassicButton } from '../buttons';

export const ConfirmationModal = () => {
  const {action, setAction, message, buttons, next, setNext, activeContext} = React.useContext(OnBeforeUnloadingContext);

  const handleClick = (btn: ModalButton) => {
    btn.action();
    if (btn.type === 'cancel') {
      setAction(false);
    } else {
      next();
      setAction(false);
    }
    setNext(() => {});
  }

  return (
  <Modal closeIcon disabled={!activeContext} open={action} onClose={() => setAction(false)}>
    <div className={styles.modal}>
      <div className={styles.title}>
        {message}
      </div>
      <div className={styles.buttons}>
        {buttons.map(btn => (
          <div key={btn.type} className={styles.button}>
            <ClassicButton secondary={btn.type === 'cancel' || btn.type === 'continue'} inverted={btn.type === 'continue'}  onClick={() => handleClick(btn)} text={btn.text} />
          </div>
        ))}
      </div>
    </div>
  </Modal>
  )
}