import * as React from 'react';

import { Input, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './alert-group.module.css';
import { AlertGroupFilterProps } from './alert-group-types';
import refreshPicto from '../../../../assets/pictos/refresh.png';
import { SelectControlOption, FilterButton } from '../../../components';
import { posthogEvents } from '../../../utils/posthog';
import DropdownFilterBtn from '../../../components/buttons/DropdownFilterBtn/dropdown-filter-btn';
import DatePickerComponent from '../../../components/date-picker/date-picker';

export function AlertGroupFilter({
 manageSearch, handleSearch
}: AlertGroupFilterProps) {
  const { t } = useTranslation();
  const [inputFetcher, setInputFetcher] = React.useState<any>();
  const [search, setSearch] = React.useState<string>('');

  const [isSelected, setIsSelected] = React.useState<string>('');

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setSearch(e.target.value);
    if (inputFetcher) {
      clearTimeout(inputFetcher);
    }

    setInputFetcher(setTimeout(() => {
      e.target && handleSearch(e.target.value);
    }, 500));
  };

 

  const handleFilterClick = (value: string, refresh : boolean) => {
    refresh ? posthogEvents.triggerEvent({ name: 'clickOn', datas: {'alertList' : 'refresh'}}) : posthogEvents.triggerEvent({name: 'filter', datas: {'alert' : value === '' ? 'all' : value} });
    setIsSelected(value);
    manageSearch('status', value);
    refresh && setSearch('');
    refresh && handleSearch('');
  }

  const handleAlertStatusClick = (value: string) => {
    switch (value) {
      case t('alerts.alertGroup.filter.resolved'):
        manageSearch('status', 'closed')
        break;
      case t('alerts.alertGroup.filter.unresolved'):
        manageSearch('status', 'open')
        break;
      case t('alerts.alertGroup.filter.closed'):
        manageSearch('status', 'closed_by_user')
        break;
      case t('alerts.alertGroup.filter.retrack'):
        manageSearch('status', 'retrack')

      default:
        break;
    }
  }

  const handleTagClick = (value: string) => {
    switch (value) {
      case t('alerts.level0.audit'):
        manageSearch('tag', 'audit')
        break;
      case t('alerts.level0.Traceability'):
        manageSearch('tag', 'traçabilité')
        break;
      default:
        break;
    }
  }

  const handleAlertType = (value: string) => {
    switch (value) {
      case t('alerts.level1.connection_key'):
        manageSearch('alert_type', 'connection_key')
        break;
      case t('alerts.level1.matching_key'):
        manageSearch('alert_type', 'matching_key')
        break;
      case t('alerts.level1.missing_data'):
        manageSearch('alert_type', 'missing_data')
        break
      case t('alerts.level1.Audit non-compliant'):
        manageSearch('tag', 'audit')
        break

      default:
        break;
    }
  }

  const handleDateClick = (value: Date) => {
    manageSearch('cluster_date',value.toLocaleDateString())
  }

  const alertStatusItems = [t('alerts.alertGroup.filter.resolved'),t('alerts.alertGroup.filter.unresolved'), t('alerts.alertGroup.filter.closed'), t('alerts.alertGroup.filter.retrack'), ]
  const tagItems = [t('alerts.level0.audit'), t('alerts.level0.Traceability')]
  const alertTypeItems = [t('alerts.level1.connection_key'), t('alerts.level1.matching_key'), t('alerts.level1.missing_data'), t('alerts.level1.Audit non-compliant')]

  return (
    <div className={styles.filter}>
      <div className={styles.filterContent}>
        <div className={styles.buttonWrapper}>
        <DropdownFilterBtn onDropdownItemClick={(e: string) => handleAlertStatusClick(e)} title={t('alerts.alertFix.status')} itemElement={alertStatusItems} />
        </div>
        <div className={styles.buttonWrapper}>
        <DropdownFilterBtn onDropdownItemClick={(e: string) => handleAlertType(e)} title={t('alerts.alertFix.alert_type')} itemElement={alertTypeItems} />
        </div>
        <div className={styles.buttonWrapper}>
        <DropdownFilterBtn onDropdownItemClick={(e: string) => handleTagClick(e)} title="Tag" itemElement={tagItems} />
        </div>
        <div className={styles.buttonWrapper}>
          <DatePickerComponent text={t('table.flow_alerts.flow_date')} onDayClick={(day: Date) => handleDateClick(day)} />
        </div>
      </div>
      <div className={styles.inputContainer}>
        <Input
            placeholder={t(`alerts.alertGroup.filter.search`)}
            className={styles.input} 
            onChange={(e) => handleInputChange(e)} 
            value={search || ''}
            focus
            icon={<Icon name='search' className={styles.iconInput} />}
            />
        </div>
        <div className={styles.refreshContainer}>
        <img className={styles.refresh}src={refreshPicto} onClick={() => handleFilterClick('', true)}/>
        {t('alerts.alertGroup.filter.refresh')}
        </div>
    </div>
  );
}
