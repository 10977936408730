import React from 'react';
import styles from './new-product.module.css';
import { ProductWizard } from '../../../../components';
import { WizardLayout } from '../../../../layouts';
import { ProductWrite } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';

export const EcosystemNewProductPage: React.FC = () => {
  const product: ProductWrite = {
    name: '',
    internal_code: '',
    unit: '',
    gs1: '',
    gs1_type: '',
    specifications: undefined,
    owner: [-1],
    is_placeholder: false,
    product_data: {}
  };
  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.wizardWrapper}>
        <ProductWizard initialProduct={product} />
      </div>
    </WizardLayout>
  );
};
