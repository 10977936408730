import { WithStatelessDatasFetching } from '../../../hocs';
import { CertificateFormProps, CertificateFormLoader } from '.';
import { ecosystemService } from '../../../services';
import { CertificateType, ApiList } from '../../../models';

const enhancer = WithStatelessDatasFetching<ApiList<CertificateType[]>, CertificateFormProps>(
  ecosystemService.getCertificateType,
  CertificateFormLoader,
  'redirect',
  'certificateTypes',
);

export default enhancer;
