import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './certificate-wizard-component.module.css';
import { CertificateWizardProps } from './certificate-wizard-type';
import { CertificateWrite, CertificateRead } from '../../../models';
import { certificateSubService } from '../../../services';
import {
  OwnerForm, ListSiteForm, CertificateForm, ListToolForm, ListProductForm, CertificateDocumentForm,
} from '../../forms';
import checkPictoWhite from '../../../../assets/pictos/checkPictoWhite.png';
import certificatePictoBlue from '../../../../assets/pictos/certificatePictoBlue.svg';
import trashPictoRed from '../../../../assets/pictos/trashPictoRed.png';
import editPictoWhite from '../../../../assets/pictos/editPictoWhite.png';
import { toastHandler } from '../../../utils';
import { WizardLoader } from '../utils';
import { redirectWithUrl } from '../utils/redirect';

export const CertificateWizard: React.FC<CertificateWizardProps> = React.memo(({ edit, initialCertificate, document, initialStep }: CertificateWizardProps) => {
  const [editedCertificate, setEditedCertificate] = React.useState<Omit<CertificateWrite, 'owner'>>(initialCertificate);
  const [editedOwner, setEditedOwner] = React.useState<number[]>([initialCertificate.owner || -1]);
  const [editedDocument, setEditedDocument] = React.useState<string | undefined>(document);
  const [activeStep, setActiveStep] = React.useState(initialStep ? initialStep : 0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const nextStep = () => {
    setActiveStep(activeStep === step.length - 1 ? step.length - 1 : activeStep + 1);
  };

  const previousStep = () => {
    setActiveStep((activeStep || 1) - 1);
  };

  const goTo = (index: number) => {
    setActiveStep(index);
  };

  const manageDeleteCertificate = async () => {
    if (editedCertificate.pk && editedCertificate.pk !== -1) {
      setLoading(true);
      try {
        const result = certificateSubService.deleteCertificate(editedCertificate.pk);
        toastHandler(t('wizard.certificates.deleteSuccess'), 'success');
        history.push({pathname: redirectWithUrl(location.search, '/ecosystem'), state: location.state});
        setLoading(false);
      } catch (err) {
        toastHandler(t('wizard.certificates.deleteError'), 'error');
        setLoading(false);
      }
    }
  };

  const manageCancel = () => {
    history.push({pathname: editedCertificate.pk ? redirectWithUrl(location.search, `/ecosystem/certificates/${editedCertificate.pk}`) : redirectWithUrl(location.search, '/ecosystem'), state: location.state});
  };

  const onNextOwner = (owner: number[]) => {
    if (owner !== editedOwner) {
      setEditedCertificate({
        ...editedCertificate, site_certified: [], product_certified: [], tool_certified: [],
      });
    }
    setEditedOwner(owner);
    nextStep();
  };

  const onNextCertificate = (certificate: Omit<CertificateWrite, 'owner'>) => {
    setEditedCertificate({ ...editedCertificate, ...certificate });
    nextStep();
  };

  const onSaveOwner = async (owner: number[]) => {
    if (owner !== editedOwner) {
      setEditedCertificate({
        ...editedCertificate, site_certified: [], product_certified: [], tool_certified: [],
      });
    }
    setEditedOwner(owner);
    try {
      await onSaveGlobalCertificate(owner, { ...editedCertificate, site_certified: owner !== editedOwner ? [] : editedCertificate.site_certified });
      nextStep();
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const onSaveCertificate = async (certificate: Omit<CertificateWrite, 'owner'>) => {
    setEditedCertificate(certificate);
    try {
      const result = await onSaveGlobalCertificate(editedOwner, certificate);
      result && setEditedCertificate({ ...result, document: undefined });
      setEditedDocument(result?.document);
      nextStep();
    } catch {
      setError(t('table.utils.error'));
    }
  };

  const onNextDocument = async (certificate: Omit<CertificateWrite, 'owner'>) => {
    setEditedCertificate({ ...editedCertificate, ...certificate });
    try {
      const result = await onSaveGlobalCertificateDocument(editedOwner, certificate);
      result && setEditedCertificate({ ...result, document: undefined });
      setEditedDocument(result?.document);
      nextStep();
    } catch {
      setError(t('table.utils.error'));
    }
  };

  const onSaveDocument = async (certificate: Omit<CertificateWrite, 'owner'>) => {
    setEditedCertificate({ ...editedCertificate, ...certificate });
    try {
      const result = await onSaveGlobalCertificateDocument(editedOwner, certificate);
      result && setEditedCertificate({ ...result, document: undefined });
      setEditedDocument(result?.document);
      nextStep();
    } catch {
      setError(t('table.utils.noResult'));
    }
  };

  const onNextSite = async (site_certified: number[]) => {
    setEditedCertificate({ ...editedCertificate, site_certified });
    try {
      const result = await onSaveGlobalCertificate(editedOwner, { ...editedCertificate, site_certified });
      result && setEditedCertificate({ ...result, document: undefined });
      setEditedDocument(result?.document);
      nextStep();
    } catch {
      setError(t('table.utils.noResult'));
    }
  };

  const onSaveSite = async (site_certified: number[]) => {
    setEditedCertificate({ ...editedCertificate, site_certified });
    try {
      const result = await onSaveGlobalCertificate(editedOwner, { ...editedCertificate, site_certified });
      setEditedDocument(result?.document);
      nextStep();
    } catch {
      setError(t('table.utils.noResult'));
    }
  };

  const onNextTool = async (tool_certified: number[]) => {
    setEditedCertificate({ ...editedCertificate, tool_certified });
    try {
      const result = await onSaveGlobalCertificate(editedOwner, { ...editedCertificate, tool_certified });
      result && setEditedCertificate({ ...result, document: undefined });
      setEditedDocument(result?.document);
      nextStep();
    } catch {
      setError(t('table.utils.noResult'));
    }
  };

  const onSaveTool = async (tool_certified: number[]) => {
    setEditedCertificate({ ...editedCertificate, tool_certified });
    try {
      const result = await onSaveGlobalCertificate(editedOwner, { ...editedCertificate, tool_certified });
      setEditedDocument(result?.document);
      nextStep();
    } catch {
      setError(t('table.utils.noResult'));
    }
  };

  const onSaveProduct = async (product_certified: number[]) => {
    setEditedCertificate({ ...editedCertificate, product_certified });
    try {
      const result = await onSaveGlobalCertificate(editedOwner, { ...editedCertificate, product_certified });
      result && history.push({pathname: redirectWithUrl(location.search, `/ecosystem/certificates/${result.pk}`), state: location.state});
    } catch {
      setError(t('table.utils.noResult'));
    }
  };

  const onSaveGlobalCertificate = async (owner: number[], certificate: Omit<CertificateWrite, 'owner'>) => {
    setLoading(true);
    try {
      if (owner.length > 0 && owner[0] !== -1) {
        const result: CertificateRead = editedCertificate.pk && editedCertificate.pk !== -1 ? await certificateSubService.editCertificate({ ...editedCertificate, ...certificate, owner: owner[0] }) : await certificateSubService.createCertificate({ ...certificate, owner: owner[0] });
        toastHandler(t('wizard.certificates.saveSuccess'), 'success');
        setLoading(false);
        return result;
      }
      setError('il manque un proprietaire');
      setLoading(false);
    } catch {
      toastHandler(t('wizard.certificates.saveError'), 'error');
      setError(t('table.utils.error'));
      setLoading(false);
    }
  };

  const onSaveGlobalCertificateDocument = async (owner: number[], certificate: Omit<CertificateWrite, 'owner'>) => {
    setLoading(true);
    try {
      if (owner.length > 0 && owner[0] !== -1 && editedCertificate.pk && editedCertificate.pk !== -1) {
        const result: CertificateRead = await certificateSubService.editCertificateDocument({ ...editedCertificate, ...certificate, owner: owner[0] });
        toastHandler(t('wizard.certificates.saveDocumentSuccess'), 'success');
        setLoading(false);
        return result;
      }
      setError('il manque un proprietaire');
      setLoading(false);
    } catch {
      toastHandler(t('wizard.certificates.saveDocumentError'), 'error');
      setError(t('table.utils.error'));
      setLoading(false);
    }
  };


  const step = [
    {
      index: 0,
      label: t('wizard.certificates.step2'),
      picto: certificatePictoBlue,
      Component: editedOwner
        ? (
          <OwnerForm
            model={editedOwner}
            onNext={(model) => onNextOwner(model)}
            onSave={editedCertificate.pk && editedCertificate.pk !== -1 ? (model) => onSaveOwner(model) : undefined}
            errorMessage={error}
            onCancel={manageCancel}
            include="certificates"
            blocking
            specificParams={{ ordering: 'name' }}
          />
        )
        : <div>pas d'acteur id</div>,
    },
    {
      index: 1,
      label: t('wizard.certificates.step1'),
      picto: certificatePictoBlue,
      Component: <CertificateForm
        model={editedCertificate}
        onSave={(model) => onSaveCertificate(model)}
        errorMessage={error}
        onPrevious={previousStep}
        onCancel={manageCancel}
        onNext={editedCertificate.pk && editedCertificate.pk !== -1 ? (model) => onNextCertificate(model) : undefined}
      />,
    },
    {
      index: 2,
      label: t('wizard.certificates.step3'),
      picto: certificatePictoBlue,
      Component: editedOwner[0] !== -1
        ? (
          <CertificateDocumentForm
            model={editedCertificate}
            currentDoc={editedDocument}
            onNext={(model) => onNextDocument(model)}
            onSave={(model) => onSaveDocument(model)}
            errorMessage={error}
            onDelete={manageDeleteCertificate}
            onPrevious={previousStep}
            onCancel={manageCancel}
          />
        )
        : <div>pas de propriétaire</div>,
    },
    {
      index: 3,
      label: t('wizard.certificates.step4'),
      picto: certificatePictoBlue,
      Component: editedOwner[0] !== -1
        ? (
          <ListSiteForm
            model={editedCertificate.site_certified}
            onSave={(model) => onSaveSite(model)}
            errorMessage={error}
            onCancel={manageCancel}
            specificParams={{ owner: editedOwner[0].toString(), ordering: 'name' }}
            onPrevious={previousStep}
            onNext={(model) => onNextSite(model)}
            include="certificates"
            multiple
          />
        )
        : <div>pas d'acteur id</div>,
    },
    {
      index: 4,
      label: t('wizard.certificates.step5'),
      picto: certificatePictoBlue,
      Component: editedOwner[0] !== -1
        ? (
          <ListToolForm
            model={editedCertificate.tool_certified}
            onSave={(model) => onSaveTool(model)}
            errorMessage={error}
            onCancel={manageCancel}
            specificParams={{ owner: editedOwner[0].toString(), ordering: 'name' }}
            onPrevious={previousStep}
            onNext={(model) => onNextTool(model)}
            include="certificates"
            multiple
          />
        )
        : <div>pas d'acteur id</div>,
    },
    {
      index: 5,
      label: t('wizard.certificates.step6'),
      picto: certificatePictoBlue,
      Component: editedOwner[0] !== -1
        ? (
          <ListProductForm
            include="certificates"
            model={editedCertificate.product_certified}
            onSave={(model) => onSaveProduct(model)}
            errorMessage={error}
            onCancel={manageCancel}
            specificParams={{ owner: editedOwner[0].toString(), ordering: 'name' }}
            onPrevious={previousStep}
            multiple
          />
        )
        : <div>pas d'acteur id</div>,
    },
  ];


  return (
    <div className={styles.wizard}>
      <div className={styles.form}>
        <div className={styles.activeStep}>
          <div className={styles.header}>
            <div className={styles.titlePictoContainer}><img src={step[activeStep].picto} className={styles.titlePicto} /></div>
            <div className={styles.titleLabel}><b>{step[activeStep].label}</b></div>
            {editedCertificate.pk && editedCertificate.pk !== -1
              && (
                <div className={styles.deleteContainer} onClick={manageDeleteCertificate}>
                  <div className={styles.trashPictoContainer}><img src={trashPictoRed} className={styles.trashPicto} /></div>
                  <div className={styles.deleteLabel}>{t('wizard.certificates.delete')}</div>
                </div>
              )}
          </div>
          <div className={`${styles.formWrapper} ${loading ? styles.blured : ''}`}>
            {loading && <WizardLoader />}
            {step[activeStep].Component}
          </div>
        </div>
      </div>
      <div className={styles.track}>
        {
          step.map((el) => (
            activeStep === el.index
              ? (
                <div className={`${styles.container} ${styles.activeContainer}`} key={el.index}>
                  <div className={`${styles.badge} ${styles.activeBadge}`}>{el.index + 1}</div>
                  <div className={`${styles.label} ${styles.activeLabel}`}>{el.label}</div>
                </div>
              )
              : editedCertificate.pk
                ? (
                  <div className={`${styles.container} ${styles.editContainer}`} key={el.index} onClick={() => goTo(el.index)}>
                    <div className={`${styles.badge} ${styles.editBadge}`}><img src={editPictoWhite} className={styles.editPicto} /></div>
                    <div className={`${styles.label} ${styles.editLabel}`}>{el.label}</div>
                  </div>
                )
                : activeStep > el.index
                  ? (
                    <div className={`${styles.container} ${styles.doneContainer}`} key={el.index}>
                      <div className={`${styles.badge} ${styles.doneBadge}`}><img src={checkPictoWhite} className={styles.checPicto} /></div>
                      <div className={`${styles.label} ${styles.doneLabel}`}>{el.label}</div>
                    </div>
                  )
                  : (
                    <div className={`${styles.container} ${styles.todoContainer}`} key={el.index}>
                      <div className={`${styles.badge} ${styles.todoBadge}`}>{el.index + 1}</div>
                      <div className={`${styles.label} ${styles.todoLabel}`}>{el.label}</div>
                    </div>
                  )
          ))
        }
      </div>
    </div>
  );
});
