import React, { SetStateAction } from 'react';
import styles from './cell-date.module.css';
import { Table, Modal} from 'semantic-ui-react';
import i18next from 'i18next';
import { formatDate, getLanguage } from '../../../utils';
import { CustomPopup } from './custom-popup';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { setDefaultLocale, registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import it from 'date-fns/locale/it'
import "react-datepicker/dist/react-datepicker.css";


export interface DateCellProps {
  messages: string[];
  enabled: boolean;
  initialValue?: Date;
  value?: Date;
  error: boolean; 
  editCell: (value: string) => void;
  setTempDate: React.Dispatch<SetStateAction<string | undefined>>;
  tempDate: string | undefined;
  setModifiedDate: React.Dispatch<SetStateAction<boolean>>;
  modifiedDate: boolean;

}

export const DateCell = ({messages, enabled, initialValue, value, error, editCell, setTempDate, tempDate, setModifiedDate, modifiedDate}: DateCellProps) => {
  
  const {t}=useTranslation();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [remodify, setRemodify] = React.useState<boolean>(false)

  const handleModalClick = (open: boolean) => {
    setModalOpen(open) 
  }
  const [popUp, setPopUp] = React.useState<boolean>(true)
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [dateSelected, setDateSelected] = React.useState<boolean>(false)

  const handleDateDeletion = () => {
    editCell('');
    setPopUp(true)
  }

  const handleOpenCalendar =() => {
    setPopUp(false);
  } 
 
  const handleDateSelect = (date : any) => {
    setPopUp(false)
    setDateSelected(true);
    setStartDate(date);
    setTempDate(date);
    setRemodify(true);
    date ? editCell(date.toString()) : handleDateDeletion();
  } 

  React.useEffect(() => {
    registerLocale('fr', fr)
    registerLocale('it', it)
    setDefaultLocale('fr')
  }, [tempDate])

  return (
   
    <CustomPopup messages={popUp ? messages.filter(el => typeof el === 'string') : []}>
      {error && enabled && !modifiedDate ?
        <Table.Cell onClick={() => !modalOpen && handleModalClick(true)} style={{ border: !error ? undefined : enabled ? '1px solid blue' : modifiedDate ? '1px solid orange' : '1px solid red', cursor : enabled ? 'pointer' : undefined}} >        
          <DatePicker
              className={styles.calendar}
              locale={getLanguage(i18next.language)}
              selected={dateSelected ? startDate : null}
              onChange={date => handleDateSelect(date)}
              onCalendarOpen={() => handleOpenCalendar}
              popperPlacement={"bottom"}
              dateFormat={getLanguage(i18next.language) === 'en' ? 'MM-dd-y h:mm aa' : 'dd-MM-yyyy HH:mm'}
              showTimeSelect
              popperModifiers={{
                flip: {
                  behavior: ['bottom'],
                  flipVariations: false,
                }
              }}
              timeFormat={getLanguage(i18next.language) === 'en'? 'hh:mm aa' : 'HH:mm'}
              timeCaption={t('alerts.time.time')}
              />
          </Table.Cell>
          :
          remodify && enabled ?
          <Table.Cell onClick={() => !modalOpen && handleModalClick(true)} style={{ border: '1px solid orange'}} >
               <DatePicker
              className={styles.calendar}
              locale={getLanguage(i18next.language)}
              selected={dateSelected ? startDate : null}
              onChange={date => handleDateSelect(date)}
              onCalendarOpen={() => handleOpenCalendar}
              popperPlacement={"bottom"}
              dateFormat={getLanguage(i18next.language) === 'en' ? 'MM-dd-y h:mm aa' : 'dd-MM-yyyy HH:mm'}
              showTimeSelect
              popperModifiers={{
                flip: {
                  behavior: ['bottom'],
                  flipVariations: false,
                }
              }}
              timeFormat={getLanguage(i18next.language) === 'en'? 'hh:mm aa' : 'HH:mm'}
              timeCaption={t('alerts.time.time')}
              />
        <div className={styles.dateDisabled} style={{ color: 'orange' }}>{modifiedDate ? tempDate && formatDate(tempDate, i18next.language) : value && formatDate(value, i18next.language)}</div>
        </Table.Cell>
          :
          <Table.Cell style={{ border: !error ? undefined : modifiedDate? '1px solid orange' : enabled ? '1px solid blue' : '1px solid red'}} >

          <div className={styles.dateDisabled} style={{ color: !error ? 'black' : modifiedDate ? 'orange' : 'red' }}>{modifiedDate ? tempDate && formatDate(tempDate, i18next.language) : value && formatDate(value, i18next.language)}</div>
          </Table.Cell>
        }
      </CustomPopup>
  )
}

