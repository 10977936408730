import React from 'react';

import { Placeholder } from 'semantic-ui-react';
import styles from './home-page.module.css';

export const HomePageLoader: React.FC = React.memo(() => (
  <div>
    <div>
      <Placeholder fluid className={styles.placeholder}>

        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="short" />


      </Placeholder>
    </div>
    <div className={styles.placeholder}>
      <Placeholder fluid>
        <Placeholder.Paragraph>
          <Placeholder.Image square />
        </Placeholder.Paragraph>
      </Placeholder>
    </div>

  </div>
));
