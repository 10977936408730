import React from 'react';
import { Error } from '../models/error';

export const useFetch = <T, SearchParamsProps> (service: (params: SearchParamsProps) => Promise<T>, params: SearchParamsProps, refresh: string[]) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | string | null>('');
  const [data, setData] = React.useState<T>();
  const lastCall = React.useRef<any>();

  const noRender = refresh.map((el) => (params as any)[el]).filter((el) => el !== undefined);
  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        lastCall.current = { service, params };
        const fetchedData = await service({ ...params });
        if (lastCall.current.service === service && lastCall.current.params === params) {
          setData(fetchedData);
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [...noRender]);


  return { loading, error, data };
};
