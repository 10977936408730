import React, { useCallback } from 'react';

import { Form, Select } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { SelectControlProps, SelectControlOption } from './select-control-type';

import styles from './select-control-component.module.css';

export const SelectControl: React.FC<SelectControlProps> = ({
  options,
  required,
  name,
  initialValue,
  hint,
  onChange,
  style,
  ...rest
}: SelectControlProps) => {
  const { t } = useTranslation();

  const optionsList: SelectControlOption[] = [
    { key: '', value: 0, text: '--', disabled: true},
    ...options,
  ];


  const validate = useCallback(
    (value: string): string | undefined => {
      let result;
      if (!value || value.length === 0 && required) {
        result = t('form.utils.required');
      }
      if(name === 'related_actor_role' && value.length>3){
        result=t('form.utils.max');
      }

      return result;
    },
    [required],
  );

  return (
    <Field
      name={name}
      validate={validate}
      render={({ field, form }: FieldProps) => (
        <Form.Field required={required} >
          <div className={styles.hint}>{hint}</div>
          <Select
            {...field}
            {...rest}
            name={name}
            className={`${styles.selector} custom-selector`}
            defaultValue={initialValue}
            scrolling
            options={optionsList}
            onBlur={() => form.setFieldTouched(field.name)}
            onChange={(option, data) => {
              form.setFieldValue(field.name, data.value);
              if (onChange) {
                onChange(data.value);
              }
            }}
            style={style}
            multiple={name === "related_entity" || name=== "related_specifications" || name === "related_actor_role" || name === "related_actor_group"}
          />
          {form
            && form.errors
            && form.errors[name]
            && form.touched
            && form.touched[name] && (
              <div className={styles.error}>{form.errors[name]}</div>
          )}
        </Form.Field>
      )}
    />
  );
};
