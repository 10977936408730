import React, {
  ComponentType,
} from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApiList, SimpleJson } from '../../models';
import { mergedGroupList } from '../../pages/ecosystem-page/utils';
import { useFetch } from '../../hooks';
import { toastHandler } from '../../utils';
import { AuthenticatedContext } from '../../contexts';

interface InnerLazyloadingDatasFetchingComponentProps<T, P> {
  Component: ComponentType<P>;
  props: P;
  remoteFetchAction: (params: SimpleJson) => Promise<ApiList<T[]>>;
  outputFieldName: string | number | symbol;
  filterProps: SimpleJson;
  limit: number;
}

const storageKey = process.env.REACT_APP_AUTHTOKEN ? process.env.REACT_APP_AUTHTOKEN : '';

function InnerLazyloadingDatasFetchingComponent<T, P>({
  Component,
  outputFieldName,
  props,
  remoteFetchAction,
  filterProps,
  limit,
}: InnerLazyloadingDatasFetchingComponentProps<T, P>): React.ReactElement<
  InnerLazyloadingDatasFetchingComponentProps<T, P>
> {
  const [fetchedData, setFetchedData] = React.useState<T[]>([]);
  const [offset, setOffset] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [endFetching, setEndFetching] = React.useState(false);
  const { t } = useTranslation();

  const offsetRef = React.useRef(offset);

  const history = useHistory();
  const redirect = history.location && history.location.pathname + history.location.search;

  const loadMoreItems = () => {
    // console.log('loadmore items in hoc')
    if (offsetRef.current + limit > count) {
      // console.log('end fetching')
      setEndFetching(true);
      return;
    }
    setOffset(offset + limit);
  };

  React.useEffect(() => {
    offsetRef.current = offset;
  }, [offset]);

  const params: SimpleJson = { offset: offset.toString(), limit: limit.toString(), ...filterProps };

  const { loading, error, data } = useFetch<ApiList<T[]>, SimpleJson>(remoteFetchAction, params, Object.keys(params));

  React.useEffect(() => {
    setCount(data ? data.count : 0);
    setFetchedData(mergedGroupList(fetchedData, data));
  }, [data]);

  const innerProps = {
    ...props, [outputFieldName]: fetchedData, loading, error, loadMoreItems, endFetching,
  };

  const authenticatedContext = React.useContext(AuthenticatedContext);

  if (error && typeof error !== 'string' && error.code === 401) {
    toastHandler(t('error.disconnected'), 'error');
    localStorage.removeItem(storageKey);
      authenticatedContext.setAuthenticated(undefined);
      return (
        <Redirect to={{
          pathname: '/login',
        }}
        />
    );
  }

  return <Component {...innerProps} />;
}

export const WithLazyloadingDatasFetching = <T, InjectedProps>(
  remoteFetchAction: (params: SimpleJson) => Promise<ApiList<T[]>>,
  outputFieldName: keyof InjectedProps,
  filterProps: SimpleJson,
  limit: number,
) => <P extends InjectedProps>(
    Component: ComponentType<P>,
  ): React.FunctionComponent<P> => {
    // console.log('props', filterProps)
  const WithLazyloadingDatasFetchingComponent: React.FC<P> = (props: P) => (
    <InnerLazyloadingDatasFetchingComponent
      Component={Component}
      props={props}
      remoteFetchAction={remoteFetchAction}
      outputFieldName={outputFieldName}
      filterProps={filterProps}
      limit={limit}
    />
  );
  return WithLazyloadingDatasFetchingComponent;
};
