import React from 'react';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';

import styles from './relations-form.module.css';
import { InputControl, CheckBoxInputControl, SelectControl } from '../form-controls';
import { ClassicButton } from '../../buttons';
import { RelationFormProps, RelationProps } from './relations-form-type';

export const RelationForm: React.FC<RelationFormProps> = ({
  model,
  onSave,
  errorMessage,
  onCancel,
  onNext,
  onPrevious,
}: RelationFormProps) => {
  const { t } = useTranslation();



  return (
    <>
      <Formik<RelationProps>
        onSubmit={onSave}
        initialValues={model}
        render={(props) => (
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.formContainer}>

              {model && (
              <>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.relation.name')}</b>
                  <InputControl
                    name="connection"
                    required
                    placeholder={t('form.relation.name')}
                  />
                </div>
              </>
              )}
            </div>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
              onCancel
                ? <ClassicButton text={t('wizard.relation.cancel')} onClick={onCancel} warning />
                : <div>&nbsp;</div>
            }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
              onPrevious
                ? <ClassicButton text={t('wizard.relation.back')} onClick={onPrevious} inverted />
                : <div>&nbsp;</div>
            }
                </div>
                <div className={styles.nextNavigation}>
                  {
              onNext
                ? <ClassicButton onClick={onNext} text={t('wizard.relation.next')} inverted />
                : <div>&nbsp;</div>
            }

                </div>
              </div>
              <div className={styles.submitActions}>
                <ClassicButton type="submit" text={t('wizard.relation.submit')} />
              </div>
            </div>
          </form>
        )}
      />
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};
