import {
  ApiList,
  ResultsLight,
  Results,
  GetResults,
  DownloadResults,
  UpDateResults
} from "../../models";
import { backendTokenService, securedRestClientContracts } from "../../backend";
import { buildUrlParameters } from "../../utils";

export const resultsSubService = {
  getFoodchainResultsLight: async ({ name, limit, ordering }: GetResults) => {
    const params: { prop: string; value: string | number | undefined }[] = [
      { prop: "foodchain", value: name },
      { prop: "limit", value: limit },
      { prop: "ordering", value: ordering },
    ];

    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/results_light/?${paramUrl}`;
    const response = await securedRestClientContracts.get<ApiList<ResultsLight[]>>(
      requestUrl
    );
    return response.data;
  },
  getSpecificationResultsLight: async ({ pk, limit, offset, ordering }: GetResults) => {
    
    const params: { prop: string; value: string | number | undefined }[] = [
      { prop: "specification_pk", value: pk },
      { prop: "limit", value: limit },
      { prop: "offset", value: offset},
      { prop: "ordering", value: ordering },
    ];
    
    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/results_light/?${paramUrl}`;
    const response = await securedRestClientContracts.get<ApiList<ResultsLight[]>>(
      requestUrl
    );
    return response.data;
  },

  getResults: async ({ name, parcel, limit, ordering }: GetResults) => {
    const params: { prop: string; value: string | number | undefined }[] = [
      { prop: "foodchain", value: name },
      { prop: "parcel", value: parcel },
      { prop: "limit", value: limit },
      { prop: "ordering", value: ordering },
    ];

    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/?${paramUrl}`;
    const response = await securedRestClientContracts.get<ApiList<Results[]>>(
      requestUrl
    );
    return response.data;
  },

  setResultsLight: async ({
    specification_pk,
    actor,
    parcel,
    foodchain,
    specifications,
    contract,
    conformity,
    limit,
    ordering,
    offset,
    year,
  }: GetResults) => {
    const params: { prop: string; value: string | number | undefined }[] = [
      { prop: "specification_pk", value: specification_pk },
      { prop: "specifications", value: specifications },
      { prop: "actor", value: actor },
      { prop: "parcel", value: parcel },
      { prop: "foodchain", value: foodchain },
      { prop: "contract", value: contract },
      { prop: "conformity", value: conformity },
      { prop: "limit", value: limit },
      { prop: "ordering", value: ordering },
      { prop: "offset", value: offset},
      { prop: "year", value: year},
    ];

    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/results_light/?${paramUrl}`;
    const response = await securedRestClientContracts.get<ApiList<ResultsLight[]>>(
      requestUrl
    );
    return response.data;
  },

  downloadResults: async ({ name, pk, year }: DownloadResults) => {
    const params: { prop: string; value: string | number | undefined }[] = [
      { prop: "foodchain", value: name },
      { prop: "specification_pk", value: pk },
      { prop: "year", value: year}
    ];

    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/exportCSV?${paramUrl}`;
    const response = await securedRestClientContracts.get(requestUrl);
    return response.data;
  },

  upDateResults: async ({ name, pk }: UpDateResults) => {
    const params: { prop: string; value: string | number | undefined }[] = [
      { prop: "foodchain", value: name },
      { prop: "specification_pk", value: pk }
    ];

    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/update_contracts/?${paramUrl}`;
    const response = await securedRestClientContracts.get(requestUrl);
    return response.data;
  },
};

