import { toast, ToastOptions } from 'react-toastify';
import { ToastType } from '../models';

export const toastHandler = (message: string, type: ToastType) => {
  const config: ToastOptions = {
    position: 'bottom-left',
  };

  switch (type) {
    case 'error': {
      toast.error(message, config);
      break;
    }
    case 'info': {
      toast.info(message, config);
      break;
    }
    case 'success': {
      toast.success(message, config);
      break;
    }
  }
};
