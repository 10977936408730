import React from 'react';

import { Tab } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './group.module.css';
import { GroupProps } from './group-types';
import enhancer from './group-enhancer';
import { TabPanel, ClickableContainer } from '../../../components';
import { ColorLayout, TitleLayout, ContainerLayout } from '../../../layouts';
import {
  actorSubService, siteSubService, toolSubService, certificateSubService, productSubService, relationSubService,
} from '../../../services';
import { useHover } from '../../../hooks';
import editPicto from '../../../../assets/pictos/editPictoWhite.png';
import editPictoHover from '../../../../assets/pictos/editPictoGreen.png';
import {
  ActorsHeader, SitesHeader, ToolsHeader, CertificatesHeader, ProductsHeader, RelationsHeader,
} from '../../../constants';
import { posthogEvents } from '../../../utils/posthog';

const InnerGroup: React.FC<GroupProps> = React.memo(({ id, groupLight }: GroupProps) => {
  const specificParams = { group_id: id, ordering: 'name' };
  const { t } = useTranslation();
  posthogEvents.triggerPageView();

  const panes = [
    {
      menuItem: t('ecosystem.actors.label'),
      render: () => (
        <TabPanel
          include="actors"
          limit={15}
          specificParams={specificParams}
          service={actorSubService.getAllActor}
          initHeader={ActorsHeader}
        />
      ),
    },
    {
      menuItem: t('ecosystem.sites.label'),
      render: () => (
        <TabPanel
          include="sites"
          limit={100}
          specificParams={specificParams}
          service={siteSubService.getSite}
          initHeader={SitesHeader}
        />
      ),
    },
    {
      menuItem: t('ecosystem.tools.label'),
      render: () => (
        <TabPanel
          include="tools"
          limit={100}
          specificParams={specificParams}
          service={toolSubService.getTool}
          initHeader={ToolsHeader}
        />
      ),
    },
    {
      menuItem: t('ecosystem.certificates.label'),
      render: () => (
        <TabPanel
          include="certificates"
          limit={100}
          specificParams={specificParams}
          service={certificateSubService.getCertificate}
          initHeader={CertificatesHeader}
        />
      ),
    },
    {
      menuItem: t('ecosystem.products.label'),
      render: () => (
        <TabPanel
          include="products"
          limit={100}
          specificParams={specificParams}
          service={productSubService.getProduct}
          initHeader={ProductsHeader}
        />
      ),
    },
    {
      menuItem: t('ecosystem.relations.label'),
      render: () => (
        <TabPanel
          include="relations"
          limit={100}
          specificParams={specificParams}
          service={relationSubService.getRelation}
          initHeader={RelationsHeader}
        />
      ),
    },
  ];

  return (
    <ColorLayout color="#0a405d">
      <ContainerLayout>
        <TitleLayout>
          <TitleBloc titleLink={id !== 'all' ? `/ecosystem/edit-group/${id}` : ''} title={groupLight.name} />
        </TitleLayout>
        <div className={styles.list}>
          <Tab panes={panes} menu={{ widths: 6 }} style={{ height: ' 100%'}} />
        </div>
      </ContainerLayout>
    </ColorLayout>
  );
});

export const EcosystemGroupPage = enhancer(InnerGroup);

const TitleBloc = ({ titleLink, title }: {titleLink: string; title: string}) => {
  const { t } = useTranslation();
  const { hovered, bind } = useHover();
  return (
    <ClickableContainer onHover={bind} className="clickable" link={titleLink}>
      <div className={styles.title}>
        {titleLink && <img src={hovered ? editPictoHover : editPicto} className={styles.picto} />}
        <b style={titleLink ? hovered ? { color: '#95c043' } : {} : { cursor: 'default' }}>{titleLink ? title : t('ecosystem.groups.all')}</b>
      </div>
    </ClickableContainer>
  );
};
