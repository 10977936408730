import { WithStatelessDatasFetchingByParameters, WithQuerystringReading } from "../../hocs";
import { Folder, GetFolder } from "../../models";
import { folderService } from "../../services";
import { PageLoader } from "../../components";
import React from 'react';
import { compose } from 'redux';
import { FolderEditPageProps } from ".";
import { FolderEditPageResult } from "./folder-edit-page-type";


export const enhancer = compose<React.FC<FolderEditPageProps>>(
    WithQuerystringReading(),
    WithStatelessDatasFetchingByParameters<FolderEditPageResult, FolderEditPageProps, GetFolder>(
    folderService.getEditFolderPage,
    PageLoader,
    'redirect',
    'result',
    ['id']
    )
)
