import React from "react";
import { useTranslation } from 'react-i18next';
import { FoodchainResultsPageProps } from "./foodchain-results-page-type";
import styles from "./foodchain-results-page.module.css";
import { enhancer } from "./resultEnhancer";
import { FoodchainResultsHeader } from "./foodchain-results-header";
import { ResultsHeader } from "../../constants/tab-header-contract";
import { TabPanel } from "../../components/tab-panel-contract";
import { resultsSubService } from "../../services";
import { useParams } from "../../hooks/use-params-contract";
import { header } from '../../constants/menu';
import { posthogEvents } from "../../utils/posthog";
import { useLocation, useParams as usePara } from "react-router-dom";


export const InnerFoodchainResultsPage: React.FC<FoodchainResultsPageProps> = ({
  results,
}: FoodchainResultsPageProps) => {
  posthogEvents.triggerPageView();
  const { t } = useTranslation();

  const location : any = useLocation()
  const year = window.localStorage.getItem('year')
  const name = window.localStorage.getItem('name')
  const {
    params,
    header,
    manageOrdering,
    manageSearch,
  } = useParams(ResultsHeader, { foodchain: name, year: year });

  if (!results) {
    return <div>Pas de résultat</div>;
  }
  return (
    <div className={styles.mainWrapper} style={{ paddingTop: '1vh' }}>
      <FoodchainResultsHeader
        results={results}
        name={name}
        manageSearch={manageSearch} 
        header={header}
        year={year}
      />

      <div className={styles.resultsWrapper}>
        <TabPanel
          limit={100}
          service={resultsSubService.setResultsLight}
          initHeader={ResultsHeader}
          manageSearch={manageSearch}
          manageOrdering={manageOrdering}
          header={header}
          params={params}
        />
      </div>
    </div>
  );
};

export const FoodchainResultsPage = enhancer(InnerFoodchainResultsPage);
