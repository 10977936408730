import {
  GetFlowAlert, Flow, 
} from '../models';
import { alertSubService, flowSubService } from './sub-services';
import { FlowAlertResult } from '../pages';
import { backendTokenService, securedRestClient } from '../backend';

export const FlowAlertService = {
  getFlowAlertPage: async ({
    id
  }: GetFlowAlert) => {
    const params = { id };
    const flowAlert = await flowSubService.getFlowAlert(params);
    const alertCluster = await alertSubService.getAlertGroup(params);
    const result: FlowAlertResult = {
      flows: flowAlert,
      general: alertCluster
    };
    // console.log(result);
    return result;
  },

  updateCluster : async (comment : string, id: number, status : string) => {
    const updateStatus = await alertSubService.updateAlerteStatus(id, status);
    //Route à créer en back
    // const saveComment = await alertSubService.saveClusterComment(comment, id);

  
    return updateStatus
  }


};
