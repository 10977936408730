import { WithStatelessDatasFetchingByParameters } from '../../../hocs';
import { GetItemCount } from '../../../models';
import { ecosystemService } from '../../../services';
import { ItemCounter, ItemProps } from './item-types';
import { PageLoader } from '../../../components';

const enhancer = WithStatelessDatasFetchingByParameters<ItemCounter, ItemProps, GetItemCount>(
    ecosystemService.getItemCount,
    PageLoader,
    'error',
    'counts',
    ['id', 'propertyName'],
  );

export default enhancer;
