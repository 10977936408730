import React from 'react';
import styles from './item.module.css';
import { ItemProps } from './item-types';
import { ContainerLayout } from '../../../layouts';
import { LightItem } from '.';
import { useTranslation } from 'react-i18next';
import { ExpanderPanel } from '../../../components';
import enhancer from './item-enhancer';

const InnerPage: React.FC<ItemProps> = React.memo(({
  counts, panels, itemLight, specificParams
}: ItemProps) => {
  const {t} = useTranslation();

  const myRef = React.useRef<HTMLDivElement | null>(null);
  const [activeExpander, setActiveExpander] = React.useState<number | undefined>();


  const handleClick = React.useCallback((active?:number, index?:number) => {
    if (active === index ) {
      setActiveExpander(undefined);
    } else {
      setActiveExpander(index);
      setTimeout(() => (myRef.current?.scrollIntoView(),0))}
  }, []);


  
  return (
    <ContainerLayout>
      <div className={styles.itemLightContainer}>
        <LightItem 
          description={itemLight.description}
          title={itemLight.title}
          picto={itemLight.picto}
          permissionRoute={itemLight.permissionRoute}
          link={itemLight.link}
          document={itemLight.document ? itemLight.document : ''}
          
          
        />
      </div>
      <div className={styles.expanderTitle}>
      </div>
      {/* {console.log('panels : ',panels)}
      {console.log('activeExpander : ',activeExpander)} */}
      {panels.actors && 
      <div className={styles.expander} ref={activeExpander === panels.actors.index ? myRef : undefined} id={`scrollStep${panels.actors.index}`} key={panels.actors.index}>
          <ExpanderPanel 
            handleActive={() => handleClick(activeExpander, panels.actors?.index)}
            item={'actors'}
            limit={10}
            specificParams={specificParams}
            service={panels.actors.service}
            initHeader={panels.actors.initHeader}
            count={counts.actors}
            active={panels.actors.index === activeExpander}
            picto={panels.actors.picto}
          />
        </div> }
        {panels.sites && 
      <div className={styles.expander} ref={activeExpander === panels.sites.index ? myRef : undefined} id={`scrollStep${panels.sites.index}`} key={panels.sites.index}>
          <ExpanderPanel 
            handleActive={() => handleClick(activeExpander, panels.sites?.index)}
            item={'sites'}
            limit={10}
            specificParams={specificParams}
            service={panels.sites.service}
            initHeader={panels.sites.initHeader}
            active={panels.sites.index === activeExpander}
            count={counts.sites}
            picto={panels.sites.picto}
          />
        </div> }
        {panels.tools && 
      <div className={styles.expander} ref={activeExpander === panels.tools.index ? myRef : undefined} id={`scrollStep${panels.tools.index}`} key={panels.tools.index}>
          <ExpanderPanel 
            handleActive={() => handleClick(activeExpander, panels.tools?.index)}
            item={'tools'}
            limit={10}
            specificParams={specificParams}
            service={panels.tools.service}
            initHeader={panels.tools.initHeader}
            active={panels.tools.index === activeExpander}
            count={counts.tools}
            picto={panels.tools.picto}
          />
        </div> }
        {panels.products && 
      <div className={styles.expander}  ref={activeExpander === panels.products.index ? myRef : undefined} id={`scrollStep${panels.products.index}`} key={panels.products.index}>
          <ExpanderPanel 
            handleActive={() => handleClick(activeExpander, panels.products?.index)}
            item={'products'}
            limit={10}
            specificParams={specificParams}
            service={panels.products.service}
            initHeader={panels.products.initHeader}
            active={panels.products.index === activeExpander}
            count={counts.products}
            picto={panels.products.picto}
          />
        </div> }
        {panels.certificates && 
      <div className={styles.expander} ref={activeExpander === panels.certificates.index ? myRef : undefined} id={`scrollStep${panels.certificates.index}`} key={panels.certificates.index}>
          <ExpanderPanel 
            handleActive={() => handleClick(activeExpander, panels.certificates?.index)}
            item={ 'certificates'}
            limit={10}
            specificParams={specificParams}
            service={panels.certificates.service}
            initHeader={panels.certificates.initHeader}
            active={panels.certificates.index === activeExpander}
            count={counts.certificates}
            picto={panels.certificates.picto}
          />
        </div> }
        {panels.relations && 
      <div className={styles.expander} ref={activeExpander === panels.relations.index ? myRef : undefined} id={`scrollStep${panels.relations.index}`} key={panels.relations.index}>
          <ExpanderPanel 
            handleActive={() => handleClick(activeExpander, panels.relations?.index)}
            item={ 'relations'}
            limit={10}
            specificParams={specificParams}
            service={panels.relations.service}
            initHeader={panels.relations.initHeader}
            active={panels.relations.index === activeExpander}
            count={counts.relations}
            picto={panels.relations.picto}
          />
        </div> }
      

    </ContainerLayout>
  );
});

export const EcosystemItem = enhancer(InnerPage);