import React from 'react';
import { OwnerFormProps } from './owner-form-type';
import styles from './owner-form.module.css';
import { actorSubService } from '../../../services';
import { ActorsHeader } from '../../../constants';
import { useParams } from '../../../hooks/use-params';
import { WithLazyloadingDatasFetching } from '../../../hocs/with-lazyloading-datas-fetching';
import { Actor } from '../../../models';
import { TableFormProps, TableForm } from '../table-form';

export const OwnerForm: React.FC<OwnerFormProps> = ({
  model,
  onSave,
  errorMessage,
  onCancel,
  onNext,
  onPrevious,
  include,
  blocking,
  multiple,
  specificParams,
}: OwnerFormProps) => {
  const {
    params, header, manageOrdering, manageSearch, focused
  } = useParams(ActorsHeader, specificParams);
  const [editedModel, setEditedModel] = React.useState(model);

  const handleAll = React.useCallback((add: boolean) => {
    const fetchAllItems = async (add: boolean) => {
      try {
        const result = await actorSubService.getAllActor({ ...params, limit: 100000 });
        const idList = result.results.map((el: any) => el.pk).filter((el) => el !== null);
        setEditedModel(idList as number[]);
      } catch {
        console.log('Une erreur est survenu');
      }
    };
    if (add) {
      fetchAllItems(add);
    } else {
      setEditedModel([]);
    }
  }, [params]);

  React.useEffect(() => {
    editedModel !== model && setEditedModel(model);
  }, [model])

  const EnhancedTableForm = WithLazyloadingDatasFetching<Actor, TableFormProps<Actor>>(
    actorSubService.getAllActor, 'data', params, params.limit ? parseInt(params.limit) : 100,
  )(TableForm);

  return (
    <div className={styles.tableWrapper}>
      <EnhancedTableForm
        data={[]}
        header={header}
        manageOrdering={manageOrdering}
        manageSearch={manageSearch}
        focused={focused}
        model={editedModel}
        onSave={onSave}
        errorMessage={errorMessage}
        onCancel={onCancel}
        onNext={onNext}
        onPrevious={onPrevious}
        include={include}
        blocking={blocking}
        multiple={multiple}
        handleAll={multiple ? handleAll : undefined}
      />
    </div>
  );
};
