import { Panel } from "./model";
import actorPictoBlue from '../../../../assets/pictos/actorPictoBlue.svg';
import certificatePictoBlue from '../../../../assets/pictos/certificatePictoBlue.svg';
import sitePictoBlue from '../../../../assets/pictos/sitePictoBlue.svg';
import productPictoBlue from '../../../../assets/pictos/productPictoBlue.svg';
import toolPictoBlue from '../../../../assets/pictos/toolPictoBlue.svg';
import relationPictoBlue from '../../../../assets/pictos/RelationPictoBlue.png';
import { actorSubService, siteSubService, toolSubService, productSubService, certificateSubService, relationSubService } from "../../../services";
import { ActorsHeader, SitesHeader, ToolsHeader, ProductsHeader, CertificatesHeader, RelationsHeader } from "../../../constants";
import { Actor, SiteRead, ToolRead, ProductRead, CertificateRead, Relation } from "../../..";

export const actorPanel: Panel<Actor> = {
    index: 0,
    picto: actorPictoBlue,
    limit: 50,
    service: actorSubService.getActor,
    initHeader:ActorsHeader
  };
  
export const sitePanel: Panel<SiteRead> = {
    index: 1,
    picto: sitePictoBlue,
    limit: 50,
    service: siteSubService.getSite,
    initHeader:SitesHeader
};

export const toolPanel: Panel<ToolRead> = {
    index: 2,
    picto: toolPictoBlue,
    limit: 50,
    service: toolSubService.getTool,
    initHeader:ToolsHeader
};

export const productPanel: Panel<ProductRead> = {
    index: 3,
    picto: productPictoBlue,
    limit: 50,
    service: productSubService.getProduct,
    initHeader:ProductsHeader
};

export const certificatePanel: Panel<CertificateRead> = {
    index: 4,
    picto: certificatePictoBlue,
    limit: 50,
    service: certificateSubService.getCertificate,
    initHeader:CertificatesHeader
};

export const relationsPanel: Panel<Relation> = {
    index: 5,
    picto : relationPictoBlue,
    limit: 50,
    service : relationSubService.getRelation,
    initHeader: RelationsHeader
};

export const initialCount = {
    actors: 0,
    sites: 0,
    tools: 0,
    products: 0,
    certificates: 0,
    flows: 0,
    batchs: 0,
    relations: 0

  }