import statusRed from '../../assets/pictos/statusRed.png';
import statusOrange from '../../assets/pictos/statusOrange.png';
import statusYellow from '../../assets/pictos/statusYellow.png';
import { Criticality } from '../models';

export const criticalityDictionnary: CriticalityDictionnary = {
  critical: statusRed,
  important: statusOrange,
  medium: statusYellow,
};

export type CriticalityDictionnary = { [key in Criticality]: string }
