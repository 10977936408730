import { compose } from 'redux';
import { WithQuerystringReading, WithNotifyRessourceLoadedInputProps, WithStatelessDatasFetchingByParameters } from '../../../../hocs';
import { GetGroupLight, GetProduct } from '../../../../models';
import { ProductProps } from '.';
import { productSubService } from '../../../../services';
import { GroupList } from '../../group';
import { PageLoader } from '../../../../components/page-loader';

const enhancer = compose<
React.ComponentType<GetGroupLight & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<GroupList, ProductProps, GetProduct>(
    productSubService.getProduct,
    PageLoader,
    'error',
    'products',
    ['id'],
  ),
);


export default enhancer;
