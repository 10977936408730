import React from 'react';

import { Placeholder } from 'semantic-ui-react';
import styles from '../../../../layouts/wizard-layout/wizard-layout.module.css';

export const EditActorLoader: React.FC = React.memo(() => (
  <div>
    <Placeholder className={styles.content}>
      <div className={styles.content}><Placeholder.Image square /></div>
      <Placeholder.Header>
        <Placeholder.Line length="full" />
        <Placeholder.Line length="short" />

      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
      </Placeholder.Paragraph>
    </Placeholder>
  </div>
));
