import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { FilterButton } from '..';
import '../date-picker/date-picker-module.css'

const DatePickerComponent = ({ onDayClick, text }: any) => {

  const [selectedDay, setSelectedDay] = useState<Date>()
  const [open, setOpen] = useState<boolean>(false)

  const styleBtn = {
    borderBottomRightRadius: '0%',
    borderBottomLeftRadius: '0%',
  }
  return (
    <div className='date-picker-container' onMouseOver={() => setOpen(true)} onMouseLeave={() => setOpen(false)} >
      <FilterButton style={open ? styleBtn : {}} text={text} onClick={() => setOpen(!open)} />
      {open && 
      <DayPicker
        selectedDays={selectedDay}
        onDayClick={(day: Date) => { 
          setSelectedDay(day)
          onDayClick(day)
        }}
        className='datePicker'
        showOutsideDays
        locale='fr'
      />}
    </div>
  );
}

export default DatePickerComponent
