import React from "react";
import { useTranslation } from "react-i18next";
import { SpecificationResultsHeaderProps } from "./specification-results-page-type";
import styles from "./specification-results-page.module.css";
import { SpecificationConformityFilter } from "./specification-results-conformity-filter";
import { SpecificationInfocard } from "./specification-infocard";
import { Modal, Icon } from "semantic-ui-react";
import reload from "../../../assets/pictos/reload.svg";
import { ClassicButton } from "../../components";
import { resultsSubService } from "../../services/sub-services";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { posthogEvents } from "../../utils/posthog";
import { CSVLink } from "react-csv";


export const SpecificationResultsHeader: React.FC<SpecificationResultsHeaderProps> = ({
  results,
  manageSearch,
  pk,
}: SpecificationResultsHeaderProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [confirm, setConfirm] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState<boolean>(true);
  const [csv, setCsv] = React.useState([])
  const csvLink = React.useRef<any>()

  const exportCSV = async () => {
    posthogEvents.triggerEvent({
      name: "clickOn",
      datas: { foodchain: "downloadCSV" },
    });
   setLoading(true)
    try {
      const response = await resultsSubService.downloadResults({ pk });
      setCsv(response);
      csvLink.current.link.click();
    } catch (error) {
    }
    setLoading(false)
  }

  const upDateresults = async (pk: number) => {
    posthogEvents.triggerEvent({ name : 'clickOn', datas:{ 'foodChainModal': 'validate'}})
    setLoading(true);
    try {
      const update = await resultsSubService.upDateResults({ pk });
      setConfirm(false);
      return update;
    } catch (error) {
    }
    setLoading(false);
  };

  const cancelledResults = () => {
    posthogEvents.triggerEvent({ name : 'clickOn', datas:{ 'foodChainModal': 'cancel'}})
    setLoading(false);
    setConfirm(false);
  };

  const handleModalOpen = () => {
    posthogEvents.triggerEvent({ name : 'clickOn', datas: {'foodchainResults' : 'refresh'}})
    setConfirm(true);
  }

  const handleChangeChecked = () => {
    setChecked(!checked);
  };

  const specification = results.results[0].specifications;
  const day = results.results[0].audited_at_day;
  const hour = results.results[0].audited_at_hour;

  return (
    <div className={styles.headerWrapper} style={{ paddingTop: "20px" }}>
      <div className={styles.titleHeader}>
        <strong>
          {specification} - {t("specificationsResults.report")} {day}{" "}
          {t("specificationsResults.at")} {hour}
        </strong>
      </div>
      <div className={styles.topHeader}>
        <div
          style={{
            cursor: "pointer",
            marginTop: "3%",
            marginRight: "1%",
            display: "flex",
            width: "20%",
          }}
        >
          <img src={reload} alt="img-reload" />

          <Modal
            onClose={() => setConfirm(false)}
            onOpen={handleModalOpen}
            open={confirm}
            trigger={
              <div
                style={{
                  marginLeft: "6px",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  display: "inline-block",
                }}
              >
                {t("specificationsResults.refreshAudits")}
              </div>
            }
          >
            <div style={{ marginLeft: "5%", marginBottom: "2%" }}>
              <div style={{ marginTop: "3%", marginBottom: "2%" }}>
                <strong
                  style={{
                    fontSize: "2em",
                    marginTop: "-4%",
                  }}
                >
                  {t("specificationsResults.refreshAudits")}
                </strong>
              </div>
              <p style={{ fontSize: "1.5em" }}>
                {t("specificationsResults.popMessage")}
              </p>
              <div
                style={{
                  marginTop: "2%",
                  float: "right",
                  marginRight: "5%",
                  marginBottom: "2%",
                }}
              >
                <ClassicButton
                  text={t("specificationsResults.cancel")}
                  secondary
                  onClick={() => cancelledResults()}
                  data-automation-id="cancelled"
                  style={{ marginRight: "15px" }}
                />

                <ClassicButton
                  text={t("specificationsResults.confirm")}
                  onClick={() => upDateresults(pk)}
                  data-automation-id="confirmed"
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <div>
        <SpecificationInfocard pk={pk} />
      </div>
      <div
        className={styles.bottomHeader}
        style={{ marginTop: "5vh", marginBottom: "4vh" }}
      >
        <div>
          <SpecificationConformityFilter manageSearch={manageSearch} />
        </div>
        <div className={styles.buttonExport}>
        <ClassicButton
            text={
              loading ?
                (<React.Fragment>
                  <Icon loading name='spinner' /> {t("foodchainResults.exportToCSV")} 
                </React.Fragment>) 
                :
                `${t("foodchainResults.exportToCSV")}`}
            secondary
            data-automation-id="downloadCSV"
            onClick={exportCSV}
          />
        <CSVLink
          data={csv}
          ref={csvLink}
          filename={`${specification.replace(/ /g, "-")}.csv`}
        />    
        </div>
      </div>
    </div>
  );
};
