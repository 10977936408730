import React from 'react';
import { FilterButtonProps } from './filter-button-type';
import styles from './filter-button-component.module.css';

export const FilterButton: React.FC<FilterButtonProps> = ({
  disabled,
  text,
  secondary,
  onClick,
  inverted,
  warning,
  selected,
  style,
  onMouseOver,
  onMouseLeave, 
}: FilterButtonProps) => (
  // eslint-disable-next-line react/button-has-type

  <button
    className={`${styles.filterBtn} ${secondary ? styles.secondary : ''} ${inverted ? styles.inverted : ''} ${warning ? styles.warning : ''} ${disabled ? styles.disabled : ''} ${selected ? styles.selected : ''}`}
    disabled={disabled}
    onClick={onClick}
    style={style}
    onMouseLeave={onMouseLeave}
    onMouseOver={onMouseOver}
  >
     {text} 
  </button>
);
