import React from 'react';
import styles from './modification-component.module.css'
import { ModificationComponentProps } from './modification-component-type';
import i18next from 'i18next';
import { formatDate, formatTime } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import { checkServerIdentity } from 'tls';
import { checkExpiredDate } from '../../table/utils';


export const ModificationComponent : React.FC<ModificationComponentProps> = (props : ModificationComponentProps) => {
    const { name, type, date, changedFields } = props
    const {t} = useTranslation();
    type Model = 'flow_date'

    return <div className={styles.modif}>
        <p>{`${name}  ${ type === 'flow' ? t(`alerts.alertHistory.modified_flow`): t('alerts.alertHistory.modified_certificate')}  ${formatDate(date, i18next.language)} à ${formatTime(date, i18next.language)}`}</p>
        {changedFields.length>0 && changedFields.map((el, key) => {
        
            return <p key={key}>{`${t('alerts.alertHistory.value')} = ${el.value } : ${t('alerts.alertHistory.old')} = ${el.changed_old !== 'none' ? el.changed_old : t('alerts.alertHistory.none')}, ${t('alerts.alertHistory.new')} = ${el.changed_new}`} </p>
        })}
        </div>
}