import React from 'react';
import i18next from 'i18next';
import styles from './alert-light.module.css';
import { AlertLightProps } from './alert-light-types';
import { GenericList } from '../../../components';
import { AlertPreview } from '../../../models';
import { SidebarContext } from '../../../contexts';
import { criticalityDictionnary } from '../../../constants';
import { formatDate, formatTime } from '../../../utils';
import { AlertLightLoader } from '.';
import { WithLazyloadingDatasFetching } from '../../../hocs/with-lazyloading-datas-fetching';


export const AlertLight: React.FC<AlertLightProps> = React.memo(({
  limit, service, specificParams, type,
}: AlertLightProps) => {
  const EnhancedAlertList = WithLazyloadingDatasFetching<AlertPreview, AlertListProps>(
    service, 'data', { ...specificParams, type }, limit,
  )(AlertList);

  return (

    <div className={styles.alertList}>
      <EnhancedAlertList data={[]} />
    </div>
  );
});

interface AlertListProps {
  data: AlertPreview[];
  loading?: boolean;
  error?: boolean;
  loadMoreItems?: () => void;
  endFetching?: boolean;
}

function AlertList({
  data, loading, error, loadMoreItems, endFetching,
}: AlertListProps) {
  const intersectLastTarget = React.useRef<HTMLDivElement>(null);

  const block = React.useRef(false);

  const [display, setDisplay] = React.useState(block.current);

  React.useEffect(() => {
    block.current = false;
  }, [display]);

  React.useEffect(() => {
    const callback: IntersectionObserverCallback = (list) => {
      list.forEach((entry) => {
        if (!block.current && entry.isIntersecting) {
          block.current = true;
          setDisplay(true);
          loadMoreItems && loadMoreItems();
          setTimeout(() => {
            setDisplay(false);
          }, 500);
        }
      });
    };

    const opts = {
      root: null,
      rootMargin: '200px',
      threshold: 1,
    };

    const observerScroll = new IntersectionObserver(callback, opts);

    if (intersectLastTarget.current !== null) {
      observerScroll.observe(intersectLastTarget.current);
    }
  });

  if (error) {
    return (
      <div>
      Erreur
      </div>
    );
  } if ((!data || data.length === 0) && loading) {
    return (
    // <div>
      <AlertLightLoader />
    // </div>
    );
  } if (!data || data.length === 0) {
    return (
      <div>
        Pas de résultat
      </div>
    );
  }
  return (
    <>
      <GenericList
        items={data}
        itemRenderer={(childElement: AlertPreview) => (<SingleAlert alert={childElement} key={childElement.pk} />)}
      />
      {!loading && !block.current && !display && !endFetching
              && (
                <>
                  <div
                    ref={intersectLastTarget}
                    style={{
                      position: 'relative',
                      top: /* data.length < 11 ? `-${5 + data.length * 25}px` : '-250px' */'-50px',
                      left: '10px'
                    }}
                  />
                </>
              )}
    </>
  );
}

interface SingleAlertProps {
  alert: AlertPreview;
}

const SingleAlert = ({ alert }: SingleAlertProps) => {
  const { handleOpenLevel2 } = React.useContext(SidebarContext);

  const handleOpen = (id: number) => {
    handleOpenLevel2(id);
  };

  const flowName = alert.flow ? alert.flow.map(el => el.name).join(", ") : ''
  const description = `${formatDate(alert.date, i18next.language)}: ${formatTime(alert.date, i18next.language)} ${flowName}, `;

  return (
    <div className={styles.alertContainer} onClick={() => handleOpen(alert.pk)}>
      <div className={styles.dateAndFlowBlock}>
        <div className={styles.criticalityContainer}>
          {alert.criticality && <img src={criticalityDictionnary[alert.criticality]} className={styles.criticalityPicto} />}
        </div>
        <div className={styles.description}>
          {description}
        </div>
      </div>
      <div className={styles.description}>
        {alert.message}
      </div>
    </div>
  );
};
