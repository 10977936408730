import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import { ForgetPasswordFormProps, ForgetPasswordProps } from './forget-password-form-type';

import styles from './forget-password-form.module.css';
import { InputControl } from '../form-controls';
import { ClassicButton } from '../../buttons';

export const ForgetPasswordForm: React.FC<ForgetPasswordFormProps> = ({
  model,
  onSuccess,
  errorMessage,
  isAuthenticated,
}: ForgetPasswordFormProps) => {
  const { t } = useTranslation();
  if (isAuthenticated) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <div className={styles.loginForm}>

        <Formik<ForgetPasswordProps>
          initialValues={model}
          onSubmit={(updatedModel) => {
            onSuccess(updatedModel);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className={styles.form}>
              <div className={styles.formContainer}>

                <div className={styles.inputBloc}>
                  <b className={styles.inputLabel}>{t('form.authentication.mail')}</b>
                  <InputControl name="email" required placeholder={t('form.authentication.mail')} />
                </div>
                <div className={styles.forgetContainer}>
                  <Link to="/login" className={styles.forgetLink}>
                    <b>{t('form.authentication.link.login')}</b>
                  </Link>
                </div>

                <div className={styles.submit}>
                  <ClassicButton type="submit" text={t('form.authentication.actions.forgetPasswordSubmit')} />
                </div>
              </div>
              {errorMessage && (
                <span className={styles.submitError}>{errorMessage}</span>
              )}
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
