import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import styles from './group-form.module.css';
import { GroupFormProps, GroupProps } from './group-form-types';
import { ClassicButton, InputControl } from '../..';

export const GroupForm: React.FC<GroupFormProps> = React.memo(({
  model,
  onSave,
  errorMessage,
  onCancel,
  onPrevious,
  onNext,
}: GroupFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Formik<GroupProps>
        onSubmit={onSave}
        initialValues={model}
        render={(props) => (
          <form className={styles.form} onSubmit={props.handleSubmit}>
            <div className={styles.formContainer}>

              {model && (
              <>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.groups.name')}</b>
                  <InputControl
                    name="name"
                    required
                    placeholder={t('form.groups.name')}
                  />
                </div>
              </>
              )}
            </div>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
              onCancel
                ? <ClassicButton text={t('wizard.groups.cancel')} onClick={onCancel} warning />
                : <div>&nbsp;</div>
            }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
              onPrevious
                ? <ClassicButton text={t('wizard.groups.back')} onClick={onPrevious} inverted />
                : <div>&nbsp;</div>
            }
                </div>
                <div className={styles.nextNavigation}>
                  {
              onNext
                ? <ClassicButton onClick={onNext} text={t('wizard.groups.next')} inverted />
                : <div>&nbsp;</div>
            }

                </div>
              </div>
              <div className={styles.submitActions}>
                <ClassicButton type="submit" text={t('wizard.groups.submit')} />
              </div>
            </div>
          </form>
        )}
      />
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
});
