import React from 'react';

import styles from './edit-certificate.module.css';
import { EditCertificateProps } from './edit-certificate-types';
import { WizardLayout } from '../../../../layouts';
import enhancer from './edit-certificate-enhancer';
import { CertificateRead, CertificateWrite } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';
import { CertificateWizard } from '../../../../components/wizards';
import { useLocation } from 'react-router-dom';
import { getParam } from '../../../../components/wizards/utils';

const InnerPage: React.FC<EditCertificateProps> = ({ certificates }: EditCertificateProps) => {
  console.log('edit certificates : ', certificates);
  const location = useLocation();
  if (certificates.length === 0) {
    return <></>;
  }
  const certificate = certificates as unknown as CertificateRead;
  const properCertificate: CertificateWrite = {
    ...certificate,
    owner: certificate.owner.pk,
    document: undefined,
    audit_date: new Date(certificate.audit_date),
    start_date: new Date(certificate.start_date),
    end_date: new Date(certificate.end_date),
    activation_date: new Date(certificate.activation_date),
  };

  const document = certificate && certificate.document;

  const urlStep = getParam(location.search.substring(1), 'step');
  const initialStep:number = urlStep ? parseInt(urlStep) : 0;
  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.wizardWrapper}>
        <CertificateWizard initialStep={initialStep}  edit initialCertificate={properCertificate} document={document} />
      </div>
    </WizardLayout>
  );
};

export const EcosystemEditCertificatePage = enhancer(InnerPage);
