import { HeaderColumn } from '../models';
import { isArray } from 'util';


export function getColumnName <T>(headerColumn: HeaderColumn<T>[], orderer: string) {
  for (const head of headerColumn) {
    if (head.orderer === orderer || `-${head.orderer}` === orderer) {
      return head.columnName;
    }
  }
  return undefined;
}

export function applyOrdering  <T>(data : T[], headerColumn : HeaderColumn<T>[], orderer: string, order : boolean) {
    const columnName : keyof T | undefined = getColumnName(headerColumn, orderer);
    //si on doit comparer sur l'edited value et pas sur la data directement on appel un efonction de comparaison différentes (cas de enhancedFlow)
    // enabled et modified sont 2 propriété propre des enhanced Flow
    if (data.length > 0 && columnName && isArray((data[0] as any).enabled) && typeof (data[0] as any).modified) {
      return data.sort(compareOnKeyEditedValue(columnName, order));
    }
    return columnName !== undefined ? data.sort(compareOnKey(columnName, order)) : data;
}


export function compareOnKey <T>(key : keyof T, order : boolean) {
    return function(a : T, b : T) {
        const aa=a[key] === null? "" : "" + a[key];
        const bb=b[key] === null? "" : "" + b[key];
        if (aa < bb) return order? -1 : 1;
        if (aa > bb) return order? 1 : -1;
        return 0;
    };
  };

export function compareOnKeyEditedValue <T>(key : keyof T, order : boolean) {
    return function(a : T, b : T) {
      //si editedValue.name existe on est dans le cas d'un batch ou d'un ecosystem = on doit comprarer sur le name et pas sur editedValue directement
        const aa=(a[key] as any).editedValue === null ? "" : (a[key] as any).editedValue.name === undefined ? "" + (a[key] as any).editedValue :  "" + (a[key] as any).editedValue.name;
        const bb=(b[key] as any).editedValue === null ? "" : (b[key] as any).editedValue.name === undefined ? "" + (b[key] as any).editedValue :  "" + (b[key] as any).editedValue.name;
        
        if (aa < bb) return order? -1 : 1;
        if (aa > bb) return order? 1 : -1;
        return 0;
    };
};

export function applyFilter <T>(data: T[], headerColumn: HeaderColumn<T>[], sorted? : string, order?: boolean) {
  let newData: T[] = [];
  const filteredheader = headerColumn.filter((el) => el.searchValue.length > 0);
  let verif = false;
  data.forEach((datamap1: T) => {
    verif = true;
    filteredheader.forEach((headermap1: HeaderColumn<T>) => {
        const value: any = datamap1[headermap1.columnName];
        const searchValue = headermap1.searchValue;
        if (value === null) {
          verif = false;
        } else if (value.editedValue === undefined) {
          if(typeof(value) === 'boolean'){
              const booleanSearchValue : boolean = searchValue === 'true';
              if(booleanSearchValue !== value) {
                  verif = false;
              }
          } else if (value === null || !(value + '' as unknown as string).toLowerCase().includes(searchValue.toLowerCase())) {
            verif = false; 
          }
        } else {
          if (value.editedValue === null) {
            verif = false;
          } else if(typeof(value.editedValue) === 'boolean'){
            const booleanSearchValue : boolean = searchValue === 'true';
            if(booleanSearchValue !== value.editedValue) {
                verif = false;
            }
        } else if (value.editedValue.name !== undefined) { // cas batch / ecosystem_preview
          if (!(value.editedValue.name as unknown as string).toLowerCase().includes(searchValue.toLowerCase())) {
            verif = false; 
          }
        } else if (!(value.editedValue + '' as unknown as string).toLowerCase().includes(searchValue.toLowerCase())) {
          verif = false; 
        }
        }
    });
    
    if(verif === true) {
      newData = [...newData, datamap1];
    }
  });
  return newData;
}
