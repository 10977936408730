import { buildUrlParameters } from '../../utils';
import { backendTokenService, securedRestClient } from '../../backend';
import {
  SiteRead, SiteWrite, GetSite, ApiList,
} from '../../models';

export const siteSubService = {
  // ===============
  // ==== SITE ====
  // ===============

  // --- CREATE SITE ---

  createSite: async (site: SiteWrite) => {
    // console.log(
    //   'ecosystem service createsite, site : ',
    //   site,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/sites/`;
    const response = await securedRestClient.post<SiteRead>(requestUrl, site);
    // console.log(response.data);
    return response.data;
  },

  // --- READ SITE ---

  getSite: async ({
    is_placeholder, group_id, id, owner, tool, certificate, limit, offset, ordering, name, internal_code, address, zip_code, city, department, region, country, site_type, owner_name,
  }: GetSite) => {
    // console.log(
    //   'ecosystem service getSite, id : ', id,
    //   'owner', owner,
    //   'tool', tool,
    //   'certificate', certificate,
    //   'limit', limit,
    //   'offset', offset,
    //   'internal_code', internal_code,
    //   'address', address,
    //   'zip_code', zip_code,
    //   'city', city,
    //   'department', department,
    //   'region', region,
    //   'country', country,
    //   'ordering :', ordering,
    //   'group_id', group_id,
    //   'site_type', site_type,
    //   'owner_name', owner_name,
    //   'is_placeholder', is_placeholder,
    // );

    const params: {prop: string; value: string | number | undefined}[] = [
      { prop: 'group_id', value: group_id },
      { prop: 'limit', value: limit },
      { prop: 'offset', value: offset },
      { prop: 'ordering', value: ordering },
      { prop: 'owner', value: owner },
      { prop: 'id', value: id },
      { prop: 'tool', value: tool },
      { prop: 'certificate', value: certificate },
      { prop: 'name', value: name },
      { prop: 'internal_code', value: internal_code },
      { prop: 'address', value: address },
      { prop: 'zip_code', value: zip_code },
      { prop: 'city', value: city },
      { prop: 'department', value: department },
      { prop: 'region', value: region },
      { prop: 'country', value: country },
      { prop: 'site_type', value: site_type },
      { prop: 'owner_name', value: owner_name },
      { prop: 'is_placeholder', value: is_placeholder },
    ];
    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/sites/${id || ''}?${paramUrl}`;
    const response = await securedRestClient.get<ApiList<SiteRead[]>>(requestUrl);
    // console.log(response.data);
    return response.data;
  },

  // --- UPDATE SITE ---

  editSite: async (site: SiteWrite) => {
    // console.log(
    //   'ecosystem service putSite, site : ',
    //   site,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/sites/${site.pk}`;
    const response = await securedRestClient.put<SiteRead>(requestUrl, site);
    // console.log(response.data);
    return response.data;
  },

  // --- DELETE SITE ---

  deleteSite: async (id: number) => {
    // console.log(
    //   'ecosystem service deleteSite, site id : ',
    //   id,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/sites/${id}`;
    const response = await securedRestClient.delete(requestUrl);
    // console.log(response.data);
    return response.data;
  },
};
