import { ApiList } from '../../models';

export const mergedGroupList = <T> (group1: T[], group2?: ApiList<T[]> | undefined): T[] => {
  let res1: T[] = [];
  let res2: T[] = [];
  if (group1) {
    res1 = group1;
  }
  if (group2 && group2.results) {
    res2 = group2.results;
  }
  return [...res1, ...res2] as T[];
};
