import * as React from 'react';

import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './alert-group.module.css';
import { AlertGroupListProps } from '.';
import { AlertGroupItem } from './alert-group-item';
import { AlertGroup, AlertGroupLight } from '../../../models';
import { posthogEvents } from '../../../utils/posthog';


export function AlertGroupList ({
  data,
  header,
  loading,
  error,
  loadMoreItems,
  endFetching,
}: AlertGroupListProps) {
  const { t } = useTranslation();
  const intersectLastTarget = React.useRef<HTMLDivElement>(null);
  const [dataEvent, setDataEvent] = React.useState<boolean>(false);
  const [bottomEvent, setBottomEvent] = React.useState<boolean>(false);

  const block = React.useRef(false);

  const [display, setDisplay] = React.useState(block.current);

  React.useEffect(() => {
    block.current = false;
  }, [display]);

  React.useEffect(() => {
    const callback: IntersectionObserverCallback = (list) => {
      list.forEach((entry) => {
        if (!block.current && entry.isIntersecting) {
          !bottomEvent && posthogEvents.triggerEvent({ name: 'goTo', datas: { 'bottom': 'alertList'} });
          setBottomEvent(true);
          block.current = true;
          setDisplay(true);
          loadMoreItems &&  loadMoreItems();
          setTimeout(() => {
            setDisplay(false);
          }, 500);
        }
      });
    };

    const opts = {
      root: null,
      rootMargin: '200px',
      threshold: 1,
    };

    const observerScroll = new IntersectionObserver(callback, opts);

    if (intersectLastTarget.current !== null) {
      observerScroll.observe(intersectLastTarget.current);
    }
  });
    const handleDataSucces = () => {
       posthogEvents.triggerEvent({name: 'request', datas: {'alertData': 'succes'} });
       setDataEvent(true);
    }
    
  if (error) {
    posthogEvents.triggerEvent({name: 'request', datas: {'alertData': 'error'} });
    return (
      <div className={styles.table}>
            <b>{t('table.utils.error')}</b>
      </div>
    );
  } if ((!data || data.length === 0) && loading) {
    return (
      <div className={styles.table}>
        <Loader active size="huge" className={styles.semanticLoader} />
      </div>
    );
  } if (!data || data.length === 0) {
    posthogEvents.triggerEvent({name: 'request', datas: {'alertData': 'noResults'} });
    return (
      <div className={styles.table}>
            <b>{t('table.utils.noResult')}</b>
      </div>
    );
  }
  !dataEvent && handleDataSucces();
  return (
    <div className={styles.errorFileContainer}>
      {
        data.map((el: AlertGroupLight, key) => (
            <AlertGroupItem
                key={key}
                data={el}
                header={header}
                position={key +1}
            />
        ))
      }
          
      {!loading && !block.current && !display && !endFetching
            && (
              <>
                <div
                  ref={intersectLastTarget}
                  style={{
                    position: 'relative',
                    top: /* data.length < 11 ? `-${5 + data.length * 25}px` : '-250px' */'-50px',
                  }}
                />
              </>
            )}
    </div>
  );
}
