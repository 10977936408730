import { compose } from 'redux';
import {
  WithNotifyRessourceLoaded,
  WithNotifyRessourceLoadedInputProps,
  WithQuerystringReading,
  WithStatelessDatasFetchingByParameters,
} from "../../hocs";
import { ApiList } from "../../models";
import {
  RiskStatus,
  GetRiskStatus,
  RiskStatusProps,
} from "../../models/risk-status";

import { ViewRiskStatusProps } from "./view-risk-status-page-type";
import { viewRiskStatusService } from "../../services";
import { PageLoader } from "../../components";
import React from "react";

export const viewRiskStatusEnhancer = compose<React.ComponentType<RiskStatusProps & WithNotifyRessourceLoadedInputProps>>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<
  ApiList<RiskStatus[]>,
  ViewRiskStatusProps,
  GetRiskStatus>(
    viewRiskStatusService.getRiskStatus, 
    PageLoader, 
    "error", 
    "datas", 
    ["specifications"]
  )
);