import {
  GetFlow, AuditedFlow,
} from '../models';
import { flowSubService, auditSubService } from './sub-services';


export const trackingService = {
  getFlowPage: async ({ id }: GetFlow) => {
    const flow = await flowSubService.getFlow({ id });
    const audits = await auditSubService.getAllAudit({ flow: id, is_archived: 'false' });
    const result: AuditedFlow = { flow, audits };
    return result;
  },
};
