import React from 'react';
import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import {
  FolderFormProps,
} from './folder-form-type';
import styles from './folder-form.module.css';
import { ClassicButton } from '../../buttons';
import { Folder } from '../../../models';
import { Input } from 'semantic-ui-react';

export const FolderForm: React.FC<FolderFormProps> = ({
  model,
  onSave,
  errorMessage,
  onCancel,
  onNext,
  onPrevious,
}: FolderFormProps) => {
  const { t } = useTranslation();
  const [folder, setFolder] = React.useState<Folder>(model);

  const handleSubmit = () => {
    onSave(folder);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFolder({...folder, name: e.target.value})
  }

  const handleNext = () => {
    onNext && onNext(folder);
  };

  return (
    <>
          <div>
            <div className={styles.formContainer}>

              {model && (
              <>
                <div className={styles.formLine}>
                  <b className={styles.inputLabel}>{t('form.folder.name')}</b>
                  <div className={styles.inputWrapper}>
                  <Input
                    className={styles.input}
                    placeholder={t('form.folder.name')}
                    onChange={handleChange}
                    value={folder.name}
                  />

                  </div>
                </div>
              </>
              )}
            </div>
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
            <div className={styles.formActions}>
              <div className={styles.cancelActions}>
                {
              onCancel
                ? <ClassicButton text={t('wizard.folder.cancel')} onClick={onCancel} warning />
                : <div>&nbsp;</div>
            }

              </div>
              <div className={styles.navigationActions}>
                <div className={styles.backNavigation}>
                  {
              onPrevious
                ? <ClassicButton text={t('wizard.folder.back')} onClick={onPrevious} inverted />
                : <div>&nbsp;</div>
            }
                </div>
                <div className={styles.nextNavigation}>
                  {
              onNext
                ? <ClassicButton onClick={handleNext} text={t('wizard.folder.next')} inverted />
                : <div>&nbsp;</div>
            }

                </div>
              </div>
              <div className={styles.submitActions}>
                <ClassicButton type="submit" text={t('wizard.folder.submit')} onClick={handleSubmit} />
              </div>
            </div>
          </div>
      {errorMessage && (
        <span className={styles.submitError}>{errorMessage}</span>
      )}
    </>
  );
};
