import React from 'react';

import styles from './new-tool.module.css';
import { WizardLayout } from '../../../../layouts';
import { ToolWrite } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';
import { ToolWizard } from '../../../../components/wizards';

export const EcosystemNewToolPage: React.FC = () => {
  const tool: ToolWrite = {
    name: '',
    internal_code: '',
    capacity: 0,
    unit: '',
    tool_type: '',
    owner: -1,
    site: [],
    is_placeholder: false
  };
  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.wizardWrapper}>
        <ToolWizard initialTool={tool} />
      </div>
    </WizardLayout>
  );
};
