import * as React from 'react';
import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './group-list-component.module.css';
import { GenericList, ClassicTile, ClassicTileProps } from '..';
import { GroupListProps } from './group-list-type';
import { GroupLight } from '../../models';
import { checkPermission } from '../../roles';
import { AuthenticatedContext } from '../../contexts';
import addActorPictoBlue from '../../../assets/pictos/addActorPictoBlue.svg';
import addSitePictoBlue from '../../../assets/pictos/addSitePictoBlue.svg';
import addToolPictoBlue from '../../../assets/pictos/addToolPictoBlue.svg';
import addProductPictoBlue from '../../../assets/pictos/addProductPictoBlue.svg';
import addCertificatePictoBlue from '../../../assets/pictos/addCertificatePictoBlue.svg';
import addRelationPictoBlue from '../../../assets/pictos/addRelationPictoBlue.png';
import { ClickableContainer } from '../clickable-container';
import { actorSubService, groupSubService } from '../../services';
import addPictoBlue from '../../../assets/pictos/addPictoBlue.png';
import { IconTileProps, IconTile } from '../tile';
import { NewGroup } from '../../models'
import reload from "../../../assets/pictos/refresh.png";
import { Loader, Dimmer } from 'semantic-ui-react';

export const GroupList: React.FC<GroupListProps> = React.memo(
  ({ groups }: GroupListProps) => {

    interface FuturNewGroup {
      groupName: string;
      groupActors: number[]
    }
    interface FuturEditGroup {
      groupName: string;
      groupActors: number[];
      pk: number
    }

    const [totalGroups, settotalGroups] = useState<any[]>([{groupName: 'all', pk: 0, favorite: true}])
    const [count, setCount] = useState<number>()
    const [loading, setLoading] = useState<boolean>(false)
    const [groupsLoading, setGroupsLoading] = useState<boolean>(false)
    const [allActors, setAllActors] = useState<any[]>([])
    const [futurNewGroups, setfuturNewGroups] = useState<FuturNewGroup[]>([])
    const [futurGroupsEdited, setfuturGroupsEdited] = useState<FuturEditGroup[]>([])
    const [refreshLoad, setrefreshLoad] = useState<boolean>(false)

    useEffect(() => {
      getCount()
      deleteGroups()
    }, [])

    const getCount = async () => {
      try {
        setLoading(true)
        const result = await actorSubService.getAllActor({group_id: 'all'})
        setCount(result.count)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    

    const getActors = async () => {
      setGroupsLoading(true)
      try {
        const result = await actorSubService.getAllActor({group_id: "all", limit: 200})
        result.results.map(actor => {
          allActors.push(actor)
        })
        for(var i = 200; i < result.count; i = i + 200) {
          const result = await actorSubService.getAllActor({group_id: "all", limit: 200, offset: i})
          result.results.map(actor => {
            allActors.push(actor)
          })
        }
      } catch (error) {
        console.log("erreur get actors", error)
      }
      sortAllActors()
      createNewGroups()
      editExistingGroup() 
      setTimeout(() => {
      setGroupsLoading(false)
      window.location.reload()
      }, 4000);
    }
    
    const sortAllActors = async () => {
        allActors?.map(async (actor) => {
          if(actor.roles[0] && actor.roles[0].role[0]) {
           const found = groups.find(group => {
           return actor.roles[0]?.role[0] == group.name
          })
          if(found) {
            const indexGroup = futurGroupsEdited.findIndex(group => {
              return actor.roles[0].role[0] == group.groupName
            })
            indexGroup == -1 
            ? futurGroupsEdited.push({groupName: actor.roles[0].role[0], groupActors: [actor.pk], pk: found.pk})
            : futurGroupsEdited[indexGroup].groupActors.push(actor.pk)
          } else {
            const indexGroup = futurNewGroups.findIndex(group => {
              return actor.roles[0].role[0] == group.groupName
            })
            indexGroup == -1 
            ? futurNewGroups.push({groupName: actor.roles[0].role[0], groupActors: [actor.pk]})
            : futurNewGroups[indexGroup].groupActors.push(actor.pk)
          }
        }
      })
      futurNewGroups.map(group => {
        totalGroups.push(group)
      })
      futurGroupsEdited.map(group => {
        totalGroups.push(group)
      })
    }
    

    

    const createNewGroup = async (group: NewGroup) => {
      try {
        const result = await groupSubService.createGroup(group)
        return result
      } catch (error) {
        console.log(error)
      }
    }

    const editG = async (group: NewGroup) => {
      try {
        const result = await groupSubService.editGroup(group)
        return result
      } catch (error) {
        console.log(error)
      }
    }

    const deleteGroupWithNoActor = async (id: number) => {
      try {
        const result = await groupSubService.deleteGroup(id)
        return result
      } catch (error) {
        console.log(error)
      }
    }

    // Create new group for actors that fit with their role
    const createNewGroups = () => {
      futurNewGroups.map(async (e) => {
        const groupToCreate: NewGroup = {
          name: e.groupName,
          group_actors: e.groupActors,
          favorite: true
        }
        await createNewGroup(groupToCreate)
      })
    }
    
    //Edit group that fits with actor's role
    const editExistingGroup = () => {
      futurGroupsEdited.map(async (e) => {
        const groupToEdit: NewGroup = {
          name: e.groupName,
          group_actors: e.groupActors,
          favorite: true,
          pk: e.pk
        }
        await editG(groupToEdit)
      })
    }
    
    //Delete group with no actors inside 
    const deleteGroups = () => {
      groups.map(async (group) => {
        if(group.actors_count == 0) {
          await deleteGroupWithNoActor(group.pk)
        } 
      })
    }
    deleteGroups()
    
    const { t } = useTranslation();
    const { folder } = React.useContext(AuthenticatedContext);
    const initialTileList: ClassicTileProps[] = groups.filter((el) => el.favorite === false).map((el: GroupLight) => ({
      title: el.name,
      link: `/ecosystem/group/${el.pk}`,
      notification: el.actors_count,
      favorite: el.favorite,
      pk: el.pk,
    }));
    
    const initialFavoriteTileList: ClassicTileProps[] = groups.filter((el) => el.favorite === true).map((el: GroupLight) => ({
      title: el.pk === 0 ? t('ecosystem.groups.all') : el.name,
      link: `/ecosystem/group/${el.pk === 0 ? 'all' : el.pk}`,
      notification: el.actors_count,
      favorite: el.favorite,
      pk: el.pk,
    }));

    const ecosytemAll = groups.filter((el) => el.name === "all").map((el: GroupLight) => ({
      title: el.name === "all" ? t('ecosystem.groups.all') : el.name,
      link: `/ecosystems/actors/?group_id=${el.name === "all" ? 'all' : el.name}`,
      notification: el.actors_count,
      favorite: el.favorite,
      name: el.name,
    }));

    const [tileList, setTileList] = React.useState<ClassicTileProps[]>(initialTileList);
    const [favoriteTileList, setFavoriteTileList] = React.useState<ClassicTileProps[]>(initialFavoriteTileList);
    const [ecosystemAllSate, setEcosystemAll] = useState<ClassicTileProps[]>(ecosytemAll)
    const newGroup: IconTileProps = {
      title: t('ecosystem.groups.createTitle'),
      link: '/ecosystem/new-group',
      image: addPictoBlue,
    };

    const newActor = {
      title: t('ecosystem.actors.createTitle'),
      link: '/ecosystem/new-actor',
      icon: addActorPictoBlue,
    };

    const newSite = {
      title: t('ecosystem.sites.createTitle'),
      link: '/ecosystem/new-site',
      icon: addSitePictoBlue,
    };

    const newTool = {
      title: t('ecosystem.tools.createTitle'),
      link: '/ecosystem/new-tool',
      icon: addToolPictoBlue,
    };

    const newProduct = {
      title: t('ecosystem.products.createTitle'),
      link: '/ecosystem/new-product',
      icon: addProductPictoBlue,
    };

    const newCertificate = {
      title: t('ecosystem.certificates.createTitle'),
      link: '/ecosystem/new-certificate',
      icon: addCertificatePictoBlue,
    };

    const newRelation = {
      title: t('ecosystem.relations.createTitle'),
      link: '/ecosystem/new-relation',
      icon: addRelationPictoBlue,
    };

    const actionBtn = [];
    if (folder && checkPermission(folder.permission, '/ecosystem/actor:create', {})) {
      actionBtn.push(newActor);
    }
    if (folder && checkPermission(folder.permission, '/ecosystem/site:create', {})) {
      actionBtn.push(newSite);
    }
    if (folder && checkPermission(folder.permission, '/ecosystem/tool:create', {})) {
      actionBtn.push(newTool);
    }
    if (folder && checkPermission(folder.permission, '/ecosystem/product:create', {})) {
      actionBtn.push(newProduct);
    }
    if (folder && checkPermission(folder.permission, '/ecosystem/certificate:create', {})) {
      actionBtn.push(newCertificate);
    }
    if (folder && checkPermission(folder.permission, '/ecosystem/relation:create', {})) {
      actionBtn.push(newRelation);
    }

    const handleFavorite = async (id?: number, favorite = false) => {
      if (id && favorite !== undefined) {
        try {
          const result = await groupSubService.editGroupFavorite({ id, favorite: !favorite });
          if (favorite) {
            const newTile = favoriteTileList.find((el) => el.pk === id);
            const newTileList = [...tileList, { ...newTile, favorite: false }].filter((el) => el !== undefined) as ClassicTileProps[];
            setTileList(newTileList);
            setFavoriteTileList(favoriteTileList.filter((el) => el.pk !== id));
          } else {
            const newFavoriteTile = tileList.find((el) => el.pk === id);
            const newFavoriteTileList = [...favoriteTileList, { ...newFavoriteTile, favorite: true }].filter((el) => el !== undefined) as ClassicTileProps[];
            setFavoriteTileList(newFavoriteTileList);
            setTileList(tileList.filter((el) => el.pk !== id));
          }
        } catch {
          console.log('erreur');
        }
      }
    };

    const handleFavoriteBuilder = (id?: number, favorite = false) => () => handleFavorite(id, favorite);

    return (
      <div className={styles.group}>
        <div className={styles.actionWrapper}>
          {
            actionBtn.map((el, key) => (
              <div key={key} className={styles.action}>
                <ClickableContainer link={el.link} className={styles.addContainer}>
                  <div className={styles.pictoContainer}>
                    <img src={el.icon} alt={el.title} className={styles.addPicto} />
                  </div>
                  <div className={styles.addTitle}>
                    <b>{el.title}</b>
                  </div>
                </ClickableContainer>
              </div>
            ))
          }
        </div>
        <div className={styles.list}>
            <div className={styles.refresh} >
              <img src={reload} alt="img-reload" onClick={getActors} className={styles.refreshIcon} />
              <b>{t('ecosystem.refreshGroups')}</b>
            </div>
          <div className={styles.favoriteTitle}>
            <b>{t('ecosystem.groups.favoriteTitle')}</b>
          </div>
          <div>
            
            {groupsLoading && <Dimmer active inverted><Loader size="big" /></Dimmer>}
          </div>
          <Grid padded container stackable>
            <GenericList
              items={favoriteTileList}
              itemRenderer={(childElement: ClassicTileProps, key: number) => (
                <Grid.Column key={key} largescreen={4} widescreen={4} computer={4} tablet={5} mobile={8} textAlign="center">
                  <ClassicTile
                    loading={loading}
                    count={count}
                    title={childElement.title}
                    link={childElement.link}
                    image={childElement.image}
                    subTitle={childElement.subTitle}
                    status={childElement.status}
                    notification={childElement.notification}
                    favorite={childElement.favorite}
                    handleFavorite={handleFavoriteBuilder(childElement.pk, childElement.favorite)}
                  />
                </Grid.Column>
              )}
            />
          </Grid>
        </div>

        <div className={styles.list}>
          <div className={styles.favoriteTitle}>
            <b>{t('ecosystem.groups.noFavoriteTitle')}</b>
          </div>
          <Grid padded container stackable>
            <GenericList
              items={[...tileList]}
              itemRenderer={(childElement: ClassicTileProps, key: number) => (
                <Grid.Column key={key} largescreen={4} widescreen={4} computer={4} tablet={5} mobile={8} textAlign="center">
                  <ClassicTile
                    title={childElement.title}
                    link={childElement.link}
                    image={childElement.image}
                    subTitle={childElement.subTitle}
                    status={childElement.status}
                    notification={childElement.notification}
                    favorite={childElement.favorite}
                    handleFavorite={handleFavoriteBuilder(childElement.pk, childElement.favorite)}
                  />
                </Grid.Column>
              )}
            />
            <div className={styles.newGroup}>
              <IconTile
                title={newGroup.title}
                link={newGroup.link}
                image={newGroup.image}
              />
            </div>
          </Grid>

        </div>
      </div>

    );
  },
);
