import React from 'react';

import styles from './wizard-layout.module.css';
import { WizardLayoutProps } from '.';

export const WizardLayout: React.FC<WizardLayoutProps> = ({ children, image }) => (
  <>
    <div className={styles.layoutImage} style={{ backgroundImage: `url(${image}` }} />
    <div className={styles.layoutColor} />
    <div className={styles.content}>

      {children}
    </div>
  </>
);
