import React from 'react';

import { useTranslation } from 'react-i18next';
import { ForgetPasswordProps } from './forget-password-page-type';

import styles from './forget-password-page.module.css';
import { AuthenticatedContext } from '../../../contexts';
import { AuthLayout } from '../../../layouts';

import backgroundImage from '../../../../assets/images/backgroundLogin.jpg';
import CFLogo from '../../../../assets/images/CF.png';
import { ForgetPasswordForm } from '../../../components';
import { authSubService } from '../../../services';
import { toastHandler } from '../../../utils';
import { posthogEvents } from '../../../utils/posthog';

export const ForgetPasswordPage: React.FC<ForgetPasswordProps> = () => {
  const authenticatedContext = React.useContext(AuthenticatedContext);
  const { t } = useTranslation();
  let errorMessage: string | undefined;

  const forgetPassword = async (email: string) => {
    try {
      const result = await authSubService.forgetPassword(email);
      toastHandler(t('authentication.actions.forgetSuccess'), 'success');
      posthogEvents.triggerEvent({name: 'request', datas: {'newPassword': 'succes'} });
    } catch (err) {
      toastHandler(t('authentication.actions.forgetError'), 'error');
      posthogEvents.triggerEvent({name: 'request', datas: {'newPassword': 'error'} });
      errorMessage = 'error auth';
    }
  };


  const isAuthenticated = authenticatedContext.authenticated;
  return (
    <AuthLayout image={backgroundImage}>
      <div className={styles.content}>
        <div className={styles.header}>
          <img src={CFLogo} className={styles.logo} />
          <div className={styles.title}>
            <b>{t('authentication.header.title')}</b>
          </div>
        </div>


        <div className={styles.formWrapper}>
          <ForgetPasswordForm
            model={{ email: '' }}
            isAuthenticated={isAuthenticated}
            onSuccess={(model: any) => forgetPassword(model.email)}
            errorMessage={errorMessage}
          />
        </div>


        {/* <div className={styles.footer}><h6>{t('authentication.header.footer')}</h6></div> */}

      </div>

    </AuthLayout>
  );
};
