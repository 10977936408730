import * as React from 'react';

import { Loader } from 'semantic-ui-react';
import styles from './tab-panel-component.module.css';
import { TabPanelProps } from './tab-panel-type';
import { useParams } from '../../hooks';
import { GenericTable } from '../table';
import { WithLazyloadingDatasFetching } from '../../hocs/with-lazyloading-datas-fetching';
import { TableProps } from '../table/table-type';

export function TabPanel <T>({
  include,
  limit,
  service,
  specificParams,
  initHeader,
}: TabPanelProps<T>) {
  const {
    params, header, manageOrdering, manageSearch, focused
  } = useParams(initHeader, specificParams);

  const EnhancedTable = WithLazyloadingDatasFetching<T, TableProps<T>>(
    service, 'data', params, limit,
  )(GenericTable);
    // console.log("TP", params)
  return (
    <div className={styles.tableWrapper}>
      <EnhancedTable data={[]} header={header} linkType={include} clickTable manageOrdering={manageOrdering} manageSearch={manageSearch} focused={focused} params={params}/>
    </div>
  );
}
