import React from 'react';

import styles from './color-layout.module.css';
import { ColorLayoutProps } from '.';

export const ColorLayout: React.FC<ColorLayoutProps> = ({ children, color }) => (
  <>
    <div className={styles.layout} style={{ backgroundColor: color }} />
    {children}
  </>
);
