import { compose } from 'redux';
import { WithQuerystringReading, WithStatelessDatasFetchingByParameters, WithNotifyRessourceLoadedInputProps } from '../../../hocs';
import { FlowProps } from '.';
import { trackingService } from '../../../services';
import { GetFlow, AuditedFlow } from '../../../models';
import { PageLoader } from '../../../components/page-loader';

const enhancer = compose<
React.ComponentType<GetFlow & WithNotifyRessourceLoadedInputProps>
>(
  WithQuerystringReading(),
  WithStatelessDatasFetchingByParameters<AuditedFlow, FlowProps, GetFlow>(
    trackingService.getFlowPage,
    PageLoader,
    'redirect',
    'result',
    ['id'],
  ),
);

export default enhancer;
