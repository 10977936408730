import React from 'react';

import styles from './edit-actor.module.css';
import { EditActorProps } from './edit-actor-types';
import { ActorWizard } from '../../../../components';
import { WizardLayout } from '../../../../layouts';
import enhancer from './edit-actor-enhancer';
import { Actor } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';
import { getParam } from '../../../../components/wizards/utils';
import { useLocation } from 'react-router-dom';

const InnerPage: React.FC<EditActorProps> = ({ actors }: EditActorProps) => {
  const location = useLocation();
  if (actors.length === 0) {
    return <></>;
  }
  const actor = actors as unknown as Actor;

  const urlStep = getParam(location.search.substring(1), 'step');
  const initialStep:number = urlStep ? parseInt(urlStep) : 0;
  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.list}>
        <ActorWizard initialStep={initialStep} edit initialActor={actor} />
      </div>
    </WizardLayout>
  );
};

export const EcosystemEditActorPage = enhancer(InnerPage);
