import * as React from 'react';

import { Table, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styles from './table-component.module.css';
import { TableProps } from './table-type';
import { Header } from './header';
import { BodyCheck } from './body-check';
import { Body } from './body';
import { BodyClickable } from './body-clickable';
import { posthogEvents } from '../../utils/posthog';


export function GenericTable <T>({
  data,
  handleAll,
  header,
  checkTable,
  loading,
  error,
  manageOrdering,
  manageSearch,
  checkedList,
  handleList,
  loadMoreItems,
  endFetching,
  clickTable,
  linkType,
  focused,
  params
}: TableProps<T>) {
  const [checkFilter, setCheckFilter] = React.useState<boolean | undefined>(undefined);
  const { t } = useTranslation();
  const intersectLastTarget = React.useRef<HTMLDivElement>(null);
  const block = React.useRef(false);
  const [display, setDisplay] = React.useState(block.current);

  React.useEffect(() => {
    block.current = false;
  }, [display]);

  React.useEffect(() => {
    const callback: IntersectionObserverCallback = (list) => {
      list.forEach((entry) => {
        if (!block.current && entry.isIntersecting) {
          block.current = true;
          setDisplay(true);
          loadMoreItems && loadMoreItems();
          setTimeout(() => {
            setDisplay(false);
          }, 500);
        }
      });
    };

    const opts = {
      root: null,
      rootMargin: '200px',
      threshold: 1,
    };

    const observerScroll = new IntersectionObserver(callback, opts);

    if (intersectLastTarget.current !== null) {
      observerScroll.observe(intersectLastTarget.current);
    }
  });
  const checkedHeader = params && params.group_id ? header : header.filter(el => el.columnName !== "owner")

  if (error) {
    posthogEvents.triggerEvent({ name: 'error', datas: {'data' : 'error'}});
    return (
      <div className={styles.table}>
        <Table compact striped celled basic="very">
          <Header
            handleAll={handleAll}
            header={checkedHeader}
            manageOrdering={manageOrdering}
            manageSearch={manageSearch}
            checkFilter={checkFilter}
            setCheckFilter={setCheckFilter}
            clickTable={clickTable}
            checkTable={checkTable}
            params={params? params : {}}
          />
        </Table>
        <div className={styles.errorWrapper}>
          <div className={styles.error}>
            <b>{t('table.utils.error')}</b>
          </div>
        </div>
      </div>
    );
  } if ((!data || data.length === 0) && loading) {
    return (
      <div className={styles.table}>
        <Table compact striped celled basic="very">
          <Header
            handleAll={handleAll}
            header={checkedHeader}
            manageOrdering={manageOrdering}
            manageSearch={manageSearch}
            checkFilter={checkFilter}
            setCheckFilter={setCheckFilter}
            clickTable={clickTable}
            checkTable={checkTable}
            focused={focused}
            params={params? params : {}}
          />
        </Table>
        <div className={styles.loader} />
        <Loader active size="huge" className={styles.semanticLoader} />
      </div>
    );
  } if (!data || data.length === 0) {
    posthogEvents.triggerEvent({ name :'request', datas: {'data': 'noResults'} });
    return (
      <div className={styles.table}>
        <Table compact striped celled basic="very">
          <Header
            handleAll={handleAll}
            header={checkedHeader}
            manageOrdering={manageOrdering}
            manageSearch={manageSearch}
            checkFilter={checkFilter}
            setCheckFilter={setCheckFilter}
            clickTable={clickTable}
            checkTable={checkTable}
            params={params? params : {}}
          />
        </Table>
        <div className={styles.noResultWrapper}>
          <div className={styles.noResult}>
            <b>{t('table.utils.noResult')}</b>
          </div>
        </div>
      </div>
    );
  }
  data.length > 1 && posthogEvents.triggerEvent({name: 'request', datas: {'data': 'succes'} });
  return (
    <div className={styles.table}>
      <Table compact striped celled basic="very">
        <Header
          handleAll={handleAll}
          header={checkedHeader}
          manageOrdering={manageOrdering}
          manageSearch={manageSearch}
          checkFilter={checkFilter}
          setCheckFilter={setCheckFilter}
          clickTable={clickTable}
          checkTable={checkTable}
          params={params? params : {}}
        />
        {
          checkTable && checkedList && handleList
            ? (
              <BodyCheck
                data={data}
                checkedList={checkedList}
                handleList={handleList}
                header={checkedHeader}
                checkFilter={checkFilter}
              />
            )
            : clickTable && linkType
              ? (
                <BodyClickable
                  data={data}
                  header={checkedHeader}
                  linkType={linkType}
                />
              )
              : (
                <Body
                  data={data}
                  header={checkedHeader}
                />
              )
}
      </Table>
      {!loading && !block.current && !display && !endFetching
            && (
              <>
                <div
                  ref={intersectLastTarget}
                  style={{
                    position: 'relative',
                    top: /* data.length < 11 ? `-${5 + data.length * 25}px` : '-250px' */'-50px',
                  }}
                />
              </>
            )}
    </div>
  );
}
