import React from 'react';
import styles from './send-comment-button.module.css';
import { SendCommentButtonProps } from './send-comment-button-type';

export const SendCommentButton : React.FC<SendCommentButtonProps> = (props : SendCommentButtonProps) => {
    const {text, disabled, onSubmit} = props
    return (
    <button 
    className={`${styles.button} ${disabled ? styles.disabled : ''}`}
    disabled={disabled}
    onSubmit={onSubmit}
    >
        {text}
    </button>)
}