import React from "react";
import styles from "./view-actor-results-page.module.css";
import "./view-actor-results-page.module.css";
import editPictoWhite from "../../../assets/pictos/editPictoWhite.png";
import actorPictoWhite from "../../../assets/pictos/actorPictoWhite.svg";
import { Link, useLocation } from "react-router-dom";
import { Divider, Table } from "semantic-ui-react";
import { ViewActorConformityProps } from "../view-actor-results-page";
import { viewActorEnhancer } from "./viewActorEnhancer";

export const ViewActorResultsPage: React.FC<ViewActorConformityProps> = ({
  datas,
}: ViewActorConformityProps) => {
  const location: any = useLocation();
  const actorResults = datas.results;

  function findName() {
    const actorName = actorResults.find((name) => name.actor !== null);
    if (actorName) {
      return actorName?.actor;
    } else {
      return location.state.actorCode;
    }
  }

  return (
    <div className={styles.viewActorContainer}>
      <div className={styles.viewActorLightItem}>
        <div className={styles.viewActorLightItemHeader}>
          <div className={styles.viewActorItemPicto}>
            <img
              src={actorPictoWhite}
              alt=""
              className={styles.viewActorItemPictoLight}
            />
            <strong>Acteur {findName()}</strong>
          </div>
          <div className={styles.viewActorEdit}>
            {/* <div className={styles.viewActorClick}>
              <Link to="#" className={styles.viewActorClickBtn}>
                <img
                  src={editPictoWhite}
                  className={styles.viewActorEditBtn}
                  alt="image"
                />
              </Link>
            </div> */}
          </div>
        </div>
        {/* <div>
          <div className={styles.viewActorContentInfo}>
            <strong>Code SIRET:</strong>&nbsp; 706171
          </div>
          <div className={styles.viewActorContentInfo}>
            <strong>Cet acteur est ambassadeur:</strong>&nbsp; Non
          </div>
        </div> */}
      </div>

      <div style={{ margin: "2% 0 2% 0" }}>
        <strong style={{ fontSize: "1.3em" }}>
          Cahiers des charges ({actorResults.length})
        </strong>
      </div>
      {actorResults.map((actor: any) => (
        <Table fixed striped key={actor.pk}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ color: "#fff", backgroundColor: "#0A405D" }}
              >
                <strong style={{ fontSize: "0.85em" }}>
                  <div style={{ width: "350px" }}>{actor.name}</div>
                </strong>
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "#fff", backgroundColor: "#0A405D" }}
              ></Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "#fff", backgroundColor: "#0A405D" }}
              >
                <span
                  style={{
                    fontSize: "0.8em",
                    fontWeight: "normal",
                    float: "right",
                  }}
                >
                  {actor.name ===
                  "Autodiagnostic des bonnes pratiques de stockage à la ferme"
                    ? null
                    : "Taux de conformité"}
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ color: "#fff", backgroundColor: "#0A405D" }}
              >
                {actor.name ===
                "Autodiagnostic des bonnes pratiques de stockage à la ferme" ? null : (
                  <span
                    style={{
                      fontSize: "0.8em",
                      fontWeight: "normal",
                      float: "left",
                    }}
                  >
                    {actor.conformity_proportion.proportion}% soit{" "}
                    {actor.conformity_proportion.compliant_results} conforme
                    {actor.conformity_proportion.compliant_results <= 1
                      ? ""
                      : "s"}{" "}
                    sur {actor.conformity_proportion.total_results}
                  </span>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <strong style={{ fontSize: "1.1em" }}>Code Site</strong>
              </Table.Cell>
              <Table.Cell>
                <strong style={{ fontSize: "1.1em" }}>Filière</strong>
              </Table.Cell>
              <Table.Cell>
                <strong style={{ fontSize: "1.1em" }}>Code Produit</strong>
              </Table.Cell>
              <Table.Cell>
                <strong style={{ fontSize: "1.1em" }}>Statut</strong>
              </Table.Cell>
            </Table.Row>

            {actor.audited_units.map((auditedUnits: any) => (
              <React.Fragment key={auditedUnits.pk}>
                {auditedUnits.foodchain ===
                "Autodiagnostic des bonnes pratiques de stockage à la ferme" ? (
                  <Table.Row key={auditedUnits.code}>
                    <Table.Cell>{auditedUnits.code}</Table.Cell>
                    <Table.Cell>{auditedUnits.foodchain}</Table.Cell>
                    <Table.Cell>{auditedUnits.product}</Table.Cell>
                    <Table.Cell>
                      <Link to={`/contract/results/${auditedUnits.pk}`}>
                        {auditedUnits.risk_status === "member" ? (
                          <button
                            style={{
                              backgroundColor: "#608A0C",
                              color: "#fff",
                              border: "0px",
                              padding: "8px 15px",
                              borderRadius: "12px",
                              cursor: "pointer",
                              width: "50%",
                            }}
                          >
                            MEMBRE
                          </button>
                        ) : auditedUnits.risk_status === "expert" ? (
                          <button
                            style={{
                              backgroundColor: "#95BF41",
                              color: "#fff",
                              border: "0px",
                              padding: "8px 15px",
                              borderRadius: "12px",
                              cursor: "pointer",
                              width: "50%",
                            }}
                          >
                            EXPERT
                          </button>
                        ) : auditedUnits.risk_status === "at risk" ? (
                          <button
                            style={{
                              backgroundColor: "#F2CB05",
                              color: "#fff",
                              border: "0px",
                              padding: "8px 15px",
                              borderRadius: "12px",
                              cursor: "pointer",
                              width: "50%",
                            }}
                          >
                            A RISQUE
                          </button>
                        ) : auditedUnits.risk_status === "critical" ? (
                          <button
                            style={{
                              backgroundColor: "#F28705",
                              color: "#fff",
                              border: "0px",
                              padding: "8px 15px",
                              borderRadius: "12px",
                              cursor: "pointer",
                              width: "50%",
                            }}
                          >
                            CRITIQUE
                          </button>
                        ) : auditedUnits.risk_status === "not valid" ? (
                          <button
                            style={{
                              backgroundColor: "rgba(255, 0, 0, 0.82)",
                              color: "#fff",
                              border: "0px",
                              padding: "8px 15px",
                              borderRadius: "12px",
                              cursor: "pointer",
                              width: "50%",
                            }}
                          >
                            NON VALIDE
                          </button>
                        ) : null}
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <Table.Row key={auditedUnits.site}>
                    <Table.Cell>{auditedUnits.site}</Table.Cell>
                    <Table.Cell>{auditedUnits.foodchain}</Table.Cell>
                    <Table.Cell>{auditedUnits.product}</Table.Cell>
                    <Table.Cell>
                      <Link to={`/contract/results/${auditedUnits.pk}`}>
                        {auditedUnits.conformity === true ? (
                          <button
                            style={{
                              backgroundColor: "#95C043",
                              color: "#fff",
                              border: "0px",
                              padding: "8px 15px",
                              borderRadius: "12px",
                              cursor: "pointer",
                              width: "50%",
                            }}
                          >
                            CONFORME
                          </button>
                        ) : (
                          <button
                            style={{
                              backgroundColor: "#c53211",
                              color: "#fff",
                              border: "0px",
                              padding: "8px 15px",
                              borderRadius: "12px",
                              cursor: "pointer",
                              width: "50%",
                            }}
                          >
                            NON CONFORME
                          </button>
                        )}
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                )}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      ))}
    </div>
  );
};

export const ActorResultsPage = viewActorEnhancer(ViewActorResultsPage);
