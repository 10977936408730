import React from 'react';
import { Redirect, useLocation, Link } from 'react-router-dom';
import { Formik } from 'formik';
// import { SubmitButton, PageTitle } from "../../../../shared/components/ui";
// import {
//   InputControl,
//   PasswordInputControl
// } from "../../../../shared/components";

//import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { LoginFormProps, LoginProps } from './login-form-type';

import styles from './login-form.module.css';
import { InputControl, PasswordInputControl, CheckBoxInputControl } from '../form-controls';
import { ClassicButton } from '../../buttons';
import { posthogEvents } from '../../../utils/posthog';

export const LoginForm: React.FC<LoginFormProps> = ({
  model,
  onSuccess,
  isAuthenticated,
  redirectPath,
  errorMessage,
}: LoginFormProps) => {
  const location = useLocation();
  const trafficSource : string = window.location.toString().includes('mail') ? 'mail' : 'direct';
  const { t } = useTranslation();
  if (isAuthenticated) {
    let url = redirectPath;
    if (!url) {
      url = location.pathname;
      let lastSlash = url.lastIndexOf('/');
      lastSlash = lastSlash === -1 ? url.length : lastSlash + 1;
      url = url.substring(0, lastSlash);
    }
    return <Redirect to={url} />;
  }
  errorMessage && posthogEvents.triggerEvent({name :'error', datas : { 'password' : errorMessage, trafficSource }});
  return (
    <>
      <div className={styles.loginForm}>

        <Formik<LoginProps>
          initialValues={model}
          onSubmit={(updatedModel) => {
            onSuccess(updatedModel);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className={styles.form}>
              <div className={styles.formContainer}>
                <div className={styles.inputBloc}>
                  <b className={styles.inputLabel}>{t('form.authentication.mail')}</b>
                  <InputControl className="email-adress" name="email" required placeholder={t('form.authentication.mail')} />
                </div>
                <div className={styles.inputBloc}>
                  <b className={styles.inputLabel}>{t('form.authentication.password')}</b>
                  <PasswordInputControl
                    data-automation-id="email-adress-password"
                    name="password"
                    placeholder={t('form.authentication.password')}
                    required
                  />
                </div>
                <div className={styles.linkContainer}>
                  <div className={styles.remember}>
                    <CheckBoxInputControl name="remember" label={t('authentication.link.rememberMe')} />
                  </div>
                  <div className={styles.forgetContainer}>
                    <Link to="/mot-de-passe-oublie" className={styles.forgetLink}>
                      <b>{t('authentication.link.forgetPassword')}</b>
                    </Link>
                  </div>
                </div>

                <div className={styles.submit}>
                  {/* <SubmitButton text="Connexion" /> */}
                  <ClassicButton type="submit" text={t('authentication.actions.login')} />
                  {/* <button type="submit">Se Connecter</button> */}
                </div>
              </div>
              {errorMessage && (
                <span className={styles.submitError}>{errorMessage}</span>
              )}
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
