import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './product-wizard-component.module.css';
import { ProductWizardProps } from './product-wizard-type';
import { ProductWrite, ProductRead } from '../../../models';
import { productSubService } from '../../../services';
import { ProductForm, OwnerForm } from '../../forms';
import checkPictoWhite from '../../../../assets/pictos/checkPictoWhite.png';
import productPictoBlue from '../../../../assets/pictos/productPictoBlue.svg';
import trashPictoRed from '../../../../assets/pictos/trashPictoRed.png';
import editPictoWhite from '../../../../assets/pictos/editPictoWhite.png';
import { toastHandler } from '../../../utils';
import { WizardLoader } from '../utils';
import { redirectWithUrl } from '../utils/redirect';

export const ProductWizard: React.FC<ProductWizardProps> = React.memo(({ edit, initialProduct, initialStep }: ProductWizardProps) => {
  const [editedProduct, setEditedProduct] = React.useState<Omit<ProductWrite, 'owner'>>({...initialProduct, is_placeholder:false});
  const [editedOwner, setEditedOwner] = React.useState<number[]>(initialProduct.owner);
  const [activeStep, setActiveStep] = React.useState(initialStep ? initialStep : 0);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const nextStep = () => {
    setActiveStep(activeStep === 1 ? 1 : activeStep + 1);
  };

  const previousStep = () => {
    setActiveStep((activeStep || 1) - 1);
  };

  const goTo = (index: number) => {
    setActiveStep(index);
  };

  const manageDeleteProduct = async () => {
    if (editedProduct.pk && editedProduct.pk !== -1) {
      setLoading(true);
      try {
        const result = productSubService.deleteProduct(editedProduct.pk);
        toastHandler(t('wizard.products.deleteSuccess'), 'success');
        setLoading(false);
        history.push({pathname: redirectWithUrl(location.search, '/ecosystem'), state: location.state});
      } catch (err) {
        console.log(err);
        setLoading(false);
        toastHandler(t('wizard.products.deleteError'), 'error');
      }
    }
  };

  const manageCancel = () => {
    history.push({pathname: editedProduct.pk ? redirectWithUrl(location.search, `/ecosystem/products/${editedProduct.pk}`) : redirectWithUrl(location.search, '/ecosystem'), state: location.state});
  };

  const onNextOwner = (owner: number[]) => {
    setEditedOwner(owner);
    nextStep();
  };

  const onSaveOwner = async (owner: number[]) => {
    setEditedOwner(owner);
    try {
      await onSaveGlobalProduct(owner, editedProduct);
      nextStep();
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const onSaveProduct = async (product: Omit<ProductWrite, 'owner'>) => {
    setEditedProduct(product);
    try {
      const result = await onSaveGlobalProduct(editedOwner, product);
      console.log("j'appelle la méthode onSaveProduct")
      console.log(result)
      result && history.push({pathname: redirectWithUrl(location.search, `/ecosystem/products/${result.pk}`), state: location.state});
    } catch {
      setError(t('table.utils.error'));
    }
  };

  const onSaveGlobalProduct = async (owner: number[], product: Omit<ProductWrite, 'owner'>) => {
    setLoading(true);
    try {
      if (owner.length > 0 && owner[0] !== -1) {
        const result: ProductRead = editedProduct.pk && editedProduct.pk !== -1 ? await productSubService.editProduct({ ...product, owner }) : await productSubService.createProduct({ ...product, owner });
        toastHandler(t('wizard.products.saveSuccess'), 'success');
        setLoading(false);
        return result;
      }
      setLoading(false);
      setError('il manque un proprietaire');
    } catch {
      setLoading(false);
      toastHandler(t('wizard.products.saveError'), 'error');
      setError(t('table.utils.error'));
    }
  };


  const step = [
    {
      index: 0,
      label: t('wizard.products.step2'),
      picto: productPictoBlue,
      Component: editedOwner
        ? (
          <OwnerForm
            model={editedOwner}
            onNext={(model) => onNextOwner(model)}
            onSave={editedProduct.pk && editedProduct.pk !== -1 ? (model) => onSaveOwner(model) : undefined}
            errorMessage={error}
            onCancel={manageCancel}
            include="products"
            blocking
            multiple
            specificParams={{ ordering: 'name' }}
          />
        )
        : <div>pas d'acteur id</div>,
    },
    {
      index: 1,
      label: t('wizard.products.step1'),
      picto: productPictoBlue,
      Component: <ProductForm
        model={editedProduct}
        onSave={(model) => onSaveProduct(model)}
        errorMessage={error}
        onCancel={manageCancel}
        onPrevious={previousStep}
      />,
    },
  ];


  return (
    <div className={styles.wizard}>
      <div className={styles.form}>
        <div className={styles.activeStep}>
          <div className={styles.header}>
            <div className={styles.titlePictoContainer}><img src={step[activeStep].picto} className={styles.titlePicto} /></div>
            <div className={styles.titleLabel}><b>{step[activeStep].label}</b></div>
            {editedProduct.pk && editedProduct.pk !== -1
              && (
              <div className={styles.deleteContainer} onClick={manageDeleteProduct}>
                <div className={styles.trashPictoContainer}><img src={trashPictoRed} className={styles.trashPicto} /></div>
                <div className={styles.deleteLabel}>{t('wizard.products.delete')}</div>
              </div>
              )}
          </div>
          <div className={`${styles.formWrapper} ${loading ? styles.blured : ''}`}>
            {loading && <WizardLoader />}
            {step[activeStep].Component}
          </div>
        </div>

      </div>
      <div className={styles.track}>
        {
        step.map((el) => (
          activeStep === el.index
            ? (
              <div className={`${styles.container} ${styles.activeContainer}`} key={el.index}>
                <div className={`${styles.badge} ${styles.activeBadge}`}>{el.index + 1}</div>
                <div className={`${styles.label} ${styles.activeLabel}`}>{el.label}</div>
              </div>
            )
            : editedProduct.pk
              ? (
                <div className={`${styles.container} ${styles.editContainer}`} key={el.index} onClick={() => goTo(el.index)}>
                  <div className={`${styles.badge} ${styles.editBadge}`}><img src={editPictoWhite} className={styles.editPicto} /></div>
                  <div className={`${styles.label} ${styles.editLabel}`}>{el.label}</div>
                </div>
              )
              : activeStep > el.index
                ? (
                  <div className={`${styles.container} ${styles.doneContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.doneBadge}`}><img src={checkPictoWhite} className={styles.checPicto} /></div>
                    <div className={`${styles.label} ${styles.doneLabel}`}>{el.label}</div>
                  </div>
                )
                : (
                  <div className={`${styles.container} ${styles.todoContainer}`} key={el.index}>
                    <div className={`${styles.badge} ${styles.todoBadge}`}>{el.index + 1}</div>
                    <div className={`${styles.label} ${styles.todoLabel}`}>{el.label}</div>
                  </div>
                )
        ))
      }
      </div>
    </div>
  );
});
