import posthog from 'posthog-js';
import { getEffectiveConstraintOfTypeParameter } from 'typescript';

export type EventType = 'clickOn' | 'login' | 'logout' | 'error' | 'setLang' | 'goTo'| 'orderBy' | 'request' | 'filter';

export interface EventData {
   [property : string] : string | number;
};

export interface Event  {
   name : EventType;
   datas : EventData;
}

const env : string | undefined = process.env.REACT_APP_STAGE;
const deployAnalytics : boolean = env === 'prod' || env === 'preprod';

export const posthogEvents = {
   initialize :  () => {
      //  console.log('posthog init');
      posthog.init(env === 'prod' ? 'phc_pGxV7xRNensBXu0drkEsg7SEDRQ2efBom1Px4ufloGG' : 'nYTVyF99MYXSFHz1oUmq5k_zu3jWP7yKc4YNRxgFRos', { api_host: env === 'prod' ? 'https://posthog.connecting-food.com' : 'https://posthog.connecting-food.xyz', disable_cookie: true})
      //  env === 'preprod' && posthog.init('nYTVyF99MYXSFHz1oUmq5k_zu3jWP7yKc4YNRxgFRos', { api_host: 'https://posthog.connecting-food.xyz', disable_cookie: true})
      //  console.log('location', window.location) Voir si intégrer ici la vérif de trafficsource
      deployAnalytics && posthog.capture('getResolution', {'screenSize' : `${window.screen.width * window.devicePixelRatio} X ${window.screen.height * window.devicePixelRatio}`})
   },
   identification: (property : string, value: string | number) => {
      deployAnalytics && posthog.people.set({[property] : value})
   },
   triggerPageView : () => {
      // console.log('pageView');
      deployAnalytics && posthog.capture('$pageview');
   },
   triggerEvent: (event : Event) => {
      // console.log("trigger event", event, 'env', process.env.REACT_APP_STAGE, 'var', env);
      deployAnalytics && posthog.capture(event.name, {...event.datas, 'environnement': process.env.REACT_APP_STAGE});
   },
   
   triggerSearch: (property : string, value : string) => {
      // console.log('PHsearch', property, value)
      deployAnalytics && posthog.capture('search', {[property] : value, 'environnement': process.env.REACT_APP_STAGE});
   },   
   login: (email : string) => {
      //check if not better in backgroundLogin
      deployAnalytics && posthog.identify(email);
      deployAnalytics && posthog.people.set({email});
    },
   logout : () => {
      deployAnalytics && posthog.capture('logout', {'environnement': process.env.REACT_APP_STAGE});
      deployAnalytics && posthog.reset(true);
   }
} 
