const configContracts = {
  backend: {
    baseUrl: '',
    rest: {
      url: '/api',
    },
    fileMaxSize: 10000,
  },
  client: {
    baseUrl: '',
  },
};

if (process.env.REACT_APP_STAGE === 'recette') {
  configContracts.backend.baseUrl = process.env.REACT_APP_CONTRACTS_RECETTE || '';
  configContracts.client.baseUrl = process.env.REACT_APP_CLIENT_RECETTE || '';
} else if (process.env.REACT_APP_STAGE === 'preprod') {
  configContracts.backend.baseUrl = process.env.REACT_APP_CONTRACTS_PREPROD || '';
  configContracts.client.baseUrl = process.env.REACT_APP_CLIENT_PREPROD || '';
} else if (process.env.REACT_APP_STAGE === 'prod') {
  configContracts.backend.baseUrl = process.env.REACT_APP_CONTRACTS_PROD || '';
  configContracts.client.baseUrl = process.env.REACT_APP_CLIENT_PROD || '';
} else {
  configContracts.backend.baseUrl = process.env.REACT_APP_CONTRACTS_DEV || '';
  configContracts.client.baseUrl = process.env.REACT_APP_CLIENT_DEV || '';
}
export { configContracts };