import * as React from 'react';

import { useTranslation } from 'react-i18next';
import {
  Table, Checkbox, Select, TableHeaderCell,
} from 'semantic-ui-react';
import styles from './header-component.module.css';
import { HeaderProps, SearchCellProps, OrdererCellProps } from './header-type';
import { SelectedValue } from '../../../models';
import sortPictoBlue from '../../../../assets/pictos/sortPictoBlue.svg';
import searchPictoBlue from '../../../../assets/pictos/searchPictoBlue.png';
import i18next from 'i18next';

export function Header <T>({
  handleAll,
  header,
  manageOrdering,
  manageSearch,
  checkFilter,
  setCheckFilter,
  checkTable,
  clickTable,
  focused,
  params
}: HeaderProps<T>) {
  const { t } = useTranslation();

  const handleAllCheck = (data: any) => {
    if (handleAll) {
      handleAll(data.checked);
    }
  };

  const handleFilterCheck = () => {
    if (checkFilter === true) {
      setCheckFilter(false);
    } else if (checkFilter === false) {
      setCheckFilter(undefined);
    } else {
      setCheckFilter(true);
    }
  };

  return (
    <Table.Header>
      <Table.Row>
        {
            checkTable
            && (
            <Table.HeaderCell>
              {
                  handleAll && (
                  <div className={styles.checkboxContainer}>
                    <Checkbox
                      value=""
                      onChange={(event, data) => handleAllCheck(data)}
                      className={styles.checkbox}
                    />

                  </div>
                  )
                }
            </Table.HeaderCell>
            )
          }
        { clickTable && <Table.HeaderCell /> }
        {header.map((el, key) => (
          <TableHeaderCell key={key}>
            <div className={styles.header}>
              <div className={styles.ordererWrapper}>
                <OrdererCell column={el} manageOrdering={manageOrdering} />
              </div>
              <div className={styles.searchWrapper}>
                <SearchCell column={el} manageSearch={manageSearch} searchValue={el.searchValue} focused={focused} params={params} />
              </div>
            </div>
          </TableHeaderCell>
        ))}
        {header[header.length-1].columnName === 'connection' && 
        <TableHeaderCell/>
        }
      </Table.Row>
    </Table.Header>
  );
}

function SearchCell <T>({
  column,
  manageSearch,
  searchValue,
  focused,
  params
}: SearchCellProps<T>) {
  const { t } = useTranslation();

  const [search, setSearch] = React.useState<string | undefined>(searchValue === '' ? undefined : searchValue);
  const [inputFetcher, setInputFetcher] = React.useState<any>();
  const category : string = params?.category ? params.category : 'active';
  const isAmbassador : string = params?.is_ambassador ? params.is_ambassador : 'all';
  const [audit, setAudit] = React.useState<SelectedValue>(params?.audit ? params.audit : 'all');

  const handleInputSubmit = (searchProp: string) => {
    if (search !== undefined && (search.length > 0 || search.length === 0)) {
      manageSearch && manageSearch(searchProp, search);
    }
  };

  const handleInputChange = async (searchProp: string, e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('handleChange :', searchProp, e.target);
    e.persist();
    setSearch(e.target.value);
    if (inputFetcher) {
      clearTimeout(inputFetcher);
    }

    setInputFetcher(setTimeout(() => {
      manageSearch && e.target && manageSearch(searchProp, e.target.value, i18next.language);
    }, 500));
  };


  const handleCheckboxChange = (searchProp: string, data: any) => {
    setSearch(data.checked ? 'true' : 'false');
    manageSearch && manageSearch(searchProp, data.checked ? 'true' : 'false');
  };


  const booleanOptions = [
    { key: '1', value: 'all', text: t('table.utils.all') },
    { key: '2', value: 'true', text: t('table.utils.true') },
    { key: '3', value: 'false', text: t('table.utils.false') },
  ];

  const compliantOptions = [
    { key: '1', value: 'all', text: t('table.utils.all') },
    { key: '2', value: 'true', text: t('table.utils.compliant') },
    { key: '3', value: 'false', text: t('table.utils.nonCompliant') },
  ];

  const certificateOptions = [
    { key: '0', value: 'all', text:t('table.utils.all')},
    { key: '1', value: 'active', text: t('table.utils.certificate.active') },
    { key: '2', value: 'future', text: t('table.utils.certificate.future') },
    { key: '3', value: 'archived', text: t('table.utils.certificate.archived') },
  ];

 
  const selectChange = (searchProp: string, value: SelectedValue) => {
    searchProp === 'audit' && setAudit(value);
    manageSearch && manageSearch(searchProp, value as string);
  };

  if (!manageSearch) {
    return (
      <></>
    );
  }

  switch (column.searchType) {
    case 'boolean': {
      return (
        <div className={styles.checkboxContainer}>
          <Checkbox
            value=""
            checked={!!(search && search === 'true')}
            onChange={(event, data) => handleCheckboxChange(column.searchProp, data)}
            className={styles.checkbox}
          />
        </div>
      );
    }
    case 'string': {
      return (
        <div className={styles.inputContainer}>
          <input className={styles.input} onChange={(e) => handleInputChange(column.searchProp, e)} value={search || ''} autoFocus={column.columnName === focused} />
          <div className={styles.button} onClick={() => handleInputSubmit(column.searchProp)}>
            <img src={searchPictoBlue} alt={'searchPicto'} className={styles.filterPicto} />
          </div>
        </div>
      );
    }
    case 'number': {
      return (
        <div className={styles.inputContainer}>
          <input className={styles.input} type="number" onChange={(e) => handleInputChange(column.searchProp, e)} value={search || ''} autoFocus={column.columnName === focused} />
          <div className={styles.button} onClick={() => handleInputSubmit(column.searchProp)}>
            <img src={searchPictoBlue} alt={'searchPicto'} className={styles.filterPicto} />
          </div>
        </div>
      );
    }
    case 'booleanSelect': {
      return (
        <div className={styles.inputContainer}>
          <Select options={booleanOptions} defaultValue="--"  value={isAmbassador} onChange={(opt, data) => selectChange(column.searchProp, data.value)} />
        </div>
      );
    }
    case 'compliantSelect': {
      return (
        <div className={styles.inputContainer}>
          <Select options={compliantOptions} value={audit} onChange={(opt, data) => selectChange(column.searchProp, data.value)} />
        </div>
      );
    }
    case 'certificateSelect': {
      return (
        <div className={styles.inputContainer}>
          <Select options={certificateOptions} value={category} onChange={(opt, data) => selectChange(column.searchProp, data.value)} />
        </div>
      );
    }
    default: {
      return (
        <></>
      );
    }
  }
}


function OrdererCell <T>({
  column,
  manageOrdering,
}: OrdererCellProps<T>) {
  const { t } = useTranslation();

  return (
    <div>
      {
      column.orderer !== '' && manageOrdering
        ? (
          <div onClick={() => manageOrdering(column.orderer)} className={styles.headerOrder}>
            <div className={styles.sortPictoContainer}>
              <img src={sortPictoBlue}  alt={'sortPicto'} className={styles.sortPicto} />
            </div>
            <div className={styles.headerLabel}>
              {t(column.header)}
            </div>
          </div>
        )
        : (
          <div className={styles.headerOrder}>
            <div className={styles.headerLabel}>
              {t(column.header)}
            </div>
          </div>
        )
    }
    </div>
  );
}
