import React, { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ResultsTableHeaderProps,
  SearchCellProps,
} from "./specification-results-table-header-type";
import styles from "./specification-results-table-header.module.css";
import { Table, TableHeaderCell, Label } from "semantic-ui-react";
import searchPictoBlue from "../../../../../assets/pictos/searchPictoBlue.png";
import { ClassicButton, FilterButton } from "../../../buttons";
import { SelectedValue } from "../../../../models";
import { posthogEvents } from "../../../../utils/posthog";

export function ResultsTableHeader<T>({
  header,
  manageOrdering,
  manageSearch,
}: ResultsTableHeaderProps<T>) {
  const { t } = useTranslation();
  console.log(header);

  return (
    <Table.Header>
      <Table.Row>
        {header.map((el, key) => (
          <Table.HeaderCell key={key}>
            <div className={styles.header}>
              {t(el.header)}
              {el.headerLabelMin ? (
                <Label className={styles.min}>{t("resultHeader.min")}</Label>
              ) : null}
              {el.headerLabelMaj ? (
                <Label className={styles.maj}>{t("resultHeader.maj")}</Label>
              ) : null}

              <div className={styles.searchWrapper}>
                {el.searchCell ? (
                  <SearchCell
                    column={el}
                    manageSearch={manageSearch}
                    searchValue={el.searchValue}
                  />
                ) : null}
              </div>
            </div>
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
}

function SearchCell<T>({
  column,
  manageSearch,
  searchValue,
}: SearchCellProps<T>) {
  const [search, setSearch] = React.useState<string | undefined>(
    searchValue === "" ? undefined : searchValue
  );
  const [inputFetcher, setInputFetcher] = React.useState<any>();

  const handleInputSubmit = (searchProp: string) => {
    if (search !== undefined && (search.length > 0 || search.length === 0)) {
      manageSearch && manageSearch(searchProp, search);
    }
  };

  const handleInputChange = async (
    searchProp: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("handleChange :", searchProp, e.target);
    e.persist();
    setSearch(e.target.value);
    if (inputFetcher) {
      clearTimeout(inputFetcher);
    }

    setInputFetcher(
      setTimeout(() => {
        manageSearch && e.target && manageSearch(searchProp, e.target.value);
      }, 500)
    );
  };

  const selectChange = (searchProp: string, value: SelectedValue) => {
    manageSearch && manageSearch(searchProp, value as string);
  };

  return (
    <div className={styles.inputContainer}>
      <input
        className={styles.input}
        onChange={(e) => handleInputChange(column.searchProp, e)}
        value={search || ""}
      />
      <div
        className={styles.button}
        onClick={() => handleInputSubmit(column.searchProp)}
      >
        <img src={searchPictoBlue} className={styles.searchPicto} />
      </div>
    </div>
  );
}
