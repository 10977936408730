import { securedRestClient, backendTokenService, restClient } from "../../backend";
import { ApiList, User, AuthenticationResult, SasToken } from "../../models";


interface RefreshResponse {
    access: string;
  }
  
export const authSubService = {
      updatePassword: async (
        oldPassword: string,
        newPassword: string,
        newPasswordConfirm: string,
      ) => await securedRestClient.post(
        '/authentication/changepassword',
        {
          old_password: oldPassword,
          new_password1: newPassword,
          new_password2: newPasswordConfirm,
        },
      ),
    
      forgetPassword: async (
        email: string,
      ) => {
        const response = await restClient.post(
          '/authentication/resetpassword',
          {
            email,
          },
        );
        console.log(response);
        return response;
      },
    
      resetPasswordConfirm: async (
        newPassword: string,
        newPasswordConfirm: string,
        id: string,
        token: string,
      ) => {
        const response = await restClient.put(
          `/authentication/resetpasswordconfirm/${id}/${token}`,
          {
            new_password1: newPassword,
            new_password2: newPasswordConfirm,
            uid: id,
            token,
          },
        );
        // console.log(response);
        return response;
      },


      getLoggedInUser: async (): Promise<ApiList<User[]>> => {
        const response = await securedRestClient.get<ApiList<User[]>>(
          '/authentication/getloggedinuser',
        );
        // console.log(response);
        return response.data;
      },
    
      logout: async () => {
        const token = backendTokenService.getToken();
        const refresh = token && token.refresh ? token.refresh : '';
        await securedRestClient.post('/authentication/logout', { refresh });
      },
    
      login: async (email: string, password: string) => {
        const response = await restClient.post<AuthenticationResult>(
          '/authentication/gettoken',
          {
            email,
            password,
          },
        );
        return response.data;
      },
    
      refreshClientToken: async () => {
        const currentToken = await backendTokenService.getToken();
        const newToken = await restClient.post<RefreshResponse>(
          '/authentication/refreshtoken',
          {
            refresh: currentToken?.refresh,
          },
        );
        return newToken.data;
      },

      getSasToken: async () => {
        const token = backendTokenService.getToken();
        const requestUrl = `/authentication/getsastoken`;
        const response = await securedRestClient.get<SasToken>(requestUrl);
        // console.log(response.data);
        return response.data;
      },
}