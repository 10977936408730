import React from 'react';

import styles from './edit-group.module.css';
import { EditGroupProps } from './edit-group-types';
import { WizardLayout } from '../../../../layouts';
import enhancer from './edit-group-enhancer';
import { GroupWizard } from '../../../../components/wizards/group-wizard';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';

const InnerPage: React.FC<EditGroupProps> = ({ group }: EditGroupProps) => (
  <WizardLayout image={backgroundLogin}>
    <div className={styles.wizardWrapper}>
      <GroupWizard initialGroup={group} />
    </div>
  </WizardLayout>
);

export const EcosystemEditGroupPage = enhancer(InnerPage);
