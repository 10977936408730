import { securedRestClient, backendTokenService } from '../backend';
import {
  GetItem,
  ApiList,
  TabInclude,
  CertificateType,
  GetItemCount,
  EntityLight, Roles
} from '../models';
import { actorSubService, siteSubService, toolSubService, productSubService, certificateSubService, relationSubService } from './sub-services';

export const ecosystemService = {

  // ===============
  // ==== UTILS ====
  // ===============

  // ----- CERTIFICATE TYPE -----

  getCertificateType: async () => {
    // console.log(
    //   'ecosystem service getCertificateType ',
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/audits/certificatetypes/`;
    const response = await securedRestClient.get<ApiList<CertificateType[]>>(requestUrl);
    // console.log(response.data);
    return response.data;
  },


  getItemById: async <P>({ id, item }: GetItem) => {
    // console.log(
    //   'ecosystem service, getItemById, id : ',
    //   id,
    //   'item',
    //   item,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/${item}/${id}`;
    const response = await securedRestClient.get<P>(requestUrl);
    return response.data;
  },

  getItemCount: async ({ id, propertyName }: GetItemCount) => {
    // console.log(
    //   'ecosystem service, getItemCount, id : ',
    //   id,
    //   'propertName:',
    //   propertyName
    // );
    const actorCount = await actorSubService.getActor({[propertyName]: id, limit:1})
    const siteCount = await siteSubService.getSite({[propertyName]: id, limit:1})
    const toolCount = await toolSubService.getTool({[propertyName]: id, limit:1})
    const productCount = await productSubService.getProduct({[propertyName]: id, limit:1})
    const certificateCount = await certificateSubService.getCertificate({[propertyName]: id, limit:1})
    const relationsCount = await relationSubService.getRelation({[propertyName]: id, limit:1})

    const result = {
      actors: actorCount.count,
      sites: siteCount.count,
      tools: toolCount.count,
      products: productCount.count,
      certificates: certificateCount.count,
      relations: relationsCount.count,
      flows: 0,
      batchs: 0
    }

    // console.log('result : ', result)

    return result
  },

  createItem: async <P>(item: P, include: TabInclude) => {
    // console.log(
    //   'ecosystem service createitem, item : ',
    //   item,
    // );
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/ecosystems/${include}/`;
    const response = await securedRestClient.post<P>(requestUrl, item);
    // console.log(response.data);
    return response.data;
  },

  getEntity: async () => {
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/entity_light/`;
    const response = await securedRestClient.get<ApiList<EntityLight[]>>( requestUrl);
    return response.data.results;
  },

  getSpecifications : async ()=> {
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/specifications/`;
    const response = await securedRestClient.get<ApiList<CertificateType[]>>( requestUrl);
    return response.data.results
  },

  getRoles : async ()=> {
    const token = backendTokenService.getToken();
    const requestUrl = `${token && token.apiVersion ? token.apiVersion : ''}/specifications/roles/`;
    const response = await securedRestClient.get<ApiList<Roles[]>>( requestUrl);
    // console.log(response.data.results);
    return response.data.results
  }

};
