import React from 'react';

import styles from './new-relation.module.css';
import { WizardLayout } from '../../../../layouts';
import { Relation, RelationWrite } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';
import { RelationWizard } from '../../../../components/wizards';


export const EcosystemNewRelationpage: React.FC = () => {
    const relation : RelationWrite = {
        owner: [],
        actor: [],
        connection: ''
    }


    return (
    <WizardLayout image={backgroundLogin}>
        <div className={styles.wizardWrapper}>
            <RelationWizard initialRelation={relation}/>
        </div>
    </WizardLayout>
    )

};