import * as React from 'react';
import { IconTileProps } from './icon-tile-type';

import styles from './icon-tile-component.module.css';
import { ClickableContainer } from '../..';
import { useHover } from '../../../hooks';

export const IconTile: React.FC<IconTileProps> = React.memo(
  ({
    link, status, title, subTitle, image,
  }: IconTileProps) => {
    const { hovered, bind } = useHover();

    return (
      <ClickableContainer
        link={link}
        onHover={bind}
      >
        <div className={`${hovered ? styles.hoveredTile : ''} ${styles.tile}`}>


          <div className={styles.tileContent}>
            {image && <div className={styles.imageContainer}><img src={image} className={styles.image} /></div>}
            <div className={styles.textWrapper}>
              {status && <div className={styles.status}>{status}</div>}
              {title && <div className={styles.title}><b>{title}</b></div>}
              {subTitle && <div className={styles.subTitle}><i>{subTitle}</i></div>}
            </div>
          </div>


        </div>
      </ClickableContainer>
    );
  },
);
