import { ApiList, GetFolders, FolderLight, GetFolder, Folder } from "../../models";
import { backendTokenService, securedRestClientContracts } from "../../backend";
import { buildUrlParameters } from "../../utils";

export const folderSubService = {
    getFolders: async () => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/folders_light/`;
        const response = await securedRestClientContracts.get<ApiList<FolderLight[]>>(requestUrl);
        console.log(response.data);
        return response.data;
    },

    getFolder: async ({id}: GetFolder) => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/folders/${id}`;
        const response = await securedRestClientContracts.get<Folder>(requestUrl);
        console.log(response.data);
        return response.data;
    },

    createFolder: async (folder: Folder) => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/folders/`;
        const response = await securedRestClientContracts.post<Folder>(requestUrl, folder);
        console.log(response.data);
        return response.data;
    },

    editFolder: async (folder: Folder) => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/folders/${folder.pk}`;
        const response = await securedRestClientContracts.put<Folder>(requestUrl, folder);
        console.log(response.data);
        return response.data;
    },

    deleteFolder: async (id: number) => {
        const token = backendTokenService.getToken();
        const requestUrl = `${
        token && token.apiVersion ? token.apiVersion : ''
        }/contracts/folders/${id}`;
        const response = await securedRestClientContracts.delete(requestUrl);
        console.log(response.data);
        return response.data;
    },
}