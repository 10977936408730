import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./home-page.module.css";
import { HomeProps } from "./home-page-types";
import { AuthenticatedContext } from "../../contexts";
import { ColorLayout, TitleLayout, ContainerLayout } from "../../layouts";
import enhancer from "./home-page-enhancer";
import { AlertPreview } from "../../models";
import { ClickableContainer } from "../../components";
import { criticalityDictionnary, FlowsHeader } from "../../constants";
import { GenericTable } from "../../components/table";
import { useLocalFilter } from "../../hooks";
import { posthogEvents } from "../../utils/posthog";

export const InnerHomePage: React.FC<HomeProps> = React.memo(
  ({ results }: HomeProps) => {
    const authenticatedContext = React.useContext(AuthenticatedContext);
    const { t } = useTranslation();
    const { flows } = results;
    const { data, header, manageOrdering, manageSearch } = useLocalFilter(
      FlowsHeader,
      flows.results
    );
    const notifications = results.alerts;
    posthogEvents.triggerPageView();
    // console.log('home page composant');
    return (
      <ColorLayout color="#0a405d">
        <ContainerLayout>
          <TitleLayout>
            {t("home.welcome")}{" "}
            {authenticatedContext.folder &&
              authenticatedContext.folder.full_name}
          </TitleLayout>

          <div className={styles.lightPaperTable}>
            <div className={styles.title}>
              <strong>{t("home.flowTitle")}</strong>
            </div>
            <div className={styles.paperContent}>
              <GenericTable
                data={data}
                header={header}
                linkType="flows"
                clickTable
                endFetching
                manageOrdering={manageOrdering}
                manageSearch={manageSearch}
              />
            </div>
          </div>
        </ContainerLayout>
        <>
          <div className={styles.footer}>
            <h6>{t("home.footer")}</h6>
          </div>
        </>
      </ColorLayout>
    );
  }
);

export const HomePage = enhancer(InnerHomePage);
