import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './relation-wizard-component.module.css';
import { relationSubService } from '../../../services';
import {
  OwnerForm, RelationProps, ToolForm, RelationForm
} from '../../forms';
import checkPictoWhite from '../../../../assets/pictos/checkPictoWhite.png';
import relationPictoBlue from '../../../../assets/pictos/RelationPictoBlue.png';
import trashPictoRed from '../../../../assets/pictos/trashPictoRed.png';
import editPictoWhite from '../../../../assets/pictos/editPictoWhite.png';
import { toastHandler } from '../../../utils';
import { WizardLoader } from '../utils';
import { redirectWithUrl } from '../utils/redirect';
import { RelationWizardProps } from './relation-wizard-type';
import { Relation, RelationWrite } from '../../../models/ecosystem';


export const RelationWizard : React.FC<RelationWizardProps> = React.memo(({ edit, initialRelation, initialStep } : RelationWizardProps) => {
    const [editedRelation, setEditedRelation] = React.useState<Omit<RelationWrite, 'owner' | 'actor'>>(initialRelation);
    const [editedOwner, setEditedOwner] = React.useState<number[]>(initialRelation.owner ? initialRelation.owner :[]);
    const [editedActor, setEditedActor] = React.useState<number[]>(initialRelation.actor ? initialRelation.actor : []);
    const [activeStep, setActiveStep] = React.useState(initialStep? initialStep : 0);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState('');
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();
    
    const nextStep = () => {
        setActiveStep(activeStep === step.length - 1 ? step.length - 1 : activeStep + 1);
    };

    const previousStep = () => {
        setActiveStep((activeStep || 1) - 1 );
    };

    const goTo = (index: number) => {
        setActiveStep(index);
    };

    const manageDeleteRelation = async () => {
        if(editedRelation.pk && editedRelation.pk !== -1){
            setLoading(true);
            try{
                const result = await relationSubService.deleteRelation(editedRelation.pk);
                toastHandler(t('wizard.relation.deleteSuccess'), 'success');
                history.push({pathname: redirectWithUrl(location.search, '/ecosystem'), state: location.state});
                setLoading(false);
            } catch(err) {
                toastHandler(t('wizard.relation.deleteError'), "error");
                setLoading(false);
            }
        }
    };

    const mannagePrevious = () => {
      previousStep();
    };

    const manageCancel = () => {
        history.push({pathname: redirectWithUrl(location.search, '/ecosystem'), state: location.state});
    };

    const onNextOwner = (owner : number[]) => {
      if(owner.length>0){
        setEditedOwner(owner);
        nextStep();
      }
    };

    const onSaveOwner = async (owner : number[]) => {
        setEditedOwner(owner);
        try{
          await onSaveGlobalRelation(owner, editedActor, editedRelation)
          nextStep();
        } catch(err){
          console.log('err : ', err)
        }
    };

    const onNextActor = (actor : number[]) => {
      setEditedActor(actor);
      nextStep();
    };

    const onSaveActor = async (actor : number[]) => {
      setEditedActor(actor);
      try{
       await onSaveGlobalRelation(editedOwner ,actor, editedRelation);
       nextStep();
      }catch(err){
        console.log('err : ', err);
      }
    };
    

    const onSaveGlobalRelation = async (owner: number[], actor: number[], relation : Omit<RelationWrite, 'owner' | 'actor'>) => {
        setLoading(true);
        try{
          if(owner.length>0 && owner[0] !== -1){
            const result : Relation = editedRelation.pk && editedRelation.pk !== -1 ? await relationSubService.editRelation({ ...relation, actor, owner}) : await relationSubService.createRelation({...relation, owner, actor});
            setLoading(false);
            toastHandler(t('wizard.relation.saveSuccess'), 'success');
            return result;
          }
            setLoading(false);
            setError('Il manque un propriétaire');
        }
        catch{
            toastHandler(t('wizard.relation.saveError'), 'error');
            setLoading(false);
            setError(t('table.utils.error'));
        }
    }

    const onSaveConnection = async (connection : Omit<RelationWrite, 'owner' | 'actor'>) => {
        setEditedRelation(connection);
        try {
            const result = await onSaveGlobalRelation(editedOwner, editedActor, connection);
            result && history.push('/ecosystem/');
        }catch{
            setError(t('table.utils.error'));
        }
    };


    const step = [
        {
            index: 0,
            label: t('wizard.relation.step1'),
            picto: relationPictoBlue,
            Component: <OwnerForm
            model={editedOwner}
            onNext={(model) => onNextOwner(model)}
            errorMessage={error}
            onCancel={manageCancel}
            include='relation'
            onSave={(model) => onSaveOwner(model)}
            blocking
            specificParams={{ordering: 'name'}}
            />
        },
        {
            index: 1,
            label: t('wizard.relation.step2'),
            picto: relationPictoBlue,
            Component: <OwnerForm
            model={editedActor}
            onNext={(model) => onNextActor(model)}
            onCancel={manageCancel}
            errorMessage={error}
            onSave={(model) => onSaveActor(model)}
            include='relation'
            blocking  
            specificParams={{ordering: 'name'}}
            />
        },
        {
            index: 2,
            label: t('wizard.relation.step3'),
            picto: relationPictoBlue,
            Component: <RelationForm
              model={editedRelation}
              onSave={(model) => onSaveConnection(model)}
              errorMessage={error}
              onPrevious={previousStep}
              onCancel={manageCancel}
              // onNext={editedRelation.pk && editedRelation.pk !== -1 ? (model) => onNextCertificate(model) : undefined}
              />
        }
    ];


  return (
    <div className={styles.wizard}>
      <div className={styles.form}>
        <div className={styles.activeStep}>
          <div className={styles.header}>
            <div className={styles.titlePictoContainer}><img src={step[activeStep].picto} className={styles.titlePicto} /></div>
            <div className={styles.titleLabel}><b>{step[activeStep].label}</b></div>
            {editedRelation.pk && editedRelation.pk !== -1
              && (
                <div className={styles.deleteContainer} onClick={manageDeleteRelation}>
                  <div className={styles.trashPictoContainer}><img src={trashPictoRed} className={styles.trashPicto} /></div>
                  <div className={styles.deleteLabel}>{t('wizard.relation.delete')}</div>
                </div>
              )}
          </div>
          <div className={`${styles.formWrapper} ${loading ? styles.blured : ''}`}>
            {loading && <WizardLoader />}
            {step[activeStep].Component}
          </div>
        </div>
      </div>
      <div className={styles.track}>
        {
          step.map((el) => (
            activeStep === el.index
              ? (
                <div className={`${styles.container} ${styles.activeContainer}`} key={el.index}>
                  <div className={`${styles.badge} ${styles.activeBadge}`}>{el.index + 1}</div>
                  <div className={`${styles.label} ${styles.activeLabel}`}>{el.label}</div>
                </div>
              )
              : editedRelation.pk
                ? (
                  <div className={`${styles.container} ${styles.editContainer}`} key={el.index} onClick={() => goTo(el.index)}>
                    <div className={`${styles.badge} ${styles.editBadge}`}><img src={editPictoWhite} className={styles.editPicto} /></div>
                    <div className={`${styles.label} ${styles.editLabel}`}>{el.label}</div>
                  </div>
                )
                : activeStep > el.index
                  ? (
                    <div className={`${styles.container} ${styles.doneContainer}`} key={el.index}>
                      <div className={`${styles.badge} ${styles.doneBadge}`}><img src={checkPictoWhite} className={styles.checPicto} /></div>
                      <div className={`${styles.label} ${styles.doneLabel}`}>{el.label}</div>
                    </div>
                  )
                  : (
                    <div className={`${styles.container} ${styles.todoContainer}`} key={el.index}>
                      <div className={`${styles.badge} ${styles.todoBadge}`}>{el.index + 1}</div>
                      <div className={`${styles.label} ${styles.todoLabel}`}>{el.label}</div>
                    </div>
                  )
          ))
        }
      </div>
    </div>
  );
});
