import React from 'react';

import styles from './edit-tool.module.css';
import { EditToolProps } from './edit-tool-types';
import { WizardLayout } from '../../../../layouts';
import enhancer from './edit-tool-enhancer';
import { ToolRead, ToolWrite } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';
import { ToolWizard } from '../../../../components/wizards';
import { getParam } from '../../../../components/wizards/utils';
import { useLocation } from 'react-router-dom';

const InnerPage: React.FC<EditToolProps> = ({ tools }: EditToolProps) => {
  const location = useLocation();
  if (tools.length === 0) {
    return <></>;
  }
  const tool = tools as unknown as ToolRead;
  const properTool: ToolWrite = {
    ...tool,
    owner: tool.owner.pk,
  };

  const urlStep = getParam(location.search.substring(1), 'step');
  const initialStep:number = urlStep ? parseInt(urlStep) : 0;

  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.wizardWrapper}>
        <ToolWizard initialStep={initialStep} edit initialTool={properTool} />
      </div>
    </WizardLayout>
  );
};

export const EcosystemEditToolPage = enhancer(InnerPage);
