import * as React from 'react';
import { Container } from 'semantic-ui-react';
import { ErrorMessageProps } from './error-message-type';
import { useTranslation } from 'react-i18next';


import styles from './error-message-component.module.css';

export const ErrorMessage: React.FC<ErrorMessageProps> = React.memo(
  ({ error }: ErrorMessageProps) => {
  const { t } = useTranslation()
  return (
    <>
      <div>
        <Container className={styles.container}>
          <div>{t('alerts.retrack.error')}</div>
          <div>
            <div className={styles.message}>{error}</div>
          </div>
        </Container>
      </div>
    </>
  )
  }
);
