import React from 'react';
import styles from './edit-site.module.css';
import { EditSiteProps } from './edit-site-types';
import { SiteWizard } from '../../../../components';
import { WizardLayout } from '../../../../layouts';
import enhancer from './edit-site-enhancer';
import { SiteRead, SiteWrite } from '../../../../models';
import backgroundLogin from '../../../../../assets/images/backgroundLogin.jpg';
import { getParam } from '../../../../components/wizards/utils';
import { useLocation } from 'react-router-dom';

const InnerPage: React.FC<EditSiteProps> = ({ sites }: EditSiteProps) => {
  const location = useLocation();
  if (sites.length === 0) {
    return <></>;
  }
  const site = sites as unknown as SiteRead;
  const properSite: SiteWrite = {
    ...site,
    owner: site.owner.pk,
  };

  const urlStep = getParam(location.search.substring(1), 'step');
  const initialStep:number = urlStep ? parseInt(urlStep) : 0;

  return (
    <WizardLayout image={backgroundLogin}>
      <div className={styles.wizardWrapper}>
        <SiteWizard initialStep={initialStep} edit initialSite={properSite} />
      </div>
    </WizardLayout>
  );
};

export const EcosystemEditSitePage = enhancer(InnerPage);
