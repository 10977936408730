import {
  ApiList,
  ContractIndicatorsDetails,
  GetContractIndicatorsDetails,
} from "../../models";
import { backendTokenService, securedRestClientContracts } from "../../backend";
import { buildUrlParameters } from "../../utils";

export const contractSubService = {
  getContractIndicatorsDetails: async ({
    pk,
  }: GetContractIndicatorsDetails) => {
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ""
    }/contracts/indicators_details/${pk}`;
    const response = await securedRestClientContracts.get<
      ApiList<ContractIndicatorsDetails>
    >(requestUrl);
    console.log(response.data);
    return response.data;
  },
};
