import React from 'react';
import styles from './list-tool-form.module.css';
import { ListToolFormProps } from './list-tool-form-types';
import { toolSubService } from '../../../services';
import { ToolRead } from '../../../models';
import { ToolsHeader } from '../../../constants';
import { useParams } from '../../../hooks/use-params';
import { WithLazyloadingDatasFetching } from '../../../hocs/with-lazyloading-datas-fetching';
import { TableForm, TableFormProps } from '../table-form';

export const ListToolForm: React.FC<ListToolFormProps> = React.memo(({
  model, onSave, errorMessage, onCancel, specificParams, onPrevious, onNext, include, blocking, multiple,
}: ListToolFormProps) => {
  const {
    params, header, manageOrdering, manageSearch, focused
  } = useParams(ToolsHeader, specificParams);
  const [editedModel, setEditedModel] = React.useState(model);

  const handleAll = React.useCallback((add: boolean) => {
    const fetchAllItems = async (add: boolean) => {
      try {
        const result = await toolSubService.getTool({ ...params, limit: 100000 });
        const idList = result.results.map((el: any) => el.pk).filter((el) => el !== null);
        setEditedModel(idList as number[]);
      } catch {
        console.log('Une erreur est survenu');
      }
    };
    if (add) {
      fetchAllItems(add);
    } else {
      setEditedModel([]);
    }
  }, [params]);


  const EnhancedTableForm = WithLazyloadingDatasFetching<ToolRead, TableFormProps<ToolRead>>(
    toolSubService.getTool, 'data', params, params.limit ? parseInt(params.limit) : 100,
  )(TableForm);
  return (
    <div className={styles.tableWrapper}>
      <EnhancedTableForm
        data={[]}
        header={header}
        manageOrdering={manageOrdering}
        manageSearch={manageSearch}
        focused={focused}
        model={editedModel}
        onSave={onSave}
        errorMessage={errorMessage}
        onCancel={onCancel}
        onNext={onNext}
        onPrevious={onPrevious}
        include={include}
        blocking={blocking}
        multiple={multiple}
        handleAll={multiple ? handleAll : undefined}
      />
    </div>
  );
});
