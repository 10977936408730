import { backendTokenService, securedRestClient } from '../../backend';
import { ApiList, AuditResult, GetAllAudit } from '../../models';
import { stringify } from 'querystring';
import { buildUrlParameters } from '../../utils';

export const auditSubService = {
  getAllAudit: async ({ flow, is_archived}: GetAllAudit) => {
    // console.log(
    //   'ecosystem service, getAllAudit, flow : ',
    //   flow, is_archived
    // );
    const params:{prop: string; value: string | undefined}[]= [
      {prop:'flow', value: flow},
      {prop: 'is_archived', value: is_archived}
    ]
    const paramUrl = buildUrlParameters(params);
    const token = backendTokenService.getToken();
    const requestUrl = `${
      token && token.apiVersion ? token.apiVersion : ''
    }/audits/results/?${paramUrl}`;
    const response = await securedRestClient.get<ApiList<AuditResult[]>>(requestUrl);
    // console.log(response.data);
    return response.data;
  },
};
