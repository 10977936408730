import { useState } from 'react';

interface UseHoverProps {
    hovered: boolean;
    bind: {
        onMouseEnter: () => void;
        onMouseLeave: () => void;
    };
}

export const useHover = (): UseHoverProps => {
  const [hovered, set] = useState(false);
  return {
    hovered,
    bind: {
      onMouseEnter: () => set(true),
      onMouseLeave: () => set(false),
    },
  };
};
