import React from 'react';

import styles from './tool.module.css';
import { ToolProps } from './tool-types';
import enhancer from './tool-enhancer';
import { ToolRead } from '../../../../models';
import { EcosystemItem } from '../../item/item';
import { useTranslation } from 'react-i18next';

import toolPictoWhite from '../../../../../assets/pictos/toolPictoWhite.svg';
import { Panels } from '../../utils/model';
import { productPanel, actorPanel, sitePanel, initialCount } from '../../utils/constant';

const InnerPage: React.FC<ToolProps> = ({ tools, id }: ToolProps) => {
  const {t} = useTranslation();
  if (tools.length === 0) {
    return <></>;
  }

  const tool = tools as unknown as ToolRead;

  const panels:Panels = {actors: actorPanel, sites: sitePanel, products: productPanel}

  const itemLight = {
    description: [
      {
        label: t('form.tools.internal_code'),
        value: tool.internal_code,
      },
      {
        label: t('form.tools.tool_type'),
        value: tool.tool_type,
      },
      {
        label: t('form.tools.capacity'),
        value: tool.capacity,
      },
      {
        label: t('form.tools.unit'),
        value: tool.unit,
      },
    ].filter((el) => el.value !== undefined && el.value !== ''),
    title: `${t('ecosystem.tools.title')} ${tool.name}`,
    picto: toolPictoWhite,
    permissionRoute: '/ecosystem/tool:update',
    link: `/ecosystem/edit-tool/${tool.pk}`
  }
  
  return (
    <EcosystemItem panels={panels} itemLight={itemLight} id={id} specificParams={{tool: id}} propertyName="tool" counts={initialCount} />
  );
};

export const EcosystemToolPage = enhancer(InnerPage);

