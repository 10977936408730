import React from 'react';
import styles from './comment-bloc.module.css'
import { formatTime, formatDate } from '../../../utils';
import i18next from 'i18next';
import { CommentBlocProps } from './comment-bloc-type';
import { useTranslation } from 'react-i18next';


export const CommentBloc : React.FC<CommentBlocProps> = (props : CommentBlocProps) => {
    const {author, description, date} = props;
    const {t} = useTranslation();

    return  <div className={styles.comment}>
                <div className={styles.commentAuthor}>{`${author} ${t('alerts.alertHistory.commented')}`}</div> 
                <div className={styles.commentContent}>{description}</div> <div className={styles.commentDate}>{`le ${formatDate(date, i18next.language)} à ${formatTime(date, i18next.language)}`}</div>  
            </div>

    // return <div className={styles.commentBloc}>
    //     <div className={styles.commentHeader}>
    //         <div className={styles.commentAuthor}>{`${author} à commenté`}</div></div>
    //         <div className={styles.comment}>
    //             <div className={styles.commentContent}>{description}</div>
    //             <div className={styles.commentDate}>{`le ${formatDate(date, i18next.language)} à ${formatTime(date, i18next.language)}`}</div>
    //         </div>
    // </div>
}