import React from 'react';
import styles from './light-item-component.module.css';
import { ClickableContainer, GenericList } from '../../../../components';
import editPictoWhite from '../../../../../assets/pictos/editPictoWhite.png';
import eyeIcon from '../../../../../assets/pictos/eyePassword.png';
import { checkPermission } from '../../../../roles';
import { AuthenticatedContext } from '../../../../contexts';
import { LightItemProps } from './light-item-type';
import { SimpleCard } from '../../../../models';
import { useTranslation } from 'react-i18next';

export const LightItem: React.FC<LightItemProps> = React.memo(({
  description,
  title,
  picto,
  permissionRoute,
  link,
  document
 }: LightItemProps): JSX.Element => {
  const { folder } = React.useContext(AuthenticatedContext);
  const {t} = useTranslation();
  const authenticatedContext = React.useContext(AuthenticatedContext);
  return (
    <div className={styles.itemContainer}>
      <div className={styles.itemHeader}>
        <div className={styles.itemPictoContainer}>
          <img src={picto} className={styles.itemPicto} />
          <b>{title}</b>        
        </div> 
        <div className={styles.editContainer}>
          {
            folder && checkPermission(folder.permission, permissionRoute, {})
            && (
                <ClickableContainer className="clickable" link={link}>
                  <img src={editPictoWhite} className={styles.editPicto} />
                </ClickableContainer>)
          }
          {
            document && document !== "" && 
                  <ClickableContainer className={styles.fileAvailable} link={`${document}?${authenticatedContext.azure}`}>
                  <img src={eyeIcon} className={styles.eyeIcon} /><div className={styles.iconText}><b>{t('tracking.flows.file')}</b></div>
                </ClickableContainer>
          }    
          </div>
      </div>
      <div className={styles.itemContent}>
        <GenericList
          items={description}
          itemRenderer={(childElement: SimpleCard, key: number) => (
            <div className={styles.information} key={key}>
              <b>
                {childElement.label}
:&nbsp;
              </b>
              {childElement.value}
            </div>
          )}
        />
      </div>
    </div>
  );
});
