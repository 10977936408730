import React from 'react';

import { Placeholder, Card } from 'semantic-ui-react';
import styles from './ecosystem-page.module.css';

export const EcosystemPageLoader: React.FC = React.memo(() => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <Placeholder fluid>
        <Placeholder.Header image>
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
        </Placeholder.Header>
      </Placeholder>
    </div>

    <div className={styles.cards}>
      <Card.Group itemsPerRow={1}>
        <Card className={styles.top2}>
          <Card.Content>
            <Placeholder fluid={false} className={styles.top}>
              <Placeholder.Image />
            </Placeholder>
          </Card.Content>
        </Card>
        <Card>
          <Card.Content>
            <Placeholder className={styles.top}>
              <Placeholder.Image />
            </Placeholder>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  </div>
));
